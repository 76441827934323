
<template>
    <!--해더 컴포넌트의 템플릿 부분 -->
    <header class="header position-relative">
        <b-container>
            <div class="d-flex justify-content-center">
                <b-row align-v="center">
                    <b-col>
                        <!--img :src="require(`@/assets/evironment.png`)" alt="환경부 로고" /-->
                    </b-col>
                    <b-col md="auto">
                        <h1 class="header-title mb-0">WEMS(Water Energy Management System)</h1>
                    </b-col>
                    <b-col>
                        <!--img :src="require(`@/assets/kwater.png`)" alt="k-water 로고" /-->
                    </b-col>
                </b-row>
                <div class="header-bottom"></div>
            </div>
        </b-container>
    </header>
</template>

<script>
</script>

<style scoped>
/* 컴포넌트에만 적용되는 스타일 정의 */
</style>
