<template>

  <div class="blinking fL" ></div>

</template>

<script>
export default {
    props:[
     
    ]
}
</script>

<style scoped>
.blinking.fL{
	width: 60%;
    height: 100%;
    background: url("@/assets/img/ai_arrow_right.png") no-repeat;
    background-position: center;
    background-size: 80% 60%;
    mix-blend-mode: color-dodge;
}
</style>