import { createRouter, createWebHistory } from "vue-router";
//import Schedule from "../views/Schedule/Schedule.vue";
import MainDashBoard from "../views/DashBoard/MainDashBoard.vue";
import WaterTankMst from "../views/Setting/WaterTankMst.vue";

const routes = [

  {  path: '/',   name: 'MainDashBoard',   component: MainDashBoard  },
  //{  path: '/',   name: 'Schedule_',   component: Schedule  },
  {  path: '/WaterTankMst',  name: 'WaterTankMst',  component: WaterTankMst },  
 

 {  path: '/ScheduleHour',   name: 'ScheduleHour',    component: () => import( "../views/Schedule/ScheduleHour.vue" ) },
 //{  path: '/ScheduleHour2',   name: 'ScheduleHour2',    component: () => import( "../views/Schedule/ScheduleHour2.vue" ) },
 {  path: '/Schedule',  name: 'Schedule',  component:  () => import( "../views/Schedule/Schedule.vue" )},
 /*
 {  path: '/dashboard1',   name: 'Dashboard_1',   component:  () => import( "../views/DashBoard/MainDashBoard_1.vue") },

 //{  path: '/GisDashboard',    name: 'GisDashboard',    component:  () => import( "../views/DashBoard/GISDashBoard.vue" )},
 {  path: '/MapMonitoring',    name: 'MapMonitoring',    component:  () => import( "../views/DashBoard/MapMonitoring.vue" ) },
 {  path: '/Simulation',  name: 'Simulation',  component:  () => import( "../views/Simulation/GIS_Simulation.vue" )},
 {  path: '/simulation-detail',  name: 'SimulationDetail',  component:  () => import( "../views/Simulation/GIS_SimulationDetail.vue") },
 {  path: '/Schedule',  name: 'Schedule',  component:  () => import( "../views/DashBoard/MainDashBoard.vue" )},
 {  path: '/PumpStation',  name: 'PumpStation',  component:  () => import( "../views/PumpStation/PumpStationMst.vue" )},
 {  path: '/PumpStatus',  name: 'PumpStatus',  component:  () => import( "../views/PumpStatus/PumpStatusMst.vue" )},

 {  path: '/WaterTank',  name: 'WaterTank',  component:  () => import( "../views/WaterTank/WaterTankMst.vue" ) },
 */
 {  path: '/dashboard',   name: 'Dashboard',    component: () => import( "../views/DashBoard/MainDashBoard.vue" ) },
 {  path: '/TagInfo',  name: 'TagMaster',  component:  () => import( "../views/Setting/TagMaster.vue" ) },
 {  path: '/LogHistory',  name: 'LogHistory',  component:  () => import( "../views/Setting/LogHistory.vue" ) },
 {  path: '/FlowForecastLog',  name: 'FlowLog',  component:  () => import( "../views/Setting/FlowForecastLog.vue" ) },
 //{  path: '/EmsModelMst',  name: 'EmsModelMst',  component:  () => import( "../views/Setting/EmsModel.vue" ) },
 
 {  path: '/QTableMst',  name: 'QTableMst',  component:  () => import( "../views/Setting/QTableMst.vue" ) },
 {  path: '/PumpControl',  name: 'Analysis',  component:  () => import( "../views/Analysis/Analysis.vue" ) },
 {  path: '/PumpControlDetailed',  name: 'AnalysisDetail',  component:  () => import( "../views/Analysis/AnalysisDetail.vue" ) },
 {  path: '/PumpHistory',  name: 'pumpPerform',  component:  () => import( "../views/PumpStatus/pumpPerform.vue" ) },
 {  path: '/PumpControlTrand',  name: 'sujiSelect',  component:  () => import( "../views/WaterTank/WaterTankTrend.vue" ) },
 {  path: '/MajorDrainage',  name: 'sujiSelect1',  component:  () => import( "../views/WaterTank/WaterTankTrend1.vue" ) },
 {  path: '/PowerPeakAnalysis',  name: 'peak',  component:  () => import( "../views/PeakEnergy/PeakEnergyTrend.vue" ) },
 {  path: '/PowerPeakDetail',  name: 'peakDetail',  component:  () => import( "../views/PeakEnergy/PeakEnergyDetail.vue" ) },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
