<script setup>
	import { ref } from 'vue';
	import Circle from './objects/CircleComponent.vue';
	import DrainField from './objects/DrainFieldComponent.vue';
	// import Pump from './objects/PumpComponent.vue';
	import anime from 'animejs/lib/anime.es.js';
	import $ from "jquery";

</script>
<template>
    
 <svg
	version="1.1"
	id="svg2"
	width="100%"
	transform="scale(1.0,1.0)"
	viewBox="0 0 1300 628"
	xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
	xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:svg="http://www.w3.org/2000/svg">
   <defs
	  id="defs6">
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(29.767006,0,0,-29.767006,233.6315,416.10516)"
		spreadMethod="pad"
		id="linearGradient106">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop92" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop94" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop96" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop98" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop100" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop102" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop104" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(24.805799,0,0,-24.805799,236.11189,419.99866)"
		spreadMethod="pad"
		id="linearGradient132">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop122" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop124" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop126" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop128" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop130" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(5.8301568,16.924423,16.924423,-5.8301568,245.33037,409.41904)"
		spreadMethod="pad"
		id="linearGradient154">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop148" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop150" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop152" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(29.767006,0,0,-29.767006,233.6315,419.99866)"
		spreadMethod="pad"
		id="linearGradient180">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop170" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop172" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop174" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop176" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop178" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(29.767105,0,0,-29.767105,200.8907,395.51254)"
		spreadMethod="pad"
		id="linearGradient210">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop196" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop198" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop200" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop202" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop204" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop206" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop208" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(24.805799,0,0,-24.805799,203.37109,399.40604)"
		spreadMethod="pad"
		id="linearGradient236">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop226" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop228" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop230" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop232" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop234" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(5.8301315,16.924351,16.924351,-5.8301315,212.5896,388.82651)"
		spreadMethod="pad"
		id="linearGradient258">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop252" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop254" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop256" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(29.767105,0,0,-29.767105,200.8907,399.40604)"
		spreadMethod="pad"
		id="linearGradient284">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop274" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop276" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop278" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop280" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop282" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(21.4748,0,0,-21.4748,328.66959,342.646)"
		spreadMethod="pad"
		id="linearGradient314">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop300" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop302" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop304" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop306" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop308" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop310" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop312" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(17.895508,0,0,-17.895508,330.45911,345.45486)"
		spreadMethod="pad"
		id="linearGradient340">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop330" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop332" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop334" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop336" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop338" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(4.206008,12.209664,12.209664,-4.206008,337.10953,337.82245)"
		spreadMethod="pad"
		id="linearGradient362">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop356" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop358" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop360" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(21.4748,0,0,-21.4748,328.66959,345.45486)"
		spreadMethod="pad"
		id="linearGradient388">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop378" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop380" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop382" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop384" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop386" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath398">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path396" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(40.923424,0,0,-40.923424,152.99727,361.70895)"
		spreadMethod="pad"
		id="linearGradient442">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop428" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop430" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop432" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop434" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop436" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop438" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop440" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(34.102726,0,0,-34.102726,156.40727,367.06165)"
		spreadMethod="pad"
		id="linearGradient468">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop458" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop460" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop462" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop464" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop466" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(8.015192,23.267385,23.267385,-8.015192,169.08075,352.51703)"
		spreadMethod="pad"
		id="linearGradient490">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop484" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop486" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop488" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(40.923424,0,0,-40.923424,152.99727,367.06171)"
		spreadMethod="pad"
		id="linearGradient516">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop506" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop508" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop510" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop512" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop514" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(34.059521,0,0,-34.059521,157.21858,320.34195)"
		spreadMethod="pad"
		id="linearGradient546">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop532" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop534" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop536" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop538" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop540" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop542" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop544" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(28.382805,0,0,-28.382805,160.05659,324.79691)"
		spreadMethod="pad"
		id="linearGradient572">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop562" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop564" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop566" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop568" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop570" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(6.6708207,19.364796,19.364796,-6.6708207,170.60442,312.69177)"
		spreadMethod="pad"
		id="linearGradient594">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop588" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop590" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop592" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(34.059521,0,0,-34.059521,157.21858,324.79691)"
		spreadMethod="pad"
		id="linearGradient620">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop610" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop612" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop614" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop616" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop618" />
	 </linearGradient>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(33.944282,0,0,-33.944282,506.04529,322.52194)"
		spreadMethod="pad"
		id="radialGradient646">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop636" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop638" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop640" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop642" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop644" />
	 </radialGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.6137724,-2.7951357,-2.7951357,1.6137724,530.58752,345.99121)"
		spreadMethod="pad"
		id="linearGradient676">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop662" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.0932978"
		  id="stop664" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.18136494"
		  id="stop666" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.524399"
		  id="stop668" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.55181052"
		  id="stop670" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.902476"
		  id="stop672" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop674" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.6137724,-2.7951357,-2.7951357,1.6137724,530.58752,352.26501)"
		spreadMethod="pad"
		id="linearGradient706">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop692" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.0932978"
		  id="stop694" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.18136494"
		  id="stop696" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.524399"
		  id="stop698" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.55181052"
		  id="stop700" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.902476"
		  id="stop702" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop704" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.6138109,-2.7952025,-2.7952025,1.6138109,530.58752,339.71759)"
		spreadMethod="pad"
		id="linearGradient736">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop722" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.0932978"
		  id="stop724" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.18136494"
		  id="stop726" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.524399"
		  id="stop728" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.55181052"
		  id="stop730" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.902476"
		  id="stop732" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop734" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.4595394,-2.5279963,-2.5279963,-1.4595394,487.34131,342.71585)"
		spreadMethod="pad"
		id="linearGradient762">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop752" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.10082117"
		  id="stop754" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.493534"
		  id="stop756" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.53025404"
		  id="stop758" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop760" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.4595047,-2.5279362,-2.5279362,-1.4595047,487.34137,348.98956)"
		spreadMethod="pad"
		id="linearGradient788">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop778" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.10082117"
		  id="stop780" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.493534"
		  id="stop782" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.53025404"
		  id="stop784" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop786" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.4595394,-2.5279963,-2.5279963,-1.4595394,487.34134,336.44214)"
		spreadMethod="pad"
		id="linearGradient814">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop804" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.10082117"
		  id="stop806" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.493534"
		  id="stop808" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.53025404"
		  id="stop810" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop812" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath824">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path822" />
	 </clipPath>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath832">
	   <path
		  d="m 464.477,363.263 h 47.502 v -44.142 h -47.502 z"
		  id="path830" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-17.173613,32.206947,-32.21339,-17.177048,496.34702,331.46078)"
		spreadMethod="pad"
		id="radialGradient848">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop838" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop840" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop842" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop844" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop846" />
	 </radialGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath870">
	   <path
		  d="M 511.979,363.274 H 547.62 V 325.966 H 511.979 Z"
		  id="path868" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(13.111694,24.584413,24.584413,-13.111694,523.70819,336.39551)"
		spreadMethod="pad"
		id="radialGradient886">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop876" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop878" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop880" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop882" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop884" />
	 </radialGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.4523971,-2.5156255,-2.5156255,-1.4523971,522.9834,322.13138)"
		spreadMethod="pad"
		id="linearGradient924">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop914" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop916" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop918" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop920" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop922" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.4523971,-2.5156255,-2.5156255,-1.4523971,522.9834,315.84659)"
		spreadMethod="pad"
		id="linearGradient950">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop940" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop942" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop944" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop946" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop948" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.4524318,-2.5156856,-2.5156856,-1.4524318,522.9834,328.40518)"
		spreadMethod="pad"
		id="linearGradient976">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop966" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop968" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop970" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop972" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop974" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.6213857,-2.8083224,-2.8083224,1.6213857,483.15454,324.95685)"
		spreadMethod="pad"
		id="linearGradient1002">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop992" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop994" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop996" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop998" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1000" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.6213857,-2.8083224,-2.8083224,1.6213857,483.15454,318.69986)"
		spreadMethod="pad"
		id="linearGradient1028">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1018" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop1020" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop1022" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop1024" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1026" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.6213857,-2.8083224,-2.8083224,1.6213857,483.15454,312.40936)"
		spreadMethod="pad"
		id="linearGradient1054">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1044" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop1046" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop1048" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop1050" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1052" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath1064">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path1062" />
	 </clipPath>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath1072">
	   <path
		  d="m 464.474,363.263 h 83.143 v -48.01 h -83.143 z"
		  id="path1070" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(33.944263,0,0,-33.944263,506.04529,339.25809)"
		spreadMethod="pad"
		id="radialGradient1088">
	   <stop
		  style="stop-opacity:1;stop-color:#4260ab"
		  offset="0"
		  id="stop1078" />
	   <stop
		  style="stop-opacity:1;stop-color:#243d83"
		  offset="0.42132691"
		  id="stop1080" />
	   <stop
		  style="stop-opacity:1;stop-color:#243d83"
		  offset="0.488735"
		  id="stop1082" />
	   <stop
		  style="stop-opacity:1;stop-color:#243d83"
		  offset="0.58831037"
		  id="stop1084" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop1086" />
	 </radialGradient>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(29.12216,0,0,-29.12216,393.24084,236.86391)"
		spreadMethod="pad"
		id="radialGradient1150">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop1140" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop1142" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop1144" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop1146" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop1148" />
	 </radialGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.118592,-3.6695089,-3.6695089,2.118592,418.16165,262.76282)"
		spreadMethod="pad"
		id="linearGradient1180">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop1166" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.0932978"
		  id="stop1168" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.18136494"
		  id="stop1170" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.524399"
		  id="stop1172" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.55181052"
		  id="stop1174" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.902476"
		  id="stop1176" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1178" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.118592,-3.6695089,-3.6695089,2.118592,418.16165,270.53381)"
		spreadMethod="pad"
		id="linearGradient1210">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop1196" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.0932978"
		  id="stop1198" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.18136494"
		  id="stop1200" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.524399"
		  id="stop1202" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.55181052"
		  id="stop1204" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.902476"
		  id="stop1206" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1208" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.118592,-3.6695089,-3.6695089,2.118592,418.16165,254.99191)"
		spreadMethod="pad"
		id="linearGradient1240">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop1226" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.0932978"
		  id="stop1228" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.18136494"
		  id="stop1230" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.524399"
		  id="stop1232" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.55181052"
		  id="stop1234" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.902476"
		  id="stop1236" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1238" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.7997509,-3.11726,-3.11726,-1.7997509,380.40247,255.97296)"
		spreadMethod="pad"
		id="linearGradient1266">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop1256" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.10082117"
		  id="stop1258" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.493534"
		  id="stop1260" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.53025404"
		  id="stop1262" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1264" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.7997509,-3.11726,-3.11726,-1.7997509,380.40247,263.74396)"
		spreadMethod="pad"
		id="linearGradient1292">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop1282" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.10082117"
		  id="stop1284" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.493534"
		  id="stop1286" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.53025404"
		  id="stop1288" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1290" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.7997509,-3.11726,-3.11726,-1.7997509,380.40247,248.20206)"
		spreadMethod="pad"
		id="linearGradient1318">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop1308" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.10082117"
		  id="stop1310" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.493534"
		  id="stop1312" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.53025404"
		  id="stop1314" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1316" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath1328">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path1326" />
	 </clipPath>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath1336">
	   <path
		  d="m 357.614,278.25 h 47.764 v -48.312 h -47.764 z"
		  id="path1334" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-17.474882,32.771942,-32.7785,-17.478378,389.65964,243.44373)"
		spreadMethod="pad"
		id="radialGradient1352">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop1342" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop1344" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop1346" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop1348" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop1350" />
	 </radialGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath1374">
	   <path
		  d="m 405.378,278.263 h 23.498 v -34.601 h -23.498 z"
		  id="path1372" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(9.2961578,17.430284,17.430284,-9.2961578,413.11087,253.33464)"
		spreadMethod="pad"
		id="radialGradient1390">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop1380" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop1382" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop1384" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop1386" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop1388" />
	 </radialGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.798987,-3.115937,-3.115937,-1.798987,404.02905,242.18526)"
		spreadMethod="pad"
		id="linearGradient1428">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1418" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop1420" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop1422" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop1424" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1426" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.7990217,-3.1159971,-3.1159971,-1.7990217,404.02902,234.40065)"
		spreadMethod="pad"
		id="linearGradient1454">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1444" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop1446" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop1448" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop1450" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1452" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.798987,-3.115937,-3.115937,-1.798987,404.02905,249.95625)"
		spreadMethod="pad"
		id="linearGradient1480">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1470" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop1472" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop1474" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop1476" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1478" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.9997511,-3.4636705,-3.4636705,1.9997511,370.55209,243.05476)"
		spreadMethod="pad"
		id="linearGradient1506">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1496" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop1498" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop1500" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop1502" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1504" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.9997511,-3.4636705,-3.4636705,1.9997511,370.55209,235.30455)"
		spreadMethod="pad"
		id="linearGradient1532">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1522" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop1524" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop1526" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop1528" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1530" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.9997511,-3.4636705,-3.4636705,1.9997511,370.55209,227.51286)"
		spreadMethod="pad"
		id="linearGradient1558">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1548" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop1550" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop1552" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop1554" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop1556" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath1568">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path1566" />
	 </clipPath>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath1576">
	   <path
		  d="m 357.61,278.25 h 71.262 V 236.939 H 357.61 Z"
		  id="path1574" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(29.12216,0,0,-29.12216,393.24084,257.59409)"
		spreadMethod="pad"
		id="radialGradient1592">
	   <stop
		  style="stop-opacity:1;stop-color:#4260ab"
		  offset="0"
		  id="stop1582" />
	   <stop
		  style="stop-opacity:1;stop-color:#243d83"
		  offset="0.42132691"
		  id="stop1584" />
	   <stop
		  style="stop-opacity:1;stop-color:#243d83"
		  offset="0.488735"
		  id="stop1586" />
	   <stop
		  style="stop-opacity:1;stop-color:#243d83"
		  offset="0.58831037"
		  id="stop1588" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop1590" />
	 </radialGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(35.841701,0,0,-35.841701,262.60449,189.6219)"
		spreadMethod="pad"
		id="linearGradient1654">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1640" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop1642" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop1644" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop1646" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop1648" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop1650" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop1652" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(29.867907,0,0,-29.867907,265.59109,194.30995)"
		spreadMethod="pad"
		id="linearGradient1680">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop1670" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop1672" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop1674" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop1676" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop1678" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(7.0198913,20.378117,20.378117,-7.0198913,276.6908,181.57135)"
		spreadMethod="pad"
		id="linearGradient1702">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1696" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop1698" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop1700" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(35.841701,0,0,-35.841701,262.60449,194.30991)"
		spreadMethod="pad"
		id="linearGradient1728">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop1718" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop1720" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop1722" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop1724" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop1726" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(9.1792002,0,0,-9.1792002,216.4166,205.21754)"
		spreadMethod="pad"
		id="linearGradient1758">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1744" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop1746" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop1748" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop1750" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop1752" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop1754" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop1756" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(7.6492944,0,0,-7.6492944,217.1815,206.4182)"
		spreadMethod="pad"
		id="linearGradient1784">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop1774" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop1776" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop1778" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop1780" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop1782" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.7978452,5.2189841,5.2189841,-1.7978452,220.02415,203.15575)"
		spreadMethod="pad"
		id="linearGradient1806">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1800" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop1802" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop1804" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(9.1792002,0,0,-9.1792002,216.4166,206.4182)"
		spreadMethod="pad"
		id="linearGradient1832">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop1822" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop1824" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop1826" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop1828" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop1830" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath1842">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path1840" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(13.913125,0,0,-13.913125,148.72307,187.5769)"
		spreadMethod="pad"
		id="linearGradient1882">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1868" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop1870" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop1872" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop1874" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop1876" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop1878" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop1880" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(11.594325,0,0,-11.594325,149.88237,189.3967)"
		spreadMethod="pad"
		id="linearGradient1908">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop1898" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop1900" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop1902" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop1904" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop1906" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7249875,7.9103947,7.9103947,-2.7249875,154.19116,184.45186)"
		spreadMethod="pad"
		id="linearGradient1930">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1924" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop1926" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop1928" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(13.913125,0,0,-13.913125,148.72307,189.3967)"
		spreadMethod="pad"
		id="linearGradient1956">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop1946" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop1948" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop1950" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop1952" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop1954" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(13.913099,0,0,-13.913099,184.3398,167.0136)"
		spreadMethod="pad"
		id="linearGradient1986">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop1972" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop1974" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop1976" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop1978" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop1980" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop1982" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop1984" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(11.594195,0,0,-11.594195,185.49921,168.8334)"
		spreadMethod="pad"
		id="linearGradient2012">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop2002" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop2004" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop2006" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop2008" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop2010" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7250075,7.9104533,7.9104533,-2.7250075,189.80789,163.88849)"
		spreadMethod="pad"
		id="linearGradient2034">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop2028" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop2030" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop2032" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(13.913099,0,0,-13.913099,184.3398,168.8334)"
		spreadMethod="pad"
		id="linearGradient2060">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop2050" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop2052" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop2054" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop2056" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop2058" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath2070">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path2068" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7152421,-4.5253959,-4.5253959,2.7152421,316.20447,382.99899)"
		spreadMethod="pad"
		id="linearGradient2110">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2096" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2098" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2100" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2102" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2104" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2106" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2108" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath2120">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path2118" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.216168,-2.1495085,-2.1495085,1.216168,318.32291,380.08432)"
		spreadMethod="pad"
		id="linearGradient2152">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2138" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2140" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2142" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2144" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2146" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2148" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2150" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2161939,-2.1495543,-2.1495543,1.2161939,320.72849,378.72333)"
		spreadMethod="pad"
		id="linearGradient2182">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2168" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2170" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2172" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2174" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2176" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2178" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2180" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7152374,-4.5253878,-4.5253878,2.7152374,321.59683,379.9819)"
		spreadMethod="pad"
		id="linearGradient2212">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2198" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2200" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2202" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2204" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2206" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2208" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2210" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath2222">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path2220" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7152421,-4.5253959,-4.5253959,2.7152421,299.77188,371.95898)"
		spreadMethod="pad"
		id="linearGradient2278">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2264" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2266" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2268" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2270" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2272" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2274" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2276" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath2288">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path2286" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2161661,-2.1495051,-2.1495051,1.2161661,301.89032,369.04431)"
		spreadMethod="pad"
		id="linearGradient2320">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2306" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2308" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2310" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2312" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2314" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2316" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2318" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2161987,-2.1495628,-2.1495628,1.2161987,304.2959,367.68329)"
		spreadMethod="pad"
		id="linearGradient2350">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2336" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2338" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2340" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2342" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2344" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2346" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2348" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7151883,-4.5253062,-4.5253062,2.7151883,305.16422,368.9418)"
		spreadMethod="pad"
		id="linearGradient2380">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2366" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2368" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2370" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2372" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2374" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2376" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2378" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath2390">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path2388" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7152402,-4.5253925,-4.5253925,2.7152402,283.33923,360.91891)"
		spreadMethod="pad"
		id="linearGradient2446">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2432" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2434" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2436" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2438" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2440" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2442" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2444" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath2456">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path2454" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2162166,-2.1495943,-2.1495943,1.2162166,285.45776,358.0043)"
		spreadMethod="pad"
		id="linearGradient2488">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2474" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2476" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2478" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2480" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2482" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2484" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2486" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2162005,-2.1495659,-2.1495659,1.2162005,287.86331,356.64328)"
		spreadMethod="pad"
		id="linearGradient2518">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2504" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2506" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2508" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2510" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2512" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2514" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2516" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.715235,-4.5253839,-4.5253839,2.715235,288.73163,357.90182)"
		spreadMethod="pad"
		id="linearGradient2548">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2534" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2536" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2538" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2540" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2542" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2544" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2546" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath2558">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path2556" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7152398,-4.5253921,-4.5253921,2.7152398,358.24722,276.39563)"
		spreadMethod="pad"
		id="linearGradient2614">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2600" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2602" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2604" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2606" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2608" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2610" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2612" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath2624">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path2622" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2161659,-2.1495047,-2.1495047,1.2161659,360.36575,273.48102)"
		spreadMethod="pad"
		id="linearGradient2656">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2642" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2644" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2646" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2648" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2650" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2652" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2654" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2161949,-2.1495559,-2.1495559,1.2161949,362.77124,272.12)"
		spreadMethod="pad"
		id="linearGradient2686">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2672" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2674" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2676" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2678" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2680" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2682" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2684" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.715235,-4.5253839,-4.5253839,2.715235,363.63962,273.37851)"
		spreadMethod="pad"
		id="linearGradient2716">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2702" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2704" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2706" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2708" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2710" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2712" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2714" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath2726">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path2724" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7152421,-4.5253959,-4.5253959,2.7152421,346.54297,269.4285)"
		spreadMethod="pad"
		id="linearGradient2782">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2768" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2770" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2772" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2774" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2776" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2778" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2780" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath2792">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path2790" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2161657,-2.1495042,-2.1495042,1.2161657,348.66141,266.51382)"
		spreadMethod="pad"
		id="linearGradient2824">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2810" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2812" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2814" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2816" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2818" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2820" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2822" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2161957,-2.1495574,-2.1495574,1.2161957,351.06692,265.1528)"
		spreadMethod="pad"
		id="linearGradient2854">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2840" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2842" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2844" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2846" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2848" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2850" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2852" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7151883,-4.5253062,-4.5253062,2.7151883,351.9353,266.41132)"
		spreadMethod="pad"
		id="linearGradient2884">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2870" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2872" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2874" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2876" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2878" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2880" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2882" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath2894">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path2892" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7152421,-4.5253959,-4.5253959,2.7152421,334.83868,262.4613)"
		spreadMethod="pad"
		id="linearGradient2950">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2936" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2938" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2940" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2942" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2944" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2946" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2948" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath2960">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path2958" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2161679,-2.1495082,-2.1495082,1.2161679,336.95709,259.54663)"
		spreadMethod="pad"
		id="linearGradient2992">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop2978" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop2980" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop2982" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop2984" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop2986" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop2988" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop2990" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2161932,-2.1495531,-2.1495531,1.2161932,339.36267,258.18567)"
		spreadMethod="pad"
		id="linearGradient3022">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3008" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3010" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3012" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3014" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3016" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3018" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3020" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7152374,-4.5253878,-4.5253878,2.7152374,340.23102,259.44421)"
		spreadMethod="pad"
		id="linearGradient3052">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3038" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3040" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3042" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3044" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3046" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3048" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3050" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath3062">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path3060" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7342427,-4.5570631,-4.5570631,2.7342427,549.84406,317.1814)"
		spreadMethod="pad"
		id="linearGradient3118">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3104" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3106" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3108" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3110" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3112" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3114" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3116" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath3128">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path3126" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247474,-2.1646719,-2.1646719,1.2247474,553.08667,313.50964)"
		spreadMethod="pad"
		id="linearGradient3160">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3146" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3148" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3150" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3152" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3154" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3156" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3158" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.22471,-2.1646061,-2.1646061,1.22471,555.50903,312.13904)"
		spreadMethod="pad"
		id="linearGradient3190">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3176" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3178" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3180" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3182" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3184" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3186" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3188" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.734272,-4.5571122,-4.5571122,2.734272,555.17725,313.98157)"
		spreadMethod="pad"
		id="linearGradient3220">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3206" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3208" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3210" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3212" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3214" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3216" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3218" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath3230">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path3228" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7342708,-4.5571103,-4.5571103,2.7342708,538.07751,310.12943)"
		spreadMethod="pad"
		id="linearGradient3286">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3272" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3274" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3276" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3278" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3280" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3282" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3284" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath3296">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path3294" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.224773,-2.1647174,-2.1647174,1.224773,541.32013,306.45767)"
		spreadMethod="pad"
		id="linearGradient3328">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3314" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3316" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3318" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3320" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3322" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3324" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3326" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247274,-2.1646366,-2.1646366,1.2247274,543.74243,305.08704)"
		spreadMethod="pad"
		id="linearGradient3358">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3344" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3346" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3348" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3350" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3352" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3354" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3356" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.734272,-4.5571122,-4.5571122,2.734272,543.41064,306.92953)"
		spreadMethod="pad"
		id="linearGradient3388">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3374" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3376" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3378" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3380" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3382" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3384" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3386" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath3398">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path3396" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7342427,-4.5570631,-4.5570631,2.7342427,526.31097,303.07739)"
		spreadMethod="pad"
		id="linearGradient3454">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3440" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3442" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3444" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3446" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3448" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3450" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3452" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath3464">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path3462" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2246989,-2.1645863,-2.1645863,1.2246989,529.55353,299.40555)"
		spreadMethod="pad"
		id="linearGradient3496">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3482" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3484" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3486" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3488" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3490" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3492" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3494" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247274,-2.1646366,-2.1646366,1.2247274,531.97595,298.03503)"
		spreadMethod="pad"
		id="linearGradient3526">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3512" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3514" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3516" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3518" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3520" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3522" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3524" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.734272,-4.5571122,-4.5571122,2.734272,531.6441,299.87753)"
		spreadMethod="pad"
		id="linearGradient3556">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3542" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3544" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3546" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3548" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3550" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3552" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3554" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath3566">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path3564" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7342393,-4.5570579,-4.5570579,2.7342393,463.41687,243.51418)"
		spreadMethod="pad"
		id="linearGradient3622">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3608" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3610" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3612" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3614" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3616" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3618" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3620" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath3632">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path3630" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2246987,-2.1645858,-2.1645858,1.2246987,466.65942,239.84236)"
		spreadMethod="pad"
		id="linearGradient3664">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3650" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3652" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3654" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3656" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3658" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3660" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3662" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247286,-2.1646388,-2.1646388,1.2247286,469.08185,238.47185)"
		spreadMethod="pad"
		id="linearGradient3694">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3680" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3682" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3684" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3686" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3688" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3690" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3692" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.734272,-4.5571122,-4.5571122,2.734272,468.75003,240.31435)"
		spreadMethod="pad"
		id="linearGradient3724">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3710" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3712" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3714" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3716" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3718" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3720" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3722" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath3734">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path3732" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.734246,-4.5570688,-4.5570688,2.734246,486.62057,257.5076)"
		spreadMethod="pad"
		id="linearGradient3790">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3776" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3778" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3780" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3782" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3784" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3786" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3788" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath3800">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path3798" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2246987,-2.1645858,-2.1645858,1.2246987,489.86313,253.83574)"
		spreadMethod="pad"
		id="linearGradient3832">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3818" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3820" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3822" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3824" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3826" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3828" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3830" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247159,-2.1646163,-2.1646163,1.2247159,492.28555,252.46519)"
		spreadMethod="pad"
		id="linearGradient3862">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3848" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3850" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3852" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3854" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3856" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3858" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3860" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.734272,-4.5571122,-4.5571122,2.734272,491.9537,254.30766)"
		spreadMethod="pad"
		id="linearGradient3892">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3878" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3880" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3882" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3884" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3886" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3888" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3890" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath3902">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path3900" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7169864,-4.4836988,-4.4836988,-2.7169864,343.82089,227.43478)"
		spreadMethod="pad"
		id="linearGradient3958">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3944" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3946" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3948" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3950" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3952" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3954" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3956" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath3968">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path3966" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121446,-1.8353132,-1.8353132,-1.1121446,341.66525,224.6757)"
		spreadMethod="pad"
		id="linearGradient4000">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop3986" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop3988" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop3990" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop3992" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop3994" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop3996" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop3998" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4010">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4008" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.698451,-4.4531107,-4.4531107,-2.698451,338.4043,224.423)"
		spreadMethod="pad"
		id="linearGradient4042">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4028" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4030" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4032" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4034" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4036" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4038" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4040" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4052">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4050" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7170258,-4.4837637,-4.4837637,-2.7170258,351.60956,222.57976)"
		spreadMethod="pad"
		id="linearGradient4108">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4094" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4096" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4098" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4100" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4102" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4104" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4106" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4118">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4116" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121699,-1.835355,-1.835355,-1.1121699,349.45392,219.82063)"
		spreadMethod="pad"
		id="linearGradient4150">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4136" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4138" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4140" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4142" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4144" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4146" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4148" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4160">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4158" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6985021,-4.4531951,-4.4531951,-2.6985021,346.19296,219.56798)"
		spreadMethod="pad"
		id="linearGradient4192">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4178" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4180" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4182" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4184" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4186" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4188" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4190" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4202">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4200" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7170212,-4.4837565,-4.4837565,-2.7170212,359.39825,217.72464)"
		spreadMethod="pad"
		id="linearGradient4258">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4244" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4246" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4248" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4250" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4252" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4254" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4256" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4268">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4266" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121687,-1.835353,-1.835353,-1.1121687,357.24261,214.96555)"
		spreadMethod="pad"
		id="linearGradient4300">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4286" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4288" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4290" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4292" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4294" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4296" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4298" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4310">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4308" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6984589,-4.453124,-4.453124,-2.6984589,353.98166,214.71288)"
		spreadMethod="pad"
		id="linearGradient4342">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4328" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4330" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4332" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4334" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4336" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4338" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4340" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4352">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4350" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.717032,-4.4837737,-4.4837737,-2.717032,367.18695,212.86957)"
		spreadMethod="pad"
		id="linearGradient4408">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4394" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4396" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4398" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4400" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4402" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4404" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4406" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4418">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4416" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121433,-1.8353112,-1.8353112,-1.1121433,365.03131,210.11047)"
		spreadMethod="pad"
		id="linearGradient4450">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4436" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4438" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4440" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4442" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4444" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4446" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4448" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4460">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4458" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6984708,-4.4531436,-4.4531436,-2.6984708,361.77032,209.85779)"
		spreadMethod="pad"
		id="linearGradient4492">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4478" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4480" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4482" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4484" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4486" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4488" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4490" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4502">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4500" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7170215,-4.4837565,-4.4837565,-2.7170215,189.88257,207.07175)"
		spreadMethod="pad"
		id="linearGradient4582">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4568" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4570" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4572" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4574" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4576" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4578" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4580" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4592">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4590" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121188,-1.8352708,-1.8352708,-1.1121188,187.72694,204.31264)"
		spreadMethod="pad"
		id="linearGradient4624">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4610" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4612" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4614" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4616" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4618" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4620" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4622" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4634">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4632" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6984458,-4.4531021,-4.4531021,-2.6984458,184.46597,204.05997)"
		spreadMethod="pad"
		id="linearGradient4666">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4652" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4654" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4656" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4658" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4660" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4662" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4664" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4676">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4674" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7170396,-4.4837866,-4.4837866,-2.7170396,197.53287,202.32529)"
		spreadMethod="pad"
		id="linearGradient4732">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4718" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4720" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4722" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4724" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4726" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4728" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4730" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4742">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4740" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121503,-1.8353229,-1.8353229,-1.1121503,195.37721,199.56618)"
		spreadMethod="pad"
		id="linearGradient4774">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4760" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4762" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4764" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4766" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4768" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4770" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4772" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4784">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4782" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6984739,-4.4531484,-4.4531484,-2.6984739,192.1162,199.31349)"
		spreadMethod="pad"
		id="linearGradient4816">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4802" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4804" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4806" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4808" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4810" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4812" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4814" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4826">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4824" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7169852,-4.4836969,-4.4836969,-2.7169852,205.18309,197.57878)"
		spreadMethod="pad"
		id="linearGradient4882">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4868" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4870" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4872" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4874" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4876" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4878" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4880" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4892">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4890" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121467,-1.8353169,-1.8353169,-1.1121467,203.02745,194.8197)"
		spreadMethod="pad"
		id="linearGradient4924">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4910" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4912" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4914" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4916" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4918" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4920" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4922" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4934">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4932" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6984506,-4.4531102,-4.4531102,-2.6984506,199.76651,194.567)"
		spreadMethod="pad"
		id="linearGradient4966">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop4952" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop4954" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop4956" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop4958" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop4960" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop4962" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop4964" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath4976">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path4974" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7170334,-4.4837761,-4.4837761,-2.7170334,212.83334,192.83237)"
		spreadMethod="pad"
		id="linearGradient5032">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop5018" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop5020" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop5022" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop5024" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop5026" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop5028" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop5030" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath5042">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path5040" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121705,-1.835356,-1.835356,-1.1121705,210.67772,190.07324)"
		spreadMethod="pad"
		id="linearGradient5074">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop5060" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop5062" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop5064" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop5066" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop5068" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop5070" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop5072" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath5084">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path5082" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6984696,-4.4531417,-4.4531417,-2.6984696,207.4167,189.82057)"
		spreadMethod="pad"
		id="linearGradient5116">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop5102" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop5104" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop5106" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop5108" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop5110" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop5112" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop5114" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath5126">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path5124" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(17.71352,0,0,-17.71352,152.75357,129.6198)"
		spreadMethod="pad"
		id="linearGradient5190">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop5176" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop5178" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop5180" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop5182" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop5184" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop5186" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop5188" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(14.761221,0,0,-14.761221,154.22957,131.93665)"
		spreadMethod="pad"
		id="linearGradient5216">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop5206" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop5208" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop5210" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop5212" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop5214" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(3.4693201,10.071126,10.071126,-3.4693201,159.71526,125.64112)"
		spreadMethod="pad"
		id="linearGradient5238">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop5232" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop5234" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop5236" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(17.71352,0,0,-17.71352,152.75357,131.93665)"
		spreadMethod="pad"
		id="linearGradient5264">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop5254" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop5256" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop5258" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop5260" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop5262" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(17.713404,0,0,-17.713404,176.5134,115.9022)"
		spreadMethod="pad"
		id="linearGradient5294">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop5280" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop5282" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop5284" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop5286" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop5288" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop5290" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop5292" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(14.7611,0,0,-14.7611,177.9894,118.2191)"
		spreadMethod="pad"
		id="linearGradient5320">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop5310" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop5312" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop5314" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop5316" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop5318" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(3.4693282,10.071149,10.071149,-3.4693282,183.47501,111.92352)"
		spreadMethod="pad"
		id="linearGradient5342">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop5336" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop5338" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop5340" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(17.713404,0,0,-17.713404,176.5134,118.2191)"
		spreadMethod="pad"
		id="linearGradient5368">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop5358" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop5360" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop5362" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop5364" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop5366" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(17.7134,0,0,-17.7134,200.27319,102.1846)"
		spreadMethod="pad"
		id="linearGradient5398">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop5384" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop5386" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop5388" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop5390" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop5392" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop5394" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop5396" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(14.761094,0,0,-14.761094,201.74921,104.50155)"
		spreadMethod="pad"
		id="linearGradient5424">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop5414" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop5416" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop5418" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop5420" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop5422" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(3.4693406,10.071185,10.071185,-3.4693406,207.2348,98.20594)"
		spreadMethod="pad"
		id="linearGradient5446">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop5440" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop5442" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop5444" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(17.7134,0,0,-17.7134,200.27319,104.50155)"
		spreadMethod="pad"
		id="linearGradient5472">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop5462" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop5464" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop5466" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop5468" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop5470" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(17.713493,0,0,-17.713493,224.0329,88.467102)"
		spreadMethod="pad"
		id="linearGradient5502">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop5488" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop5490" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop5492" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop5494" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop5496" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop5498" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop5500" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(14.761194,0,0,-14.761194,225.5089,90.783951)"
		spreadMethod="pad"
		id="linearGradient5528">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop5518" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop5520" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop5522" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop5524" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop5526" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(3.4693182,10.07112,10.07112,-3.4693182,230.99457,84.488434)"
		spreadMethod="pad"
		id="linearGradient5550">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop5544" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop5546" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop5548" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(17.713493,0,0,-17.713493,224.0329,90.783951)"
		spreadMethod="pad"
		id="linearGradient5576">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop5566" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop5568" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop5570" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop5572" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop5574" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath5586">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path5584" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7170334,-4.4837761,-4.4837761,-2.7170334,272.35394,151.72176)"
		spreadMethod="pad"
		id="linearGradient5638">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop5624" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop5626" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop5628" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop5630" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop5632" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop5634" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop5636" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath5648">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path5646" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121716,-1.8353579,-1.8353579,-1.1121716,270.19833,148.96265)"
		spreadMethod="pad"
		id="linearGradient5680">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop5666" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop5668" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop5670" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop5672" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop5674" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop5676" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop5678" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath5690">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path5688" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6984575,-4.4531217,-4.4531217,-2.6984575,266.93735,148.70998)"
		spreadMethod="pad"
		id="linearGradient5722">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop5708" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop5710" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop5712" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop5714" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop5716" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop5718" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop5720" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath5732">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path5730" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7170374,-4.4837828,-4.4837828,-2.7170374,279.94904,146.90587)"
		spreadMethod="pad"
		id="linearGradient5788">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop5774" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop5776" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop5778" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop5780" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop5782" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop5784" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop5786" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath5798">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path5796" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121699,-1.835355,-1.835355,-1.1121699,277.7934,144.14673)"
		spreadMethod="pad"
		id="linearGradient5830">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop5816" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop5818" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop5820" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop5822" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop5824" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop5826" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop5828" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath5840">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path5838" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6985135,-4.4532142,-4.4532142,-2.6985135,274.53238,143.89407)"
		spreadMethod="pad"
		id="linearGradient5872">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop5858" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop5860" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop5862" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop5864" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop5866" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop5868" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop5870" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath5882">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path5880" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7169821,-4.4836917,-4.4836917,-2.7169821,287.5441,142.08987)"
		spreadMethod="pad"
		id="linearGradient5938">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop5924" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop5926" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop5928" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop5930" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop5932" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop5934" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop5936" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath5948">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path5946" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121371,-1.835301,-1.835301,-1.1121371,285.38849,139.3308)"
		spreadMethod="pad"
		id="linearGradient5980">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop5966" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop5968" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop5970" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop5972" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop5974" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop5976" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop5978" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath5990">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path5988" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6984782,-4.4531555,-4.4531555,-2.6984782,282.1275,139.07809)"
		spreadMethod="pad"
		id="linearGradient6022">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6008" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6010" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6012" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6014" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6016" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6018" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6020" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath6032">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path6030" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7170291,-4.4837694,-4.4837694,-2.7170291,295.13919,137.27399)"
		spreadMethod="pad"
		id="linearGradient6088">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6074" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6076" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6078" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6080" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6082" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6084" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6086" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath6098">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path6096" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121513,-1.8353243,-1.8353243,-1.1121513,292.98352,134.51489)"
		spreadMethod="pad"
		id="linearGradient6130">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6116" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6118" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6120" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6122" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6124" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6126" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6128" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath6140">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path6138" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6984591,-4.4531245,-4.4531245,-2.6984591,289.72256,134.26221)"
		spreadMethod="pad"
		id="linearGradient6172">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6158" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6160" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6162" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6164" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6166" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6168" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6170" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath6182">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path6180" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7342708,-4.5571103,-4.5571103,2.7342708,659.94519,245.68929)"
		spreadMethod="pad"
		id="linearGradient6254">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6240" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6242" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6244" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6246" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6248" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6250" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6252" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath6264">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path6262" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2246988,-2.1645861,-2.1645861,1.2246988,663.18768,242.01746)"
		spreadMethod="pad"
		id="linearGradient6296">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6282" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6284" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6286" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6288" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6290" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6292" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6294" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247083,-2.1646028,-2.1646028,1.2247083,665.61011,240.64688)"
		spreadMethod="pad"
		id="linearGradient6326">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6312" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6314" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6316" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6318" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6320" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6322" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6324" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.734272,-4.5571122,-4.5571122,2.734272,665.27832,242.48936)"
		spreadMethod="pad"
		id="linearGradient6356">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6342" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6344" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6346" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6348" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6350" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6352" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6354" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath6366">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path6364" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7342706,-4.5571098,-4.5571098,2.7342706,672.38861,252.91251)"
		spreadMethod="pad"
		id="linearGradient6422">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6408" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6410" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6412" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6414" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6416" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6418" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6420" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath6432">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path6430" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247263,-2.1646349,-2.1646349,1.2247263,675.63116,249.24069)"
		spreadMethod="pad"
		id="linearGradient6464">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6450" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6452" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6454" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6456" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6458" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6460" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6462" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247102,-2.1646063,-2.1646063,1.2247102,678.05353,247.8701)"
		spreadMethod="pad"
		id="linearGradient6494">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6480" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6482" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6484" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6486" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6488" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6490" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6492" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7342253,-4.557034,-4.557034,2.7342253,677.72168,249.71257)"
		spreadMethod="pad"
		id="linearGradient6524">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6510" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6512" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6514" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6516" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6518" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6520" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6522" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath6534">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path6532" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(19.979015,0,0,-19.979015,638.6001,191.2823)"
		spreadMethod="pad"
		id="linearGradient6598">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop6584" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop6586" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop6588" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop6590" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop6592" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop6594" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop6596" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(16.648983,0,0,-16.648983,640.26489,193.89555)"
		spreadMethod="pad"
		id="linearGradient6624">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop6614" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop6616" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop6618" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop6620" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop6622" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(3.9130285,11.359171,11.359171,-3.9130285,646.45209,186.79483)"
		spreadMethod="pad"
		id="linearGradient6646">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop6640" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop6642" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop6644" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(19.979015,0,0,-19.979015,638.6001,193.89555)"
		spreadMethod="pad"
		id="linearGradient6672">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop6662" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop6664" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop6666" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop6668" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop6670" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath6682">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path6680" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(24.7962,0,0,-24.7962,592.22913,168.11095)"
		spreadMethod="pad"
		id="linearGradient6714">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop6700" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop6702" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop6704" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop6706" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop6708" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop6710" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop6712" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(20.663416,0,0,-20.663416,594.29529,171.35425)"
		spreadMethod="pad"
		id="linearGradient6740">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop6730" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop6732" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop6734" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop6736" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop6738" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(4.8565164,14.098033,14.098033,-4.8565164,601.97437,162.5414)"
		spreadMethod="pad"
		id="linearGradient6762">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop6756" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop6758" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop6760" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(24.7962,0,0,-24.7962,592.22913,171.35425)"
		spreadMethod="pad"
		id="linearGradient6788">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop6778" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop6780" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop6782" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop6784" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop6786" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath6798">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path6796" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7170506,-4.4838042,-4.4838042,-2.7170506,379.19855,138.10144)"
		spreadMethod="pad"
		id="linearGradient6850">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6836" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6838" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6840" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6842" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6844" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6846" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6848" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath6860">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path6858" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121804,-1.8353723,-1.8353723,-1.1121804,377.04297,135.34233)"
		spreadMethod="pad"
		id="linearGradient6892">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6878" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6880" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6882" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6884" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6886" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6888" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6890" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath6902">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path6900" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6985171,-4.4532199,-4.4532199,-2.6985171,373.78195,135.08966)"
		spreadMethod="pad"
		id="linearGradient6934">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6920" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6922" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6924" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6926" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6928" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6930" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6932" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath6944">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path6942" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7169845,-4.4836955,-4.4836955,-2.7169845,386.7937,133.28549)"
		spreadMethod="pad"
		id="linearGradient7000">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop6986" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop6988" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop6990" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop6992" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop6994" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop6996" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop6998" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7010">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7008" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121472,-1.8353177,-1.8353177,-1.1121472,384.63803,130.5264)"
		spreadMethod="pad"
		id="linearGradient7042">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7028" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7030" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7032" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7034" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7036" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7038" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7040" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7052">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7050" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6984622,-4.4531293,-4.4531293,-2.6984622,381.37711,130.27371)"
		spreadMethod="pad"
		id="linearGradient7084">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7070" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7072" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7074" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7076" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7078" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7080" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7082" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7094">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7092" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7169964,-4.4837151,-4.4837151,-2.7169964,394.38876,128.4696)"
		spreadMethod="pad"
		id="linearGradient7150">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7136" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7138" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7140" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7142" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7144" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7146" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7148" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7160">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7158" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121485,-1.8353198,-1.8353198,-1.1121485,392.23312,125.7105)"
		spreadMethod="pad"
		id="linearGradient7192">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7178" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7180" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7182" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7184" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7186" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7188" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7190" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7202">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7200" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6984627,-4.4531302,-4.4531302,-2.6984627,388.97217,125.45781)"
		spreadMethod="pad"
		id="linearGradient7234">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7220" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7222" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7224" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7226" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7228" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7230" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7232" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7244">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7242" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7170334,-4.4837761,-4.4837761,-2.7170334,401.98386,123.65367)"
		spreadMethod="pad"
		id="linearGradient7300">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7286" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7288" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7290" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7292" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7294" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7296" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7298" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7310">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7308" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.1121458,-1.8353152,-1.8353152,-1.1121458,399.82819,120.89457)"
		spreadMethod="pad"
		id="linearGradient7342">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7328" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7330" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7332" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7334" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7336" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7338" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7340" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7352">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7350" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.6984663,-4.453136,-4.453136,-2.6984663,396.5672,120.64188)"
		spreadMethod="pad"
		id="linearGradient7384">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7370" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7372" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7374" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7376" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7378" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7380" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7382" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7394">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7392" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7342708,-4.5571103,-4.5571103,2.7342708,465.35452,146.00832)"
		spreadMethod="pad"
		id="linearGradient7454">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7440" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7442" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7444" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7446" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7448" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7450" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7452" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7464">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7462" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247233,-2.1646295,-2.1646295,1.2247233,468.59708,142.33649)"
		spreadMethod="pad"
		id="linearGradient7496">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7482" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7484" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7486" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7488" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7490" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7492" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7494" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247274,-2.1646366,-2.1646366,1.2247274,471.01944,140.96594)"
		spreadMethod="pad"
		id="linearGradient7526">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7512" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7514" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7516" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7518" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7520" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7522" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7524" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.734272,-4.5571122,-4.5571122,2.734272,470.68765,142.80843)"
		spreadMethod="pad"
		id="linearGradient7556">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7542" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7544" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7546" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7548" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7550" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7552" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7554" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7566">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7564" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7342741,-4.5571156,-4.5571156,2.7342741,453.58801,138.95633)"
		spreadMethod="pad"
		id="linearGradient7622">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7608" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7610" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7612" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7614" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7616" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7618" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7620" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7632">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7630" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2246989,-2.1645863,-2.1645863,1.2246989,456.83051,135.28445)"
		spreadMethod="pad"
		id="linearGradient7664">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7650" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7652" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7654" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7656" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7658" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7660" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7662" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247274,-2.1646366,-2.1646366,1.2247274,459.25296,133.91394)"
		spreadMethod="pad"
		id="linearGradient7694">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7680" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7682" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7684" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7686" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7688" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7690" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7692" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.734272,-4.5571122,-4.5571122,2.734272,458.92111,135.75644)"
		spreadMethod="pad"
		id="linearGradient7724">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7710" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7712" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7714" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7716" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7718" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7720" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7722" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7734">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7732" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7342708,-4.5571103,-4.5571103,2.7342708,441.82144,131.90431)"
		spreadMethod="pad"
		id="linearGradient7790">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7776" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7778" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7780" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7782" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7784" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7786" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7788" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7800">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7798" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247294,-2.1646402,-2.1646402,1.2247294,445.06396,128.23248)"
		spreadMethod="pad"
		id="linearGradient7832">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7818" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7820" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7822" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7824" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7826" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7828" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7830" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247261,-2.1646345,-2.1646345,1.2247261,447.48636,126.86194)"
		spreadMethod="pad"
		id="linearGradient7862">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7848" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7850" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7852" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7854" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7856" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7858" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7860" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7343004,-4.5571594,-4.5571594,2.7343004,447.1546,128.70447)"
		spreadMethod="pad"
		id="linearGradient7892">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop7878" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop7880" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop7882" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop7884" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop7886" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop7888" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop7890" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath7902">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path7900" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(19.979,0,0,-19.979,300.07031,105.5032)"
		spreadMethod="pad"
		id="linearGradient7950">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop7936" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop7938" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop7940" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop7942" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop7944" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop7946" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop7948" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(16.649008,0,0,-16.649008,301.73511,108.11645)"
		spreadMethod="pad"
		id="linearGradient7976">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop7966" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop7968" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop7970" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop7972" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop7974" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(3.9130371,11.359197,11.359197,-3.9130371,307.9223,101.01569)"
		spreadMethod="pad"
		id="linearGradient7998">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop7992" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop7994" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop7996" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(19.979,0,0,-19.979,300.07031,108.11645)"
		spreadMethod="pad"
		id="linearGradient8024">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop8014" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop8016" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop8018" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop8020" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop8022" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(19.979008,0,0,-19.979008,335.69959,84.973503)"
		spreadMethod="pad"
		id="linearGradient8054">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop8040" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop8042" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop8044" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop8046" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop8048" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop8050" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop8052" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(16.648991,0,0,-16.648991,337.36441,87.5867)"
		spreadMethod="pad"
		id="linearGradient8080">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop8070" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop8072" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop8074" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop8076" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop8078" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(3.9130287,11.359172,11.359172,-3.9130287,343.55161,80.486)"
		spreadMethod="pad"
		id="linearGradient8102">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop8096" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop8098" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop8100" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(19.979008,0,0,-19.979008,335.69959,87.586746)"
		spreadMethod="pad"
		id="linearGradient8128">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop8118" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop8120" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop8122" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop8124" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop8126" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath8138">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path8136" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(19.978901,0,0,-19.978901,501.99939,78.8592)"
		spreadMethod="pad"
		id="linearGradient8182">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop8168" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop8170" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop8172" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop8174" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop8176" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop8178" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop8180" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(16.649,0,0,-16.649,503.66422,81.47245)"
		spreadMethod="pad"
		id="linearGradient8208">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop8198" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop8200" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop8202" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop8204" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop8206" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(3.9130287,11.359172,11.359172,-3.9130287,509.85141,74.371719)"
		spreadMethod="pad"
		id="linearGradient8230">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop8224" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop8226" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop8228" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(19.978901,0,0,-19.978901,501.99939,81.47245)"
		spreadMethod="pad"
		id="linearGradient8256">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop8246" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop8248" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop8250" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop8252" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop8254" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(16.246485,0,0,-16.246485,569.69562,81.780403)"
		spreadMethod="pad"
		id="linearGradient8286">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop8272" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop8274" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop8276" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop8278" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop8280" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop8282" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop8284" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(13.538614,0,0,-13.538614,571.04938,83.905403)"
		spreadMethod="pad"
		id="linearGradient8312">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop8302" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop8304" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop8306" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop8308" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop8310" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(3.1820009,9.2370634,9.2370634,-3.1820009,576.08069,78.131203)"
		spreadMethod="pad"
		id="linearGradient8334">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop8328" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop8330" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop8332" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(16.246485,0,0,-16.246485,569.69562,83.905403)"
		spreadMethod="pad"
		id="linearGradient8360">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop8350" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop8352" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop8354" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop8356" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop8358" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath8370">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path8368" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-19.630655,0,0,-19.630655,468.23434,175.09425)"
		spreadMethod="pad"
		id="radialGradient8406">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop8396" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop8398" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop8400" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop8402" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop8404" />
	 </radialGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.4371299,-2.489182,2.489182,1.4371299,451.34772,192.35313)"
		spreadMethod="pad"
		id="linearGradient8436">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop8422" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.0932978"
		  id="stop8424" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.18136494"
		  id="stop8426" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.524399"
		  id="stop8428" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.55181052"
		  id="stop8430" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.902476"
		  id="stop8432" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8434" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.3633405,-2.3613749,2.3613749,1.3633405,451.38531,197.67831)"
		spreadMethod="pad"
		id="linearGradient8466">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop8452" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.0932978"
		  id="stop8454" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.18136494"
		  id="stop8456" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.524399"
		  id="stop8458" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.55181052"
		  id="stop8460" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.902476"
		  id="stop8462" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8464" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.3534113,-2.344177,2.344177,1.3534113,451.46692,187.17035)"
		spreadMethod="pad"
		id="linearGradient8496">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop8482" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.0932978"
		  id="stop8484" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.18136494"
		  id="stop8486" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.524399"
		  id="stop8488" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.55181052"
		  id="stop8490" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.902476"
		  id="stop8492" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8494" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.4187624,-2.4573686,2.4573686,-1.4187624,477.05341,188.25064)"
		spreadMethod="pad"
		id="linearGradient8522">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop8512" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.10082117"
		  id="stop8514" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.493534"
		  id="stop8516" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.53025404"
		  id="stop8518" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8520" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.4187278,-2.4573085,2.4573085,-1.4187278,477.05338,193.51607)"
		spreadMethod="pad"
		id="linearGradient8548">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop8538" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.10082117"
		  id="stop8540" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.493534"
		  id="stop8542" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.53025404"
		  id="stop8544" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8546" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.4187278,-2.4573085,2.4573085,-1.4187278,477.05338,182.98517)"
		spreadMethod="pad"
		id="linearGradient8574">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop8564" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.10082117"
		  id="stop8566" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.493534"
		  id="stop8568" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.53025404"
		  id="stop8570" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8572" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath8584">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path8582" />
	 </clipPath>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath8592">
	   <path
		  d="m 460.01,202.847 h 32.364 V 170.691 H 460.01 Z"
		  id="path8590" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(11.80934,22.146929,22.15136,-11.811703,470.66058,179.6801)"
		spreadMethod="pad"
		id="radialGradient8608">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop8598" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop8600" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop8602" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop8604" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop8606" />
	 </radialGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath8630">
	   <path
		  d="M 444.089,202.857 H 460.01 V 179.986 H 444.089 Z"
		  id="path8628" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-6.2568641,11.731613,-11.731613,-6.2568641,454.77072,186.37918)"
		spreadMethod="pad"
		id="radialGradient8646">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop8636" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop8638" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop8640" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop8642" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop8644" />
	 </radialGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.4205962,-2.4605448,2.4605448,-1.4205962,461.04623,178.90887)"
		spreadMethod="pad"
		id="linearGradient8684">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop8674" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop8676" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop8678" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop8680" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8682" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2201674,-2.1133919,2.1133919,-1.2201674,461.05038,173.64134)"
		spreadMethod="pad"
		id="linearGradient8710">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop8700" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop8702" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop8704" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop8706" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8708" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.4205962,-2.4605448,2.4605448,-1.4205962,461.04623,184.17436)"
		spreadMethod="pad"
		id="linearGradient8736">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop8726" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop8728" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop8730" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop8732" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8734" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.3549776,-2.34689,2.34689,1.3549776,483.60745,179.57816)"
		spreadMethod="pad"
		id="linearGradient8762">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop8752" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop8754" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop8756" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop8758" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8760" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.3549776,-2.34689,2.34689,1.3549776,483.60745,174.32675)"
		spreadMethod="pad"
		id="linearGradient8788">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop8778" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop8780" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop8782" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop8784" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8786" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.3549776,-2.34689,2.34689,1.3549776,483.60745,169.04726)"
		spreadMethod="pad"
		id="linearGradient8814">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop8804" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop8806" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop8808" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop8810" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8812" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath8824">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path8822" />
	 </clipPath>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath8832">
	   <path
		  d="m 444.092,202.847 h 48.285 v -27.413 h -48.285 z"
		  id="path8830" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-19.630655,0,0,-19.630655,468.23434,189.14066)"
		spreadMethod="pad"
		id="radialGradient8848">
	   <stop
		  style="stop-opacity:1;stop-color:#4260ab"
		  offset="0"
		  id="stop8838" />
	   <stop
		  style="stop-opacity:1;stop-color:#243d83"
		  offset="0.42132691"
		  id="stop8840" />
	   <stop
		  style="stop-opacity:1;stop-color:#243d83"
		  offset="0.488735"
		  id="stop8842" />
	   <stop
		  style="stop-opacity:1;stop-color:#243d83"
		  offset="0.58831037"
		  id="stop8844" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop8846" />
	 </radialGradient>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(29.122156,0,0,-29.122156,428.84674,147.7534)"
		spreadMethod="pad"
		id="radialGradient8886">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop8876" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop8878" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop8880" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop8882" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop8884" />
	 </radialGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.1185944,-3.669513,-3.669513,2.1185944,453.76755,173.65231)"
		spreadMethod="pad"
		id="linearGradient8916">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop8902" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.0932978"
		  id="stop8904" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.18136494"
		  id="stop8906" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.524399"
		  id="stop8908" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.55181052"
		  id="stop8910" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.902476"
		  id="stop8912" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8914" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.1185944,-3.669513,-3.669513,2.1185944,453.76755,181.42331)"
		spreadMethod="pad"
		id="linearGradient8946">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop8932" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.0932978"
		  id="stop8934" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.18136494"
		  id="stop8936" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.524399"
		  id="stop8938" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.55181052"
		  id="stop8940" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.902476"
		  id="stop8942" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8944" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.1185944,-3.669513,-3.669513,2.1185944,453.76755,165.88141)"
		spreadMethod="pad"
		id="linearGradient8976">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop8962" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.0932978"
		  id="stop8964" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.18136494"
		  id="stop8966" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.524399"
		  id="stop8968" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.55181052"
		  id="stop8970" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.902476"
		  id="stop8972" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop8974" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.7997487,-3.1172562,-3.1172562,-1.7997487,416.00839,166.86246)"
		spreadMethod="pad"
		id="linearGradient9002">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop8992" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.10082117"
		  id="stop8994" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.493534"
		  id="stop8996" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.53025404"
		  id="stop8998" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9000" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.7997487,-3.1172562,-3.1172562,-1.7997487,416.00839,174.63345)"
		spreadMethod="pad"
		id="linearGradient9028">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop9018" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.10082117"
		  id="stop9020" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.493534"
		  id="stop9022" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.53025404"
		  id="stop9024" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9026" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.7997487,-3.1172562,-3.1172562,-1.7997487,416.00839,159.09155)"
		spreadMethod="pad"
		id="linearGradient9054">
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0"
		  id="stop9044" />
	   <stop
		  style="stop-opacity:1;stop-color:#00a0b6"
		  offset="0.10082117"
		  id="stop9046" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.493534"
		  id="stop9048" />
	   <stop
		  style="stop-opacity:1;stop-color:#01669c"
		  offset="0.53025404"
		  id="stop9050" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9052" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9064">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path9062" />
	 </clipPath>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9072">
	   <path
		  d="m 393.22,189.139 h 47.764 V 140.828 H 393.22 Z"
		  id="path9070" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-17.474888,32.771954,-32.778507,-17.478384,425.26556,154.33322)"
		spreadMethod="pad"
		id="radialGradient9088">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop9078" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop9080" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop9082" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop9084" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop9086" />
	 </radialGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9110">
	   <path
		  d="m 440.984,189.153 h 23.498 v -34.602 h -23.498 z"
		  id="path9108" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(9.2961578,17.430284,17.430284,-9.2961578,448.7168,164.22414)"
		spreadMethod="pad"
		id="radialGradient9126">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop9116" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop9118" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop9120" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop9122" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop9124" />
	 </radialGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.7989851,-3.1159337,-3.1159337,-1.7989851,439.63495,153.07475)"
		spreadMethod="pad"
		id="linearGradient9164">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop9154" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop9156" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop9158" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop9160" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9162" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.7990198,-3.1159937,-3.1159937,-1.7990198,439.63492,145.29015)"
		spreadMethod="pad"
		id="linearGradient9190">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop9180" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop9182" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop9184" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop9186" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9188" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(1.7989851,-3.1159337,-3.1159337,-1.7989851,439.63495,160.84576)"
		spreadMethod="pad"
		id="linearGradient9216">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop9206" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop9208" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop9210" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop9212" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9214" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.999751,-3.4636703,-3.4636703,1.999751,406.15802,153.94426)"
		spreadMethod="pad"
		id="linearGradient9242">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop9232" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop9234" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop9236" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop9238" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9240" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.999751,-3.4636703,-3.4636703,1.999751,406.15802,146.19406)"
		spreadMethod="pad"
		id="linearGradient9268">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop9258" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop9260" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop9262" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop9264" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9266" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.999751,-3.4636703,-3.4636703,1.999751,406.15802,138.40236)"
		spreadMethod="pad"
		id="linearGradient9294">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop9284" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop9286" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.493534"
		  id="stop9288" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.53025404"
		  id="stop9290" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9292" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9304">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path9302" />
	 </clipPath>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9312">
	   <path
		  d="m 393.216,189.139 h 71.262 v -41.311 h -71.262 z"
		  id="path9310" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(29.122156,0,0,-29.122156,428.84674,168.4836)"
		spreadMethod="pad"
		id="radialGradient9328">
	   <stop
		  style="stop-opacity:1;stop-color:#4260ab"
		  offset="0"
		  id="stop9318" />
	   <stop
		  style="stop-opacity:1;stop-color:#243d83"
		  offset="0.42132691"
		  id="stop9320" />
	   <stop
		  style="stop-opacity:1;stop-color:#243d83"
		  offset="0.488735"
		  id="stop9322" />
	   <stop
		  style="stop-opacity:1;stop-color:#243d83"
		  offset="0.58831037"
		  id="stop9324" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop9326" />
	 </radialGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7342744,-4.557116,-4.557116,2.7342744,502.30075,155.91942)"
		spreadMethod="pad"
		id="linearGradient9382">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop9368" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop9370" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop9372" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop9374" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop9376" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop9378" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9380" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9392">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path9390" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247024,-2.1645925,-2.1645925,1.2247024,505.54327,152.24759)"
		spreadMethod="pad"
		id="linearGradient9424">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop9410" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop9412" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop9414" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop9416" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop9418" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop9420" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9422" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247255,-2.1646333,-2.1646333,1.2247255,507.96564,150.87704)"
		spreadMethod="pad"
		id="linearGradient9454">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop9440" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop9442" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop9444" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop9446" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop9448" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop9450" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9452" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7343037,-4.5571651,-4.5571651,2.7343037,507.63391,152.71956)"
		spreadMethod="pad"
		id="linearGradient9484">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop9470" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop9472" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop9474" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop9476" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop9478" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop9480" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9482" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9494">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path9492" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.7342744,-4.557116,-4.557116,2.7342744,512.67883,163.16843)"
		spreadMethod="pad"
		id="linearGradient9550">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop9536" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop9538" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop9540" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop9542" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop9544" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop9546" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9548" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9560">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path9558" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247716,-2.1647148,-2.1647148,1.2247716,515.92145,159.49666)"
		spreadMethod="pad"
		id="linearGradient9592">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop9578" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop9580" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop9582" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop9584" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop9586" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop9588" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9590" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-1.2247086,-2.1646032,-2.1646032,1.2247086,518.34375,158.12604)"
		spreadMethod="pad"
		id="linearGradient9622">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop9608" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop9610" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop9612" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop9614" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop9616" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop9618" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9620" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.734272,-4.5571122,-4.5571122,2.734272,518.0119,159.96854)"
		spreadMethod="pad"
		id="linearGradient9652">
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0"
		  id="stop9638" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.01775274"
		  id="stop9640" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.244857"
		  id="stop9642" />
	   <stop
		  style="stop-opacity:1;stop-color:#499ad4"
		  offset="0.26787364"
		  id="stop9644" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.562317"
		  id="stop9646" />
	   <stop
		  style="stop-opacity:1;stop-color:#0980c3"
		  offset="0.5940501"
		  id="stop9648" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="1"
		  id="stop9650" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9662">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path9660" />
	 </clipPath>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9686">
	   <path
		  d="m 127.382,175.123 41.336,-24.54 7.497,5.5 -40.833,23.668 z"
		  id="path9684" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-4.2621603,-7.6856942,-11.658263,6.4654408,153.78238,169.17976)"
		spreadMethod="pad"
		id="linearGradient9706">
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0"
		  id="stop9696" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0.03987499"
		  id="stop9698" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.549981"
		  id="stop9700" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.58260849"
		  id="stop9702" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="1"
		  id="stop9704" />
	 </linearGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask9708">
	   <g
		  id="g9718">
		 <g
			id="g9716">
		   <g
			  id="g9714">
			 <g
				id="g9712">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#linearGradient9706);stroke:none"
				  id="path9710" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-4.2621603,-7.6856942,-11.658263,6.4654408,153.78238,169.17976)"
		spreadMethod="pad"
		id="linearGradient9734">
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0"
		  id="stop9724" />
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0.03987499"
		  id="stop9726" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.549981"
		  id="stop9728" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.58260849"
		  id="stop9730" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="1"
		  id="stop9732" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9744">
	   <path
		  d="m 137.218,125.718 89.226,-52.97 8.856,5.386 -90.082,52.211 z"
		  id="path9742" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-5.0091367,-9.032671,-13.701463,7.5985584,188.47612,105.9244)"
		spreadMethod="pad"
		id="linearGradient9764">
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0"
		  id="stop9754" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0.03987499"
		  id="stop9756" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.549981"
		  id="stop9758" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.58260849"
		  id="stop9760" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="1"
		  id="stop9762" />
	 </linearGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask9766">
	   <g
		  id="g9776">
		 <g
			id="g9774">
		   <g
			  id="g9772">
			 <g
				id="g9770">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#linearGradient9764);stroke:none"
				  id="path9768" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-5.0091367,-9.032671,-13.701463,7.5985584,188.47612,105.9244)"
		spreadMethod="pad"
		id="linearGradient9792">
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0"
		  id="stop9782" />
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0.03987499"
		  id="stop9784" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.549981"
		  id="stop9786" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.58260849"
		  id="stop9788" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="1"
		  id="stop9790" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9802">
	   <path
		  d="m 224.256,174.676 19.117,-12.218 5.699,6.497 -19.014,11.567 z"
		  id="path9800" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-4.2626791,-7.6866293,-5.4949837,3.0470381,238.64732,175.39267)"
		spreadMethod="pad"
		id="linearGradient9822">
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0"
		  id="stop9812" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0.03987499"
		  id="stop9814" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.549981"
		  id="stop9816" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.58260849"
		  id="stop9818" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="1"
		  id="stop9820" />
	 </linearGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask9824">
	   <g
		  id="g9834">
		 <g
			id="g9832">
		   <g
			  id="g9830">
			 <g
				id="g9828">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#linearGradient9822);stroke:none"
				  id="path9826" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-4.2626791,-7.6866293,-5.4949837,3.0470381,238.64732,175.39267)"
		spreadMethod="pad"
		id="linearGradient9850">
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0"
		  id="stop9840" />
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0.03987499"
		  id="stop9842" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.549981"
		  id="stop9844" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.58260849"
		  id="stop9846" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="1"
		  id="stop9848" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9860">
	   <path
		  d="m 244.768,164.454 10.899,-7.155 3.737,4.569 -10.868,6.729 z"
		  id="path9858" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.9310031,-5.2843752,-3.1547558,1.7495567,253.45036,165.62027)"
		spreadMethod="pad"
		id="linearGradient9880">
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0"
		  id="stop9870" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0.03987499"
		  id="stop9872" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.549981"
		  id="stop9874" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.58260849"
		  id="stop9876" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="1"
		  id="stop9878" />
	 </linearGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask9882">
	   <g
		  id="g9892">
		 <g
			id="g9890">
		   <g
			  id="g9888">
			 <g
				id="g9886">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#linearGradient9880);stroke:none"
				  id="path9884" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-2.9310031,-5.2843752,-3.1547558,1.7495567,253.45036,165.62027)"
		spreadMethod="pad"
		id="linearGradient9908">
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0"
		  id="stop9898" />
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0.03987499"
		  id="stop9900" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.549981"
		  id="stop9902" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.58260849"
		  id="stop9904" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="1"
		  id="stop9906" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9918">
	   <path
		  d="m 277.967,86.557 41.121,-24.926 8.78,7.812 -40.698,23.911 z"
		  id="path9916" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-5.5959134,-10.09137,-11.658353,6.4654899,305.52155,82.695938)"
		spreadMethod="pad"
		id="linearGradient9938">
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0"
		  id="stop9928" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0.03987499"
		  id="stop9930" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.549981"
		  id="stop9932" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.58260849"
		  id="stop9934" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="1"
		  id="stop9936" />
	 </linearGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask9940">
	   <g
		  id="g9950">
		 <g
			id="g9948">
		   <g
			  id="g9946">
			 <g
				id="g9944">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#linearGradient9938);stroke:none"
				  id="path9942" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-5.5959134,-10.09137,-11.658353,6.4654899,305.52155,82.695938)"
		spreadMethod="pad"
		id="linearGradient9966">
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0"
		  id="stop9956" />
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0.03987499"
		  id="stop9958" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.549981"
		  id="stop9960" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.58260849"
		  id="stop9962" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="1"
		  id="stop9964" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath9976">
	   <path
		  d="m 517.983,71.569 8.663,-5.839 6.06,7.103 -9.338,6.026 z"
		  id="path9974" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(10.090603,-5.5962815,-1.2312772,-2.2197897,520.31128,74.898605)"
		spreadMethod="pad"
		id="linearGradient9996">
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0"
		  id="stop9986" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0.03987499"
		  id="stop9988" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.549981"
		  id="stop9990" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.58260849"
		  id="stop9992" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="1"
		  id="stop9994" />
	 </linearGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask9998">
	   <g
		  id="g10008">
		 <g
			id="g10006">
		   <g
			  id="g10004">
			 <g
				id="g10002">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#linearGradient9996);stroke:none"
				  id="path10000" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(10.090603,-5.5962815,-1.2312772,-2.2197897,520.31128,74.898605)"
		spreadMethod="pad"
		id="linearGradient10024">
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0"
		  id="stop10014" />
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0.03987499"
		  id="stop10016" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.549981"
		  id="stop10018" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.58260849"
		  id="stop10020" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="1"
		  id="stop10022" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath10034">
	   <path
		  d="m 582.549,74.185 6.09,-4.412 5.601,7.358 -6.597,4.505 z"
		  id="path10032" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(7.2379975,-4.0137053,-1.2313244,-2.2198749,584.77289,77.572632)"
		spreadMethod="pad"
		id="linearGradient10054">
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0"
		  id="stop10044" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0.03987499"
		  id="stop10046" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.549981"
		  id="stop10048" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.58260849"
		  id="stop10050" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="1"
		  id="stop10052" />
	 </linearGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask10056">
	   <g
		  id="g10066">
		 <g
			id="g10064">
		   <g
			  id="g10062">
			 <g
				id="g10060">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#linearGradient10054);stroke:none"
				  id="path10058" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(7.2379975,-4.0137053,-1.2313244,-2.2198749,584.77289,77.572632)"
		spreadMethod="pad"
		id="linearGradient10082">
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0"
		  id="stop10072" />
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0.03987499"
		  id="stop10074" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.549981"
		  id="stop10076" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.58260849"
		  id="stop10078" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="1"
		  id="stop10080" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath10092">
	   <path
		  d="m 113.622,361.016 5.411,-6.124 13.623,17.596 -4.776,6.17 z"
		  id="path10090" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-6.7394218,4.821126,3.6549165,5.1091084,126.59288,364.53162)"
		spreadMethod="pad"
		id="linearGradient10112">
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0"
		  id="stop10102" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0.03987499"
		  id="stop10104" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.549981"
		  id="stop10106" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.58260849"
		  id="stop10108" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="1"
		  id="stop10110" />
	 </linearGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask10114">
	   <g
		  id="g10124">
		 <g
			id="g10122">
		   <g
			  id="g10120">
			 <g
				id="g10118">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#linearGradient10112);stroke:none"
				  id="path10116" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-6.7394218,4.821126,3.6549165,5.1091084,126.59288,364.53162)"
		spreadMethod="pad"
		id="linearGradient10140">
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0"
		  id="stop10130" />
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0.03987499"
		  id="stop10132" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.549981"
		  id="stop10134" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.58260849"
		  id="stop10136" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="1"
		  id="stop10138" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath10150">
	   <path
		  d="m 184.473,409.965 3.716,-13.273 45.443,29.633 -2.445,13.175 z"
		  id="path10148" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-7.6974788,11.17289,12.617803,8.6932869,213.26984,412.89697)"
		spreadMethod="pad"
		id="linearGradient10170">
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0"
		  id="stop10160" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0.03987499"
		  id="stop10162" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.549981"
		  id="stop10164" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.58260849"
		  id="stop10166" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="1"
		  id="stop10168" />
	 </linearGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask10172">
	   <g
		  id="g10182">
		 <g
			id="g10180">
		   <g
			  id="g10178">
			 <g
				id="g10176">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#linearGradient10170);stroke:none"
				  id="path10174" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-7.6974788,11.17289,12.617803,8.6932869,213.26984,412.89697)"
		spreadMethod="pad"
		id="linearGradient10198">
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0"
		  id="stop10188" />
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0.03987499"
		  id="stop10190" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.549981"
		  id="stop10192" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.58260849"
		  id="stop10194" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="1"
		  id="stop10196" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath10208">
	   <path
		  d="m 136.044,375.07 7.101,-11.2 22.495,19.031 -6.162,10.98 z"
		  id="path10206" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-9.2380857,9.9363184,6.0896945,5.6620274,155.61049,374.25168)"
		spreadMethod="pad"
		id="linearGradient10228">
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0"
		  id="stop10218" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0.03987499"
		  id="stop10220" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.549981"
		  id="stop10222" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.58260849"
		  id="stop10224" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="1"
		  id="stop10226" />
	 </linearGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask10230">
	   <g
		  id="g10240">
		 <g
			id="g10238">
		   <g
			  id="g10236">
			 <g
				id="g10234">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#linearGradient10228);stroke:none"
				  id="path10232" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(-9.2380857,9.9363184,6.0896945,5.6620274,155.61049,374.25168)"
		spreadMethod="pad"
		id="linearGradient10256">
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0"
		  id="stop10246" />
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0.03987499"
		  id="stop10248" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.549981"
		  id="stop10250" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.58260849"
		  id="stop10252" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="1"
		  id="stop10254" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath10266">
	   <path
		  d="m 607.081,157.058 6.161,-10.98 20.829,21.294 -4.496,8.717 z"
		  id="path10264" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(7.840456,-8.433053,-5.1683846,-4.805419,617.0592,165.76196)"
		spreadMethod="pad"
		id="linearGradient10286">
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0"
		  id="stop10276" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0.03987499"
		  id="stop10278" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.549981"
		  id="stop10280" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.58260849"
		  id="stop10282" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="1"
		  id="stop10284" />
	 </linearGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask10288">
	   <g
		  id="g10298">
		 <g
			id="g10296">
		   <g
			  id="g10294">
			 <g
				id="g10292">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#linearGradient10286);stroke:none"
				  id="path10290" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(7.840456,-8.433053,-5.1683846,-4.805419,617.0592,165.76196)"
		spreadMethod="pad"
		id="linearGradient10314">
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0"
		  id="stop10304" />
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0.03987499"
		  id="stop10306" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.549981"
		  id="stop10308" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.58260849"
		  id="stop10310" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="1"
		  id="stop10312" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath10324">
	   <path
		  d="m 648.589,181.679 6.162,-10.98 23.434,18.81 -7.101,11.202 z"
		  id="path10322" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(9.2380772,-9.9363089,-6.0896883,-5.6620216,658.61859,190.32869)"
		spreadMethod="pad"
		id="linearGradient10344">
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0"
		  id="stop10334" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0.03987499"
		  id="stop10336" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.549981"
		  id="stop10338" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.58260849"
		  id="stop10340" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="1"
		  id="stop10342" />
	 </linearGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask10346">
	   <g
		  id="g10356">
		 <g
			id="g10354">
		   <g
			  id="g10352">
			 <g
				id="g10350">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#linearGradient10344);stroke:none"
				  id="path10348" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(9.2380772,-9.9363089,-6.0896883,-5.6620216,658.61859,190.32869)"
		spreadMethod="pad"
		id="linearGradient10372">
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0"
		  id="stop10362" />
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0.03987499"
		  id="stop10364" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.549981"
		  id="stop10366" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.58260849"
		  id="stop10368" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="1"
		  id="stop10370" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath10382">
	   <path
		  d="M 0,489.356 H 841.89 V 0 H 0 Z"
		  id="path10380" />
	 </clipPath>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath10394">
	   <path
		  d="m 703.792,215.581 5.619,-9.15 16.938,13.185 -6.371,9.394 z"
		  id="path10392" />
	 </clipPath>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(7.9483876,-8.5490589,-4.34515,-4.0404496,710.99652,221.6987)"
		spreadMethod="pad"
		id="linearGradient10414">
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0"
		  id="stop10404" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="0.03987499"
		  id="stop10406" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.549981"
		  id="stop10408" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.58260849"
		  id="stop10410" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="1"
		  id="stop10412" />
	 </linearGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask10416">
	   <g
		  id="g10426">
		 <g
			id="g10424">
		   <g
			  id="g10422">
			 <g
				id="g10420">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#linearGradient10414);stroke:none"
				  id="path10418" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(7.9483876,-8.5490589,-4.34515,-4.0404496,710.99652,221.6987)"
		spreadMethod="pad"
		id="linearGradient10442">
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0"
		  id="stop10432" />
	   <stop
		  style="stop-opacity:1;stop-color:#091a38"
		  offset="0.03987499"
		  id="stop10434" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.549981"
		  id="stop10436" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="0.58260849"
		  id="stop10438" />
	   <stop
		  style="stop-opacity:1;stop-color:#0f1d3c"
		  offset="1"
		  id="stop10440" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(13.9132,0,0,-13.9132,696.51587,221.49596)"
		spreadMethod="pad"
		id="linearGradient10472">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop10458" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10082117"
		  id="stop10460" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.493534"
		  id="stop10462" />
	   <stop
		  style="stop-opacity:1;stop-color:#0070b0"
		  offset="0.51375878"
		  id="stop10464" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.772487"
		  id="stop10466" />
	   <stop
		  style="stop-opacity:1;stop-color:#282e6c"
		  offset="0.851852"
		  id="stop10468" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop10470" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(11.5942,0,0,-11.5942,697.67529,223.31575)"
		spreadMethod="pad"
		id="linearGradient10498">
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0"
		  id="stop10488" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="0.21164"
		  id="stop10490" />
	   <stop
		  style="stop-opacity:1;stop-color:#0778b7"
		  offset="0.612286"
		  id="stop10492" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.92079618"
		  id="stop10494" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="1"
		  id="stop10496" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(2.7249823,7.9103794,7.9103794,-2.7249823,701.98401,218.37086)"
		spreadMethod="pad"
		id="linearGradient10520">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop10514" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.20428413"
		  id="stop10516" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop10518" />
	 </linearGradient>
	 <linearGradient
		x1="0"
		y1="0"
		x2="1"
		y2="0"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(13.9132,0,0,-13.9132,696.51587,223.31575)"
		spreadMethod="pad"
		id="linearGradient10546">
	   <stop
		  style="stop-opacity:1;stop-color:#2f4e9e"
		  offset="0"
		  id="stop10536" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.29076831"
		  id="stop10538" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.488735"
		  id="stop10540" />
	   <stop
		  style="stop-opacity:1;stop-color:#253c81"
		  offset="0.58831037"
		  id="stop10542" />
	   <stop
		  style="stop-opacity:1;stop-color:#18234e"
		  offset="1"
		  id="stop10544" />
	 </linearGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath10556">
	   <path
		  d="m 271.1,304.843 c 0,-2.362 1.914,-4.277 4.276,-4.277 v 0 c 2.361,0 4.276,1.915 4.276,4.277 v 0 c 0,2.361 -1.915,4.275 -4.276,4.275 v 0 c -2.362,0 -4.276,-1.914 -4.276,-4.275"
		  id="path10554" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(4.2763486,0,0,-4.2763486,275.37589,304.84253)"
		spreadMethod="pad"
		id="radialGradient10576">
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0"
		  id="stop10566" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.10214207"
		  id="stop10568" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.5"
		  id="stop10570" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.60214207"
		  id="stop10572" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="1"
		  id="stop10574" />
	 </radialGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask10578">
	   <g
		  id="g10588">
		 <g
			id="g10586">
		   <g
			  id="g10584">
			 <g
				id="g10582">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#radialGradient10576);stroke:none"
				  id="path10580" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(4.2763486,0,0,-4.2763486,275.37589,304.84253)"
		spreadMethod="pad"
		id="radialGradient10604">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop10594" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10214207"
		  id="stop10596" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.5"
		  id="stop10598" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.60214207"
		  id="stop10600" />
	   <stop
		  style="stop-opacity:1;stop-color:#77b4f4"
		  offset="1"
		  id="stop10602" />
	 </radialGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath10614">
	   <path
		  d="m 258.11,312.2 c 0,-2.361 1.915,-4.277 4.277,-4.277 v 0 c 2.362,0 4.276,1.916 4.276,4.277 v 0 c 0,2.362 -1.914,4.276 -4.276,4.276 v 0 c -2.362,0 -4.277,-1.914 -4.277,-4.276"
		  id="path10612" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(4.2763486,0,0,-4.2763486,262.38681,312.19989)"
		spreadMethod="pad"
		id="radialGradient10634">
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0"
		  id="stop10624" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.10214207"
		  id="stop10626" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.5"
		  id="stop10628" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.60214207"
		  id="stop10630" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="1"
		  id="stop10632" />
	 </radialGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask10636">
	   <g
		  id="g10646">
		 <g
			id="g10644">
		   <g
			  id="g10642">
			 <g
				id="g10640">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#radialGradient10634);stroke:none"
				  id="path10638" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(4.2763486,0,0,-4.2763486,262.38681,312.19989)"
		spreadMethod="pad"
		id="radialGradient10662">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop10652" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10214207"
		  id="stop10654" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.5"
		  id="stop10656" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.60214207"
		  id="stop10658" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop10660" />
	 </radialGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath10672">
	   <path
		  d="m 246.341,319.074 c 0,-2.361 1.914,-4.276 4.277,-4.276 v 0 c 2.361,0 4.275,1.915 4.275,4.276 v 0 c 0,2.362 -1.914,4.277 -4.275,4.277 v 0 c -2.363,0 -4.277,-1.915 -4.277,-4.277"
		  id="path10670" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(4.2763486,0,0,-4.2763486,250.61714,319.0744)"
		spreadMethod="pad"
		id="radialGradient10692">
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0"
		  id="stop10682" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.10214207"
		  id="stop10684" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.5"
		  id="stop10686" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.60214207"
		  id="stop10688" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="1"
		  id="stop10690" />
	 </radialGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask10694">
	   <g
		  id="g10704">
		 <g
			id="g10702">
		   <g
			  id="g10700">
			 <g
				id="g10698">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#radialGradient10692);stroke:none"
				  id="path10696" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(4.2763486,0,0,-4.2763486,250.61714,319.0744)"
		spreadMethod="pad"
		id="radialGradient10720">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop10710" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10214207"
		  id="stop10712" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.5"
		  id="stop10714" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.60214207"
		  id="stop10716" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop10718" />
	 </radialGradient>
	 <clipPath
		clipPathUnits="userSpaceOnUse"
		id="clipPath10730">
	   <path
		  d="m 235.48,325.616 c 0,-2.362 1.914,-4.276 4.276,-4.276 v 0 c 2.362,0 4.276,1.914 4.276,4.276 v 0 c 0,2.362 -1.914,4.276 -4.276,4.276 v 0 c -2.362,0 -4.276,-1.914 -4.276,-4.276"
		  id="path10728" />
	 </clipPath>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(4.2763486,0,0,-4.2763486,239.75601,325.61606)"
		spreadMethod="pad"
		id="radialGradient10750">
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0"
		  id="stop10740" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.10214207"
		  id="stop10742" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.5"
		  id="stop10744" />
	   <stop
		  style="stop-opacity:1;stop-color:#ffffff"
		  offset="0.60214207"
		  id="stop10746" />
	   <stop
		  style="stop-opacity:0;stop-color:#ffffff"
		  offset="1"
		  id="stop10748" />
	 </radialGradient>
	 <mask
		maskUnits="userSpaceOnUse"
		x="0"
		y="0"
		width="1"
		height="1"
		id="mask10752">
	   <g
		  id="g10762">
		 <g
			id="g10760">
		   <g
			  id="g10758">
			 <g
				id="g10756">
			   <path
				  d="M -32768,32767 H 32767 V -32768 H -32768 Z"
				  style="fill:url(#radialGradient10750);stroke:none"
				  id="path10754" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </mask>
	 <radialGradient
		fx="0"
		fy="0"
		cx="0"
		cy="0"
		r="1"
		gradientUnits="userSpaceOnUse"
		gradientTransform="matrix(4.2763486,0,0,-4.2763486,239.75601,325.61606)"
		spreadMethod="pad"
		id="radialGradient10778">
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0"
		  id="stop10768" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.10214207"
		  id="stop10770" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.5"
		  id="stop10772" />
	   <stop
		  style="stop-opacity:1;stop-color:#38c2de"
		  offset="0.60214207"
		  id="stop10774" />
	   <stop
		  style="stop-opacity:1;stop-color:#0a5ea8"
		  offset="1"
		  id="stop10776" />
	 </radialGradient>
   </defs>
   
   <g
	  id="g8"
	  inkscape:groupmode="layer"
	  inkscape:label="wi_start_240401"
	  transform="matrix(1.3333333,0,0,-1.3333333,0,652.47467)">
	 <path
		d="M 0,0 H 1300 V 488 H 0 Z"
		style="fill:#0f1d3c;fill-opacity:0;fill-rule:nonzero;stroke:none"
		id="path10" />
	 <g
		id="g12"
		transform="translate(428.9851,147.7534)">
	   <path
		  d="m 0,0 17.486,-10.263 16.607,-9.588 72.668,-41.955 17.819,10.288 35.711,-20.53"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line"
		  data-duration="7000"
		  data-delay="1000"
		  id="path14" />
			<circle r="2" cx="0" cy="0" class="path14-circle circle"/>
			<circle r="2" cx="0" cy="0" class="path14-circle circle"/>
			<circle r="2" cx="0" cy="0" class="path14-circle circle"/>
			<circle r="2" cx="0" cy="0" class="path14-circle circle"/>

	   
	 </g>
	 <g
		id="g16"
		transform="translate(399.2456,130.5238)">
	   <path
		  d="M 0,0 8.467,4.948"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line-reverse"
		  id="path18" />
	 </g>
	 <g
		id="g20"
		transform="translate(191.4377,188.849)">
	   <path
		  d="M 0,0 -6.067,-3.503"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line"
		  id="path22" />
	 </g>
	 <g
		id="g24"
		transform="translate(137.8543,178.4904)">
	   <path
		  d="M 21.384522,-12.358104 35.61,-20.579 65.334,-3.431 47.658905,6.773705"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line-reverse"
		  id="path26"
		  data-duration="3000"
		  data-delay="1000"
		  sodipodi:nodetypes="cccc"
		  inkscape:label="path26" />
		  <circle r="2" cx="0" cy="0" class="path26-circle circle"/>
		  <circle r="2" cx="0" cy="0" class="path26-circle circle"/>
		  <circle r="2" cx="0" cy="0" class="path26-circle circle"/>

	   <path
		  d="M 47.689695,6.7737054 29.72779,17.144 0.03078981,3.864866e-7 21.415312,-12.358104"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line"
		  id="path11"
		  data-duration="3000"
		  data-delay="1000"
		  sodipodi:nodetypes="cccc"
		  inkscape:label="path26-1" />

		  <circle r="2" cx="0" cy="0" class="path11-circle circle"/>
		  <circle r="2" cx="0" cy="0" class="path11-circle circle"/>
		  <circle r="2" cx="0" cy="0" class="path11-circle circle"/>

	 </g>
	 <g
		id="g28"
		transform="translate(221.0039,192.2031)">
	   <path
		  d="M -29.514845,-3.3844731 -17.816,-10.286 0,0 -11.669634,6.7381869"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line-reverse"
		  id="path30"
		  sodipodi:nodetypes="cccc"
		  inkscape:label="path30" />
	   <path
		  d="m -11.404469,6.6056049 -12.086366,6.9788131 -17.561,-10.1390001 11.802155,-6.962473"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line"
		  id="path9"
		  sodipodi:nodetypes="cccc"
		  inkscape:label="path30-1" />
	 </g>
	 <g
		id="g32"
		transform="translate(327.8035,274.5578)">
	   <path
		  d="M 0,0 130.745,75.386 160.427,58.29"
		  style="fill:none;stroke:#ffffff;stroke-width:1;"
		  data-duration="5000"
		  data-delay="800"
		  class="line"
		  id="path34" />

		  <circle r="2" cx="0" cy="0" class="path34-circle circle reverse"/>
		  <circle r="2" cx="0" cy="0" class="path34-circle circle reverse"/>
		  <circle r="2" cx="0" cy="0" class="path34-circle circle reverse"/>
		  <circle r="2" cx="0" cy="0" class="path34-circle circle reverse"/>
		  <circle r="2" cx="0" cy="0" class="path34-circle circle reverse"/>
		  <circle r="2" cx="0" cy="0" class="path34-circle circle reverse"/>
		  <circle r="2" cx="0" cy="0" class="path34-circle circle reverse"/>
		  <circle r="2" cx="0" cy="0" class="path34-circle circle reverse"/>
		  <circle r="2" cx="0" cy="0" class="path34-circle circle reverse"/>

	 </g>
	 <g
		id="g36"
		transform="translate(543.1772,315.253)">
	   <path
		  d="M 4.7239636,-16.611411 16.355,-9.896 0,0 -12.255879,-7.0731394"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line-reverse"
		  id="path38"
		  sodipodi:nodetypes="cccc"
		  inkscape:label="path38" />
	   <path
		  d="M -12.443379,-7.1668896 -23.9485,-13.80675 l 16.3599996,-9.899 12.124964,7.000589"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line"
		  id="path6"
		  sodipodi:nodetypes="cccc"
		  inkscape:label="path38-1" />
	 </g>
	 <g
		id="g40"
		transform="translate(517.9223,315.704)">
	   <path
		  d="M 0,0 5.94,-3.432"
		  style="fill:none;stroke:#2f5289;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
		  id="path42" />
	 </g>
	 <g
		id="g44"
		transform="translate(541.68,301.9883)">
	   <path
		  d="M  -17.817,10.284 0,0"
		  style="fill:none;stroke:#ffffff;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-opacity:1"
		  id="path46" class="line" />
	 </g>
	 <g
		id="g48"
		transform="translate(541.68,301.9883)">
	   <path
		  d="M 0,0 53.454,-30.864 24.173027,-47.769452"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line"
		  id="path50"
		  data-duration="3000"
		  data-delay="700"
		  sodipodi:nodetypes="ccc" />
			<circle r="2" cx="0" cy="0" class="path50-circle circle"/>
			<circle r="2" cx="0" cy="0" class="path50-circle circle"/>
			<circle r="2" cx="0" cy="0" class="path50-circle circle"/>
			<circle r="2" cx="0" cy="0" class="path50-circle circle"/>
	   
	   <path
		  d="m 24.673728,-47.482221 -53.864027,-31.098548 -23.761,13.714"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line-reverse"
		  data-duration="3000"
		  data-delay="800"

		  id="path50-8"
		  sodipodi:nodetypes="ccc"
		  inkscape:label="path50-8" />
			<circle r="2" cx="0" cy="0" class="path50-8-circle circle reverse"/>
			<circle r="2" cx="0" cy="0" class="path50-8-circle circle reverse"/>
			<circle r="2" cx="0" cy="0" class="path50-8-circle circle reverse"/>
			<circle r="2" cx="0" cy="0" class="path50-8-circle circle reverse"/>

	 </g>
	 <g
		id="g52"
		transform="translate(476.3489,229.9757)">
	   <path
		  d="M 12.410666,7.1653026 23.762,13.719 0.006,27.435 -11.907927,20.556045"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line-reverse"
		  id="path54"
		  sodipodi:nodetypes="cccc"
		  inkscape:label="path54" />
	   <path
		  d="M -11.510179,20.82121 -23.353252,13.983165 0.39774793,0.2651654 12.808414,7.4304676"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line"
		  id="path5"
		  sodipodi:nodetypes="cccc"
		  inkscape:label="path54-1" />
	 </g>
	 <g
		id="g56"
		transform="translate(464.4836,250.5562)">
	   <path
		  d="M 0,0 -29.703,17.141 -41.582,10.283"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line-reverse"
		  data-duration="2000"
		  data-delay="1000"
		  id="path58" />

			<circle r="2" cx="0" cy="0" class="path58-circle circle reverse"/>
			<circle r="2" cx="0" cy="0" class="path58-circle circle reverse"/>
			<circle r="2" cx="0" cy="0" class="path58-circle circle reverse"/>
			<circle r="2" cx="0" cy="0" class="path58-circle circle reverse"/>


	 </g>
	 <g
		id="g60"
		transform="translate(191.2718,408.287)">
	   <path
		  d="M 65.852512,-16.844409 83.148,-6.859 35.634,20.573"
		  id="path62"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line"
		  sodipodi:nodetypes="ccc"
		  inkscape:label="path62" />
	   
	   <path
		  d="M 0.1409427,0.09193689 47.654942,-27.340062 l 18.33851,10.58759"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line-reverse"
		  id="path13"
		  sodipodi:nodetypes="ccc"
		  inkscape:label="path62-1" />
	   
	 </g>
	 <g
		id="g64"
		transform="translate(280.3563,377.4238)">
	   <path
		  d="M 0,0 47.515,-27.431"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line-reverse"
		  id="path66" />
	 </g>
	 <g
		id="g68"
		transform="translate(351.6717,336.2243)">
	   <path
		  d="M 0,0 -23.801,13.768"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line"
		  id="path70" />
	 </g>
	 <g
		id="g72"
		transform="translate(257.0881,391.1424)">
	   <path
		  d="M 0,0 23.268,-13.719"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line-reverse"
		  id="path74" />
	 </g>
	 <g
		id="g76"
		transform="translate(262.5424,367.1389)">
	   <path
		  d="M 17.854506,10.308154 35.634,20.573 71.302,-0.059 53.697082,-10.22355"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line-reverse"
		  id="path78"
		  data-duration="4000"
		  data-delay="1000"
		sodipodi:nodetypes="cccc"
		  inkscape:label="path78" />
		  <circle r="2" cx="0" cy="0" class="path78-circle circle reverse"/>
		  <circle r="2" cx="0" cy="0" class="path78-circle circle reverse"/>

		  <path
		  d="M 53.829665,-10.256695 35.800583,-20.666145 0.13258269,-0.03314562 17.987089,10.275009"
		  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
		  class="line"
		  id="path12"
			data-duration="4000"
			data-delay="1000"

		  sodipodi:nodetypes="cccc"
		  inkscape:label="path78-1" />

		  <circle r="2" cx="0" cy="0" class="path12-circle circle reverse"/>
		  <circle r="2" cx="0" cy="0" class="path12-circle circle reverse"/>

	 </g>
	 <g
		id="g80">
	   <g
		  id="g82">
		 <g
			id="g88">
		   <g
			  id="g90">
			 <path
				d="m 234.486,419.964 h -0.854 v -4.844 c 0,-4.745 6.664,-8.593 14.883,-8.593 v 0 c 8.22,0 14.883,3.848 14.883,8.593 v 0 1.97 2.874 h -0.854 c -2.048,3.332 -7.556,5.719 -14.029,5.719 v 0 c -6.473,0 -11.981,-2.387 -14.029,-5.719"
				style="fill:url(#linearGradient106);stroke:none"
				id="path108" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g110">
	   <g
		  id="g112">
		 <g
			id="g118">
		   <g
			  id="g120">
			 <path
				d="m 236.112,419.999 c 0,-3.955 5.553,-7.161 12.403,-7.161 v 0 c 6.85,0 12.403,3.206 12.403,7.161 v 0 c 0,3.955 -5.553,7.161 -12.403,7.161 v 0 c -6.85,0 -12.403,-3.206 -12.403,-7.161"
				style="fill:url(#linearGradient132);stroke:none"
				id="path134" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g136">
	   <g
		  id="g138">
		 <g
			id="g144">
		   <g
			  id="g146">
			 <path
				d="m 236.112,418.663 c 0,-3.955 5.553,-7.16 12.403,-7.16 v 0 c 6.85,0 12.403,3.205 12.403,7.16 v 0 c 0,3.955 -5.553,7.161 -12.403,7.161 v 0 c -6.85,0 -12.403,-3.206 -12.403,-7.161"
				style="fill:url(#linearGradient154);stroke:none"
				id="path156" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g158">
	   <g
		  id="g160">
		 <g
			id="g166">
		   <g
			  id="g168">
			 <path
				d="m 233.632,419.999 c 0,-4.746 6.664,-8.594 14.883,-8.594 v 0 c 8.22,0 14.883,3.848 14.883,8.594 v 0 c 0,4.745 -6.663,8.593 -14.883,8.593 v 0 c -8.219,0 -14.883,-3.848 -14.883,-8.593 m 2.48,0 c 0,3.955 5.553,7.161 12.403,7.161 v 0 c 6.85,0 12.403,-3.206 12.403,-7.161 v 0 c 0,-3.955 -5.553,-7.162 -12.403,-7.162 v 0 c -6.85,0 -12.403,3.207 -12.403,7.162"
				style="fill:url(#linearGradient180);stroke:none"
				id="path182" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g184">
	   <g
		  id="g186">
		 <g
			id="g192">
		   <g
			  id="g194">
			 <path
				d="m 201.745,399.371 h -0.854 v -4.842 c 0,-4.746 6.663,-8.594 14.883,-8.594 v 0 c 8.22,0 14.883,3.848 14.883,8.594 v 0 1.968 2.874 h -0.854 c -2.048,3.332 -7.555,5.719 -14.029,5.719 v 0 c -6.474,0 -11.981,-2.387 -14.029,-5.719"
				style="fill:url(#linearGradient210);stroke:none"
				id="path212" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g214">
	   <g
		  id="g216">
		 <g
			id="g222">
		   <g
			  id="g224">
			 <path
				d="m 203.371,399.406 c 0,-3.955 5.553,-7.161 12.403,-7.161 v 0 c 6.85,0 12.403,3.206 12.403,7.161 v 0 c 0,3.955 -5.553,7.161 -12.403,7.161 v 0 c -6.85,0 -12.403,-3.206 -12.403,-7.161"
				style="fill:url(#linearGradient236);stroke:none"
				id="path238" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g240">
	   <g
		  id="g242">
		 <g
			id="g248">
		   <g
			  id="g250">
			 <path
				d="m 203.371,398.071 c 0,-3.956 5.553,-7.161 12.403,-7.161 v 0 c 6.85,0 12.403,3.205 12.403,7.161 v 0 c 0,3.954 -5.553,7.16 -12.403,7.16 v 0 c -6.85,0 -12.403,-3.206 -12.403,-7.16"
				style="fill:url(#linearGradient258);stroke:none"
				id="path260" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g262">
	   <g
		  id="g264">
		 <g
			id="g270">
		   <g
			  id="g272">
			 <path
				d="m 200.891,399.406 c 0,-4.745 6.663,-8.593 14.883,-8.593 v 0 c 8.22,0 14.883,3.848 14.883,8.593 v 0 c 0,4.746 -6.663,8.593 -14.883,8.593 v 0 c -8.22,0 -14.883,-3.847 -14.883,-8.593 m 2.48,0 c 0,3.955 5.553,7.161 12.403,7.161 v 0 c 6.85,0 12.403,-3.206 12.403,-7.161 v 0 c 0,-3.955 -5.553,-7.161 -12.403,-7.161 v 0 c -6.85,0 -12.403,3.206 -12.403,7.161"
				style="fill:url(#linearGradient284);stroke:none"
				id="path286" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g288">
	   <g
		  id="g290">
		 <g
			id="g296">
		   <g
			  id="g298">
			 <path
				d="m 329.286,345.43 h -0.617 v -3.494 c 0,-3.424 4.808,-6.199 10.738,-6.199 v 0 c 5.93,0 10.737,2.775 10.737,6.199 v 0 1.42 2.074 h -0.616 c -1.478,2.404 -5.451,4.125 -10.121,4.125 v 0 c -4.67,0 -8.644,-1.721 -10.121,-4.125"
				style="fill:url(#linearGradient314);stroke:none"
				id="path316" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g318">
	   <g
		  id="g320">
		 <g
			id="g326">
		   <g
			  id="g328">
			 <path
				d="m 330.459,345.455 c 0,-2.853 4.006,-5.166 8.948,-5.166 v 0 c 4.942,0 8.948,2.313 8.948,5.166 v 0 c 0,2.853 -4.006,5.166 -8.948,5.166 v 0 c -4.942,0 -8.948,-2.313 -8.948,-5.166"
				style="fill:url(#linearGradient340);stroke:none"
				id="path342" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g344">
	   <g
		  id="g346">
		 <g
			id="g352">
		   <g
			  id="g354">
			 <path
				d="m 330.459,344.491 c 0,-2.853 4.006,-5.166 8.948,-5.166 v 0 c 4.942,0 8.948,2.313 8.948,5.166 v 0 c 0,2.853 -4.006,5.166 -8.948,5.166 v 0 c -4.942,0 -8.948,-2.313 -8.948,-5.166"
				style="fill:url(#linearGradient362);stroke:none"
				id="path364" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g366">
	   <g
		  id="g368">
		 <g
			id="g374">
		   <g
			  id="g376">
			 <path
				d="m 328.669,345.455 c 0,-3.424 4.808,-6.199 10.738,-6.199 v 0 c 5.93,0 10.737,2.775 10.737,6.199 v 0 c 0,3.424 -4.807,6.199 -10.737,6.199 v 0 c -5.93,0 -10.738,-2.775 -10.738,-6.199 m 1.79,0 c 0,2.853 4.006,5.166 8.948,5.166 v 0 c 4.942,0 8.948,-2.313 8.948,-5.166 v 0 c 0,-2.853 -4.006,-5.166 -8.948,-5.166 v 0 c -4.942,0 -8.948,2.313 -8.948,5.166"
				style="fill:url(#linearGradient388);stroke:none"
				id="path390" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g392">
	   <g
		  id="g394"
		  clip-path="url(#clipPath398)">
		 <g
			id="g400"
			transform="translate(345.7233,332.79)">
		   <path
			  d="M 23.769,-13.575375 2.757464e-7,0.14062501 5.7745417,3.4686727"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path402"
			  sodipodi:nodetypes="ccc"
			  inkscape:label="path402" />
		 </g>
		 <g
			id="g404"
			transform="translate(369.4921,319.0744)">
		   <path
			  d="m -18,17.15625 6.150759,3.310923 L 11.955746,6.9531503 -23.768,-13.716 -35.665,-6.861 -71.299,-27.435"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  data-duration="5000"
			  data-delay="1000"
			  id="path406"
			  sodipodi:nodetypes="cccccc" />

			  <circle r="2" cx="0" cy="0" class="path406-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path406-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path406-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path406-circle circle reverse"/>

		 </g>
		 <g
			id="g408"
			transform="translate(148.8104,377.9389)">
		   <path
			  d="M 0,0 168.34831,-97.230874 190.89,-110.25"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  data-duration="6000"
			  data-delay="800"
			  class="line-reverse"
			  id="path410"
			  sodipodi:nodetypes="ccc" />

			  <circle r="2" cx="0" cy="0" class="path410-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path410-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path410-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path410-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path410-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path410-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path410-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path410-circle circle reverse"/>

		 </g>
		 <g
			id="g412"
			transform="translate(238.7918,325.9819)">
		   <path
			  d="M 0,0 -35.421,-20.437 -85.795,8.369"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  data-duration="3000"
			  data-delay="1000"
			  id="path414" />
			  <circle r="2" cx="0" cy="0" class="path414-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path414-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path414-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path414-circle circle reverse"/>
		 </g>
	   </g>
	 </g>
	 <g
		id="g416">
	   <g
		  id="g418">
		 <g
			id="g424">
		   <g
			  id="g426">
			 <path
				d="m 154.172,367.015 h -1.175 v -6.66 c 0,-6.524 9.161,-11.813 20.462,-11.813 v 0 c 11.3,0 20.461,5.289 20.461,11.813 v 0 2.707 3.953 h -1.174 c -2.816,4.58 -10.388,7.861 -19.287,7.861 v 0 c -8.899,0 -16.471,-3.281 -19.287,-7.861"
				style="fill:url(#linearGradient442);stroke:none"
				id="path444" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g446">
	   <g
		  id="g448">
		 <g
			id="g454">
		   <g
			  id="g456">
			 <path
				d="m 156.407,367.061 c 0,-5.437 7.635,-9.844 17.052,-9.844 v 0 c 9.417,0 17.051,4.407 17.051,9.844 v 0 c 0,5.438 -7.634,9.845 -17.051,9.845 v 0 c -9.417,0 -17.052,-4.407 -17.052,-9.845"
				style="fill:url(#linearGradient468);stroke:none"
				id="path470" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g472">
	   <g
		  id="g474">
		 <g
			id="g480">
		   <g
			  id="g482">
			 <path
				d="m 156.407,365.225 c 0,-5.437 7.635,-9.844 17.052,-9.844 v 0 c 9.417,0 17.051,4.407 17.051,9.844 v 0 c 0,5.437 -7.634,9.845 -17.051,9.845 v 0 c -9.417,0 -17.052,-4.408 -17.052,-9.845"
				style="fill:url(#linearGradient490);stroke:none"
				id="path492" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g494">
	   <g
		  id="g496">
		 <g
			id="g502">
		   <g
			  id="g504">
			 <path
				d="m 152.997,367.061 c 0,-6.524 9.161,-11.813 20.462,-11.813 v 0 c 11.3,0 20.461,5.289 20.461,11.813 v 0 c 0,6.525 -9.161,11.815 -20.461,11.815 v 0 c -11.301,0 -20.462,-5.29 -20.462,-11.815 m 3.41,0 c 0,5.438 7.635,9.845 17.052,9.845 v 0 c 9.417,0 17.051,-4.407 17.051,-9.845 v 0 c 0,-5.437 -7.634,-9.844 -17.051,-9.844 v 0 c -9.417,0 -17.052,4.407 -17.052,9.844"
				style="fill:url(#linearGradient516);stroke:none"
				id="path518" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g520">
	   <g
		  id="g522">
		 <g
			id="g528">
		   <g
			  id="g530">
			 <path
				d="m 158.197,324.758 h -0.979 v -5.542 c 0,-5.43 7.625,-9.833 17.03,-9.833 v 0 c 9.405,0 17.03,4.403 17.03,9.833 v 0 2.252 3.29 H 190.3 c -2.343,3.812 -8.645,6.543 -16.052,6.543 v 0 c -7.407,0 -13.708,-2.731 -16.051,-6.543"
				style="fill:url(#linearGradient546);stroke:none"
				id="path548" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g550">
	   <g
		  id="g552">
		 <g
			id="g558">
		   <g
			  id="g560">
			 <path
				d="m 160.057,324.796 c 0,-4.525 6.354,-8.192 14.191,-8.192 v 0 c 7.837,0 14.191,3.667 14.191,8.192 v 0 c 0,4.526 -6.354,8.194 -14.191,8.194 v 0 c -7.837,0 -14.191,-3.668 -14.191,-8.194"
				style="fill:url(#linearGradient572);stroke:none"
				id="path574" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g576">
	   <g
		  id="g578">
		 <g
			id="g584">
		   <g
			  id="g586">
			 <path
				d="m 160.057,323.269 c 0,-4.525 6.354,-8.193 14.191,-8.193 v 0 c 7.837,0 14.191,3.668 14.191,8.193 v 0 c 0,4.524 -6.354,8.193 -14.191,8.193 v 0 c -7.837,0 -14.191,-3.669 -14.191,-8.193"
				style="fill:url(#linearGradient594);stroke:none"
				id="path596" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g598">
	   <g
		  id="g600">
		 <g
			id="g606">
		   <g
			  id="g608">
			 <path
				d="m 157.218,324.796 c 0,-5.429 7.625,-9.831 17.03,-9.831 v 0 c 9.405,0 17.03,4.402 17.03,9.831 v 0 c 0,5.431 -7.625,9.833 -17.03,9.833 v 0 c -9.405,0 -17.03,-4.402 -17.03,-9.833 m 2.839,0 c 0,4.526 6.354,8.194 14.191,8.194 v 0 c 7.837,0 14.191,-3.668 14.191,-8.194 v 0 c 0,-4.525 -6.354,-8.193 -14.191,-8.193 v 0 c -7.837,0 -14.191,3.668 -14.191,8.193"
				style="fill:url(#linearGradient620);stroke:none"
				id="path622" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g624">
	   <g
		  id="g626">
		 <g
			id="g632">
		   <g
			  id="g634">
			 <path
				d="m 464.474,319.118 35.63,-20.602 47.513,27.43 -35.63,20.581 z"
				style="fill:url(#radialGradient646);stroke:none"
				id="path648" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g650">
	   <g
		  id="g652">
		 <g
			id="g658">
		   <g
			  id="g660">
			 <path
				d="m 511.979,352.807 35.63,-20.574 v 4.186 L 511.979,357 Z"
				style="fill:url(#linearGradient676);stroke:none"
				id="path678" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g680">
	   <g
		  id="g682">
		 <g
			id="g688">
		   <g
			  id="g690">
			 <path
				d="m 511.979,359.081 35.63,-20.574 v 4.186 l -35.63,20.581 z"
				style="fill:url(#linearGradient706);stroke:none"
				id="path708" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g710">
	   <g
		  id="g712">
		 <g
			id="g718">
		   <g
			  id="g720">
			 <path
				d="m 511.979,346.534 35.63,-20.574 v 4.186 l -35.63,20.581 z"
				style="fill:url(#linearGradient736);stroke:none"
				id="path738" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g740">
	   <g
		  id="g742">
		 <g
			id="g748">
		   <g
			  id="g750">
			 <path
				d="m 464.467,329.578 v -4.189 l 47.512,27.411 v 4.19 z"
				style="fill:url(#linearGradient762);stroke:none"
				id="path764" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g766">
	   <g
		  id="g768">
		 <g
			id="g774">
		   <g
			  id="g776">
			 <path
				d="m 464.467,335.852 v -4.189 l 47.512,27.411 v 4.189 z"
				style="fill:url(#linearGradient788);stroke:none"
				id="path790" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g792">
	   <g
		  id="g794">
		 <g
			id="g800">
		   <g
			  id="g802">
			 <path
				d="m 464.467,323.305 v -4.19 l 47.512,27.411 v 4.19 z"
				style="fill:url(#linearGradient814);stroke:none"
				id="path816" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g818">
	   <g
		  id="g820"
		  clip-path="url(#clipPath824)">
		 <g
			id="g826">
		   <g
			  id="g828" />
		   <g
			  id="g862">
			 <g
				clip-path="url(#clipPath832)"
				opacity="0.600006"
				id="g860">
			   <g
				  id="g858">
				 <g
					id="g856">
				   <g
					  id="g854">
					 <g
						id="g852">
					   <path
						  d="m 511.973,363.263 0.006,-16.714 -47.502,-27.428 v 16.739 z"
						  style="fill:url(#radialGradient848);stroke:none"
						  id="path850" />
					 </g>
				   </g>
				 </g>
			   </g>
			 </g>
		   </g>
		 </g>
		 <g
			id="g864">
		   <g
			  id="g866" />
		   <g
			  id="g900">
			 <g
				clip-path="url(#clipPath870)"
				opacity="0.600006"
				id="g898">
			   <g
				  id="g896">
				 <g
					id="g894">
				   <g
					  id="g892">
					 <g
						id="g890">
					   <path
						  d="m 511.986,363.274 -0.007,-16.714 35.641,-20.594 v 16.739 z"
						  style="fill:url(#radialGradient886);stroke:none"
						  id="path888" />
					 </g>
				   </g>
				 </g>
			   </g>
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g902">
	   <g
		  id="g904">
		 <g
			id="g910">
		   <g
			  id="g912">
			 <path
				d="m 500.104,308.992 v -4.19 l 47.513,27.431 v 4.19 z"
				style="fill:url(#linearGradient924);stroke:none"
				id="path926" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g928">
	   <g
		  id="g930">
		 <g
			id="g936">
		   <g
			  id="g938">
			 <path
				d="m 500.104,302.707 v -4.19 l 47.513,27.431 v 4.19 z"
				style="fill:url(#linearGradient950);stroke:none"
				id="path952" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g954">
	   <g
		  id="g956">
		 <g
			id="g962">
		   <g
			  id="g964">
			 <path
				d="m 500.104,315.266 v -4.19 l 47.513,27.43 v 4.191 z"
				style="fill:url(#linearGradient976);stroke:none"
				id="path978" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g980">
	   <g
		  id="g982">
		 <g
			id="g988">
		   <g
			  id="g990">
			 <path
				d="m 464.474,331.658 35.63,-20.594 v 4.186 l -35.63,20.601 z"
				style="fill:url(#linearGradient1002);stroke:none"
				id="path1004" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1006">
	   <g
		  id="g1008">
		 <g
			id="g1014">
		   <g
			  id="g1016">
			 <path
				d="m 464.474,325.401 35.63,-20.594 v 4.186 l -35.63,20.601 z"
				style="fill:url(#linearGradient1028);stroke:none"
				id="path1030" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1032">
	   <g
		  id="g1034">
		 <g
			id="g1040">
		   <g
			  id="g1042">
			 <path
				d="m 464.474,319.111 35.63,-20.595 v 4.186 l -35.63,20.602 z"
				style="fill:url(#linearGradient1054);stroke:none"
				id="path1056" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1058"
		style="display:inline">
	   <g
		  id="g1060"
		  clip-path="url(#clipPath1064)">
		 <g
			id="g1066">
		   <g
			  id="g1068" />
		   <g
			  id="g1102">
			 <g
				clip-path="url(#clipPath1072)"
				opacity="0.660004"
				id="g1100">
			   <g
				  id="g1098">
				 <g
					id="g1096">
				   <g
					  id="g1094">
					 <g
						id="g1092">
					   <path
						  d="m 464.474,335.854 47.513,27.409 35.63,-20.581 -47.513,-27.429 z"
						  style="fill:url(#radialGradient1088);stroke:none"
						  id="path1090" />
					 </g>
				   </g>
				 </g>
			   </g>
			 </g>
		   </g>
		 </g>
		 <g
			id="g1104"
			transform="translate(369.5821,223.1206)">
		   <path
			  d="M 0,0 -5.985,-3.455"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path1106" />
		 </g>
		 <g
			id="g1108"
			transform="translate(351.7674,226.5478)">
		   <path
			  d="M 11.852235,-6.9000642 23.651,-13.769 5.837,-24.058 -5.902528,-17.280444"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path1110"
			  sodipodi:nodetypes="cccc" />
		   <path
			  d="M -5.8300917,-17.356482 -17.846563,-10.419038 0.07243637,-0.07603811 11.924672,-6.9761017"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  id="path1110-5"
			  sodipodi:nodetypes="cccc" />
		 </g>
		 <g
			id="g1112"
			transform="translate(367.7067,217.2681)">
		   <path
			  d="M 0,0 -17.919,-10.261"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path1114" />
		 </g>
		 <g
			id="g1116"
			transform="translate(359.6298,221.9704)">
		   <path
			  d="M 0,0 -17.86,-10.338"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path1118" />
		 </g>
		 <g
			id="g1120"
			transform="translate(351.5628,274.5533)">
		   <path
			  d="M 5.8976173,-17.283279 17.919,-10.343 0,0 -11.964553,-6.9079733"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path1122"
			  sodipodi:nodetypes="cccc" />
		   <path
			  d="m -11.709188,-6.8735547 -11.791446,-6.8080273 17.9189992,-10.342 11.734618,6.774721"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  id="path1122-6"
			  sodipodi:nodetypes="cccc" />
		 </g>
		 <g
			id="g1124"
			transform="translate(339.7002,267.6892)">
		   <path
			  d="M 0,0 C 9.9241834,-5.7391925 19.853993,-11.468629 29.785,-17.196"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  id="path1126"
			  sodipodi:nodetypes="cc" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g1128">
	   <g
		  id="g1130">
		 <g
			id="g1136">
		   <g
			  id="g1138">
			 <path
				d="m 357.611,229.935 23.749,-13.726 47.512,27.428 -23.484,13.883 z"
				style="fill:url(#radialGradient1150);stroke:none"
				id="path1152" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1154">
	   <g
		  id="g1156">
		 <g
			id="g1162">
		   <g
			  id="g1164">
			 <path
				d="m 405.378,265.298 23.484,-13.873 v 5.185 l -23.484,13.882 z"
				style="fill:url(#linearGradient1180);stroke:none"
				id="path1182" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1184">
	   <g
		  id="g1186">
		 <g
			id="g1192">
		   <g
			  id="g1194">
			 <path
				d="m 405.378,273.07 23.484,-13.874 v 5.185 l -23.484,13.883 z"
				style="fill:url(#linearGradient1210);stroke:none"
				id="path1212" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1214">
	   <g
		  id="g1216">
		 <g
			id="g1222">
		   <g
			  id="g1224">
			 <path
				d="m 405.378,257.528 23.484,-13.874 v 5.185 l -23.484,13.883 z"
				style="fill:url(#linearGradient1240);stroke:none"
				id="path1242" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1244">
	   <g
		  id="g1246">
		 <g
			id="g1252">
		   <g
			  id="g1254">
			 <path
				d="m 357.601,242.892 v -5.189 l 47.777,27.586 v 5.19 z"
				style="fill:url(#linearGradient1266);stroke:none"
				id="path1268" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1270">
	   <g
		  id="g1272">
		 <g
			id="g1278">
		   <g
			  id="g1280">
			 <path
				d="m 357.601,250.663 v -5.19 l 47.777,27.587 v 5.189 z"
				style="fill:url(#linearGradient1292);stroke:none"
				id="path1294" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1296">
	   <g
		  id="g1298">
		 <g
			id="g1304">
		   <g
			  id="g1306">
			 <path
				d="m 357.601,235.12 v -5.189 l 47.777,27.587 v 5.189 z"
				style="fill:url(#linearGradient1318);stroke:none"
				id="path1320" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1322">
	   <g
		  id="g1324"
		  clip-path="url(#clipPath1328)">
		 <g
			id="g1330">
		   <g
			  id="g1332" />
		   <g
			  id="g1366">
			 <g
				clip-path="url(#clipPath1336)"
				opacity="0.600006"
				id="g1364">
			   <g
				  id="g1362">
				 <g
					id="g1360">
				   <g
					  id="g1358">
					 <g
						id="g1356">
					   <path
						  d="m 405.37,278.25 0.008,-20.704 -47.764,-27.608 v 20.734 z"
						  style="fill:url(#radialGradient1352);stroke:none"
						  id="path1354" />
					 </g>
				   </g>
				 </g>
			   </g>
			 </g>
		   </g>
		 </g>
		 <g
			id="g1368">
		   <g
			  id="g1370" />
		   <g
			  id="g1404">
			 <g
				clip-path="url(#clipPath1374)"
				opacity="0.600006"
				id="g1402">
			   <g
				  id="g1400">
				 <g
					id="g1398">
				   <g
					  id="g1396">
					 <g
						id="g1394">
					   <path
						  d="m 405.386,278.263 -0.008,-20.703 23.498,-13.898 v 20.733 z"
						  style="fill:url(#radialGradient1390);stroke:none"
						  id="path1392" />
					 </g>
				   </g>
				 </g>
			   </g>
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1406">
	   <g
		  id="g1408">
		 <g
			id="g1414">
		   <g
			  id="g1416">
			 <path
				d="m 381.36,229.183 v -5.19 l 47.512,27.431 v 5.189 z"
				style="fill:url(#linearGradient1428);stroke:none"
				id="path1430" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1432">
	   <g
		  id="g1434">
		 <g
			id="g1440">
		   <g
			  id="g1442">
			 <path
				d="m 381.36,221.398 v -5.189 l 47.512,27.431 v 5.189 z"
				style="fill:url(#linearGradient1454);stroke:none"
				id="path1456" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1458">
	   <g
		  id="g1460">
		 <g
			id="g1466">
		   <g
			  id="g1468">
			 <path
				d="m 381.36,236.954 v -5.19 l 47.512,27.431 v 5.19 z"
				style="fill:url(#linearGradient1480);stroke:none"
				id="path1482" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1484">
	   <g
		  id="g1486">
		 <g
			id="g1492">
		   <g
			  id="g1494">
			 <path
				d="M 357.611,245.468 381.36,231.75 v 5.185 l -23.749,13.727 z"
				style="fill:url(#linearGradient1506);stroke:none"
				id="path1508" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1510">
	   <g
		  id="g1512">
		 <g
			id="g1518">
		   <g
			  id="g1520">
			 <path
				d="M 357.611,237.718 381.36,224 v 5.185 l -23.749,13.727 z"
				style="fill:url(#linearGradient1532);stroke:none"
				id="path1534" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1536">
	   <g
		  id="g1538">
		 <g
			id="g1544">
		   <g
			  id="g1546">
			 <path
				d="m 357.611,229.926 23.749,-13.717 v 5.184 l -23.749,13.727 z"
				style="fill:url(#linearGradient1558);stroke:none"
				id="path1560" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1562">
	   <g
		  id="g1564"
		  clip-path="url(#clipPath1568)">
		 <g
			id="g1570"
			style="display:inline">
		   <g
			  id="g1572" />
		   <g
			  id="g1606">
			 <g
				clip-path="url(#clipPath1576)"
				opacity="0.660004"
				id="g1604">
			   <g
				  id="g1602">
				 <g
					id="g1600">
				   <g
					  id="g1598">
					 <g
						id="g1596">
					   <path
						  d="m 357.61,250.665 47.778,27.585 23.484,-13.883 -47.513,-27.428 z"
						  style="fill:url(#radialGradient1592);stroke:none"
						  id="path1594" />
					 </g>
				   </g>
				 </g>
			   </g>
			 </g>
		   </g>
		 </g>
		 <g
			id="g1608"
			transform="translate(345.7265,209.3475)"
			style="display:inline">
		   <path
			  d="M 0,0 -23.835,-13.776 -47.488,0.002 -65.303,-10.286"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  data-duration="1500"
			  data-delay="600"

			  id="path1610" />


			  <circle r="2" cx="0" cy="0" class="path1610-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path1610-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path1610-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path1610-circle circle"/>

		 </g>
		 <g
			id="g1612"
			transform="translate(304.1239,205.9212)"
			style="display:inline">
		   <path
			  d="M 0,0 -17.655,-10.35"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path1614" />
		 </g>
		 <g
			id="g1616"
			transform="translate(292.2989,192.2019)"
			style="display:inline">
		   <path
			  d="M 0,0 17.766,10.258"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  id="path1618" />
		 </g>
		 <g
			id="g1620"
			transform="translate(279.8188,178.9419)"
			style="display:inline">
		   <path
			  d="M 0,0 -30.163,-17.406"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path1622" />
		 </g>
		 <g
			id="g1624"
			transform="translate(272.821,180.9702)"
			style="display:inline">
		   <path
			  d="M 0,0 -10.241,-5.911 -34.005,7.809 1.633,28.379 -22.117,42.091 -63.698,18.092"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  data-duration="4000"
			  data-delay="1000"
			  id="path1626" />
			  <circle r="2" cx="0" cy="0" class="path1626-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path1626-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path1626-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path1626-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path1626-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path1626-circle circle"/>

		 </g>
	   </g>
	 </g>
	 <g
		id="g1628">
	   <g
		  id="g1630">
		 <g
			id="g1636">
		   <g
			  id="g1638">
			 <path
				d="m 263.633,194.269 h -1.028 v -5.832 c 0,-5.714 8.023,-10.347 17.92,-10.347 v 0 c 9.898,0 17.921,4.633 17.921,10.347 v 0 2.37 3.462 h -1.028 c -2.467,4.011 -9.099,6.885 -16.893,6.885 v 0 c -7.794,0 -14.426,-2.874 -16.892,-6.885"
				style="fill:url(#linearGradient1654);stroke:none"
				id="path1656" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1658">
	   <g
		  id="g1660">
		 <g
			id="g1666">
		   <g
			  id="g1668">
			 <path
				d="m 265.591,194.31 c 0,-4.762 6.687,-8.622 14.934,-8.622 v 0 c 8.248,0 14.934,3.86 14.934,8.622 v 0 c 0,4.762 -6.686,8.622 -14.934,8.622 v 0 c -8.247,0 -14.934,-3.86 -14.934,-8.622"
				style="fill:url(#linearGradient1680);stroke:none"
				id="path1682" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1684">
	   <g
		  id="g1686">
		 <g
			id="g1692">
		   <g
			  id="g1694">
			 <path
				d="m 265.591,192.702 c 0,-4.762 6.687,-8.622 14.934,-8.622 v 0 c 8.248,0 14.934,3.86 14.934,8.622 v 0 c 0,4.762 -6.686,8.622 -14.934,8.622 v 0 c -8.247,0 -14.934,-3.86 -14.934,-8.622"
				style="fill:url(#linearGradient1702);stroke:none"
				id="path1704" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1706">
	   <g
		  id="g1708">
		 <g
			id="g1714">
		   <g
			  id="g1716">
			 <path
				d="m 262.605,194.31 c 0,-5.714 8.023,-10.346 17.92,-10.346 v 0 c 9.898,0 17.921,4.632 17.921,10.346 v 0 c 0,5.714 -8.023,10.346 -17.921,10.346 v 0 c -9.897,0 -17.92,-4.632 -17.92,-10.346 m 2.986,0 c 0,4.762 6.687,8.622 14.934,8.622 v 0 c 8.248,0 14.934,-3.86 14.934,-8.622 v 0 c 0,-4.762 -6.686,-8.622 -14.934,-8.622 v 0 c -8.247,0 -14.934,3.86 -14.934,8.622"
				style="fill:url(#linearGradient1728);stroke:none"
				id="path1730" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1732">
	   <g
		  id="g1734">
		 <g
			id="g1740">
		   <g
			  id="g1742">
			 <path
				d="m 216.68,206.408 h -0.263 v -1.494 c 0,-1.464 2.054,-2.65 4.589,-2.65 v 0 c 2.535,0 4.59,1.186 4.59,2.65 v 0 0.607 0.887 h -0.264 c -0.632,1.027 -2.33,1.763 -4.326,1.763 v 0 c -1.996,0 -3.695,-0.736 -4.326,-1.763"
				style="fill:url(#linearGradient1758);stroke:none"
				id="path1760" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1762">
	   <g
		  id="g1764">
		 <g
			id="g1770">
		   <g
			  id="g1772">
			 <path
				d="m 217.182,206.419 c 0,-1.221 1.711,-2.209 3.824,-2.209 v 0 c 2.112,0 3.825,0.988 3.825,2.209 v 0 c 0,1.219 -1.713,2.207 -3.825,2.207 v 0 c -2.113,0 -3.824,-0.988 -3.824,-2.207"
				style="fill:url(#linearGradient1784);stroke:none"
				id="path1786" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1788">
	   <g
		  id="g1790">
		 <g
			id="g1796">
		   <g
			  id="g1798">
			 <path
				d="m 217.182,206.006 c 0,-1.219 1.711,-2.208 3.824,-2.208 v 0 c 2.112,0 3.825,0.989 3.825,2.208 v 0 c 0,1.22 -1.713,2.209 -3.825,2.209 v 0 c -2.113,0 -3.824,-0.989 -3.824,-2.209"
				style="fill:url(#linearGradient1806);stroke:none"
				id="path1808" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1810">
	   <g
		  id="g1812">
		 <g
			id="g1818">
		   <g
			  id="g1820">
			 <path
				d="m 216.417,206.419 c 0,-1.464 2.054,-2.651 4.589,-2.651 v 0 c 2.535,0 4.59,1.187 4.59,2.651 v 0 c 0,1.463 -2.055,2.649 -4.59,2.649 v 0 c -2.535,0 -4.589,-1.186 -4.589,-2.649 m 0.765,0 c 0,1.219 1.711,2.207 3.824,2.207 v 0 c 2.112,0 3.825,-0.988 3.825,-2.207 v 0 c 0,-1.221 -1.713,-2.209 -3.825,-2.209 v 0 c -2.113,0 -3.824,0.988 -3.824,2.209"
				style="fill:url(#linearGradient1832);stroke:none"
				id="path1834" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1836">
	   <g
		  id="g1838"
		  clip-path="url(#clipPath1842)">
		 <g
			id="g1844"
			transform="translate(250.6975,181.9147)">
		   <path
			  d="M 0,0 -17.818,-10.287"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path1846" />
		 </g>
		 <g
			id="g1848"
			transform="translate(187.2162,191.3394)">
		   <path
			  d="M 0,0 17.678,10.166"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path1850" />
		 </g>
		 <g
			id="g1852"
			transform="translate(194.9807,186.7589)">
		   <path
			  d="M 0,0 17.51,10.291"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path1854" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g1856">
	   <g
		  id="g1858">
		 <g
			id="g1864">
		   <g
			  id="g1866">
			 <path
				d="m 149.123,189.381 h -0.4 v -2.264 c 0,-2.218 3.115,-4.017 6.956,-4.017 v 0 c 3.842,0 6.957,1.799 6.957,4.017 v 0 0.92 1.344 h -0.399 c -0.958,1.557 -3.532,2.673 -6.558,2.673 v 0 c -3.025,0 -5.6,-1.116 -6.556,-2.673"
				style="fill:url(#linearGradient1882);stroke:none"
				id="path1884" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1886">
	   <g
		  id="g1888">
		 <g
			id="g1894">
		   <g
			  id="g1896">
			 <path
				d="m 149.882,189.397 c 0,-1.849 2.596,-3.347 5.797,-3.347 v 0 c 3.203,0 5.798,1.498 5.798,3.347 v 0 c 0,1.848 -2.595,3.347 -5.798,3.347 v 0 c -3.201,0 -5.797,-1.499 -5.797,-3.347"
				style="fill:url(#linearGradient1908);stroke:none"
				id="path1910" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1912">
	   <g
		  id="g1914">
		 <g
			id="g1920">
		   <g
			  id="g1922">
			 <path
				d="m 149.882,188.772 c 0,-1.848 2.596,-3.347 5.797,-3.347 v 0 c 3.203,0 5.798,1.499 5.798,3.347 v 0 c 0,1.849 -2.595,3.347 -5.798,3.347 v 0 c -3.201,0 -5.797,-1.498 -5.797,-3.347"
				style="fill:url(#linearGradient1930);stroke:none"
				id="path1932" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1934">
	   <g
		  id="g1936">
		 <g
			id="g1942">
		   <g
			  id="g1944">
			 <path
				d="m 148.723,189.397 c 0,-2.219 3.115,-4.017 6.956,-4.017 v 0 c 3.842,0 6.957,1.798 6.957,4.017 v 0 c 0,2.218 -3.115,4.016 -6.957,4.016 v 0 c -3.841,0 -6.956,-1.798 -6.956,-4.016 m 1.159,0 c 0,1.848 2.596,3.347 5.797,3.347 v 0 c 3.203,0 5.798,-1.499 5.798,-3.347 v 0 c 0,-1.849 -2.595,-3.347 -5.798,-3.347 v 0 c -3.201,0 -5.797,1.498 -5.797,3.347"
				style="fill:url(#linearGradient1956);stroke:none"
				id="path1958" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1960">
	   <g
		  id="g1962">
		 <g
			id="g1968">
		   <g
			  id="g1970">
			 <path
				d="m 184.739,168.817 h -0.399 v -2.264 c 0,-2.218 3.115,-4.016 6.957,-4.016 v 0 c 3.841,0 6.956,1.798 6.956,4.016 v 0 0.921 1.343 h -0.4 c -0.957,1.557 -3.531,2.673 -6.556,2.673 v 0 c -3.026,0 -5.6,-1.116 -6.558,-2.673"
				style="fill:url(#linearGradient1986);stroke:none"
				id="path1988" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g1990">
	   <g
		  id="g1992">
		 <g
			id="g1998">
		   <g
			  id="g2000">
			 <path
				d="m 185.499,168.834 c 0,-1.849 2.596,-3.347 5.798,-3.347 v 0 c 3.201,0 5.797,1.498 5.797,3.347 v 0 c 0,1.848 -2.596,3.347 -5.797,3.347 v 0 c -3.202,0 -5.798,-1.499 -5.798,-3.347"
				style="fill:url(#linearGradient2012);stroke:none"
				id="path2014" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2016">
	   <g
		  id="g2018">
		 <g
			id="g2024">
		   <g
			  id="g2026">
			 <path
				d="m 185.499,168.209 c 0,-1.849 2.596,-3.347 5.798,-3.347 v 0 c 3.201,0 5.797,1.498 5.797,3.347 v 0 c 0,1.849 -2.596,3.347 -5.797,3.347 v 0 c -3.202,0 -5.798,-1.498 -5.798,-3.347"
				style="fill:url(#linearGradient2034);stroke:none"
				id="path2036" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2038">
	   <g
		  id="g2040">
		 <g
			id="g2046">
		   <g
			  id="g2048">
			 <path
				d="m 184.34,168.834 c 0,-2.219 3.115,-4.017 6.957,-4.017 v 0 c 3.841,0 6.956,1.798 6.956,4.017 v 0 c 0,2.217 -3.115,4.016 -6.956,4.016 v 0 c -3.842,0 -6.957,-1.799 -6.957,-4.016 m 1.159,0 c 0,1.848 2.596,3.347 5.798,3.347 v 0 c 3.201,0 5.797,-1.499 5.797,-3.347 v 0 c 0,-1.849 -2.596,-3.347 -5.797,-3.347 v 0 c -3.202,0 -5.798,1.498 -5.798,3.347"
				style="fill:url(#linearGradient2060);stroke:none"
				id="path2062" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2064">
	   <g
		  id="g2066"
		  clip-path="url(#clipPath2070)">
		 <g
			id="g2072"
			transform="translate(324.9136,374.6345)">
		   <path
			  d="m 0,0 -4.425,-2.555 -0.011,0.995 4.425,2.555 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2074" />
		 </g>
		 <g
			id="g2076"
			transform="translate(324.9025,375.6292)">
		   <path
			  d="m 0,0 -7.738,5.083 -4.929,-2.848 8.242,-4.79 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2078" />
		 </g>
		 <g
			id="g2080"
			transform="translate(320.4777,373.0743)">
		   <path
			  d="m 0,0 -8.243,4.789 0.011,-0.994 8.243,-4.79 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2082" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g2084">
	   <g
		  id="g2086">
		 <g
			id="g2092">
		   <g
			  id="g2094">
			 <path
				d="m 314.942,382.667 c -0.603,-0.349 -1.135,-1.012 -1.447,-1.734 v 0 c -0.188,-0.43 -0.296,-0.88 -0.297,-1.299 v 0 c 0.001,-0.558 0.196,-0.947 0.51,-1.133 v 0 l 0.065,-0.038 0.908,-0.524 v 10e-4 c -0.313,0.184 -0.509,0.575 -0.509,1.132 v 0 c 0,0.418 0.109,0.868 0.296,1.299 v 0 c 0.313,0.722 0.845,1.385 1.448,1.733 v 0 c 0.484,0.28 0.922,0.302 1.238,0.117 v 0 l -0.974,0.562 c -0.134,0.079 -0.29,0.12 -0.463,0.12 v 0 c -0.233,0 -0.496,-0.076 -0.775,-0.236"
				style="fill:url(#linearGradient2110);stroke:none"
				id="path2112" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2114">
	   <g
		  id="g2116"
		  clip-path="url(#clipPath2120)">
		 <g
			id="g2122"
			transform="translate(315.9165,382.1046)">
		   <path
			  d="m 0,0 c -0.963,-0.556 -1.744,-1.914 -1.744,-3.033 0,-1.119 0.781,-1.576 1.744,-1.02 0.963,0.556 1.744,1.914 1.744,3.033 C 1.744,0.1 0.963,0.556 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2124" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g2126">
	   <g
		  id="g2128">
		 <g
			id="g2134">
		   <g
			  id="g2136">
			 <path
				d="m 315.85,380.971 c -0.259,-0.149 -0.487,-0.435 -0.621,-0.745 v 0 c -0.081,-0.185 -0.127,-0.378 -0.128,-0.558 v 0 c 10e-4,-0.24 0.084,-0.407 0.219,-0.487 v 0 l 0.028,-0.016 4.232,-2.452 h 0.001 c -0.135,0.08 -0.22,0.247 -0.22,0.487 v 0 c 0,0.18 0.047,0.373 0.128,0.558 v 0 c 0.134,0.31 0.363,0.595 0.622,0.745 v 0 c 0.208,0.12 0.396,0.13 0.532,0.05 v 0 l -4.261,2.467 c -0.057,0.034 -0.125,0.052 -0.199,0.052 v 0 c -0.1,0 -0.213,-0.032 -0.333,-0.101"
				style="fill:url(#linearGradient2152);stroke:none"
				id="path2154" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2156">
	   <g
		  id="g2158">
		 <g
			id="g2164">
		   <g
			  id="g2166">
			 <path
				d="m 320.111,378.503 c -0.414,-0.239 -0.749,-0.823 -0.749,-1.303 v 0 c 0,-0.481 0.335,-0.677 0.749,-0.438 v 0 c 0.414,0.239 0.75,0.822 0.75,1.303 v 0 c 0,0.342 -0.17,0.54 -0.417,0.54 v 0 c -0.1,0 -0.213,-0.033 -0.333,-0.102"
				style="fill:url(#linearGradient2182);stroke:none"
				id="path2184" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2186">
	   <g
		  id="g2188">
		 <g
			id="g2194">
		   <g
			  id="g2196">
			 <path
				d="m 319.352,380.122 c -0.603,-0.348 -1.135,-1.011 -1.448,-1.733 v 0 c -0.187,-0.43 -0.296,-0.88 -0.297,-1.299 v 0 c 10e-4,-0.558 0.196,-0.948 0.511,-1.133 v 0 h -10e-4 l 0.066,-0.038 2.778,-1.631 c -0.314,0.186 -0.51,0.575 -0.511,1.134 v 0 c 0.001,0.418 0.11,0.868 0.297,1.298 v 0 c 0.314,0.723 0.845,1.385 1.448,1.734 v 0 c 0.485,0.279 0.922,0.302 1.237,0.117 v 0 l -2.843,1.668 c -0.134,0.079 -0.29,0.12 -0.462,0.12 v 0 c -0.233,0 -0.497,-0.076 -0.775,-0.237"
				style="fill:url(#linearGradient2212);stroke:none"
				id="path2214" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2216">
	   <g
		  id="g2218"
		  clip-path="url(#clipPath2222)">
		 <g
			id="g2224"
			transform="translate(322.1955,378.4539)">
		   <path
			  d="m 0,0 c -0.963,-0.556 -1.744,-1.914 -1.744,-3.033 0,-1.119 0.781,-1.576 1.744,-1.02 0.963,0.556 1.744,1.914 1.744,3.033 C 1.744,0.1 0.963,0.556 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2226" />
		 </g>
		 <g
			id="g2228"
			transform="translate(322.4177,378.984)">
		   <path
			  d="m 0,0 -1.013,-0.585 -0.003,0.432 1.013,0.585 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2230" />
		 </g>
		 <g
			id="g2232"
			transform="translate(322.4151,379.4162)">
		   <path
			  d="M 0,0 -1.627,0.943 -2.638,0.359 -1.013,-0.585 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2234" />
		 </g>
		 <g
			id="g2236"
			transform="translate(321.4019,378.8312)">
		   <path
			  d="M 0,0 -1.624,0.944 -1.622,0.512 0.002,-0.432 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2238" />
		 </g>
		 <g
			id="g2240"
			transform="translate(308.481,363.5945)">
		   <path
			  d="m 0,0 -4.425,-2.555 -0.011,0.995 4.425,2.555 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2242" />
		 </g>
		 <g
			id="g2244"
			transform="translate(308.4699,364.5891)">
		   <path
			  d="m 0,0 -7.738,5.083 -4.929,-2.848 8.242,-4.79 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2246" />
		 </g>
		 <g
			id="g2248"
			transform="translate(304.0451,362.0342)">
		   <path
			  d="m 0,0 -8.243,4.79 0.011,-0.995 8.243,-4.79 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2250" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g2252">
	   <g
		  id="g2254">
		 <g
			id="g2260">
		   <g
			  id="g2262">
			 <path
				d="m 298.51,371.626 c -0.604,-0.348 -1.135,-1.01 -1.448,-1.732 v 0 c -0.187,-0.431 -0.296,-0.881 -0.296,-1.3 v 0 c 0,-0.557 0.196,-0.948 0.509,-1.133 v 0 l 0.066,-0.038 0.907,-0.525 0.001,10e-4 c -0.314,0.186 -0.509,0.575 -0.51,1.134 v 0 c 0.001,0.417 0.11,0.867 0.297,1.298 v 0 c 0.312,0.722 0.844,1.385 1.447,1.733 v 0 c 0.478,0.276 0.911,0.302 1.225,0.124 v 0 l -0.96,0.555 c -0.134,0.079 -0.29,0.12 -0.463,0.12 v 0 c -0.233,0 -0.496,-0.076 -0.775,-0.237 m 2.211,-0.445 c -0.005,0.002 -0.009,0.005 -0.013,0.007 v 0 z"
				style="fill:url(#linearGradient2278);stroke:none"
				id="path2280" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2282">
	   <g
		  id="g2284"
		  clip-path="url(#clipPath2288)">
		 <g
			id="g2290"
			transform="translate(299.4839,371.0645)">
		   <path
			  d="m 0,0 c -0.963,-0.556 -1.744,-1.914 -1.744,-3.033 0,-1.119 0.781,-1.576 1.744,-1.02 0.963,0.556 1.744,1.914 1.744,3.033 C 1.744,0.1 0.963,0.556 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2292" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g2294">
	   <g
		  id="g2296">
		 <g
			id="g2302">
		   <g
			  id="g2304">
			 <path
				d="m 299.418,369.931 c -0.259,-0.15 -0.488,-0.435 -0.622,-0.745 v 0 c -0.081,-0.185 -0.127,-0.379 -0.127,-0.558 v 0 c 0,-0.24 0.084,-0.407 0.218,-0.487 v 0 l 0.029,-0.017 4.232,-2.451 v 10e-4 c -0.135,0.079 -0.219,0.246 -0.219,0.486 v 0 c 0,0.18 0.047,0.373 0.127,0.559 v 0 c 0.135,0.309 0.363,0.594 0.622,0.744 v 0 c 0.209,0.119 0.397,0.129 0.532,0.05 v 0 l -4.261,2.468 c -0.057,0.034 -0.124,0.051 -0.198,0.051 v 0 c -0.1,0 -0.213,-0.032 -0.333,-0.101"
				style="fill:url(#linearGradient2320);stroke:none"
				id="path2322" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2324">
	   <g
		  id="g2326">
		 <g
			id="g2332">
		   <g
			  id="g2334">
			 <path
				d="m 303.679,367.463 c -0.414,-0.239 -0.75,-0.823 -0.75,-1.303 v 0 c 0,-0.481 0.336,-0.677 0.75,-0.439 v 0 c 0.413,0.24 0.749,0.823 0.749,1.304 v 0 c 0,0.342 -0.17,0.539 -0.416,0.539 v 0 c -0.101,0 -0.214,-0.032 -0.333,-0.101"
				style="fill:url(#linearGradient2350);stroke:none"
				id="path2352" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2354">
	   <g
		  id="g2356">
		 <g
			id="g2362">
		   <g
			  id="g2364">
			 <path
				d="m 302.919,369.082 c -0.603,-0.348 -1.135,-1.011 -1.448,-1.733 v 0 c -0.187,-0.431 -0.296,-0.881 -0.296,-1.298 v 0 c 10e-4,-0.558 0.196,-0.949 0.509,-1.133 v 0 -0.001 l 0.066,-0.038 2.778,-1.631 v 0.001 c -0.314,0.185 -0.509,0.575 -0.51,1.132 v 0 c 0.001,0.419 0.109,0.869 0.297,1.3 v 0 c 0.313,0.722 0.844,1.384 1.447,1.732 v 0 c 0.485,0.28 0.923,0.304 1.238,0.117 v 0 l -2.843,1.669 c -0.134,0.079 -0.29,0.12 -0.462,0.12 v 0 c -0.233,0 -0.497,-0.076 -0.776,-0.237"
				style="fill:url(#linearGradient2380);stroke:none"
				id="path2382" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2384">
	   <g
		  id="g2386"
		  clip-path="url(#clipPath2390)">
		 <g
			id="g2392"
			transform="translate(305.7629,367.4139)">
		   <path
			  d="m 0,0 c -0.963,-0.556 -1.744,-1.914 -1.744,-3.033 0,-1.119 0.781,-1.576 1.744,-1.02 0.963,0.556 1.744,1.914 1.744,3.033 C 1.744,0.1 0.963,0.556 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2394" />
		 </g>
		 <g
			id="g2396"
			transform="translate(305.9851,367.944)">
		   <path
			  d="m 0,0 -1.013,-0.585 -0.003,0.432 1.013,0.585 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2398" />
		 </g>
		 <g
			id="g2400"
			transform="translate(305.9825,368.3762)">
		   <path
			  d="M 0,0 -1.627,0.943 -2.638,0.359 -1.013,-0.585 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2402" />
		 </g>
		 <g
			id="g2404"
			transform="translate(304.9693,367.7911)">
		   <path
			  d="M 0,0 -1.624,0.944 -1.622,0.512 0.002,-0.432 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2406" />
		 </g>
		 <g
			id="g2408"
			transform="translate(292.0484,352.5544)">
		   <path
			  d="m 0,0 -4.425,-2.555 -0.011,0.995 4.425,2.555 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2410" />
		 </g>
		 <g
			id="g2412"
			transform="translate(292.0373,353.5491)">
		   <path
			  d="m 0,0 -7.738,5.083 -4.929,-2.848 8.242,-4.79 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2414" />
		 </g>
		 <g
			id="g2416"
			transform="translate(287.6125,350.9942)">
		   <path
			  d="m 0,0 -8.243,4.79 0.011,-0.995 8.243,-4.79 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2418" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g2420">
	   <g
		  id="g2422">
		 <g
			id="g2428">
		   <g
			  id="g2430">
			 <path
				d="m 282.077,360.586 c -0.603,-0.348 -1.135,-1.011 -1.447,-1.733 v 0 c -0.188,-0.431 -0.296,-0.881 -0.297,-1.298 v 0 c 10e-4,-0.558 0.196,-0.949 0.51,-1.133 v 0 l -0.001,-0.001 0.066,-0.038 0.908,-0.524 c -0.313,0.185 -0.508,0.575 -0.509,1.133 v 0 c 10e-4,0.419 0.109,0.869 0.296,1.299 v 0 c 0.313,0.722 0.845,1.385 1.448,1.733 v 0 c 0.477,0.276 0.91,0.302 1.224,0.124 v 0 l -0.96,0.555 c -0.134,0.079 -0.29,0.12 -0.462,0.12 v 0 c -0.233,0 -0.497,-0.076 -0.776,-0.237 m 2.211,-0.446 c -0.004,0.003 -0.009,0.006 -0.013,0.008 v 0 z"
				style="fill:url(#linearGradient2446);stroke:none"
				id="path2448" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2450">
	   <g
		  id="g2452"
		  clip-path="url(#clipPath2456)">
		 <g
			id="g2458"
			transform="translate(283.0513,360.0245)">
		   <path
			  d="m 0,0 c -0.963,-0.556 -1.744,-1.914 -1.744,-3.033 0,-1.119 0.781,-1.576 1.744,-1.02 0.963,0.556 1.744,1.914 1.744,3.033 C 1.744,0.1 0.963,0.556 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2460" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g2462">
	   <g
		  id="g2464">
		 <g
			id="g2470">
		   <g
			  id="g2472">
			 <path
				d="m 282.985,358.89 c -0.259,-0.149 -0.488,-0.434 -0.622,-0.744 v 0 c -0.08,-0.185 -0.127,-0.378 -0.127,-0.558 v 0 c 0,-0.239 0.084,-0.408 0.219,-0.487 v 0 l 0.028,-0.016 4.233,-2.452 c -0.135,0.079 -0.219,0.248 -0.22,0.487 v 0 c 0.001,0.179 0.047,0.373 0.128,0.558 v 0 c 0.134,0.311 0.363,0.595 0.622,0.744 v 0 c 0.208,0.121 0.396,0.131 0.532,0.051 v 0 l -4.261,2.468 c -0.058,0.034 -0.125,0.051 -0.199,0.051 v 0 c -0.1,0 -0.213,-0.032 -0.333,-0.102"
				style="fill:url(#linearGradient2488);stroke:none"
				id="path2490" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2492">
	   <g
		  id="g2494">
		 <g
			id="g2500">
		   <g
			  id="g2502">
			 <path
				d="m 287.246,356.422 c -0.414,-0.238 -0.749,-0.821 -0.749,-1.303 v 0 c 0,-0.48 0.335,-0.676 0.749,-0.437 v 0 c 0.414,0.238 0.749,0.822 0.749,1.303 v 0 c 0,0.342 -0.169,0.54 -0.416,0.54 v 0 c -0.1,0 -0.213,-0.033 -0.333,-0.103"
				style="fill:url(#linearGradient2518);stroke:none"
				id="path2520" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2522">
	   <g
		  id="g2524">
		 <g
			id="g2530">
		   <g
			  id="g2532">
			 <path
				d="m 286.487,358.042 c -0.604,-0.348 -1.136,-1.011 -1.448,-1.733 v 0 c -0.187,-0.431 -0.296,-0.88 -0.297,-1.299 v 0 c 10e-4,-0.558 0.196,-0.947 0.51,-1.133 v 0 -0.001 l 0.066,-0.037 2.777,-1.631 v 10e-4 c -0.313,0.185 -0.509,0.575 -0.509,1.133 v 0 c 0,0.418 0.109,0.867 0.296,1.299 v 0 c 0.313,0.721 0.844,1.385 1.448,1.733 v 0 c 0.484,0.28 0.922,0.302 1.238,0.116 v 0 l -2.844,1.669 c -0.134,0.079 -0.29,0.12 -0.463,0.12 v 0 c -0.233,0 -0.496,-0.076 -0.774,-0.237"
				style="fill:url(#linearGradient2548);stroke:none"
				id="path2550" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2552">
	   <g
		  id="g2554"
		  clip-path="url(#clipPath2558)">
		 <g
			id="g2560"
			transform="translate(289.3303,356.3739)">
		   <path
			  d="m 0,0 c -0.963,-0.556 -1.744,-1.914 -1.744,-3.033 0,-1.119 0.781,-1.576 1.744,-1.02 0.963,0.556 1.744,1.914 1.744,3.033 C 1.744,0.1 0.963,0.556 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2562" />
		 </g>
		 <g
			id="g2564"
			transform="translate(289.5525,356.9039)">
		   <path
			  d="m 0,0 -1.013,-0.585 -0.003,0.432 1.013,0.585 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2566" />
		 </g>
		 <g
			id="g2568"
			transform="translate(289.5499,357.3362)">
		   <path
			  d="M 0,0 -1.627,0.943 -2.638,0.359 -1.013,-0.585 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2570" />
		 </g>
		 <g
			id="g2572"
			transform="translate(288.5367,356.7511)">
		   <path
			  d="M 0,0 -1.624,0.944 -1.622,0.512 0.002,-0.432 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2574" />
		 </g>
		 <g
			id="g2576"
			transform="translate(366.9564,268.0311)">
		   <path
			  d="m 0,0 -4.425,-2.555 -0.011,0.995 4.425,2.555 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2578" />
		 </g>
		 <g
			id="g2580"
			transform="translate(366.9453,269.0258)">
		   <path
			  d="m 0,0 -7.738,5.083 -4.929,-2.848 8.242,-4.79 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2582" />
		 </g>
		 <g
			id="g2584"
			transform="translate(362.5204,266.4709)">
		   <path
			  d="m 0,0 -8.242,4.79 0.01,-0.995 8.243,-4.79 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2586" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g2588">
	   <g
		  id="g2590">
		 <g
			id="g2596">
		   <g
			  id="g2598">
			 <path
				d="m 356.985,276.063 c -0.603,-0.348 -1.135,-1.011 -1.447,-1.733 v 0 c -0.188,-0.431 -0.296,-0.88 -0.297,-1.299 v 0 c 0.001,-0.558 0.196,-0.948 0.51,-1.133 v 0 l -10e-4,-0.001 0.067,-0.038 0.907,-0.523 c -0.313,0.185 -0.509,0.576 -0.509,1.133 v 0 c 0,0.418 0.108,0.868 0.297,1.299 v 0 c 0.312,0.722 0.844,1.384 1.447,1.733 v 0 c 0.484,0.28 0.922,0.302 1.237,0.117 v 0 l -0.973,0.561 c -0.134,0.08 -0.291,0.121 -0.463,0.121 v 0 c -0.233,0 -0.497,-0.076 -0.775,-0.237"
				style="fill:url(#linearGradient2614);stroke:none"
				id="path2616" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2618">
	   <g
		  id="g2620"
		  clip-path="url(#clipPath2624)">
		 <g
			id="g2626"
			transform="translate(357.9593,275.5012)">
		   <path
			  d="m 0,0 c -0.963,-0.556 -1.744,-1.914 -1.744,-3.033 0,-1.119 0.781,-1.576 1.744,-1.02 0.963,0.556 1.744,1.914 1.744,3.033 C 1.744,0.1 0.963,0.556 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2628" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g2630">
	   <g
		  id="g2632">
		 <g
			id="g2638">
		   <g
			  id="g2640">
			 <path
				d="m 357.893,274.368 c -0.259,-0.151 -0.487,-0.435 -0.622,-0.746 v 0 c -0.08,-0.184 -0.127,-0.378 -0.127,-0.558 v 0 c 0,-0.239 0.084,-0.406 0.219,-0.486 v 0 l 0.028,-0.017 4.233,-2.451 c -0.135,0.08 -0.219,0.247 -0.22,0.487 v 0 c 10e-4,0.18 0.047,0.372 0.128,0.558 v 0 c 0.134,0.31 0.363,0.595 0.622,0.744 v 0 c 0.208,0.121 0.396,0.13 0.532,0.05 v 0 l -4.261,2.468 c -0.057,0.034 -0.124,0.052 -0.198,0.052 v 0 c -0.101,0 -0.214,-0.033 -0.334,-0.101"
				style="fill:url(#linearGradient2656);stroke:none"
				id="path2658" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2660">
	   <g
		  id="g2662">
		 <g
			id="g2668">
		   <g
			  id="g2670">
			 <path
				d="m 362.154,271.9 c -0.414,-0.24 -0.749,-0.823 -0.749,-1.303 v 0 c 0,-0.482 0.335,-0.678 0.749,-0.439 v 0 c 0.414,0.239 0.749,0.823 0.749,1.304 v 0 c 0,0.341 -0.169,0.539 -0.416,0.539 v 0 c -0.101,0 -0.214,-0.032 -0.333,-0.101"
				style="fill:url(#linearGradient2686);stroke:none"
				id="path2688" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2690">
	   <g
		  id="g2692">
		 <g
			id="g2698">
		   <g
			  id="g2700">
			 <path
				d="m 361.395,273.519 c -0.604,-0.348 -1.135,-1.012 -1.448,-1.733 v 0 c -0.187,-0.431 -0.296,-0.881 -0.296,-1.299 v 0 c 0,-0.558 0.195,-0.948 0.509,-1.133 v 0 l 0.066,-0.039 2.777,-1.63 c -0.313,0.186 -0.509,0.575 -0.509,1.133 v 0 c 0,0.419 0.109,0.869 0.296,1.299 v 0 c 0.313,0.722 0.845,1.385 1.448,1.733 v 0 c 0.484,0.28 0.922,0.303 1.238,0.116 v 0 l -2.844,1.669 c -0.134,0.079 -0.29,0.121 -0.463,0.121 v 0 c -0.233,0 -0.496,-0.076 -0.774,-0.237"
				style="fill:url(#linearGradient2716);stroke:none"
				id="path2718" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2720">
	   <g
		  id="g2722"
		  clip-path="url(#clipPath2726)">
		 <g
			id="g2728"
			transform="translate(364.2383,271.8506)">
		   <path
			  d="m 0,0 c -0.963,-0.556 -1.744,-1.914 -1.744,-3.033 0,-1.119 0.781,-1.576 1.744,-1.02 0.963,0.556 1.744,1.914 1.744,3.033 C 1.744,0.1 0.963,0.556 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2730" />
		 </g>
		 <g
			id="g2732"
			transform="translate(364.4604,272.3806)">
		   <path
			  d="m 0,0 -1.013,-0.585 -0.003,0.432 1.013,0.585 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2734" />
		 </g>
		 <g
			id="g2736"
			transform="translate(364.4579,272.8129)">
		   <path
			  d="M 0,0 -1.627,0.943 -2.638,0.359 -1.013,-0.585 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2738" />
		 </g>
		 <g
			id="g2740"
			transform="translate(363.4446,272.2278)">
		   <path
			  d="M 0,0 -1.624,0.944 -1.622,0.512 0.003,-0.432 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2742" />
		 </g>
		 <g
			id="g2744"
			transform="translate(355.2521,261.064)">
		   <path
			  d="m 0,0 -4.425,-2.555 -0.011,0.995 4.425,2.555 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2746" />
		 </g>
		 <g
			id="g2748"
			transform="translate(355.241,262.0586)">
		   <path
			  d="m 0,0 -7.738,5.083 -4.929,-2.848 8.242,-4.79 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2750" />
		 </g>
		 <g
			id="g2752"
			transform="translate(350.8161,259.5038)">
		   <path
			  d="m 0,0 -8.243,4.789 0.011,-0.994 8.243,-4.79 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2754" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g2756">
	   <g
		  id="g2758">
		 <g
			id="g2764">
		   <g
			  id="g2766">
			 <path
				d="m 345.281,269.095 c -0.604,-0.348 -1.135,-1.01 -1.448,-1.732 v 0 c -0.187,-0.431 -0.296,-0.881 -0.297,-1.299 v 0 c 10e-4,-0.558 0.196,-0.948 0.511,-1.133 v 0 -10e-4 l 0.065,-0.038 0.908,-0.523 v 0 c -0.314,0.185 -0.509,0.575 -0.51,1.133 v 0 c 0.001,0.418 0.109,0.868 0.297,1.298 v 0 c 0.312,0.722 0.844,1.386 1.447,1.734 v 0 c 0.478,0.276 0.91,0.302 1.224,0.124 v 0 l -0.96,0.554 c -0.134,0.08 -0.289,0.121 -0.461,0.121 v 0 c -0.233,0 -0.497,-0.076 -0.776,-0.238 m 2.211,-0.445 c -0.005,0.003 -0.009,0.005 -0.014,0.008 v 0 z"
				style="fill:url(#linearGradient2782);stroke:none"
				id="path2784" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2786">
	   <g
		  id="g2788"
		  clip-path="url(#clipPath2792)">
		 <g
			id="g2794"
			transform="translate(346.255,268.5341)">
		   <path
			  d="m 0,0 c -0.963,-0.556 -1.744,-1.914 -1.744,-3.033 0,-1.119 0.781,-1.576 1.744,-1.02 0.963,0.556 1.744,1.914 1.744,3.033 C 1.744,0.1 0.963,0.556 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2796" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g2798">
	   <g
		  id="g2800">
		 <g
			id="g2806">
		   <g
			  id="g2808">
			 <path
				d="m 346.189,267.4 c -0.259,-0.15 -0.487,-0.434 -0.622,-0.744 v 0 c -0.08,-0.186 -0.127,-0.378 -0.127,-0.558 v 0 c 0,-0.24 0.084,-0.408 0.219,-0.487 v 0 l 0.028,-0.017 4.232,-2.451 c -0.135,0.079 -0.219,0.246 -0.219,0.487 v 0 c 0,0.179 0.047,0.372 0.127,0.558 v 0 c 0.135,0.309 0.363,0.595 0.622,0.744 v 0 c 0.208,0.121 0.397,0.131 0.532,0.05 v 0 l -4.261,2.468 c -0.057,0.034 -0.124,0.052 -0.197,0.052 v 0 c -0.101,0 -0.214,-0.033 -0.334,-0.102"
				style="fill:url(#linearGradient2824);stroke:none"
				id="path2826" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2828">
	   <g
		  id="g2830">
		 <g
			id="g2836">
		   <g
			  id="g2838">
			 <path
				d="m 350.449,264.932 c -0.413,-0.238 -0.749,-0.822 -0.749,-1.303 v 0 c 0,-0.481 0.336,-0.677 0.749,-0.438 v 0 c 0.414,0.239 0.75,0.822 0.75,1.304 v 0 c 0,0.341 -0.17,0.539 -0.416,0.539 v 0 c -0.101,0 -0.214,-0.033 -0.334,-0.102"
				style="fill:url(#linearGradient2854);stroke:none"
				id="path2856" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2858">
	   <g
		  id="g2860">
		 <g
			id="g2866">
		   <g
			  id="g2868">
			 <path
				d="m 349.69,266.551 c -0.603,-0.347 -1.134,-1.011 -1.448,-1.732 v 0 c -0.187,-0.431 -0.296,-0.881 -0.296,-1.299 v 0 c 0,-0.558 0.196,-0.948 0.51,-1.133 v 0 -10e-4 l 0.065,-0.038 2.778,-1.631 v 0.001 c -0.313,0.186 -0.509,0.575 -0.51,1.133 v 0 c 0,0.418 0.109,0.868 0.296,1.299 v 0 c 0.314,0.722 0.846,1.385 1.449,1.733 v 0 c 0.484,0.28 0.922,0.303 1.237,0.117 v 0 l -2.843,1.668 c -0.134,0.079 -0.29,0.121 -0.462,0.121 v 0 c -0.233,0 -0.497,-0.077 -0.776,-0.238"
				style="fill:url(#linearGradient2884);stroke:none"
				id="path2886" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2888">
	   <g
		  id="g2890"
		  clip-path="url(#clipPath2894)">
		 <g
			id="g2896"
			transform="translate(352.534,264.8834)">
		   <path
			  d="m 0,0 c -0.963,-0.556 -1.744,-1.914 -1.744,-3.033 0,-1.119 0.781,-1.576 1.744,-1.02 0.963,0.556 1.744,1.914 1.744,3.033 C 1.744,0.1 0.963,0.556 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2898" />
		 </g>
		 <g
			id="g2900"
			transform="translate(352.7561,265.4135)">
		   <path
			  d="m 0,0 -1.013,-0.585 -0.003,0.432 1.013,0.585 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2902" />
		 </g>
		 <g
			id="g2904"
			transform="translate(352.7536,265.8457)">
		   <path
			  d="M 0,0 -1.627,0.943 -2.638,0.359 -1.013,-0.585 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2906" />
		 </g>
		 <g
			id="g2908"
			transform="translate(351.7403,265.2606)">
		   <path
			  d="M 0,0 -1.624,0.944 -1.622,0.512 0.003,-0.432 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2910" />
		 </g>
		 <g
			id="g2912"
			transform="translate(343.5478,254.0968)">
		   <path
			  d="m 0,0 -4.425,-2.555 -0.011,0.995 4.425,2.555 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2914" />
		 </g>
		 <g
			id="g2916"
			transform="translate(343.5367,255.0915)">
		   <path
			  d="m 0,0 -7.738,5.083 -4.929,-2.848 8.242,-4.79 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2918" />
		 </g>
		 <g
			id="g2920"
			transform="translate(339.1118,252.5366)">
		   <path
			  d="m 0,0 -8.242,4.79 0.01,-0.995 8.243,-4.79 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2922" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g2924">
	   <g
		  id="g2926">
		 <g
			id="g2932">
		   <g
			  id="g2934">
			 <path
				d="m 333.577,262.129 c -0.603,-0.349 -1.135,-1.011 -1.448,-1.734 v 0 c -0.187,-0.43 -0.296,-0.88 -0.296,-1.299 v 0 c 0,-0.558 0.196,-0.947 0.509,-1.133 v 0 l 0.066,-0.037 0.908,-0.525 v 10e-4 c -0.314,0.185 -0.51,0.575 -0.51,1.133 v 0 c 0,0.417 0.109,0.867 0.297,1.299 v 0 c 0.312,0.721 0.844,1.385 1.448,1.732 v 0 c 0.477,0.276 0.909,0.303 1.223,0.125 v 0 l -0.96,0.555 c -0.134,0.078 -0.29,0.12 -0.462,0.12 v 0 c -0.233,0 -0.496,-0.076 -0.775,-0.237 m 2.21,-0.446 c -0.004,0.003 -0.008,0.005 -0.013,0.008 v 0 z"
				style="fill:url(#linearGradient2950);stroke:none"
				id="path2952" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2954">
	   <g
		  id="g2956"
		  clip-path="url(#clipPath2960)">
		 <g
			id="g2962"
			transform="translate(334.5507,261.5669)">
		   <path
			  d="m 0,0 c -0.963,-0.556 -1.744,-1.914 -1.744,-3.033 0,-1.119 0.781,-1.576 1.744,-1.02 0.963,0.556 1.744,1.914 1.744,3.033 C 1.744,0.1 0.963,0.556 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path2964" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g2966">
	   <g
		  id="g2968">
		 <g
			id="g2974">
		   <g
			  id="g2976">
			 <path
				d="m 334.485,260.433 c -0.259,-0.149 -0.488,-0.435 -0.622,-0.744 v 0 c -0.081,-0.186 -0.128,-0.379 -0.128,-0.558 v 0 c 0,-0.241 0.085,-0.408 0.219,-0.487 v 0 -0.001 l 0.029,-0.016 4.232,-2.452 v 10e-4 c -0.135,0.079 -0.219,0.247 -0.219,0.486 v 0 c 0,0.18 0.047,0.373 0.127,0.558 v 0 c 0.135,0.31 0.363,0.596 0.623,0.745 v 0 c 0.207,0.12 0.396,0.13 0.531,0.05 v 0 l -4.261,2.468 c -0.057,0.034 -0.124,0.052 -0.198,0.052 v 0 c -0.1,0 -0.213,-0.033 -0.333,-0.102"
				style="fill:url(#linearGradient2992);stroke:none"
				id="path2994" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g2996">
	   <g
		  id="g2998">
		 <g
			id="g3004">
		   <g
			  id="g3006">
			 <path
				d="m 338.746,257.965 c -0.414,-0.239 -0.75,-0.822 -0.75,-1.303 v 0 c 0,-0.481 0.336,-0.677 0.75,-0.438 v 0 c 0.413,0.239 0.748,0.822 0.748,1.303 v 0 c 0,0.342 -0.169,0.54 -0.416,0.54 v 0 c -0.1,0 -0.213,-0.033 -0.332,-0.102"
				style="fill:url(#linearGradient3022);stroke:none"
				id="path3024" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3026">
	   <g
		  id="g3028">
		 <g
			id="g3034">
		   <g
			  id="g3036">
			 <path
				d="m 337.986,259.584 c -0.603,-0.348 -1.135,-1.01 -1.447,-1.732 v 0 c -0.188,-0.431 -0.296,-0.881 -0.297,-1.3 v 0 c 10e-4,-0.557 0.196,-0.948 0.509,-1.132 v 0 -10e-4 l 0.066,-0.038 2.777,-1.63 h 0.001 c -0.314,0.185 -0.509,0.575 -0.51,1.133 v 0 c 0.001,0.418 0.109,0.868 0.297,1.299 v 0 c 0.312,0.722 0.844,1.384 1.447,1.733 v 0 c 0.484,0.28 0.923,0.302 1.238,0.117 v 0 l -2.844,1.668 c -0.133,0.079 -0.289,0.121 -0.461,0.121 v 0 c -0.234,0 -0.497,-0.077 -0.776,-0.238"
				style="fill:url(#linearGradient3052);stroke:none"
				id="path3054" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3056">
	   <g
		  id="g3058"
		  clip-path="url(#clipPath3062)">
		 <g
			id="g3064"
			transform="translate(340.8297,257.9162)">
		   <path
			  d="m 0,0 c -0.963,-0.556 -1.744,-1.914 -1.744,-3.033 0,-1.119 0.781,-1.576 1.744,-1.02 0.963,0.556 1.744,1.914 1.744,3.033 C 1.744,0.1 0.963,0.556 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3066" />
		 </g>
		 <g
			id="g3068"
			transform="translate(341.0518,258.4463)">
		   <path
			  d="m 0,0 -1.013,-0.585 -0.003,0.432 1.013,0.585 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3070" />
		 </g>
		 <g
			id="g3072"
			transform="translate(341.0493,258.8785)">
		   <path
			  d="M 0,0 -1.627,0.943 -2.638,0.359 -1.013,-0.585 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3074" />
		 </g>
		 <g
			id="g3076"
			transform="translate(340.036,258.2935)">
		   <path
			  d="M 0,0 -1.624,0.944 -1.622,0.512 0.003,-0.432 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3078" />
		 </g>
		 <g
			id="g3080"
			transform="translate(557.6243,309.2346)">
		   <path
			  d="m 0,0 -4.456,-2.573 -0.011,1.002 4.456,2.573 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3082" />
		 </g>
		 <g
			id="g3084"
			transform="translate(557.6132,310.2363)">
		   <path
			  d="m 0,0 -7.792,5.119 -4.964,-2.869 8.3,-4.823 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3086" />
		 </g>
		 <g
			id="g3088"
			transform="translate(553.1573,307.6635)">
		   <path
			  d="m 0,0 -8.3,4.823 0.011,-1.002 8.3,-4.823 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3090" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g3092">
	   <g
		  id="g3094">
		 <g
			id="g3100">
		   <g
			  id="g3102">
			 <path
				d="m 547.583,317.323 c -0.607,-0.351 -1.143,-1.018 -1.458,-1.745 v 0 c -0.189,-0.434 -0.298,-0.888 -0.298,-1.309 v 0 c 0,-0.561 0.198,-0.954 0.513,-1.14 v 0 -0.001 l 0.066,-0.038 2.797,-1.642 v 0.001 c -0.315,0.186 -0.513,0.578 -0.513,1.14 v 0 c 0,0.421 0.11,0.875 0.298,1.308 v 0 c 0.315,0.728 0.851,1.395 1.458,1.746 v 0 c 0.488,0.281 0.929,0.304 1.247,0.117 v 0 l -2.863,1.68 c -0.135,0.079 -0.292,0.121 -0.466,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.781,-0.238"
				style="fill:url(#linearGradient3118);stroke:none"
				id="path3120" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3122">
	   <g
		  id="g3124"
		  clip-path="url(#clipPath3128)">
		 <g
			id="g3130"
			transform="translate(550.447,315.6428)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3132" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g3134">
	   <g
		  id="g3136">
		 <g
			id="g3142">
		   <g
			  id="g3144">
			 <path
				d="m 550.597,314.402 c -0.261,-0.151 -0.491,-0.437 -0.627,-0.749 v 0 c -0.081,-0.188 -0.128,-0.382 -0.128,-0.563 v 0 c 0,-0.241 0.085,-0.409 0.221,-0.489 v 0 -10e-4 l 0.028,-0.017 4.262,-2.468 c -0.136,0.08 -0.22,0.249 -0.22,0.49 v 0 c 0,0.181 0.047,0.376 0.127,0.562 v 0 c 0.136,0.312 0.366,0.599 0.627,0.75 v 0 c 0.21,0.121 0.399,0.131 0.535,0.05 v 0 l -4.29,2.486 c -0.058,0.034 -0.125,0.052 -0.199,0.052 v 0 c -0.102,0 -0.216,-0.033 -0.336,-0.103"
				style="fill:url(#linearGradient3160);stroke:none"
				id="path3162" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3164">
	   <g
		  id="g3166">
		 <g
			id="g3172">
		   <g
			  id="g3174">
			 <path
				d="m 554.888,311.917 c -0.417,-0.241 -0.755,-0.828 -0.755,-1.313 v 0 c 0,-0.484 0.338,-0.681 0.755,-0.441 v 0 c 0.417,0.242 0.754,0.828 0.754,1.313 v 0 c 0,0.344 -0.171,0.543 -0.419,0.543 v 0 c -0.101,0 -0.215,-0.033 -0.335,-0.102"
				style="fill:url(#linearGradient3190);stroke:none"
				id="path3192" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3194">
	   <g
		  id="g3196">
		 <g
			id="g3202">
		   <g
			  id="g3204">
			 <path
				d="m 553.907,313.646 c -0.608,-0.35 -1.143,-1.018 -1.458,-1.745 v 0 c -0.189,-0.434 -0.299,-0.886 -0.299,-1.307 v 0 c 10e-4,-0.562 0.197,-0.955 0.513,-1.142 v 0 l 0.067,-0.038 0.913,-0.528 h 10e-4 c -0.316,0.187 -0.513,0.58 -0.514,1.141 v 0 c 10e-4,0.422 0.11,0.874 0.299,1.308 v 0 c 0.315,0.727 0.85,1.395 1.458,1.745 v 0 c 0.481,0.278 0.917,0.305 1.232,0.126 v 0 l -0.967,0.558 c -0.134,0.08 -0.291,0.121 -0.464,0.121 v 0 c -0.236,0 -0.501,-0.076 -0.781,-0.239 m 2.226,-0.448 c -0.005,0.003 -0.009,0.005 -0.014,0.008 v 0 z"
				style="fill:url(#linearGradient3220);stroke:none"
				id="path3222" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3224">
	   <g
		  id="g3226"
		  clip-path="url(#clipPath3230)">
		 <g
			id="g3232"
			transform="translate(554.8872,313.0809)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.055 0,-1.126 0.786,-1.586 1.756,-1.026 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3234" />
		 </g>
		 <g
			id="g3236"
			transform="translate(550.3419,316.371)">
		   <path
			  d="m 0,0 -1.02,-0.589 -0.003,0.435 1.02,0.589 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3238" />
		 </g>
		 <g
			id="g3240"
			transform="translate(550.3394,316.8063)">
		   <path
			  d="M 0,0 -1.638,0.949 -2.656,0.361 -1.02,-0.589 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3242" />
		 </g>
		 <g
			id="g3244"
			transform="translate(549.319,316.2171)">
		   <path
			  d="m 0,0 -1.636,0.951 0.003,-0.436 1.636,-0.95 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3246" />
		 </g>
		 <g
			id="g3248"
			transform="translate(545.8578,302.1826)">
		   <path
			  d="m 0,0 -4.456,-2.573 -0.011,1.002 4.456,2.573 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3250" />
		 </g>
		 <g
			id="g3252"
			transform="translate(545.8467,303.1843)">
		   <path
			  d="m 0,0 -7.792,5.119 -4.964,-2.869 8.3,-4.823 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3254" />
		 </g>
		 <g
			id="g3256"
			transform="translate(541.3908,300.6115)">
		   <path
			  d="M 0,0 -8.3,4.823 -8.29,3.821 0.011,-1.002 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3258" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g3260">
	   <g
		  id="g3262">
		 <g
			id="g3268">
		   <g
			  id="g3270">
			 <path
				d="m 535.817,310.27 c -0.608,-0.35 -1.143,-1.017 -1.458,-1.745 v 0 c -0.189,-0.433 -0.298,-0.886 -0.299,-1.307 v 0 c 0.001,-0.562 0.198,-0.955 0.514,-1.142 v 0 h -10e-4 l 0.067,-0.038 2.797,-1.642 c -0.316,0.186 -0.513,0.58 -0.513,1.141 v 0 c 0,0.422 0.11,0.874 0.299,1.308 v 0 c 0.314,0.727 0.849,1.395 1.457,1.745 v 0 c 0.487,0.282 0.928,0.305 1.246,0.118 v 0 l -2.863,1.68 c -0.134,0.08 -0.291,0.121 -0.465,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.781,-0.239"
				style="fill:url(#linearGradient3286);stroke:none"
				id="path3288" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3290">
	   <g
		  id="g3292"
		  clip-path="url(#clipPath3296)">
		 <g
			id="g3298"
			transform="translate(538.6804,308.5908)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3300" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g3302">
	   <g
		  id="g3304">
		 <g
			id="g3310">
		   <g
			  id="g3312">
			 <path
				d="m 538.83,307.35 c -0.261,-0.15 -0.491,-0.437 -0.626,-0.75 v 0 c -0.081,-0.186 -0.128,-0.38 -0.128,-0.561 v 0 c 0,-0.242 0.084,-0.411 0.22,-0.491 v 0 l 0.029,-0.017 4.262,-2.469 v 10e-4 c -0.136,0.08 -0.221,0.249 -0.221,0.49 v 0 c 0,0.181 0.047,0.376 0.128,0.562 v 0 c 0.136,0.313 0.365,0.599 0.626,0.75 v 0 c 0.21,0.121 0.4,0.131 0.536,0.05 v 0 l -4.291,2.485 c -0.057,0.035 -0.125,0.053 -0.199,0.053 v 0 c -0.101,0 -0.215,-0.033 -0.336,-0.103"
				style="fill:url(#linearGradient3328);stroke:none"
				id="path3330" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3332">
	   <g
		  id="g3334">
		 <g
			id="g3340">
		   <g
			  id="g3342">
			 <path
				d="m 543.121,304.865 c -0.417,-0.24 -0.755,-0.828 -0.755,-1.312 v 0 c 0,-0.484 0.338,-0.682 0.755,-0.441 v 0 c 0.417,0.24 0.754,0.828 0.754,1.312 v 0 c 0,0.344 -0.17,0.543 -0.419,0.543 v 0 c -0.101,0 -0.214,-0.032 -0.335,-0.102"
				style="fill:url(#linearGradient3358);stroke:none"
				id="path3360" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3362">
	   <g
		  id="g3364">
		 <g
			id="g3370">
		   <g
			  id="g3372">
			 <path
				d="m 542.14,306.595 c -0.607,-0.351 -1.143,-1.019 -1.458,-1.745 v 0 c -0.189,-0.434 -0.298,-0.888 -0.299,-1.309 v 0 c 10e-4,-0.561 0.198,-0.954 0.513,-1.14 v 0 -0.001 l 0.067,-0.039 0.913,-0.527 10e-4,10e-4 c -0.316,0.186 -0.513,0.578 -0.514,1.14 v 0 c 10e-4,0.421 0.11,0.875 0.299,1.309 v 0 c 0.315,0.726 0.851,1.394 1.458,1.745 v 0 c 0.481,0.278 0.917,0.303 1.233,0.124 v 0 l -0.967,0.559 c -0.135,0.079 -0.293,0.121 -0.466,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.78,-0.238 m 2.226,-0.449 c -0.004,0.002 -0.009,0.005 -0.013,0.007 v 0 z"
				style="fill:url(#linearGradient3388);stroke:none"
				id="path3390" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3392">
	   <g
		  id="g3394"
		  clip-path="url(#clipPath3398)">
		 <g
			id="g3400"
			transform="translate(543.1206,306.0289)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.055 0,-1.126 0.786,-1.586 1.756,-1.026 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3402" />
		 </g>
		 <g
			id="g3404"
			transform="translate(538.5754,309.319)">
		   <path
			  d="m 0,0 -1.02,-0.589 -0.003,0.435 1.02,0.589 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3406" />
		 </g>
		 <g
			id="g3408"
			transform="translate(538.5728,309.7543)">
		   <path
			  d="M 0,0 -1.638,0.949 -2.656,0.361 -1.02,-0.589 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3410" />
		 </g>
		 <g
			id="g3412"
			transform="translate(537.5525,309.1651)">
		   <path
			  d="m 0,0 -1.636,0.951 0.003,-0.436 1.636,-0.95 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3414" />
		 </g>
		 <g
			id="g3416"
			transform="translate(534.0912,295.1306)">
		   <path
			  d="m 0,0 -4.456,-2.573 -0.011,1.002 4.456,2.573 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3418" />
		 </g>
		 <g
			id="g3420"
			transform="translate(534.0801,296.1323)">
		   <path
			  d="m 0,0 -7.792,5.119 -4.964,-2.869 8.3,-4.823 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3422" />
		 </g>
		 <g
			id="g3424"
			transform="translate(529.6242,293.5595)">
		   <path
			  d="m 0,0 -8.3,4.823 0.011,-1.002 8.3,-4.823 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3426" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g3428">
	   <g
		  id="g3430">
		 <g
			id="g3436">
		   <g
			  id="g3438">
			 <path
				d="m 524.05,303.219 c -0.607,-0.351 -1.143,-1.019 -1.458,-1.745 v 0 c -0.188,-0.434 -0.298,-0.888 -0.299,-1.309 v 0 c 10e-4,-0.561 0.198,-0.954 0.514,-1.14 v 0 -10e-4 l 0.066,-0.038 2.797,-1.642 v 0.001 c -0.316,0.186 -0.513,0.578 -0.513,1.14 v 0 c 0,0.421 0.11,0.875 0.299,1.309 v 0 c 0.314,0.726 0.85,1.394 1.457,1.745 v 0 c 0.488,0.281 0.929,0.304 1.247,0.117 v 0 l -2.863,1.68 c -0.135,0.08 -0.293,0.121 -0.466,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.781,-0.238"
				style="fill:url(#linearGradient3454);stroke:none"
				id="path3456" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3458">
	   <g
		  id="g3460"
		  clip-path="url(#clipPath3464)">
		 <g
			id="g3466"
			transform="translate(526.9139,301.5388)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3468" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g3470">
	   <g
		  id="g3472">
		 <g
			id="g3478">
		   <g
			  id="g3480">
			 <path
				d="m 527.064,300.298 c -0.261,-0.151 -0.491,-0.437 -0.626,-0.75 v 0 c -0.082,-0.186 -0.129,-0.381 -0.129,-0.562 v 0 c 0,-0.241 0.085,-0.41 0.221,-0.49 v 0 l 0.028,-0.016 4.262,-2.469 c -0.136,0.08 -0.221,0.249 -0.221,0.49 v 0 c 0,0.181 0.047,0.376 0.129,0.562 v 0 c 0.135,0.312 0.365,0.599 0.626,0.75 v 0 c 0.209,0.121 0.399,0.131 0.535,0.05 v 0 l -4.29,2.486 c -0.058,0.034 -0.125,0.052 -0.2,0.052 v 0 c -0.101,0 -0.214,-0.033 -0.335,-0.103"
				style="fill:url(#linearGradient3496);stroke:none"
				id="path3498" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3500">
	   <g
		  id="g3502">
		 <g
			id="g3508">
		   <g
			  id="g3510">
			 <path
				d="m 531.355,297.813 c -0.417,-0.241 -0.756,-0.828 -0.756,-1.313 v 0 c 0,-0.484 0.339,-0.681 0.756,-0.441 v 0 c 0.416,0.241 0.754,0.828 0.754,1.313 v 0 c 0,0.344 -0.171,0.543 -0.419,0.543 v 0 c -0.101,0 -0.215,-0.033 -0.335,-0.102"
				style="fill:url(#linearGradient3526);stroke:none"
				id="path3528" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3530">
	   <g
		  id="g3532">
		 <g
			id="g3538">
		   <g
			  id="g3540">
			 <path
				d="m 530.374,299.542 c -0.608,-0.35 -1.143,-1.017 -1.458,-1.745 v 0 c -0.189,-0.433 -0.298,-0.886 -0.299,-1.307 v 0 c 0.001,-0.562 0.197,-0.955 0.513,-1.142 v 0 0 l 0.066,-0.038 0.914,-0.528 h 10e-4 c -0.316,0.187 -0.513,0.58 -0.514,1.142 v 0 c 10e-4,0.421 0.11,0.873 0.299,1.307 v 0 c 0.315,0.727 0.85,1.395 1.458,1.745 v 0 c 0.481,0.278 0.916,0.305 1.233,0.126 v 0 l -0.968,0.557 c -0.135,0.08 -0.292,0.122 -0.465,0.122 v 0 c -0.235,0 -0.5,-0.076 -0.78,-0.239 m 2.226,-0.448 c -0.004,0.003 -0.009,0.005 -0.013,0.008 v 0 z"
				style="fill:url(#linearGradient3556);stroke:none"
				id="path3558" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3560">
	   <g
		  id="g3562"
		  clip-path="url(#clipPath3566)">
		 <g
			id="g3568"
			transform="translate(531.3541,298.9769)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.055 0,-1.126 0.786,-1.586 1.756,-1.026 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3570" />
		 </g>
		 <g
			id="g3572"
			transform="translate(526.8088,302.267)">
		   <path
			  d="m 0,0 -1.02,-0.589 -0.003,0.435 1.02,0.589 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3574" />
		 </g>
		 <g
			id="g3576"
			transform="translate(526.8063,302.7023)">
		   <path
			  d="M 0,0 -1.638,0.949 -2.656,0.361 -1.02,-0.589 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3578" />
		 </g>
		 <g
			id="g3580"
			transform="translate(525.7859,302.1131)">
		   <path
			  d="m 0,0 -1.636,0.951 0.003,-0.436 1.636,-0.95 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3582" />
		 </g>
		 <g
			id="g3584"
			transform="translate(471.1971,235.5674)">
		   <path
			  d="m 0,0 -4.456,-2.573 -0.011,1.002 4.456,2.573 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3586" />
		 </g>
		 <g
			id="g3588"
			transform="translate(471.186,236.569)">
		   <path
			  d="m 0,0 -7.792,5.119 -4.964,-2.869 8.3,-4.823 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3590" />
		 </g>
		 <g
			id="g3592"
			transform="translate(466.7301,233.9963)">
		   <path
			  d="M 0,0 -8.3,4.823 -8.29,3.821 0.011,-1.002 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3594" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g3596">
	   <g
		  id="g3598">
		 <g
			id="g3604">
		   <g
			  id="g3606">
			 <path
				d="m 461.156,243.655 c -0.607,-0.35 -1.143,-1.018 -1.458,-1.745 v 0 c -0.189,-0.434 -0.298,-0.886 -0.298,-1.308 v 0 c 0,-0.561 0.198,-0.955 0.513,-1.141 v 0 l 0.066,-0.038 2.796,-1.642 h 0.001 c -0.315,0.186 -0.513,0.58 -0.514,1.141 v 0 c 10e-4,0.421 0.111,0.874 0.299,1.308 v 0 c 0.315,0.727 0.851,1.395 1.458,1.745 v 0 c 0.488,0.282 0.929,0.305 1.247,0.118 v 0 l -2.864,1.68 c -0.134,0.079 -0.291,0.121 -0.464,0.121 v 0 c -0.235,0 -0.501,-0.077 -0.782,-0.239"
				style="fill:url(#linearGradient3622);stroke:none"
				id="path3624" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3626">
	   <g
		  id="g3628"
		  clip-path="url(#clipPath3632)">
		 <g
			id="g3634"
			transform="translate(464.0198,241.9755)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3636" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g3638">
	   <g
		  id="g3640">
		 <g
			id="g3646">
		   <g
			  id="g3648">
			 <path
				d="m 464.17,240.735 c -0.261,-0.151 -0.491,-0.438 -0.627,-0.75 v 0 c -0.081,-0.186 -0.128,-0.381 -0.128,-0.562 v 0 c 0,-0.241 0.085,-0.41 0.221,-0.49 v 0 l 0.028,-0.016 4.262,-2.47 v 0.001 c -0.136,0.08 -0.22,0.248 -0.22,0.49 v 0 c 0,0.18 0.047,0.376 0.127,0.562 v 0 c 0.136,0.312 0.366,0.599 0.627,0.75 v 0 c 0.21,0.12 0.399,0.13 0.535,0.05 v 0 l -4.29,2.485 c -0.058,0.035 -0.126,0.053 -0.2,0.053 v 0 c -0.101,0 -0.215,-0.033 -0.335,-0.103"
				style="fill:url(#linearGradient3664);stroke:none"
				id="path3666" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3668">
	   <g
		  id="g3670">
		 <g
			id="g3676">
		   <g
			  id="g3678">
			 <path
				d="m 468.46,238.25 c -0.417,-0.241 -0.754,-0.828 -0.754,-1.312 v 0 c 0,-0.485 0.337,-0.683 0.754,-0.441 v 0 c 0.417,0.24 0.755,0.828 0.755,1.312 v 0 c 0,0.344 -0.171,0.543 -0.42,0.543 v 0 c -0.101,0 -0.215,-0.033 -0.335,-0.102"
				style="fill:url(#linearGradient3694);stroke:none"
				id="path3696" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3698">
	   <g
		  id="g3700">
		 <g
			id="g3706">
		   <g
			  id="g3708">
			 <path
				d="m 467.479,239.98 c -0.608,-0.351 -1.143,-1.019 -1.457,-1.746 v 0 c -0.189,-0.433 -0.299,-0.887 -0.299,-1.308 v 0 c 0,-0.562 0.197,-0.954 0.513,-1.14 v 0 -0.001 l 0.066,-0.038 0.914,-0.528 v 0 c -0.316,0.187 -0.512,0.579 -0.513,1.141 v 0 c 0.001,0.421 0.11,0.875 0.299,1.308 v 0 c 0.315,0.727 0.85,1.394 1.458,1.745 v 0 c 0.488,0.282 0.928,0.305 1.246,0.118 v 0 l -0.981,0.566 c -0.135,0.079 -0.292,0.121 -0.466,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.78,-0.238"
				style="fill:url(#linearGradient3724);stroke:none"
				id="path3726" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3728">
	   <g
		  id="g3730"
		  clip-path="url(#clipPath3734)">
		 <g
			id="g3736"
			transform="translate(468.46,239.4136)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.928 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3738" />
		 </g>
		 <g
			id="g3740"
			transform="translate(463.9147,242.7037)">
		   <path
			  d="m 0,0 -1.02,-0.589 -0.003,0.435 1.02,0.589 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3742" />
		 </g>
		 <g
			id="g3744"
			transform="translate(463.9122,243.139)">
		   <path
			  d="M 0,0 -1.638,0.949 -2.656,0.361 -1.02,-0.589 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3746" />
		 </g>
		 <g
			id="g3748"
			transform="translate(462.8918,242.5499)">
		   <path
			  d="m 0,0 -1.636,0.95 0.003,-0.435 1.635,-0.95 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3750" />
		 </g>
		 <g
			id="g3752"
			transform="translate(494.4008,249.5608)">
		   <path
			  d="m 0,0 -4.456,-2.573 -0.011,1.002 4.456,2.573 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3754" />
		 </g>
		 <g
			id="g3756"
			transform="translate(494.3897,250.5624)">
		   <path
			  d="m 0,0 -7.792,5.119 -4.964,-2.869 8.3,-4.823 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3758" />
		 </g>
		 <g
			id="g3760"
			transform="translate(489.9338,247.9896)">
		   <path
			  d="M 0,0 -8.3,4.823 -8.29,3.821 0.011,-1.002 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3762" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g3764">
	   <g
		  id="g3766">
		 <g
			id="g3772">
		   <g
			  id="g3774">
			 <path
				d="m 484.36,257.648 c -0.607,-0.35 -1.143,-1.018 -1.458,-1.744 v 0 c -0.189,-0.435 -0.298,-0.887 -0.299,-1.308 v 0 c 10e-4,-0.562 0.198,-0.954 0.513,-1.142 v 0 l 0.067,-0.038 2.796,-1.642 h 0.001 c -0.316,0.187 -0.513,0.58 -0.514,1.141 v 0 c 10e-4,0.422 0.11,0.874 0.299,1.308 v 0 c 0.315,0.727 0.851,1.395 1.458,1.745 v 0 c 0.488,0.283 0.929,0.305 1.246,0.118 v 0 l -2.863,1.68 c -0.135,0.08 -0.292,0.121 -0.466,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.78,-0.239"
				style="fill:url(#linearGradient3790);stroke:none"
				id="path3792" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3794">
	   <g
		  id="g3796"
		  clip-path="url(#clipPath3800)">
		 <g
			id="g3802"
			transform="translate(487.2235,255.9689)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.928 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3804" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g3806">
	   <g
		  id="g3808">
		 <g
			id="g3814">
		   <g
			  id="g3816">
			 <path
				d="m 487.373,254.728 c -0.261,-0.15 -0.491,-0.438 -0.626,-0.75 v 0 c -0.081,-0.186 -0.128,-0.38 -0.128,-0.561 v 0 c 0,-0.242 0.085,-0.411 0.221,-0.491 v 0 h -10e-4 l 0.029,-0.016 4.261,-2.469 h 10e-4 c -0.136,0.08 -0.221,0.249 -0.221,0.49 v 0 c 0,0.182 0.047,0.376 0.128,0.562 v 0 c 0.135,0.313 0.366,0.6 0.627,0.75 v 0 c 0.209,0.122 0.398,0.131 0.535,0.05 v 0 l -4.29,2.485 c -0.058,0.035 -0.126,0.053 -0.2,0.053 v 0 c -0.101,0 -0.215,-0.033 -0.336,-0.103"
				style="fill:url(#linearGradient3832);stroke:none"
				id="path3834" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3836">
	   <g
		  id="g3838">
		 <g
			id="g3844">
		   <g
			  id="g3846">
			 <path
				d="m 491.664,252.243 c -0.417,-0.24 -0.755,-0.828 -0.755,-1.312 v 0 c 0,-0.484 0.338,-0.682 0.755,-0.441 v 0 c 0.416,0.24 0.755,0.828 0.755,1.312 v 0 c 0,0.344 -0.171,0.543 -0.42,0.543 v 0 c -0.101,0 -0.215,-0.033 -0.335,-0.102"
				style="fill:url(#linearGradient3862);stroke:none"
				id="path3864" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3866">
	   <g
		  id="g3868">
		 <g
			id="g3874">
		   <g
			  id="g3876">
			 <path
				d="m 490.683,253.973 c -0.608,-0.351 -1.143,-1.018 -1.458,-1.745 v 0 c -0.188,-0.434 -0.298,-0.888 -0.298,-1.309 v 0 c 0,-0.562 0.197,-0.954 0.513,-1.14 v 0 -0.001 l 0.065,-0.038 0.915,-0.528 v 0.001 c -0.316,0.186 -0.512,0.578 -0.513,1.141 v 0 c 0,0.421 0.11,0.874 0.299,1.308 v 0 c 0.315,0.727 0.85,1.394 1.458,1.745 v 0 c 0.479,0.277 0.915,0.304 1.231,0.125 v 0 l -0.966,0.558 c -0.135,0.079 -0.292,0.121 -0.466,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.78,-0.238 m 2.226,-0.449 c -0.004,0.002 -0.009,0.005 -0.014,0.008 v 0 z"
				style="fill:url(#linearGradient3892);stroke:none"
				id="path3894" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3896">
	   <g
		  id="g3898"
		  clip-path="url(#clipPath3902)">
		 <g
			id="g3904"
			transform="translate(491.6637,253.407)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3906" />
		 </g>
		 <g
			id="g3908"
			transform="translate(487.1184,256.6971)">
		   <path
			  d="m 0,0 -1.02,-0.589 -0.003,0.435 1.02,0.589 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3910" />
		 </g>
		 <g
			id="g3912"
			transform="translate(487.1159,257.1324)">
		   <path
			  d="M 0,0 -1.638,0.95 -2.656,0.361 -1.02,-0.589 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3914" />
		 </g>
		 <g
			id="g3916"
			transform="translate(486.0955,256.5432)">
		   <path
			  d="m 0,0 -1.636,0.951 0.003,-0.436 1.636,-0.95 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3918" />
		 </g>
		 <g
			id="g3920"
			transform="translate(348.938,222.42)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3922" />
		 </g>
		 <g
			id="g3924"
			transform="translate(348.938,223.4227)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3926" />
		 </g>
		 <g
			id="g3928"
			transform="translate(340.6634,218.5926)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3930" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g3932">
	   <g
		  id="g3934">
		 <g
			id="g3940">
		   <g
			  id="g3942">
			 <path
				d="m 345.287,228.162 -2.684,-1.572 c -0.086,-0.042 -0.162,-0.098 -0.23,-0.167 v 0 c -0.002,-0.001 -0.003,-0.003 -0.005,-0.004 v 0 c -0.03,-0.031 -0.058,-0.065 -0.084,-0.102 v 0 c -0.004,-0.006 -0.009,-0.01 -0.012,-0.016 v 0 c -0.023,-0.033 -0.043,-0.069 -0.063,-0.106 v 0 c -0.007,-0.011 -0.012,-0.02 -0.017,-0.03 v 0 c -0.016,-0.034 -0.031,-0.07 -0.044,-0.106 v 0 c -0.007,-0.017 -0.014,-0.032 -0.02,-0.05 v 0 c -0.01,-0.032 -0.018,-0.065 -0.027,-0.098 v 0 c -0.007,-0.025 -0.014,-0.049 -0.019,-0.075 v 0 c -0.005,-0.028 -0.009,-0.057 -0.014,-0.086 v 0 c -0.004,-0.035 -0.01,-0.067 -0.014,-0.102 v 0 c -0.002,-0.022 -0.002,-0.047 -0.004,-0.07 v 0 c -0.001,-0.043 -0.005,-0.085 -0.005,-0.131 v 0 c 0,-0.276 0.049,-0.568 0.137,-0.859 v 0 c 0.265,-0.871 0.885,-1.734 1.609,-2.152 v 0 c 0.243,-0.14 0.474,-0.217 0.685,-0.235 v 0 c 0.052,-0.004 0.103,-0.005 0.153,-0.003 v 0 c 0.004,0 0.008,0.001 0.012,0.001 v 0 c 0.045,0.004 0.089,0.008 0.131,0.017 v 0 c 0.009,0.002 0.018,0.006 0.027,0.007 v 0 c 0.036,0.009 0.073,0.019 0.107,0.032 v 0 c 0.015,0.005 0.028,0.013 0.041,0.019 v 0 c 0.028,0.012 0.056,0.024 0.082,0.039 v 0 c 10e-4,0 0.003,0.001 0.004,0.002 v 0 l 2.713,1.589 c 0.279,0.157 0.467,0.48 0.506,0.938 v 0 c 0.007,0.06 0.008,0.123 0.008,0.187 v 0 c 0.001,0.343 -0.075,0.708 -0.209,1.066 v 0 c -0.134,0.366 -0.328,0.725 -0.563,1.042 v 0 c -0.278,0.374 -0.611,0.692 -0.973,0.9 v 0 c -0.239,0.14 -0.468,0.216 -0.676,0.234 v 0 c -0.033,0.003 -0.066,0.005 -0.098,0.005 v 0 c -0.17,0 -0.323,-0.039 -0.454,-0.114"
				style="fill:url(#linearGradient3958);stroke:none"
				id="path3960" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g3962">
	   <g
		  id="g3964"
		  clip-path="url(#clipPath3968)">
		 <g
			id="g3970"
			transform="translate(345.5617,223.4408)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path3972" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g3974">
	   <g
		  id="g3976">
		 <g
			id="g3982">
		   <g
			  id="g3984">
			 <path
				d="m 343.066,225.381 -2.667,-1.517 c -0.034,-0.016 -0.065,-0.038 -0.092,-0.066 v 0 c 0,0 -0.001,0 -0.002,-0.001 v 0 c -0.011,-0.013 -0.022,-0.026 -0.033,-0.041 v 0 c -0.002,-0.002 -0.003,-0.003 -0.005,-0.006 v 0 c -0.009,-0.013 -0.017,-0.027 -0.025,-0.042 v 0 c -0.002,-0.004 -0.004,-0.008 -0.006,-0.012 v 0 c -0.006,-0.013 -0.013,-0.027 -0.018,-0.042 v 0 c -0.002,-0.007 -0.005,-0.013 -0.007,-0.019 v 0 c -0.005,-0.013 -0.007,-0.026 -0.011,-0.039 v 0 c -0.003,-0.01 -0.005,-0.02 -0.008,-0.03 v 0 c -0.002,-0.011 -0.003,-0.023 -0.005,-0.034 v 0 c -0.002,-0.013 -0.004,-0.026 -0.006,-0.04 v 0 c -10e-4,-0.009 -10e-4,-0.019 -10e-4,-0.028 v 0 c -0.001,-0.017 -0.003,-0.034 -0.003,-0.052 v 0 c 0,-0.109 0.02,-0.225 0.055,-0.34 v 0 c 0.105,-0.345 0.35,-0.686 0.637,-0.851 v 0 c 0.096,-0.056 0.187,-0.086 0.271,-0.093 v 0 c 0.02,-0.002 0.04,-0.003 0.06,-0.002 v 0 c 0.002,0 0.003,0.001 0.004,0.001 v 0 c 0.018,10e-4 0.036,0.004 0.053,0.006 v 0 c 0.003,10e-4 0.007,0.003 0.01,0.004 v 0 c 0.015,0.003 0.029,0.007 0.043,0.012 v 0 c 0.006,0.003 0.01,0.005 0.016,0.008 v 0 c 0.012,0.004 0.022,0.009 0.032,0.015 v 0 c 10e-4,0 10e-4,0 0.002,0.001 v 0 l 2.679,1.522 c 0.111,0.063 0.185,0.191 0.201,0.372 v 0 c 0.003,0.023 0.003,0.048 0.003,0.074 v 0 c 0.001,0.135 -0.03,0.28 -0.083,0.422 v 0 c -0.053,0.145 -0.13,0.287 -0.222,0.412 v 0 c -0.11,0.148 -0.242,0.273 -0.386,0.356 v 0 c -0.094,0.055 -0.185,0.085 -0.267,0.093 v 0 c -0.013,0.001 -0.026,0.002 -0.039,0.002 v 0 c -0.067,0 -0.127,-0.016 -0.18,-0.045"
				style="fill:url(#linearGradient4000);stroke:none"
				id="path4002" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4004">
	   <g
		  id="g4006"
		  clip-path="url(#clipPath4010)">
		 <g
			id="g4012"
			transform="translate(341.5692,222.6184)">
		   <path
			  d="m 0,0 c 0,0.441 -0.311,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.397 0,-0.441 0.312,-0.978 0.696,-1.2 C -0.311,-0.619 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4014" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4016">
	   <g
		  id="g4018">
		 <g
			id="g4024">
		   <g
			  id="g4026">
			 <path
				d="m 339.046,224.691 -1.054,-0.623 c -0.085,-0.043 -0.163,-0.099 -0.23,-0.168 v 0 c -0.002,-0.001 -0.003,-0.003 -0.005,-0.003 v 0 c -0.03,-0.032 -0.058,-0.067 -0.084,-0.104 v 0 c -0.005,-0.004 -0.008,-0.009 -0.012,-0.014 v 0 c -0.022,-0.034 -0.044,-0.07 -0.064,-0.106 v 0 c -0.005,-0.011 -0.012,-0.021 -0.016,-0.031 v 0 c -0.016,-0.033 -0.031,-0.069 -0.044,-0.105 v 0 c -0.006,-0.017 -0.014,-0.034 -0.02,-0.051 v 0 c -0.011,-0.031 -0.018,-0.065 -0.027,-0.098 v 0 c -0.006,-0.025 -0.013,-0.049 -0.019,-0.075 v 0 c -0.005,-0.028 -0.009,-0.057 -0.013,-0.086 v 0 c -0.005,-0.034 -0.011,-0.067 -0.015,-0.102 v 0 c -0.002,-0.022 -0.002,-0.047 -0.003,-0.07 v 0 c -0.002,-0.043 -0.006,-0.085 -0.006,-0.13 v 0 c 0,-0.277 0.049,-0.569 0.137,-0.86 v 0 c 0.266,-0.872 0.886,-1.734 1.608,-2.152 v 0 c 0.244,-0.14 0.476,-0.217 0.685,-0.235 v 0 h 0.001 c 0.052,-0.004 0.104,-0.005 0.152,-0.002 v 0 c 0.005,0 0.008,0 0.013,0 v 0 c 0.045,0.004 0.089,0.009 0.131,0.018 v 0 c 0.009,0.001 0.018,0.005 0.026,0.007 v 0 c 0.037,0.009 0.074,0.018 0.108,0.031 v 0 c 0.015,0.006 0.028,0.013 0.042,0.019 v 0 c 0.028,0.013 0.056,0.024 0.082,0.039 v 0 c 0.001,0.001 0.002,0.002 0.003,0.003 v 0 l 1.083,0.639 c 0.28,0.159 0.467,0.482 0.507,0.94 v 0 c 0.006,0.06 0.008,0.122 0.008,0.185 v 0 c 0.001,0.343 -0.076,0.71 -0.21,1.067 v 0 c -0.134,0.365 -0.328,0.725 -0.562,1.042 v 0 c -0.279,0.375 -0.611,0.692 -0.973,0.901 v 0 c -0.24,0.139 -0.468,0.215 -0.676,0.234 v 0 c -0.033,0.003 -0.066,0.004 -0.098,0.004 v 0 c -0.169,0 -0.323,-0.039 -0.455,-0.114"
				style="fill:url(#linearGradient4042);stroke:none"
				id="path4044" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4046">
	   <g
		  id="g4048"
		  clip-path="url(#clipPath4052)">
		 <g
			id="g4054"
			transform="translate(340.9509,220.918)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.759,3.033 -0.971,0.561 -1.758,0.112 -1.758,-1.002 0,-1.115 0.787,-2.473 1.758,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4056" />
		 </g>
		 <g
			id="g4058"
			transform="translate(346.7516,227.6389)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.675,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4060" />
		 </g>
		 <g
			id="g4062"
			transform="translate(346.7491,228.0748)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4064" />
		 </g>
		 <g
			id="g4066"
			transform="translate(345.0749,227.1085)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4068" />
		 </g>
		 <g
			id="g4070"
			transform="translate(356.7267,217.5649)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4072" />
		 </g>
		 <g
			id="g4074"
			transform="translate(356.7267,218.5676)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4076" />
		 </g>
		 <g
			id="g4078"
			transform="translate(348.4521,213.7375)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4080" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4082">
	   <g
		  id="g4084">
		 <g
			id="g4090">
		   <g
			  id="g4092">
			 <path
				d="m 353.075,223.307 -2.683,-1.572 c -0.085,-0.042 -0.162,-0.097 -0.231,-0.167 v 0 c -10e-4,-0.002 -0.002,-0.002 -0.004,-0.004 v 0 c -0.03,-0.032 -0.059,-0.066 -0.085,-0.103 v 0 c -0.004,-0.005 -0.008,-0.01 -0.012,-0.014 v 0 c -0.022,-0.034 -0.043,-0.069 -0.063,-0.107 v 0 c -0.005,-0.01 -0.011,-0.02 -0.017,-0.031 v 0 c -0.016,-0.033 -0.03,-0.068 -0.044,-0.104 v 0 c -0.006,-0.017 -0.013,-0.034 -0.02,-0.052 v 0 c -0.01,-0.03 -0.018,-0.064 -0.027,-0.098 v 0 c -0.006,-0.024 -0.013,-0.048 -0.019,-0.074 v 0 c -0.005,-0.027 -0.009,-0.058 -0.012,-0.087 v 0 c -0.006,-0.033 -0.012,-0.066 -0.015,-0.102 v 0 c -0.002,-0.022 -0.002,-0.046 -0.003,-0.07 v 0 c -0.003,-0.042 -0.006,-0.084 -0.006,-0.129 v 0 c 0,-0.278 0.048,-0.569 0.136,-0.86 v 0 c 0.266,-0.872 0.886,-1.735 1.61,-2.152 v 0 c 0.243,-0.14 0.474,-0.217 0.684,-0.235 v 0 c 0.053,-0.005 0.104,-0.006 0.153,-0.003 v 0 c 0.004,0 0.009,0.002 0.012,0.002 v 0 c 0.045,0.002 0.089,0.008 0.132,0.016 v 0 c 0.009,0.002 0.018,0.005 0.027,0.008 v 0 c 0.036,0.008 0.072,0.018 0.107,0.03 v 0 c 0.014,0.006 0.028,0.014 0.042,0.02 v 0 c 0.027,0.012 0.055,0.024 0.081,0.039 v 0 c 0.001,0.001 0.003,0.001 0.004,0.002 v 0 l 2.712,1.588 c 0.281,0.159 0.467,0.482 0.507,0.94 v 0 c 0.006,0.059 0.008,0.122 0.008,0.185 v 0 c 0.001,0.343 -0.076,0.709 -0.21,1.067 v 0 c -0.133,0.365 -0.327,0.725 -0.562,1.042 v 0 c -0.278,0.374 -0.611,0.692 -0.973,0.901 v 0 c -0.24,0.139 -0.468,0.214 -0.676,0.233 v 0 c -0.034,0.003 -0.068,0.005 -0.101,0.005 v 0 c -0.168,0 -0.321,-0.04 -0.452,-0.114"
				style="fill:url(#linearGradient4108);stroke:none"
				id="path4110" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4112">
	   <g
		  id="g4114"
		  clip-path="url(#clipPath4118)">
		 <g
			id="g4120"
			transform="translate(353.3504,218.5857)">
		   <path
			  d="m 0,0 c 0,1.115 -0.787,2.472 -1.759,3.033 -0.971,0.561 -1.758,0.112 -1.758,-1.002 0,-1.115 0.787,-2.473 1.758,-3.034 C -0.787,-1.563 0,-1.114 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4122" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4124">
	   <g
		  id="g4126">
		 <g
			id="g4132">
		   <g
			  id="g4134">
			 <path
				d="m 350.855,220.525 -2.668,-1.515 c -0.034,-0.018 -0.064,-0.039 -0.091,-0.067 v 0 l -10e-4,-0.001 c -0.013,-0.012 -0.024,-0.026 -0.034,-0.041 v 0 c -0.002,-0.002 -0.003,-0.004 -0.005,-0.006 v 0 c -0.009,-0.013 -0.017,-0.028 -0.025,-0.042 v 0 c -0.002,-0.005 -0.005,-0.008 -0.006,-0.012 v 0 c -0.007,-0.013 -0.013,-0.028 -0.018,-0.042 v 0 c -0.002,-0.006 -0.006,-0.013 -0.008,-0.02 v 0 c -0.004,-0.012 -0.007,-0.025 -0.01,-0.039 v 0 c -0.003,-0.009 -0.006,-0.018 -0.008,-0.029 v 0 c -0.002,-0.011 -0.003,-0.023 -0.005,-0.034 v 0 c -0.002,-0.014 -0.005,-0.027 -0.006,-0.04 v 0 c -0.001,-0.009 0,-0.019 -0.001,-0.028 v 0 c -10e-4,-0.017 -0.003,-0.034 -0.003,-0.051 v 0 c 0,-0.11 0.02,-0.226 0.055,-0.34 v 0 c 0.104,-0.346 0.35,-0.687 0.636,-0.853 v 0 c 0.097,-0.055 0.187,-0.085 0.271,-0.092 v 0 c 0.021,-0.002 0.041,-0.002 0.06,-0.001 v 0 c 0.002,0 0.004,0 0.006,0.001 v 0 c 0.017,0 0.035,0.002 0.051,0.006 v 0 c 0.004,0.001 0.007,0.002 0.011,0.003 v 0 c 0.014,0.003 0.029,0.007 0.042,0.012 v 0 c 0.007,0.002 0.011,0.006 0.017,0.007 v 0 c 0.011,0.006 0.022,0.01 0.032,0.016 v 0 c 0.001,0.001 0.002,0.001 0.002,0.001 v 0 l 2.679,1.522 c 0.111,0.062 0.184,0.191 0.201,0.372 v 0 c 0.002,0.024 0.003,0.049 0.003,0.074 v 0 c 0,0.135 -0.03,0.28 -0.083,0.421 v 0 c -0.053,0.145 -0.13,0.287 -0.223,0.412 v 0 c -0.11,0.149 -0.242,0.275 -0.385,0.358 v 0 c -0.094,0.054 -0.185,0.084 -0.267,0.091 v 0 c -0.014,0.002 -0.027,0.002 -0.04,0.002 v 0 c -0.067,0 -0.127,-0.015 -0.179,-0.045"
				style="fill:url(#linearGradient4150);stroke:none"
				id="path4152" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4154">
	   <g
		  id="g4156"
		  clip-path="url(#clipPath4160)">
		 <g
			id="g4162"
			transform="translate(349.3579,217.7633)">
		   <path
			  d="m 0,0 c 0,0.441 -0.312,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.397 0,-0.441 0.312,-0.978 0.696,-1.2 C -0.312,-0.619 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4164" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4166">
	   <g
		  id="g4168">
		 <g
			id="g4174">
		   <g
			  id="g4176">
			 <path
				d="m 346.835,219.837 -1.054,-0.625 c -0.086,-0.041 -0.162,-0.097 -0.23,-0.166 v 0 c -0.002,-0.002 -0.003,-0.003 -0.005,-0.005 v 0 c -0.03,-0.031 -0.059,-0.066 -0.085,-0.103 v 0 c -0.003,-0.005 -0.008,-0.009 -0.011,-0.014 v 0 c -0.023,-0.033 -0.044,-0.069 -0.064,-0.107 v 0 c -0.006,-0.011 -0.011,-0.02 -0.016,-0.031 v 0 c -0.017,-0.033 -0.031,-0.068 -0.044,-0.105 v 0 c -0.007,-0.016 -0.014,-0.032 -0.02,-0.05 v 0 c -0.01,-0.031 -0.018,-0.065 -0.027,-0.098 v 0 c -0.007,-0.025 -0.014,-0.049 -0.019,-0.074 v 0 c -0.006,-0.028 -0.009,-0.059 -0.014,-0.088 v 0 c -0.004,-0.033 -0.01,-0.065 -0.014,-0.101 v 0 c -0.002,-0.023 -0.002,-0.047 -0.004,-0.07 v 0 c -0.001,-0.043 -0.005,-0.085 -0.005,-0.13 v 0 c 0,-0.277 0.048,-0.569 0.137,-0.86 v 0 c 0.265,-0.872 0.885,-1.735 1.609,-2.152 v 0 c 0.243,-0.141 0.474,-0.216 0.684,-0.235 v 0 c 10e-4,0 10e-4,0 10e-4,0 v 0 c 0.052,-0.005 0.103,-0.006 0.153,-0.003 v 0 c 0.003,0 0.008,0.001 0.011,0.002 v 0 c 0.045,0.003 0.09,0.008 0.132,0.016 v 0 c 0.009,0.002 0.018,0.005 0.027,0.008 v 0 c 0.036,0.008 0.073,0.018 0.107,0.031 v 0 c 0.014,0.005 0.028,0.013 0.041,0.02 v 0 c 0.028,0.011 0.056,0.023 0.082,0.038 v 0 c 0.001,0.001 0.003,0.001 0.004,0.002 v 0 l 1.083,0.641 c 0.28,0.157 0.467,0.481 0.506,0.939 v 0 c 0.007,0.059 0.008,0.122 0.008,0.186 v 0 c 0.001,0.343 -0.075,0.708 -0.209,1.066 v 0 c -0.134,0.366 -0.328,0.725 -0.563,1.042 v 0 c -0.278,0.374 -0.611,0.692 -0.973,0.901 v 0 c -0.239,0.139 -0.468,0.215 -0.676,0.234 v 0 c -0.033,0.002 -0.066,0.004 -0.098,0.004 v 0 c -0.169,0 -0.323,-0.039 -0.454,-0.113"
				style="fill:url(#linearGradient4192);stroke:none"
				id="path4194" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4196">
	   <g
		  id="g4198"
		  clip-path="url(#clipPath4202)">
		 <g
			id="g4204"
			transform="translate(348.7396,216.0629)">
		   <path
			  d="m 0,0 c 0,1.115 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4206" />
		 </g>
		 <g
			id="g4208"
			transform="translate(354.5403,222.7838)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4210" />
		 </g>
		 <g
			id="g4212"
			transform="translate(354.5378,223.2197)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4214" />
		 </g>
		 <g
			id="g4216"
			transform="translate(352.8636,222.2535)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4218" />
		 </g>
		 <g
			id="g4220"
			transform="translate(364.5154,212.7098)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4222" />
		 </g>
		 <g
			id="g4224"
			transform="translate(364.5154,213.7125)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4226" />
		 </g>
		 <g
			id="g4228"
			transform="translate(356.2408,208.8825)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4230" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4232">
	   <g
		  id="g4234">
		 <g
			id="g4240">
		   <g
			  id="g4242">
			 <path
				d="m 360.864,218.452 -2.684,-1.572 c -0.084,-0.042 -0.162,-0.098 -0.23,-0.167 v 0 c -10e-4,-0.001 -0.003,-0.003 -0.004,-0.004 v 0 c -0.03,-0.031 -0.059,-0.066 -0.085,-0.103 v 0 c -0.004,-0.005 -0.008,-0.009 -0.012,-0.015 v 0 c -0.022,-0.033 -0.043,-0.069 -0.063,-0.106 v 0 c -0.005,-0.011 -0.012,-0.021 -0.017,-0.031 v 0 c -0.016,-0.033 -0.031,-0.069 -0.044,-0.105 v 0 c -0.006,-0.017 -0.014,-0.033 -0.02,-0.05 v 0 c -0.01,-0.032 -0.018,-0.065 -0.026,-0.098 v 0 c -0.007,-0.026 -0.015,-0.049 -0.02,-0.075 v 0 c -0.005,-0.028 -0.009,-0.058 -0.013,-0.086 v 0 c -0.005,-0.035 -0.011,-0.067 -0.015,-0.102 v 0 c -0.002,-0.023 -0.002,-0.047 -0.003,-0.07 v 0 c -0.002,-0.044 -0.006,-0.085 -0.006,-0.131 v 0 c 0,-0.276 0.049,-0.569 0.138,-0.859 v 0 c 0.265,-0.872 0.885,-1.735 1.608,-2.152 v 0 c 0.243,-0.141 0.474,-0.217 0.685,-0.235 v 0 c 0.052,-0.005 0.104,-0.005 0.153,-0.003 v 0 c 0.004,0 0.008,0.001 0.012,0.001 v 0 c 0.045,0.004 0.089,0.008 0.132,0.017 v 0 c 0.009,0.002 0.017,0.006 0.026,0.007 v 0 c 0.037,0.009 0.073,0.018 0.107,0.032 v 0 c 0.015,0.005 0.028,0.012 0.043,0.019 v 0 c 0.027,0.012 0.055,0.024 0.081,0.038 v 0 c 0,0.001 0.002,0.002 0.004,0.003 v 0 l 2.712,1.589 c 0.281,0.157 0.467,0.48 0.506,0.938 v 0 c 0.007,0.06 0.01,0.123 0.009,0.187 v 0 c 10e-4,0.342 -0.076,0.708 -0.209,1.066 v 0 c -0.134,0.366 -0.329,0.725 -0.564,1.041 v 0 c -0.278,0.375 -0.611,0.693 -0.973,0.901 v 0 c -0.239,0.14 -0.468,0.215 -0.675,0.234 v 0 c -0.033,0.003 -0.066,0.005 -0.098,0.005 v 0 c -0.17,0 -0.323,-0.04 -0.455,-0.114"
				style="fill:url(#linearGradient4258);stroke:none"
				id="path4260" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4262">
	   <g
		  id="g4264"
		  clip-path="url(#clipPath4268)">
		 <g
			id="g4270"
			transform="translate(361.1391,213.7306)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.114 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4272" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4274">
	   <g
		  id="g4276">
		 <g
			id="g4282">
		   <g
			  id="g4284">
			 <path
				d="m 358.644,215.671 -2.668,-1.517 c -0.034,-0.016 -0.065,-0.039 -0.092,-0.066 v 0 c 0,-0.001 -0.001,-0.001 -0.001,-0.001 v 0 c -0.012,-0.013 -0.023,-0.026 -0.034,-0.041 v 0 c -10e-4,-0.002 -0.003,-0.004 -0.004,-0.006 v 0 c -0.009,-0.013 -0.017,-0.027 -0.026,-0.043 v 0 c -0.001,-0.003 -0.004,-0.007 -0.006,-0.011 v 0 c -0.006,-0.014 -0.012,-0.027 -0.017,-0.042 v 0 c -0.003,-0.007 -0.006,-0.013 -0.008,-0.019 v 0 c -0.004,-0.013 -0.007,-0.027 -0.011,-0.039 v 0 c -0.002,-0.01 -0.005,-0.02 -0.007,-0.03 v 0 c -0.003,-0.011 -0.004,-0.023 -0.005,-0.034 v 0 c -0.002,-0.013 -0.005,-0.026 -0.007,-0.041 v 0 c 0,-0.009 0,-0.018 -10e-4,-0.027 v 0 c 0,-0.018 -0.002,-0.034 -0.002,-0.052 v 0 c 0,-0.11 0.02,-0.225 0.055,-0.34 v 0 c 0.104,-0.345 0.35,-0.687 0.636,-0.851 v 0 c 0.096,-0.056 0.187,-0.086 0.271,-0.093 v 0 c 0.02,-0.002 0.041,-0.003 0.06,-0.002 v 0 c 0.002,0 0.004,0.001 0.006,0.001 v 0 c 0.017,0.001 0.035,0.003 0.051,0.006 v 0 c 0.003,0.001 0.007,0.003 0.011,0.004 v 0 c 0.014,0.003 0.028,0.007 0.042,0.012 v 0 c 0.006,0.002 0.011,0.005 0.017,0.008 v 0 c 0.011,0.004 0.022,0.009 0.031,0.015 v 0 c 10e-4,0 0.002,0 0.002,0.001 v 0 l 2.68,1.522 c 0.11,0.063 0.185,0.191 0.2,0.372 v 0 c 0.003,0.023 0.004,0.048 0.004,0.073 v 0 c 0,0.136 -0.031,0.281 -0.084,0.422 v 0 c -0.052,0.145 -0.129,0.288 -0.222,0.413 v 0 c -0.11,0.148 -0.242,0.273 -0.385,0.356 v 0 c -0.095,0.055 -0.186,0.085 -0.268,0.093 v 0 c -0.013,0.001 -0.026,0.002 -0.039,0.002 v 0 c -0.066,0 -0.127,-0.016 -0.179,-0.045"
				style="fill:url(#linearGradient4300);stroke:none"
				id="path4302" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4304">
	   <g
		  id="g4306"
		  clip-path="url(#clipPath4310)">
		 <g
			id="g4312"
			transform="translate(357.1466,212.9082)">
		   <path
			  d="m 0,0 c 0,0.441 -0.311,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.397 0,-0.44 0.312,-0.978 0.696,-1.2 C -0.311,-0.619 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4314" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4316">
	   <g
		  id="g4318">
		 <g
			id="g4324">
		   <g
			  id="g4326">
			 <path
				d="m 354.623,214.981 -1.054,-0.624 c -0.085,-0.042 -0.161,-0.098 -0.23,-0.167 v 0 l -0.004,-0.004 c -0.03,-0.031 -0.059,-0.066 -0.085,-0.103 v 0 c -0.004,-0.005 -0.008,-0.009 -0.012,-0.014 v 0 c -0.022,-0.034 -0.043,-0.07 -0.063,-0.107 v 0 c -0.006,-0.01 -0.011,-0.02 -0.017,-0.031 v 0 c -0.016,-0.033 -0.03,-0.068 -0.044,-0.105 v 0 c -0.006,-0.017 -0.013,-0.033 -0.02,-0.05 v 0 c -0.01,-0.031 -0.018,-0.065 -0.027,-0.098 v 0 c -0.006,-0.025 -0.013,-0.049 -0.019,-0.075 v 0 c -0.005,-0.028 -0.009,-0.057 -0.013,-0.087 v 0 c -0.005,-0.033 -0.011,-0.066 -0.014,-0.101 v 0 c -0.002,-0.022 -0.002,-0.048 -0.003,-0.07 v 0 c -0.003,-0.043 -0.006,-0.086 -0.006,-0.131 v 0 c 0,-0.276 0.048,-0.568 0.136,-0.859 v 0 c 0.266,-0.872 0.886,-1.734 1.61,-2.152 v 0 c 0.242,-0.14 0.474,-0.217 0.684,-0.235 v 0 c 0.053,-0.004 0.104,-0.005 0.153,-0.003 v 0 c 0.004,0 0.008,0.001 0.012,0.001 v 0 c 0.045,0.003 0.089,0.009 0.131,0.017 v 0 c 0.01,0.002 0.019,0.006 0.028,0.008 v 0 c 0.035,0.009 0.072,0.018 0.107,0.031 v 0 c 0.014,0.006 0.027,0.013 0.042,0.019 v 0 c 0.027,0.013 0.055,0.024 0.081,0.039 v 0 c 0.001,10e-4 0.003,0.002 0.003,0.002 v 0 l 1.083,0.64 c 0.281,0.159 0.467,0.482 0.507,0.94 v 0 c 0.006,0.059 0.009,0.122 0.008,0.185 v 0 c 0.001,0.343 -0.076,0.71 -0.209,1.067 v 0 c -0.134,0.365 -0.328,0.725 -0.563,1.042 v 0 c -0.278,0.374 -0.611,0.692 -0.973,0.901 v 0 c -0.24,0.139 -0.468,0.214 -0.675,0.233 v 0 c -0.034,0.003 -0.068,0.005 -0.101,0.005 v 0 c -0.169,0 -0.322,-0.04 -0.453,-0.114"
				style="fill:url(#linearGradient4342);stroke:none"
				id="path4344" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4346">
	   <g
		  id="g4348"
		  clip-path="url(#clipPath4352)">
		 <g
			id="g4354"
			transform="translate(356.5283,211.2078)">
		   <path
			  d="m 0,0 c 0,1.115 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.114 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4356" />
		 </g>
		 <g
			id="g4358"
			transform="translate(362.329,217.9287)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4360" />
		 </g>
		 <g
			id="g4362"
			transform="translate(362.3265,218.3646)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4364" />
		 </g>
		 <g
			id="g4366"
			transform="translate(360.6523,217.3984)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4368" />
		 </g>
		 <g
			id="g4370"
			transform="translate(372.304,207.8548)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.274,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4372" />
		 </g>
		 <g
			id="g4374"
			transform="translate(372.304,208.8574)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.4,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4376" />
		 </g>
		 <g
			id="g4378"
			transform="translate(364.0295,204.0274)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4380" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4382">
	   <g
		  id="g4384">
		 <g
			id="g4390">
		   <g
			  id="g4392">
			 <path
				d="m 368.653,213.597 -2.684,-1.572 c -0.085,-0.042 -0.162,-0.098 -0.231,-0.167 v 0 c -10e-4,-0.002 -0.002,-0.003 -0.003,-0.004 v 0 c -0.031,-0.032 -0.06,-0.066 -0.086,-0.103 v 0 c -0.003,-0.005 -0.008,-0.01 -0.011,-0.014 v 0 c -0.023,-0.034 -0.044,-0.07 -0.063,-0.107 v 0 c -0.006,-0.01 -0.012,-0.02 -0.018,-0.031 v 0 c -0.016,-0.033 -0.029,-0.068 -0.044,-0.105 v 0 c -0.006,-0.017 -0.013,-0.033 -0.019,-0.051 v 0 c -0.01,-0.03 -0.018,-0.065 -0.027,-0.098 v 0 c -0.006,-0.024 -0.014,-0.048 -0.019,-0.074 v 0 c -0.006,-0.028 -0.008,-0.058 -0.013,-0.087 v 0 c -0.005,-0.033 -0.012,-0.066 -0.015,-0.102 v 0 c -0.002,-0.022 -0.002,-0.046 -0.003,-0.07 v 0 c -0.003,-0.042 -0.006,-0.084 -0.006,-0.129 v 0 c 0,-0.278 0.048,-0.569 0.137,-0.86 v 0 c 0.265,-0.872 0.885,-1.735 1.609,-2.152 v 0 c 0.243,-0.14 0.474,-0.217 0.685,-0.236 v 0 c 0.052,-0.004 0.103,-0.005 0.153,-0.002 v 0 c 0.004,0 0.008,10e-4 0.012,10e-4 v 0 c 0.045,0.003 0.089,0.009 0.132,0.017 v 0 c 0.009,10e-4 0.017,0.005 0.026,0.008 v 0 c 0.037,0.008 0.073,0.018 0.108,0.03 v 0 c 0.014,0.006 0.027,0.014 0.041,0.02 v 0 c 0.027,0.012 0.055,0.024 0.081,0.039 v 0 c 0.002,0.001 0.003,0.001 0.005,0.002 v 0 l 2.711,1.588 c 0.281,0.159 0.467,0.482 0.507,0.94 v 0 c 0.006,0.059 0.009,0.121 0.009,0.185 v 0 c 0,0.343 -0.076,0.708 -0.21,1.067 v 0 c -0.134,0.365 -0.328,0.724 -0.563,1.041 v 0 c -0.278,0.375 -0.611,0.693 -0.973,0.902 v 0 c -0.239,0.138 -0.468,0.214 -0.675,0.233 v 0 c -0.033,0.003 -0.066,0.004 -0.099,0.004 v 0 c -0.169,0 -0.322,-0.039 -0.454,-0.113"
				style="fill:url(#linearGradient4408);stroke:none"
				id="path4410" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4412">
	   <g
		  id="g4414"
		  clip-path="url(#clipPath4418)">
		 <g
			id="g4420"
			transform="translate(368.9278,208.8756)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.759,3.033 -0.971,0.561 -1.758,0.112 -1.758,-1.002 0,-1.115 0.787,-2.473 1.758,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4422" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4424">
	   <g
		  id="g4426">
		 <g
			id="g4432">
		   <g
			  id="g4434">
			 <path
				d="m 366.432,210.815 -2.667,-1.516 c -0.035,-0.017 -0.065,-0.038 -0.091,-0.066 v 0 c -10e-4,0 -0.002,-0.001 -0.002,-0.001 v 0 c -0.013,-0.013 -0.023,-0.026 -0.034,-0.041 v 0 c -10e-4,-0.002 -0.003,-0.004 -0.005,-0.006 v 0 c -0.009,-0.013 -0.017,-0.028 -0.025,-0.042 v 0 c -0.002,-0.005 -0.004,-0.008 -0.006,-0.013 v 0 c -0.007,-0.012 -0.012,-0.027 -0.017,-0.041 v 0 c -0.003,-0.006 -0.006,-0.013 -0.008,-0.02 v 0 c -0.005,-0.012 -0.008,-0.025 -0.011,-0.039 v 0 c -0.003,-0.01 -0.006,-0.019 -0.007,-0.029 v 0 c -0.003,-0.011 -0.004,-0.023 -0.006,-0.035 v 0 c -0.002,-0.013 -0.004,-0.026 -0.005,-0.04 v 0 c -10e-4,-0.008 -10e-4,-0.018 -0.002,-0.027 v 0 c -10e-4,-0.017 -0.003,-0.034 -0.003,-0.052 v 0 c 0,-0.109 0.02,-0.225 0.055,-0.34 v 0 c 0.105,-0.345 0.35,-0.686 0.636,-0.852 v 0 c 0.097,-0.055 0.189,-0.085 0.271,-0.093 v 0 c 0.021,-0.001 0.042,-0.001 0.061,0 v 0 c 0.002,0 0.003,0 0.005,0 v 0 c 0.018,10e-4 0.035,0.003 0.052,0.007 v 0 c 0.004,0.001 0.007,0.002 0.01,0.003 v 0 c 0.015,0.003 0.029,0.007 0.043,0.012 v 0 c 0.006,0.002 0.011,0.005 0.017,0.007 v 0 c 0.01,0.006 0.021,0.01 0.032,0.016 v 0 c 0,0 10e-4,0 10e-4,0 v 0 l 2.679,1.523 c 0.112,0.062 0.186,0.19 0.201,0.372 v 0 c 0.003,0.023 0.003,0.049 0.003,0.073 v 0 c 0,0.136 -0.03,0.281 -0.082,0.422 v 0 c -0.053,0.145 -0.131,0.287 -0.224,0.412 v 0 c -0.109,0.149 -0.242,0.275 -0.385,0.357 v 0 c -0.094,0.054 -0.184,0.085 -0.267,0.092 v 0 c -0.013,0.001 -0.026,0.002 -0.039,0.002 v 0 c -0.067,0 -0.128,-0.015 -0.18,-0.045"
				style="fill:url(#linearGradient4450);stroke:none"
				id="path4452" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4454">
	   <g
		  id="g4456"
		  clip-path="url(#clipPath4460)">
		 <g
			id="g4462"
			transform="translate(364.9353,208.0531)">
		   <path
			  d="m 0,0 c 0,0.441 -0.312,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.397 0,-0.44 0.312,-0.978 0.696,-1.2 C -0.312,-0.619 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4464" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4466">
	   <g
		  id="g4468">
		 <g
			id="g4474">
		   <g
			  id="g4476">
			 <path
				d="m 362.412,210.126 -1.054,-0.624 c -0.085,-0.041 -0.162,-0.097 -0.23,-0.167 v 0 c -10e-4,-0.001 -0.003,-0.002 -0.004,-0.004 v 0 c -0.03,-0.032 -0.059,-0.066 -0.085,-0.103 v 0 c -0.004,-0.005 -0.009,-0.01 -0.012,-0.015 v 0 c -0.023,-0.032 -0.043,-0.069 -0.063,-0.106 v 0 c -0.006,-0.011 -0.012,-0.02 -0.017,-0.031 v 0 c -0.016,-0.033 -0.031,-0.069 -0.044,-0.105 v 0 c -0.007,-0.017 -0.014,-0.032 -0.02,-0.05 v 0 c -0.01,-0.032 -0.018,-0.065 -0.026,-0.099 v 0 c -0.007,-0.024 -0.015,-0.048 -0.02,-0.074 v 0 c -0.005,-0.027 -0.009,-0.058 -0.013,-0.087 v 0 c -0.005,-0.033 -0.011,-0.066 -0.015,-0.101 v 0 c -0.002,-0.023 -0.002,-0.047 -0.003,-0.07 v 0 c -0.002,-0.043 -0.006,-0.085 -0.006,-0.13 v 0 c 0,-0.277 0.049,-0.569 0.137,-0.86 v 0 c 0.265,-0.872 0.885,-1.735 1.609,-2.152 v 0 c 0.243,-0.141 0.474,-0.216 0.685,-0.235 v 0 c 0.052,-0.005 0.104,-0.006 0.153,-0.003 v 0 c 0.004,0 0.008,10e-4 0.012,0.002 v 0 c 0.045,0.003 0.089,0.008 0.132,0.016 v 0 c 0.009,0.002 0.017,0.005 0.026,0.007 v 0 c 0.037,0.009 0.073,0.019 0.107,0.032 v 0 c 0.015,0.005 0.028,0.013 0.042,0.019 v 0 c 0.027,0.012 0.055,0.024 0.081,0.039 v 0 c 0.001,0.001 0.003,0.001 0.004,0.002 v 0 l 1.083,0.641 c 0.281,0.157 0.467,0.48 0.507,0.939 v 0 c 0.006,0.059 0.009,0.122 0.009,0.186 v 0 c 0,0.343 -0.077,0.708 -0.21,1.066 v 0 c -0.134,0.366 -0.329,0.725 -0.564,1.042 v 0 c -0.278,0.374 -0.611,0.692 -0.972,0.9 v 0 c -0.24,0.14 -0.469,0.216 -0.676,0.235 v 0 c -0.033,0.002 -0.066,0.004 -0.098,0.004 v 0 c -0.17,0 -0.323,-0.039 -0.455,-0.114"
				style="fill:url(#linearGradient4492);stroke:none"
				id="path4494" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4496">
	   <g
		  id="g4498"
		  clip-path="url(#clipPath4502)">
		 <g
			id="g4504"
			transform="translate(364.317,206.3527)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.114 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4506" />
		 </g>
		 <g
			id="g4508"
			transform="translate(370.1177,213.0736)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4510" />
		 </g>
		 <g
			id="g4512"
			transform="translate(370.1151,213.5095)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4514" />
		 </g>
		 <g
			id="g4516"
			transform="translate(368.441,212.5433)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4518" />
		 </g>
		 <g
			id="g4520"
			transform="translate(144.983,174.3746)">
		   <path
			  d="M 0,0 -6.13,-3.314"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path4522" />
		 </g>
		 <g
			id="g4524"
			transform="translate(137.8627,178.4904)">
		   <path
			  d="M 0,0 -6.13,-3.314"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path4526" />
		 </g>
		 <g
			id="g4528"
			transform="translate(152.1034,170.2587)">
		   <path
			  d="M 0,0 -6.13,-3.314"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path4530" />
		 </g>
		 <g
			id="g4532"
			transform="translate(159.2238,166.1429)">
		   <path
			  d="M 0,0 -6.13,-3.314"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path4534" />
		 </g>
		 <g
			id="g4536"
			transform="translate(166.3441,162.027)">
		   <path
			  d="M 0,0 -6.13,-3.314"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path4538" />
		 </g>
		 <g
			id="g4540"
			transform="translate(173.4645,157.9111)">
		   <path
			  d="M 0,0 -6.13,-3.314"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path4542" />
		 </g>
		 <g
			id="g4544"
			transform="translate(194.9997,202.0569)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4546" />
		 </g>
		 <g
			id="g4548"
			transform="translate(194.9997,203.0596)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4550" />
		 </g>
		 <g
			id="g4552"
			transform="translate(186.7251,198.2296)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4554" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4556">
	   <g
		  id="g4558">
		 <g
			id="g4564">
		   <g
			  id="g4566">
			 <path
				d="m 191.349,207.799 -2.684,-1.572 c -0.085,-0.041 -0.163,-0.097 -0.231,-0.167 v 0 c -0.001,-0.001 -0.003,-0.002 -0.004,-0.004 v 0 c -0.03,-0.032 -0.058,-0.066 -0.085,-0.103 v 0 c -0.004,-0.005 -0.007,-0.01 -0.011,-0.014 v 0 c -0.023,-0.033 -0.044,-0.069 -0.064,-0.107 v 0 c -0.005,-0.01 -0.012,-0.02 -0.017,-0.031 v 0 c -0.016,-0.033 -0.03,-0.068 -0.043,-0.105 v 0 c -0.007,-0.016 -0.015,-0.033 -0.02,-0.051 v 0 c -0.011,-0.03 -0.019,-0.064 -0.027,-0.098 v 0 c -0.006,-0.024 -0.014,-0.048 -0.019,-0.073 v 0 c -0.006,-0.028 -0.009,-0.059 -0.013,-0.088 v 0 c -0.006,-0.033 -0.012,-0.065 -0.015,-0.101 v 0 c -0.003,-0.023 -0.003,-0.047 -0.003,-0.071 v 0 c -0.003,-0.042 -0.007,-0.084 -0.007,-0.129 v 0 c 0,-0.277 0.05,-0.569 0.138,-0.86 v 0 c 0.265,-0.872 0.886,-1.735 1.608,-2.152 v 0 c 0.243,-0.14 0.475,-0.217 0.685,-0.235 v 0 c 0,0 0,0 0.001,0 v 0 c 0.052,-0.005 0.103,-0.006 0.152,-0.003 v 0 c 0.005,0 0.008,0.001 0.013,0.002 v 0 c 0.045,0.002 0.089,0.008 0.131,0.016 v 0 c 0.009,0.002 0.017,0.005 0.026,0.008 v 0 c 0.037,0.008 0.073,0.018 0.108,0.031 v 0 c 0.015,0.005 0.027,0.013 0.042,0.019 v 0 c 0.028,0.012 0.055,0.024 0.081,0.039 v 0 c 0.001,0.001 0.002,0.001 0.004,0.002 v 0 l 2.712,1.588 c 0.281,0.159 0.467,0.482 0.507,0.94 v 0 c 0.006,0.059 0.009,0.122 0.009,0.185 v 0 c 0,0.343 -0.077,0.709 -0.21,1.067 v 0 c -0.134,0.365 -0.329,0.724 -0.564,1.041 v 0 c -0.277,0.375 -0.611,0.693 -0.972,0.902 v 0 c -0.24,0.139 -0.468,0.214 -0.675,0.233 v 0 c -0.034,0.003 -0.067,0.004 -0.099,0.004 v 0 c -0.169,0 -0.323,-0.039 -0.454,-0.113"
				style="fill:url(#linearGradient4582);stroke:none"
				id="path4584" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4586">
	   <g
		  id="g4588"
		  clip-path="url(#clipPath4592)">
		 <g
			id="g4594"
			transform="translate(191.6234,203.0777)">
		   <path
			  d="m 0,0 c 0,1.115 -0.787,2.473 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.114 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4596" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4598">
	   <g
		  id="g4600">
		 <g
			id="g4606">
		   <g
			  id="g4608">
			 <path
				d="m 189.129,205.017 -2.669,-1.515 c -0.033,-0.017 -0.064,-0.039 -0.091,-0.067 v 0 c -0.001,0 -0.002,-0.001 -0.002,-0.001 v 0 c -0.012,-0.012 -0.023,-0.026 -0.034,-0.041 v 0 c -0.001,-0.002 -0.002,-0.004 -0.004,-0.006 v 0 c -0.009,-0.013 -0.017,-0.028 -0.024,-0.042 v 0 c -0.003,-0.004 -0.006,-0.008 -0.008,-0.012 v 0 c -0.006,-0.013 -0.011,-0.028 -0.017,-0.042 v 0 c -0.003,-0.006 -0.005,-0.012 -0.008,-0.02 v 0 c -0.004,-0.012 -0.007,-0.025 -0.011,-0.038 v 0 c -0.002,-0.01 -0.005,-0.019 -0.007,-0.03 v 0 c -0.003,-0.011 -0.003,-0.023 -0.005,-0.034 v 0 c -0.002,-0.014 -0.005,-0.026 -0.006,-0.041 v 0 c -0.001,-0.008 -0.001,-0.018 -0.001,-0.027 v 0 c -10e-4,-0.017 -0.002,-0.034 -0.002,-0.052 v 0 c 0,-0.109 0.019,-0.225 0.054,-0.339 v 0 c 0.104,-0.346 0.35,-0.687 0.636,-0.853 v 0 c 0.096,-0.055 0.188,-0.085 0.271,-0.092 v 0 c 0.021,-0.002 0.041,-0.002 0.06,-10e-4 v 0 c 0.002,0 0.004,0 0.006,0 v 0 c 0.018,0.002 0.035,0.003 0.052,0.007 v 0 c 0.003,0.001 0.006,0.002 0.01,0.003 v 0 c 0.014,0.003 0.028,0.007 0.043,0.012 v 0 c 0.005,0.002 0.011,0.006 0.016,0.008 v 0 c 0.011,0.005 0.022,0.009 0.033,0.015 v 0 c 0,0.001 0,0.001 0,0.001 v 0 l 2.68,1.523 c 0.111,0.062 0.185,0.189 0.2,0.371 v 0 c 0.003,0.024 0.004,0.049 0.004,0.073 v 0 c 0,0.136 -0.031,0.281 -0.083,0.422 v 0 c -0.053,0.145 -0.13,0.287 -0.223,0.412 v 0 c -0.11,0.149 -0.242,0.275 -0.384,0.358 v 0 c -0.096,0.054 -0.186,0.084 -0.268,0.092 v 0 c -0.013,0.001 -0.026,0.001 -0.039,0.001 v 0 c -0.067,0 -0.128,-0.015 -0.179,-0.045"
				style="fill:url(#linearGradient4624);stroke:none"
				id="path4626" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4628">
	   <g
		  id="g4630"
		  clip-path="url(#clipPath4634)">
		 <g
			id="g4636"
			transform="translate(187.6309,202.2553)">
		   <path
			  d="m 0,0 c 0,0.441 -0.311,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.396 0,-0.441 0.312,-0.979 0.696,-1.201 C -0.311,-0.619 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4638" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4640">
	   <g
		  id="g4642">
		 <g
			id="g4648">
		   <g
			  id="g4650">
			 <path
				d="m 185.108,204.328 -1.054,-0.624 c -0.085,-0.041 -0.162,-0.097 -0.231,-0.167 v 0 c -0.001,-0.001 -0.003,-0.002 -0.003,-0.004 v 0 c -0.031,-0.031 -0.06,-0.066 -0.086,-0.102 v 0 c -0.004,-0.006 -0.008,-0.01 -0.011,-0.016 v 0 c -0.023,-0.032 -0.044,-0.069 -0.064,-0.106 v 0 c -0.005,-0.011 -0.011,-0.02 -0.017,-0.03 v 0 c -0.016,-0.034 -0.029,-0.07 -0.044,-0.106 v 0 c -0.006,-0.017 -0.013,-0.032 -0.02,-0.05 v 0 c -0.01,-0.032 -0.018,-0.065 -0.026,-0.098 v 0 c -0.006,-0.025 -0.014,-0.049 -0.019,-0.075 v 0 c -0.006,-0.027 -0.009,-0.058 -0.013,-0.086 v 0 c -0.006,-0.034 -0.012,-0.067 -0.015,-0.102 v 0 c -0.002,-0.023 -0.002,-0.047 -0.003,-0.07 v 0 c -0.003,-0.043 -0.006,-0.085 -0.006,-0.13 v 0 c 0,-0.277 0.048,-0.569 0.137,-0.859 v 0 c 0.265,-0.873 0.885,-1.736 1.609,-2.153 v 0 c 0.243,-0.141 0.474,-0.216 0.684,-0.235 v 0 c 0.053,-0.005 0.104,-0.006 0.153,-0.003 v 0 c 0.005,0 0.009,10e-4 0.013,0.002 v 0 c 0.045,0.003 0.089,0.008 0.131,0.016 v 0 c 0.009,0.002 0.018,0.006 0.027,0.007 v 0 c 0.036,0.009 0.072,0.019 0.108,0.032 v 0 c 0.014,0.005 0.027,0.013 0.041,0.02 v 0 c 0.027,0.011 0.055,0.023 0.081,0.038 v 0 c 0.002,0.001 0.003,0.001 0.004,0.002 v 0 l 1.082,0.641 c 0.281,0.157 0.467,0.481 0.508,0.939 v 0 c 0.005,0.059 0.008,0.122 0.008,0.186 v 0 c 0,0.343 -0.077,0.708 -0.21,1.066 v 0 c -0.134,0.366 -0.328,0.725 -0.563,1.042 v 0 c -0.277,0.374 -0.611,0.692 -0.972,0.901 v 0 c -0.24,0.139 -0.469,0.215 -0.676,0.234 v 0 c -0.033,0.003 -0.066,0.004 -0.099,0.004 v 0 c -0.169,0 -0.322,-0.039 -0.454,-0.114"
				style="fill:url(#linearGradient4666);stroke:none"
				id="path4668" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4670">
	   <g
		  id="g4672"
		  clip-path="url(#clipPath4676)">
		 <g
			id="g4678"
			transform="translate(187.0126,200.5549)">
		   <path
			  d="m 0,0 c 0,1.115 -0.787,2.473 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.114 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4680" />
		 </g>
		 <g
			id="g4682"
			transform="translate(192.8133,207.2758)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.675,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4684" />
		 </g>
		 <g
			id="g4686"
			transform="translate(192.8108,207.7117)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4688" />
		 </g>
		 <g
			id="g4690"
			transform="translate(191.1366,206.7455)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4692" />
		 </g>
		 <g
			id="g4694"
			transform="translate(202.6499,197.3105)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4696" />
		 </g>
		 <g
			id="g4698"
			transform="translate(202.6499,198.3131)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4700" />
		 </g>
		 <g
			id="g4702"
			transform="translate(194.3754,193.4831)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4704" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4706">
	   <g
		  id="g4708">
		 <g
			id="g4714">
		   <g
			  id="g4716">
			 <path
				d="m 198.999,203.053 -2.684,-1.573 c -0.085,-0.041 -0.163,-0.097 -0.23,-0.166 v 0 c -0.002,-0.002 -0.003,-0.003 -0.005,-0.005 v 0 c -0.03,-0.031 -0.058,-0.065 -0.085,-0.102 v 0 c -0.004,-0.005 -0.007,-0.01 -0.011,-0.015 v 0 c -0.023,-0.033 -0.044,-0.069 -0.064,-0.107 v 0 c -0.005,-0.01 -0.012,-0.02 -0.017,-0.03 v 0 c -0.016,-0.034 -0.03,-0.069 -0.043,-0.105 v 0 c -0.006,-0.017 -0.015,-0.033 -0.02,-0.051 v 0 c -0.011,-0.031 -0.018,-0.065 -0.027,-0.098 v 0 c -0.006,-0.024 -0.014,-0.048 -0.019,-0.074 v 0 c -0.005,-0.028 -0.009,-0.058 -0.013,-0.087 v 0 c -0.005,-0.034 -0.011,-0.066 -0.015,-0.102 v 0 c -0.002,-0.022 -0.002,-0.047 -0.003,-0.07 v 0 c -0.003,-0.043 -0.007,-0.085 -0.007,-0.13 v 0 c 0,-0.277 0.05,-0.569 0.138,-0.859 v 0 c 0.266,-0.872 0.886,-1.735 1.608,-2.152 v 0 c 0.243,-0.141 0.475,-0.217 0.685,-0.235 v 0 c 0,-0.001 10e-4,0 10e-4,-0.001 v 0 c 0.052,-0.004 0.103,-0.005 0.152,-0.003 v 0 c 0.005,0.001 0.008,0.002 0.013,0.002 v 0 c 0.045,0.003 0.089,0.008 0.131,0.016 v 0 c 0.009,0.003 0.017,0.006 0.026,0.008 v 0 c 0.037,0.008 0.074,0.018 0.108,0.031 v 0 c 0.015,0.005 0.028,0.013 0.042,0.02 v 0 c 0.028,0.011 0.055,0.023 0.082,0.038 v 0 c 0,0.001 0.002,0.001 0.003,0.002 v 0 l 2.713,1.589 c 0.28,0.158 0.467,0.481 0.506,0.939 v 0 c 0.007,0.06 0.009,0.123 0.009,0.186 v 0 c 0,0.343 -0.077,0.708 -0.21,1.066 v 0 c -0.134,0.366 -0.328,0.726 -0.563,1.042 v 0 c -0.278,0.375 -0.612,0.693 -0.973,0.901 v 0 c -0.24,0.139 -0.468,0.215 -0.675,0.233 v 0 c -0.035,0.004 -0.068,0.005 -0.101,0.005 v 0 c -0.169,0 -0.321,-0.04 -0.452,-0.113"
				style="fill:url(#linearGradient4732);stroke:none"
				id="path4734" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4736">
	   <g
		  id="g4738"
		  clip-path="url(#clipPath4742)">
		 <g
			id="g4744"
			transform="translate(199.2737,198.3313)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4746" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4748">
	   <g
		  id="g4750">
		 <g
			id="g4756">
		   <g
			  id="g4758">
			 <path
				d="m 196.779,200.271 -2.669,-1.516 c -0.033,-0.017 -0.064,-0.039 -0.091,-0.067 v 0 c -0.001,0 -0.001,0 -0.001,0 v 0 c -0.012,-0.013 -0.024,-0.027 -0.034,-0.042 v 0 c -0.002,-0.002 -0.003,-0.003 -0.004,-0.005 v 0 c -0.009,-0.014 -0.018,-0.027 -0.026,-0.043 v 0 c -0.002,-0.003 -0.004,-0.008 -0.007,-0.011 v 0 c -0.006,-0.014 -0.011,-0.028 -0.017,-0.042 v 0 c -0.003,-0.007 -0.005,-0.013 -0.008,-0.02 v 0 c -0.003,-0.012 -0.007,-0.026 -0.01,-0.039 v 0 c -0.002,-0.01 -0.005,-0.019 -0.008,-0.03 v 0 c -0.002,-0.011 -0.004,-0.022 -0.005,-0.034 v 0 c -0.002,-0.013 -0.005,-0.026 -0.006,-0.04 v 0 c -0.001,-0.008 -0.001,-0.018 -0.001,-0.027 v 0 c -0.001,-0.018 -0.002,-0.035 -0.002,-0.052 v 0 c 0,-0.11 0.019,-0.225 0.054,-0.34 v 0 c 0.105,-0.346 0.35,-0.687 0.636,-0.851 v 0 c 0.096,-0.056 0.188,-0.087 0.271,-0.094 v 0 h 0.001 c 0.02,-0.002 0.04,-0.002 0.06,-0.001 v 0 c 0.002,0 0.003,0 0.005,0.001 v 0 c 0.018,0.001 0.035,0.003 0.052,0.006 v 0 c 0.003,0.001 0.007,0.002 0.011,0.003 v 0 c 0.014,0.004 0.028,0.007 0.042,0.013 v 0 c 0.005,0.002 0.011,0.005 0.016,0.007 v 0 c 0.011,0.005 0.023,0.01 0.032,0.015 v 0 c 0.001,10e-4 0.001,10e-4 0.002,10e-4 v 0 l 2.68,1.523 c 0.11,0.062 0.184,0.191 0.2,0.372 v 0 c 0.002,0.023 0.003,0.048 0.003,0.073 v 0 c 0,0.135 -0.03,0.28 -0.083,0.422 v 0 c -0.053,0.145 -0.129,0.287 -0.222,0.412 v 0 c -0.111,0.148 -0.242,0.274 -0.385,0.357 v 0 c -0.096,0.055 -0.186,0.085 -0.268,0.092 v 0 c -0.013,0.001 -0.026,0.002 -0.039,0.002 v 0 c -0.067,0 -0.127,-0.016 -0.179,-0.045"
				style="fill:url(#linearGradient4774);stroke:none"
				id="path4776" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4778">
	   <g
		  id="g4780"
		  clip-path="url(#clipPath4784)">
		 <g
			id="g4786"
			transform="translate(195.2811,197.5088)">
		   <path
			  d="m 0,0 c 0,0.441 -0.311,0.978 -0.696,1.2 -0.384,0.222 -0.695,0.044 -0.695,-0.397 0,-0.44 0.311,-0.978 0.695,-1.2 C -0.311,-0.618 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4788" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4790">
	   <g
		  id="g4792">
		 <g
			id="g4798">
		   <g
			  id="g4800">
			 <path
				d="m 192.758,199.582 -1.054,-0.624 c -0.085,-0.042 -0.162,-0.098 -0.23,-0.167 v 0 c -0.002,-0.002 -0.003,-0.003 -0.004,-0.004 v 0 c -0.031,-0.032 -0.059,-0.066 -0.086,-0.103 v 0 c -0.004,-0.005 -0.007,-0.01 -0.011,-0.014 v 0 c -0.023,-0.034 -0.044,-0.07 -0.064,-0.108 v 0 c -0.005,-0.009 -0.011,-0.019 -0.017,-0.03 v 0 c -0.016,-0.033 -0.029,-0.068 -0.043,-0.105 v 0 c -0.007,-0.017 -0.014,-0.033 -0.02,-0.051 v 0 c -0.011,-0.03 -0.019,-0.065 -0.027,-0.098 v 0 c -0.006,-0.024 -0.013,-0.049 -0.019,-0.074 v 0 c -0.006,-0.028 -0.009,-0.058 -0.013,-0.087 v 0 c -0.006,-0.033 -0.012,-0.066 -0.014,-0.102 v 0 c -0.003,-0.022 -0.002,-0.047 -0.004,-0.07 v 0 c -0.003,-0.042 -0.006,-0.084 -0.006,-0.13 v 0 c 0,-0.277 0.049,-0.568 0.137,-0.859 v 0 c 0.265,-0.872 0.886,-1.735 1.609,-2.152 v 0 c 0.243,-0.14 0.474,-0.217 0.684,-0.236 v 0 h 0.001 c 0.053,-0.004 0.103,-0.005 0.152,-0.002 v 0 c 0.005,0 0.009,0.001 0.013,0.001 v 0 c 0.045,0.003 0.089,0.009 0.132,0.017 v 0 c 0.008,0.001 0.017,0.005 0.026,0.008 v 0 c 0.036,0.008 0.073,0.018 0.108,0.03 v 0 c 0.014,0.006 0.027,0.014 0.041,0.02 v 0 c 0.028,0.012 0.056,0.023 0.081,0.039 v 0 c 0.002,10e-4 0.004,10e-4 0.004,0.002 v 0 l 1.083,0.64 c 0.28,0.158 0.467,0.482 0.507,0.939 v 0 c 0.006,0.06 0.008,0.122 0.008,0.186 v 0 c 0,0.343 -0.076,0.709 -0.21,1.067 v 0 c -0.134,0.365 -0.327,0.724 -0.562,1.041 v 0 c -0.278,0.375 -0.612,0.692 -0.973,0.902 v 0 c -0.24,0.139 -0.469,0.214 -0.676,0.233 v 0 c -0.033,0.003 -0.066,0.004 -0.099,0.004 v 0 c -0.169,0 -0.322,-0.039 -0.454,-0.113"
				style="fill:url(#linearGradient4816);stroke:none"
				id="path4818" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4820">
	   <g
		  id="g4822"
		  clip-path="url(#clipPath4826)">
		 <g
			id="g4828"
			transform="translate(194.6629,195.8085)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4830" />
		 </g>
		 <g
			id="g4832"
			transform="translate(200.4636,202.5293)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4834" />
		 </g>
		 <g
			id="g4836"
			transform="translate(200.461,202.9652)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4838" />
		 </g>
		 <g
			id="g4840"
			transform="translate(198.7869,201.999)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4842" />
		 </g>
		 <g
			id="g4844"
			transform="translate(210.3002,192.564)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4846" />
		 </g>
		 <g
			id="g4848"
			transform="translate(210.3002,193.5667)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4850" />
		 </g>
		 <g
			id="g4852"
			transform="translate(202.0256,188.7366)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4854" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4856">
	   <g
		  id="g4858">
		 <g
			id="g4864">
		   <g
			  id="g4866">
			 <path
				d="m 206.649,198.306 -2.684,-1.572 c -0.085,-0.042 -0.162,-0.098 -0.23,-0.167 v 0 c -0.002,-0.002 -0.003,-0.003 -0.005,-0.004 v 0 c -0.029,-0.032 -0.058,-0.066 -0.084,-0.103 v 0 c -0.004,-0.004 -0.008,-0.009 -0.012,-0.014 v 0 c -0.023,-0.034 -0.043,-0.07 -0.063,-0.107 v 0 c -0.006,-0.01 -0.012,-0.02 -0.017,-0.031 v 0 c -0.016,-0.032 -0.031,-0.068 -0.044,-0.104 v 0 c -0.007,-0.018 -0.014,-0.034 -0.02,-0.051 v 0 c -0.01,-0.031 -0.018,-0.066 -0.027,-0.098 v 0 c -0.006,-0.025 -0.014,-0.048 -0.019,-0.075 v 0 c -0.005,-0.028 -0.009,-0.057 -0.013,-0.087 v 0 c -0.005,-0.033 -0.011,-0.066 -0.015,-0.101 v 0 c -0.002,-0.022 -0.002,-0.047 -0.003,-0.07 v 0 c -0.002,-0.043 -0.006,-0.085 -0.006,-0.13 v 0 c 0,-0.277 0.049,-0.569 0.137,-0.86 v 0 c 0.265,-0.872 0.886,-1.734 1.609,-2.152 v 0 c 0.243,-0.14 0.475,-0.217 0.685,-0.235 v 0 c 0.052,-0.004 0.104,-0.005 0.153,-0.003 v 0 c 0.004,10e-4 0.008,10e-4 0.012,10e-4 v 0 c 0.045,0.003 0.089,0.009 0.131,0.018 v 0 c 0.009,0.001 0.018,0.004 0.027,0.007 v 0 c 0.036,0.008 0.073,0.018 0.107,0.03 v 0 c 0.015,0.007 0.028,0.014 0.042,0.02 v 0 c 0.028,0.013 0.055,0.024 0.082,0.039 v 0 c 0.001,0.001 0.002,0.001 0.003,0.002 v 0 l 2.713,1.588 c 0.28,0.159 0.467,0.482 0.507,0.94 v 0 c 0.006,0.059 0.008,0.122 0.008,0.185 v 0 c 0.001,0.343 -0.076,0.709 -0.21,1.067 v 0 c -0.134,0.365 -0.328,0.725 -0.563,1.042 v 0 c -0.278,0.374 -0.611,0.692 -0.972,0.901 v 0 c -0.24,0.138 -0.468,0.215 -0.676,0.234 v 0 c -0.034,0.003 -0.067,0.004 -0.099,0.004 v 0 c -0.169,0 -0.322,-0.039 -0.454,-0.114"
				style="fill:url(#linearGradient4882);stroke:none"
				id="path4884" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4886">
	   <g
		  id="g4888"
		  clip-path="url(#clipPath4892)">
		 <g
			id="g4894"
			transform="translate(206.9239,193.5848)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.114 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4896" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4898">
	   <g
		  id="g4900">
		 <g
			id="g4906">
		   <g
			  id="g4908">
			 <path
				d="m 204.429,195.524 -2.668,-1.516 c -0.034,-0.016 -0.065,-0.038 -0.092,-0.065 v 0 c 0,-0.001 -0.001,-0.002 -0.002,-0.002 v 0 c -0.011,-0.013 -0.022,-0.026 -0.033,-0.041 v 0 c -0.002,-0.002 -0.002,-0.004 -0.004,-0.006 v 0 c -0.009,-0.013 -0.017,-0.027 -0.026,-0.042 v 0 c -0.001,-0.004 -0.004,-0.008 -0.006,-0.012 v 0 c -0.006,-0.013 -0.012,-0.027 -0.018,-0.041 v 0 c -0.002,-0.007 -0.005,-0.014 -0.007,-0.021 v 0 c -0.005,-0.012 -0.007,-0.025 -0.011,-0.039 v 0 c -0.003,-0.01 -0.005,-0.019 -0.008,-0.028 v 0 c -0.002,-0.012 -0.003,-0.024 -0.004,-0.036 v 0 c -0.003,-0.012 -0.005,-0.026 -0.007,-0.039 v 0 c -0.001,-0.009 -0.001,-0.019 -0.001,-0.028 v 0 c 0,-0.017 -0.002,-0.033 -0.002,-0.051 v 0 c 0,-0.11 0.019,-0.225 0.055,-0.341 v 0 c 0.104,-0.345 0.35,-0.686 0.636,-0.851 v 0 c 0.096,-0.056 0.187,-0.086 0.271,-0.094 v 0 c 0.02,-10e-4 0.04,-10e-4 0.06,0 v 0 c 0.002,0 0.004,0 0.005,0 v 0 c 0.018,0.001 0.036,0.003 0.052,0.007 v 0 c 0.003,0.001 0.007,0.002 0.011,0.003 v 0 c 0.014,0.003 0.028,0.007 0.042,0.012 v 0 c 0.006,0.002 0.011,0.005 0.016,0.008 v 0 c 0.012,0.005 0.023,0.009 0.033,0.015 v 0 c 0,0 0,0 0.001,0 v 0 l 2.68,1.523 c 0.11,0.063 0.184,0.191 0.2,0.372 v 0 c 0.003,0.023 0.003,0.049 0.003,0.074 v 0 c 0.001,0.135 -0.03,0.28 -0.083,0.422 v 0 c -0.053,0.144 -0.129,0.286 -0.222,0.412 v 0 c -0.11,0.148 -0.242,0.274 -0.385,0.356 v 0 c -0.095,0.055 -0.186,0.085 -0.268,0.092 v 0 c -0.013,0.001 -0.026,0.002 -0.039,0.002 v 0 c -0.067,0 -0.127,-0.015 -0.179,-0.045"
				style="fill:url(#linearGradient4924);stroke:none"
				id="path4926" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4928">
	   <g
		  id="g4930"
		  clip-path="url(#clipPath4934)">
		 <g
			id="g4936"
			transform="translate(202.9314,192.7624)">
		   <path
			  d="m 0,0 c 0,0.441 -0.312,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.397 0,-0.441 0.312,-0.978 0.696,-1.2 C -0.312,-0.619 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4938" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g4940">
	   <g
		  id="g4942">
		 <g
			id="g4948">
		   <g
			  id="g4950">
			 <path
				d="m 200.408,194.836 -1.054,-0.625 c -0.085,-0.041 -0.162,-0.097 -0.23,-0.167 v 0 c -0.002,-0.001 -0.003,-0.002 -0.004,-0.004 v 0 c -0.031,-0.032 -0.059,-0.066 -0.085,-0.103 v 0 c -0.004,-0.004 -0.008,-0.01 -0.012,-0.014 v 0 c -0.022,-0.033 -0.044,-0.069 -0.064,-0.107 v 0 c -0.005,-0.01 -0.011,-0.02 -0.016,-0.031 v 0 c -0.016,-0.033 -0.03,-0.068 -0.044,-0.104 v 0 c -0.006,-0.017 -0.013,-0.033 -0.02,-0.051 v 0 c -0.011,-0.031 -0.018,-0.065 -0.027,-0.099 v 0 c -0.006,-0.024 -0.013,-0.047 -0.019,-0.073 v 0 c -0.005,-0.028 -0.009,-0.059 -0.013,-0.088 v 0 c -0.005,-0.033 -0.011,-0.065 -0.015,-0.1 v 0 c -0.001,-0.023 -0.001,-0.048 -0.003,-0.071 v 0 c -0.002,-0.043 -0.006,-0.085 -0.006,-0.13 v 0 c 0,-0.277 0.049,-0.569 0.137,-0.86 v 0 c 0.266,-0.872 0.886,-1.734 1.609,-2.152 v 0 c 0.243,-0.14 0.475,-0.216 0.685,-0.234 v 0 0 c 0.053,-0.005 0.104,-0.007 0.153,-0.004 v 0 c 0.004,0.001 0.008,0.002 0.012,0.002 v 0 c 0.045,0.003 0.089,0.008 0.131,0.017 v 0 c 0.01,0.002 0.019,0.004 0.027,0.007 v 0 c 0.037,0.008 0.073,0.018 0.108,0.031 v 0 c 0.014,0.005 0.028,0.013 0.041,0.019 v 0 c 0.028,0.012 0.056,0.024 0.082,0.039 v 0 c 0.001,0.001 0.003,0.001 0.004,0.002 v 0 l 1.082,0.641 c 0.28,0.157 0.467,0.481 0.507,0.939 v 0 c 0.006,0.06 0.008,0.122 0.008,0.186 v 0 c 0.001,0.343 -0.076,0.708 -0.21,1.066 v 0 c -0.134,0.366 -0.327,0.725 -0.562,1.042 v 0 c -0.278,0.375 -0.611,0.692 -0.973,0.901 v 0 c -0.24,0.139 -0.468,0.215 -0.676,0.234 v 0 c -0.033,0.002 -0.066,0.004 -0.099,0.004 v 0 c -0.169,0 -0.322,-0.039 -0.454,-0.113"
				style="fill:url(#linearGradient4966);stroke:none"
				id="path4968" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g4970">
	   <g
		  id="g4972"
		  clip-path="url(#clipPath4976)">
		 <g
			id="g4978"
			transform="translate(202.3131,191.062)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4980" />
		 </g>
		 <g
			id="g4982"
			transform="translate(208.1138,197.7829)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4984" />
		 </g>
		 <g
			id="g4986"
			transform="translate(208.1113,198.2188)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4988" />
		 </g>
		 <g
			id="g4990"
			transform="translate(206.4372,197.2526)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.002,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4992" />
		 </g>
		 <g
			id="g4994"
			transform="translate(217.9504,187.8175)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.274,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path4996" />
		 </g>
		 <g
			id="g4998"
			transform="translate(217.9504,188.8202)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.4,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5000" />
		 </g>
		 <g
			id="g5002"
			transform="translate(209.6759,183.9902)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5004" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g5006">
	   <g
		  id="g5008">
		 <g
			id="g5014">
		   <g
			  id="g5016">
			 <path
				d="m 214.299,193.559 -2.684,-1.571 c -0.084,-0.042 -0.162,-0.098 -0.23,-0.167 v 0 c -0.001,-0.001 -0.003,-0.003 -0.004,-0.004 v 0 c -0.03,-0.032 -0.059,-0.067 -0.085,-0.103 v 0 c -0.004,-0.005 -0.008,-0.01 -0.011,-0.015 v 0 c -0.023,-0.033 -0.044,-0.069 -0.064,-0.107 v 0 c -0.005,-0.01 -0.012,-0.02 -0.017,-0.031 v 0 c -0.016,-0.032 -0.03,-0.068 -0.044,-0.104 v 0 c -0.006,-0.017 -0.014,-0.033 -0.02,-0.05 v 0 c -0.01,-0.032 -0.018,-0.065 -0.026,-0.098 v 0 c -0.006,-0.026 -0.014,-0.049 -0.02,-0.075 v 0 c -0.005,-0.028 -0.008,-0.058 -0.013,-0.088 v 0 c -0.005,-0.033 -0.011,-0.065 -0.015,-0.1 v 0 c -0.002,-0.023 -0.002,-0.048 -0.003,-0.071 v 0 c -0.002,-0.043 -0.006,-0.085 -0.006,-0.13 v 0 c 0,-0.276 0.049,-0.569 0.138,-0.859 v 0 c 0.265,-0.873 0.885,-1.735 1.608,-2.152 v 0 c 0.243,-0.141 0.474,-0.217 0.685,-0.235 v 0 c 0.052,-0.005 0.104,-0.006 0.153,-0.003 v 0 c 0.005,0 0.008,0.001 0.013,0.001 v 0 c 0.045,0.003 0.089,0.008 0.131,0.017 v 0 c 0.009,0.002 0.017,0.005 0.026,0.007 v 0 c 0.037,0.009 0.073,0.018 0.108,0.032 v 0 c 0.015,0.005 0.027,0.012 0.042,0.019 v 0 c 0.027,0.012 0.055,0.024 0.081,0.038 v 0 c 0.001,0.001 0.002,0.002 0.004,0.002 v 0 l 2.712,1.589 c 0.281,0.158 0.467,0.481 0.506,0.939 v 0 c 0.007,0.06 0.009,0.123 0.009,0.186 v 0 c 0,0.342 -0.076,0.709 -0.209,1.066 v 0 c -0.134,0.365 -0.329,0.725 -0.564,1.042 v 0 c -0.278,0.375 -0.611,0.692 -0.973,0.901 v 0 c -0.239,0.139 -0.468,0.215 -0.675,0.234 v 0 c -0.033,0.003 -0.066,0.005 -0.098,0.005 v 0 c -0.169,0 -0.323,-0.04 -0.455,-0.115"
				style="fill:url(#linearGradient5032);stroke:none"
				id="path5034" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5036">
	   <g
		  id="g5038"
		  clip-path="url(#clipPath5042)">
		 <g
			id="g5044"
			transform="translate(214.5742,188.8383)">
		   <path
			  d="m 0,0 c 0,1.115 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.114 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5046" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g5048">
	   <g
		  id="g5050">
		 <g
			id="g5056">
		   <g
			  id="g5058">
			 <path
				d="m 212.079,190.778 -2.668,-1.516 c -0.034,-0.016 -0.065,-0.039 -0.091,-0.066 v 0 c -0.001,-0.001 -0.002,-0.001 -0.002,-0.002 v 0 c -0.012,-0.012 -0.023,-0.026 -0.034,-0.04 v 0 c -0.001,-0.002 -0.003,-0.004 -0.004,-0.006 v 0 c -0.009,-0.013 -0.017,-0.027 -0.025,-0.043 v 0 c -0.002,-0.003 -0.005,-0.007 -0.007,-0.011 v 0 c -0.006,-0.014 -0.012,-0.027 -0.017,-0.042 v 0 c -0.003,-0.007 -0.005,-0.013 -0.008,-0.02 v 0 c -0.005,-0.012 -0.007,-0.026 -0.011,-0.039 v 0 c -0.002,-0.01 -0.005,-0.019 -0.007,-0.029 v 0 c -0.003,-0.012 -0.004,-0.023 -0.005,-0.035 v 0 c -0.002,-0.013 -0.005,-0.026 -0.006,-0.04 v 0 c -10e-4,-0.009 -10e-4,-0.019 -0.002,-0.028 v 0 c -0.001,-0.017 -0.002,-0.033 -0.002,-0.051 v 0 c 0,-0.11 0.02,-0.225 0.055,-0.34 v 0 c 0.104,-0.345 0.35,-0.687 0.636,-0.852 v 0 c 0.096,-0.055 0.188,-0.085 0.271,-0.093 v 0 c 0.021,-0.001 0.041,-0.002 0.06,-0.001 v 0 c 0.002,0 0.004,0.001 0.005,0.001 v 0 c 0.019,0.001 0.036,0.003 0.053,0.006 v 0 c 0.003,0.001 0.006,0.003 0.01,0.003 v 0 c 0.014,0.003 0.028,0.007 0.042,0.012 v 0 c 0.006,0.003 0.012,0.006 0.017,0.009 v 0 c 0.011,0.004 0.022,0.009 0.032,0.015 v 0 c 0,0 0.001,0 0.001,0.001 v 0 l 2.68,1.522 c 0.111,0.063 0.185,0.19 0.2,0.371 v 0 c 0.003,0.024 0.004,0.049 0.004,0.074 v 0 c 0,0.136 -0.031,0.28 -0.083,0.422 v 0 c -0.053,0.144 -0.13,0.286 -0.223,0.412 v 0 c -0.11,0.148 -0.242,0.274 -0.385,0.357 v 0 c -0.095,0.055 -0.185,0.084 -0.268,0.093 v 0 c -0.012,0.001 -0.025,0.001 -0.038,0.001 v 0 c -0.067,0 -0.128,-0.016 -0.18,-0.045"
				style="fill:url(#linearGradient5074);stroke:none"
				id="path5076" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5078">
	   <g
		  id="g5080"
		  clip-path="url(#clipPath5084)">
		 <g
			id="g5086"
			transform="translate(210.5816,188.0159)">
		   <path
			  d="m 0,0 c 0,0.441 -0.311,0.978 -0.696,1.2 -0.384,0.222 -0.695,0.044 -0.695,-0.397 0,-0.44 0.311,-0.978 0.695,-1.2 C -0.311,-0.619 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5088" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g5090">
	   <g
		  id="g5092">
		 <g
			id="g5098">
		   <g
			  id="g5100">
			 <path
				d="m 208.058,190.089 -1.053,-0.625 c -0.085,-0.041 -0.163,-0.097 -0.231,-0.166 v 0 c -0.001,-0.002 -0.003,-0.003 -0.004,-0.004 v 0 c -0.03,-0.031 -0.059,-0.066 -0.085,-0.103 v 0 c -0.004,-0.005 -0.008,-0.009 -0.012,-0.015 v 0 c -0.022,-0.033 -0.043,-0.069 -0.063,-0.107 v 0 c -0.005,-0.01 -0.011,-0.02 -0.017,-0.03 v 0 c -0.016,-0.033 -0.03,-0.069 -0.044,-0.105 v 0 c -0.006,-0.017 -0.013,-0.033 -0.02,-0.05 v 0 c -0.01,-0.032 -0.018,-0.066 -0.026,-0.098 v 0 c -0.007,-0.025 -0.014,-0.049 -0.02,-0.075 v 0 c -0.005,-0.028 -0.009,-0.057 -0.012,-0.087 v 0 c -0.006,-0.034 -0.012,-0.066 -0.016,-0.101 v 0 c -0.001,-0.022 -0.001,-0.048 -0.002,-0.07 v 0 c -0.003,-0.043 -0.006,-0.086 -0.006,-0.131 v 0 c 0,-0.276 0.048,-0.569 0.136,-0.859 v 0 c 0.266,-0.872 0.886,-1.734 1.61,-2.152 v 0 c 0.243,-0.14 0.474,-0.217 0.684,-0.235 v 0 c 0.053,-0.004 0.104,-0.005 0.153,-0.004 v 0 c 0.004,0.001 0.008,0.002 0.012,0.002 v 0 c 0.045,0.003 0.089,0.008 0.132,0.017 v 0 c 0.009,0.002 0.017,0.005 0.027,0.007 v 0 c 0.036,0.009 0.072,0.019 0.107,0.032 v 0 c 0.014,0.005 0.027,0.013 0.042,0.019 v 0 c 0.027,0.012 0.055,0.023 0.081,0.039 v 0 c 10e-4,0 0.003,0.001 0.003,0.001 v 0 l 1.083,0.641 c 0.281,0.159 0.467,0.482 0.507,0.94 v 0 c 0.006,0.059 0.009,0.122 0.009,0.185 v 0 c 0,0.343 -0.077,0.708 -0.21,1.067 v 0 c -0.134,0.365 -0.328,0.725 -0.563,1.041 v 0 c -0.278,0.375 -0.611,0.693 -0.973,0.902 v 0 c -0.239,0.138 -0.468,0.214 -0.675,0.233 v 0 c -0.034,0.003 -0.067,0.004 -0.099,0.004 v 0 c -0.169,0 -0.322,-0.039 -0.455,-0.113"
				style="fill:url(#linearGradient5116);stroke:none"
				id="path5118" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5120">
	   <g
		  id="g5122"
		  clip-path="url(#clipPath5126)">
		 <g
			id="g5128"
			transform="translate(209.9634,186.3155)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.114 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5130" />
		 </g>
		 <g
			id="g5132"
			transform="translate(215.7641,193.0364)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5134" />
		 </g>
		 <g
			id="g5136"
			transform="translate(215.7615,193.4723)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5138" />
		 </g>
		 <g
			id="g5140"
			transform="translate(214.0874,192.5061)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5142" />
		 </g>
		 <g
			id="g5144"
			transform="translate(327.9344,199.0639)">
		   <path
			  d="M 0,0 29.858,-17.256 -35.663,-55.061"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  data-duration="4000"
			  data-delay="1000"

			  id="path5146" />

				<circle r="2" cx="0" cy="0" class="path5146-circle circle"/>
				<circle r="2" cx="0" cy="0" class="path5146-circle circle"/>
				<circle r="2" cx="0" cy="0" class="path5146-circle circle"/>
				<circle r="2" cx="0" cy="0" class="path5146-circle circle"/>


		 </g>
		 <g
			id="g5148"
			transform="translate(226.9467,106.4795)">
		   <path
			  d="M 0,0 47.614,27.372"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  data-duration="2000"
			  data-delay="800"
			  id="path5150" />

				<circle r="2" cx="0" cy="0" class="path5150-circle circle reverse"/>
				<circle r="2" cx="0" cy="0" class="path5150-circle circle reverse"/>
				<circle r="2" cx="0" cy="0" class="path5150-circle circle reverse"/>
				<circle r="2" cx="0" cy="0" class="path5150-circle circle reverse"/>
		 </g>
		 <g
			id="g5152"
			transform="translate(217.9866,80.75)"
			style="display:inline">
		   <path
			  d="M 9.4104109,25.473361 -44.493,56.591 -70.253,41.73"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path5154"
			  sodipodi:nodetypes="ccc"
			  inkscape:label="path5154" />
		   
		   <path
			  d="M -0.56250025,0.37500047 26.2195,15.82 8.8479107,25.848361"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  id="path7"
			  sodipodi:nodetypes="ccc"
			  inkscape:label="path5154-1" />
		 </g>
		 <g
			id="g5156"
			transform="translate(197.0934,123.5342)">
		   <path
			  d="M 0,0 -26.694,-15.253"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path5158" />
		 </g>
		 <g
			id="g5160"
			transform="translate(221.0039,109.9141)">
		   <path
			  d="M 0,0 -26.777,-15.459"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path5162" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g5164">
	   <g
		  id="g5166">
		 <g
			id="g5172">
		   <g
			  id="g5174">
			 <path
				d="m 153.262,131.917 h -0.509 v -2.883 c 0,-2.824 3.966,-5.113 8.857,-5.113 v 0 c 4.892,0 8.857,2.289 8.857,5.113 v 0 1.172 1.711 h -0.508 c -1.22,1.982 -4.497,3.402 -8.349,3.402 v 0 c -3.852,0 -7.13,-1.42 -8.348,-3.402"
				style="fill:url(#linearGradient5190);stroke:none"
				id="path5192" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5194">
	   <g
		  id="g5196">
		 <g
			id="g5202">
		   <g
			  id="g5204">
			 <path
				d="m 154.229,131.936 c 0,-2.353 3.305,-4.26 7.381,-4.26 v 0 c 4.076,0 7.381,1.907 7.381,4.26 v 0 c 0,2.354 -3.305,4.262 -7.381,4.262 v 0 c -4.076,0 -7.381,-1.908 -7.381,-4.262"
				style="fill:url(#linearGradient5216);stroke:none"
				id="path5218" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5220">
	   <g
		  id="g5222">
		 <g
			id="g5228">
		   <g
			  id="g5230">
			 <path
				d="m 154.229,131.142 c 0,-2.354 3.305,-4.261 7.381,-4.261 v 0 c 4.076,0 7.381,1.907 7.381,4.261 v 0 c 0,2.353 -3.305,4.261 -7.381,4.261 v 0 c -4.076,0 -7.381,-1.908 -7.381,-4.261"
				style="fill:url(#linearGradient5238);stroke:none"
				id="path5240" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5242">
	   <g
		  id="g5244">
		 <g
			id="g5250">
		   <g
			  id="g5252">
			 <path
				d="m 152.753,131.936 c 0,-2.823 3.966,-5.112 8.857,-5.112 v 0 c 4.892,0 8.857,2.289 8.857,5.112 v 0 c 0,2.825 -3.965,5.114 -8.857,5.114 v 0 c -4.891,0 -8.857,-2.289 -8.857,-5.114 m 1.476,0 c 0,2.354 3.305,4.262 7.381,4.262 v 0 c 4.076,0 7.381,-1.908 7.381,-4.262 v 0 c 0,-2.353 -3.305,-4.26 -7.381,-4.26 v 0 c -4.076,0 -7.381,1.907 -7.381,4.26"
				style="fill:url(#linearGradient5264);stroke:none"
				id="path5266" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5268">
	   <g
		  id="g5270">
		 <g
			id="g5276">
		   <g
			  id="g5278">
			 <path
				d="m 177.022,118.199 h -0.509 v -2.883 c 0,-2.823 3.966,-5.113 8.857,-5.113 v 0 c 4.892,0 8.857,2.29 8.857,5.113 v 0 1.172 1.711 h -0.508 c -1.22,1.983 -4.497,3.403 -8.349,3.403 v 0 c -3.852,0 -7.13,-1.42 -8.348,-3.403"
				style="fill:url(#linearGradient5294);stroke:none"
				id="path5296" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5298">
	   <g
		  id="g5300">
		 <g
			id="g5306">
		   <g
			  id="g5308">
			 <path
				d="m 177.989,118.219 c 0,-2.353 3.305,-4.261 7.381,-4.261 v 0 c 4.076,0 7.38,1.908 7.38,4.261 v 0 c 0,2.353 -3.304,4.261 -7.38,4.261 v 0 c -4.076,0 -7.381,-1.908 -7.381,-4.261"
				style="fill:url(#linearGradient5320);stroke:none"
				id="path5322" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5324">
	   <g
		  id="g5326">
		 <g
			id="g5332">
		   <g
			  id="g5334">
			 <path
				d="m 177.989,117.425 c 0,-2.354 3.305,-4.262 7.381,-4.262 v 0 c 4.076,0 7.38,1.908 7.38,4.262 v 0 c 0,2.352 -3.304,4.26 -7.38,4.26 v 0 c -4.076,0 -7.381,-1.908 -7.381,-4.26"
				style="fill:url(#linearGradient5342);stroke:none"
				id="path5344" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5346">
	   <g
		  id="g5348">
		 <g
			id="g5354">
		   <g
			  id="g5356">
			 <path
				d="m 176.513,118.219 c 0,-2.824 3.966,-5.113 8.857,-5.113 v 0 c 4.892,0 8.857,2.289 8.857,5.113 v 0 c 0,2.824 -3.965,5.113 -8.857,5.113 v 0 c -4.891,0 -8.857,-2.289 -8.857,-5.113 m 1.476,0 c 0,2.353 3.305,4.261 7.381,4.261 v 0 c 4.076,0 7.38,-1.908 7.38,-4.261 v 0 c 0,-2.353 -3.304,-4.261 -7.38,-4.261 v 0 c -4.076,0 -7.381,1.908 -7.381,4.261"
				style="fill:url(#linearGradient5368);stroke:none"
				id="path5370" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5372">
	   <g
		  id="g5374">
		 <g
			id="g5380">
		   <g
			  id="g5382">
			 <path
				d="m 200.782,104.481 h -0.509 v -2.882 c 0,-2.824 3.966,-5.114 8.857,-5.114 v 0 c 4.891,0 8.856,2.29 8.856,5.114 v 0 1.171 1.711 h -0.508 c -1.219,1.983 -4.496,3.403 -8.348,3.403 v 0 c -3.853,0 -7.129,-1.42 -8.348,-3.403"
				style="fill:url(#linearGradient5398);stroke:none"
				id="path5400" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5402">
	   <g
		  id="g5404">
		 <g
			id="g5410">
		   <g
			  id="g5412">
			 <path
				d="m 201.749,104.502 c 0,-2.354 3.305,-4.262 7.381,-4.262 v 0 c 4.075,0 7.38,1.908 7.38,4.262 v 0 c 0,2.353 -3.305,4.261 -7.38,4.261 v 0 c -4.076,0 -7.381,-1.908 -7.381,-4.261"
				style="fill:url(#linearGradient5424);stroke:none"
				id="path5426" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5428">
	   <g
		  id="g5430">
		 <g
			id="g5436">
		   <g
			  id="g5438">
			 <path
				d="m 201.749,103.707 c 0,-2.353 3.305,-4.262 7.381,-4.262 v 0 c 4.075,0 7.38,1.909 7.38,4.262 v 0 c 0,2.354 -3.305,4.262 -7.38,4.262 v 0 c -4.076,0 -7.381,-1.908 -7.381,-4.262"
				style="fill:url(#linearGradient5446);stroke:none"
				id="path5448" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5450">
	   <g
		  id="g5452">
		 <g
			id="g5458">
		   <g
			  id="g5460">
			 <path
				d="m 200.273,104.502 c 0,-2.824 3.966,-5.114 8.857,-5.114 v 0 c 4.891,0 8.856,2.29 8.856,5.114 v 0 c 0,2.824 -3.965,5.113 -8.856,5.113 v 0 c -4.891,0 -8.857,-2.289 -8.857,-5.113 m 1.476,0 c 0,2.353 3.305,4.261 7.381,4.261 v 0 c 4.075,0 7.38,-1.908 7.38,-4.261 v 0 c 0,-2.354 -3.305,-4.262 -7.38,-4.262 v 0 c -4.076,0 -7.381,1.908 -7.381,4.262"
				style="fill:url(#linearGradient5472);stroke:none"
				id="path5474" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5476">
	   <g
		  id="g5478">
		 <g
			id="g5484">
		   <g
			  id="g5486">
			 <path
				d="m 224.541,90.763 h -0.508 v -2.882 c 0,-2.824 3.965,-5.113 8.856,-5.113 v 0 c 4.892,0 8.857,2.289 8.857,5.113 v 0 1.172 1.71 h -0.508 c -1.219,1.983 -4.497,3.403 -8.349,3.403 v 0 c -3.852,0 -7.129,-1.42 -8.348,-3.403"
				style="fill:url(#linearGradient5502);stroke:none"
				id="path5504" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5506">
	   <g
		  id="g5508">
		 <g
			id="g5514">
		   <g
			  id="g5516">
			 <path
				d="m 225.508,90.784 c 0,-2.354 3.305,-4.262 7.381,-4.262 v 0 c 4.076,0 7.381,1.908 7.381,4.262 v 0 c 0,2.353 -3.305,4.261 -7.381,4.261 v 0 c -4.076,0 -7.381,-1.908 -7.381,-4.261"
				style="fill:url(#linearGradient5528);stroke:none"
				id="path5530" />
				id="path5530" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5532">
	   <g
		  id="g5534">
		 <g
			id="g5540">
		   <g
			  id="g5542">
			 <path
				d="m 225.508,89.989 c 0,-2.353 3.305,-4.261 7.381,-4.261 v 0 c 4.076,0 7.381,1.908 7.381,4.261 v 0 c 0,2.354 -3.305,4.262 -7.381,4.262 v 0 c -4.076,0 -7.381,-1.908 -7.381,-4.262"
				style="fill:url(#linearGradient5550);stroke:none"
				id="path5552" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5554">
	   <g
		  id="g5556">
		 <g
			id="g5562">
		   <g
			  id="g5564">
			 <path
				d="m 224.033,90.784 c 0,-2.824 3.965,-5.114 8.856,-5.114 v 0 c 4.892,0 8.857,2.29 8.857,5.114 v 0 c 0,2.824 -3.965,5.114 -8.857,5.114 v 0 c -4.891,0 -8.856,-2.29 -8.856,-5.114 m 1.476,0 c 0,2.353 3.304,4.261 7.38,4.261 v 0 c 4.076,0 7.381,-1.908 7.381,-4.261 v 0 c 0,-2.354 -3.305,-4.262 -7.381,-4.262 v 0 c -4.076,0 -7.38,1.908 -7.38,4.262"
				style="fill:url(#linearGradient5576);stroke:none"
				id="path5578" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5580">
	   <g
		  id="g5582"
		  clip-path="url(#clipPath5586)">
		 <g
			id="g5588"
			transform="translate(280.5232,151.0592)">
		   <path
			  d="M 12.063488,-7.0230504 23.651,-13.769 5.87,-24.039 -5.7558108,-17.345692"
			  style="fill:none;stroke:#2f5289;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
			  id="path5590"
			  sodipodi:nodetypes="cccc"
			  inkscape:label="path5590" />
		   <path
			  d="M -5.5683111,-17.439442 -17.7315,-10.43675 0.18749988,-0.09374996 12.250988,-7.1168004"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
			  id="path8"
			  sodipodi:nodetypes="cccc"
			  inkscape:label="path5590-1" />
		 </g>
		 <g
			id="g5592"
			transform="translate(296.4624,141.7795)">
		   <path
			  d="M 0,0 -17.919,-10.261"
			  style="fill:none;stroke:#2f5289;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
			  id="path5594" />
		 </g>
		 <g
			id="g5596"
			transform="translate(288.3855,146.4818)">
		   <path
			  d="M 0,0 -17.86,-10.338"
			  style="fill:none;stroke:#2f5289;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
			  id="path5598" />
		 </g>
		 <g
			id="g5600"
			transform="translate(277.471,146.7069)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5602" />
		 </g>
		 <g
			id="g5604"
			transform="translate(277.471,147.7096)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5606" />
		 </g>
		 <g
			id="g5608"
			transform="translate(269.1965,142.8796)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5610" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g5612">
	   <g
		  id="g5614">
		 <g
			id="g5620">
		   <g
			  id="g5622">
			 <path
				d="m 273.82,152.449 -2.684,-1.572 c -0.085,-0.042 -0.162,-0.097 -0.231,-0.167 v 0 c -10e-4,-0.001 -0.002,-0.002 -0.003,-0.004 v 0 c -0.031,-0.032 -0.06,-0.066 -0.086,-0.103 v 0 c -0.003,-0.005 -0.008,-0.01 -0.011,-0.014 v 0 c -0.023,-0.033 -0.044,-0.069 -0.063,-0.107 v 0 c -0.006,-0.01 -0.012,-0.02 -0.018,-0.031 v 0 c -0.016,-0.033 -0.029,-0.068 -0.044,-0.105 v 0 c -0.006,-0.016 -0.013,-0.033 -0.019,-0.05 v 0 c -0.01,-0.031 -0.018,-0.065 -0.027,-0.099 v 0 c -0.006,-0.024 -0.014,-0.048 -0.019,-0.073 v 0 c -0.006,-0.028 -0.008,-0.059 -0.013,-0.088 v 0 c -0.005,-0.033 -0.012,-0.065 -0.015,-0.101 v 0 c -0.002,-0.023 -0.002,-0.047 -0.003,-0.071 v 0 c -0.003,-0.042 -0.006,-0.084 -0.006,-0.129 v 0 c 0,-0.277 0.048,-0.569 0.137,-0.86 v 0 c 0.265,-0.872 0.885,-1.735 1.609,-2.152 v 0 c 0.243,-0.14 0.474,-0.216 0.685,-0.235 v 0 c 0.052,-0.005 0.103,-0.006 0.153,-0.003 v 0 c 0.004,0 0.008,10e-4 0.012,0.002 v 0 c 0.045,0.002 0.089,0.008 0.132,0.016 v 0 c 0.009,0.002 0.017,0.005 0.026,0.008 v 0 c 0.037,0.008 0.073,0.018 0.108,0.031 v 0 c 0.014,0.005 0.027,0.013 0.041,0.019 v 0 c 0.027,0.012 0.055,0.024 0.081,0.039 v 0 c 0.002,0.001 0.003,0.001 0.005,0.002 v 0 l 2.711,1.588 c 0.281,0.159 0.467,0.482 0.507,0.94 v 0 c 0.006,0.059 0.009,0.121 0.009,0.185 v 0 c 0,0.343 -0.076,0.709 -0.21,1.067 v 0 c -0.134,0.365 -0.328,0.724 -0.563,1.041 v 0 c -0.278,0.375 -0.611,0.693 -0.973,0.902 v 0 c -0.239,0.139 -0.468,0.214 -0.675,0.233 v 0 c -0.033,0.003 -0.066,0.004 -0.099,0.004 v 0 c -0.169,0 -0.322,-0.039 -0.454,-0.113"
				style="fill:url(#linearGradient5638);stroke:none"
				id="path5640" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5642">
	   <g
		  id="g5644"
		  clip-path="url(#clipPath5648)">
		 <g
			id="g5650"
			transform="translate(274.0948,147.7277)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.114 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5652" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g5654">
	   <g
		  id="g5656">
		 <g
			id="g5662">
		   <g
			  id="g5664">
			 <path
				d="m 271.599,149.667 -2.667,-1.515 c -0.035,-0.017 -0.065,-0.039 -0.091,-0.067 v 0 c -0.001,0 -0.002,-0.001 -0.002,-0.002 v 0 c -0.013,-0.011 -0.023,-0.026 -0.034,-0.04 v 0 c -0.001,-0.002 -0.003,-0.004 -0.005,-0.006 v 0 c -0.009,-0.013 -0.017,-0.028 -0.025,-0.042 v 0 c -0.002,-0.004 -0.004,-0.008 -0.006,-0.013 v 0 c -0.007,-0.012 -0.012,-0.027 -0.017,-0.041 v 0 c -0.003,-0.006 -0.006,-0.013 -0.008,-0.02 v 0 c -0.005,-0.012 -0.008,-0.025 -0.011,-0.038 v 0 c -0.003,-0.01 -0.006,-0.019 -0.007,-0.03 v 0 c -0.003,-0.011 -0.004,-0.023 -0.006,-0.034 v 0 c -0.002,-0.014 -0.004,-0.026 -0.005,-0.041 v 0 c -10e-4,-0.008 -10e-4,-0.018 -0.002,-0.027 v 0 c -0.001,-0.017 -0.003,-0.034 -0.003,-0.052 v 0 c 0,-0.109 0.02,-0.225 0.055,-0.339 v 0 c 0.105,-0.346 0.35,-0.687 0.636,-0.853 v 0 c 0.097,-0.055 0.189,-0.085 0.271,-0.092 v 0 c 0.021,-0.002 0.042,-0.002 0.061,-0.001 v 0 c 10e-4,0 0.003,0 0.005,0 v 0 c 0.018,0.002 0.035,0.003 0.052,0.007 v 0 c 0.004,0.001 0.007,0.002 0.01,0.003 v 0 c 0.015,0.003 0.029,0.007 0.042,0.012 v 0 c 0.007,0.002 0.012,0.006 0.018,0.007 v 0 c 0.01,0.006 0.021,0.01 0.032,0.016 v 0 c 0,0.001 10e-4,0.001 10e-4,0.001 v 0 l 2.679,1.522 c 0.112,0.063 0.186,0.19 0.201,0.372 v 0 c 0.003,0.024 0.003,0.049 0.003,0.073 v 0 c 0,0.136 -0.03,0.281 -0.082,0.422 v 0 c -0.053,0.145 -0.131,0.287 -0.224,0.412 v 0 c -0.109,0.149 -0.242,0.275 -0.385,0.357 v 0 c -0.094,0.055 -0.184,0.085 -0.267,0.092 v 0 c -0.013,0.002 -0.026,0.002 -0.039,0.002 v 0 c -0.067,0 -0.128,-0.015 -0.18,-0.045"
				style="fill:url(#linearGradient5680);stroke:none"
				id="path5682" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5684">
	   <g
		  id="g5686"
		  clip-path="url(#clipPath5690)">
		 <g
			id="g5692"
			transform="translate(270.1023,146.9053)">
		   <path
			  d="m 0,0 c 0,0.441 -0.311,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.397 0,-0.44 0.312,-0.978 0.696,-1.2 C -0.311,-0.619 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5694" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g5696">
	   <g
		  id="g5698">
		 <g
			id="g5704">
		   <g
			  id="g5706">
			 <path
				d="m 267.579,148.978 -1.054,-0.624 c -0.085,-0.041 -0.162,-0.097 -0.23,-0.167 v 0 c -0.001,-0.001 -0.003,-0.002 -0.004,-0.004 v 0 c -0.031,-0.031 -0.059,-0.066 -0.085,-0.102 v 0 c -0.004,-0.006 -0.009,-0.01 -0.012,-0.016 v 0 c -0.023,-0.033 -0.043,-0.069 -0.063,-0.106 v 0 c -0.006,-0.011 -0.012,-0.02 -0.017,-0.031 v 0 c -0.016,-0.033 -0.031,-0.069 -0.044,-0.105 v 0 c -0.007,-0.017 -0.014,-0.032 -0.02,-0.05 v 0 c -0.01,-0.032 -0.018,-0.065 -0.026,-0.098 v 0 c -0.007,-0.025 -0.015,-0.049 -0.02,-0.075 v 0 c -0.005,-0.027 -0.009,-0.058 -0.013,-0.086 v 0 c -0.005,-0.034 -0.011,-0.067 -0.015,-0.102 v 0 c -0.002,-0.023 -0.002,-0.047 -0.003,-0.07 v 0 c -0.002,-0.043 -0.006,-0.085 -0.006,-0.13 v 0 c 0,-0.277 0.049,-0.569 0.138,-0.859 v 0 c 0.264,-0.873 0.885,-1.736 1.608,-2.153 v 0 c 0.243,-0.141 0.474,-0.216 0.685,-0.235 v 0 c 0.052,-0.005 0.103,-0.005 0.153,-0.003 v 0 c 0.004,0 0.008,0.001 0.012,0.002 v 0 c 0.045,0.003 0.089,0.008 0.132,0.016 v 0 c 0.009,0.002 0.017,0.006 0.026,0.007 v 0 c 0.037,0.009 0.073,0.019 0.107,0.032 v 0 c 0.015,0.005 0.028,0.013 0.042,0.02 v 0 c 0.027,0.011 0.055,0.023 0.081,0.038 v 0 c 10e-4,0.001 0.003,0.001 0.005,0.002 v 0 l 1.082,0.641 c 0.281,0.158 0.467,0.481 0.506,0.939 v 0 c 0.007,0.059 0.009,0.122 0.009,0.186 v 0 c 0,0.343 -0.076,0.708 -0.209,1.066 v 0 c -0.134,0.366 -0.329,0.725 -0.564,1.042 v 0 c -0.278,0.374 -0.611,0.692 -0.973,0.901 v 0 c -0.239,0.139 -0.468,0.215 -0.675,0.234 v 0 c -0.033,0.003 -0.066,0.004 -0.098,0.004 v 0 c -0.169,0 -0.323,-0.039 -0.455,-0.114"
				style="fill:url(#linearGradient5722);stroke:none"
				id="path5724" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5726">
	   <g
		  id="g5728"
		  clip-path="url(#clipPath5732)">
		 <g
			id="g5734"
			transform="translate(269.484,145.2049)">
		   <path
			  d="m 0,0 c 0,1.115 -0.787,2.472 -1.759,3.033 -0.971,0.561 -1.758,0.112 -1.758,-1.002 0,-1.115 0.787,-2.473 1.758,-3.034 C -0.787,-1.563 0,-1.114 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5736" />
		 </g>
		 <g
			id="g5738"
			transform="translate(275.2847,151.9258)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5740" />
		 </g>
		 <g
			id="g5742"
			transform="translate(275.2821,152.3617)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5744" />
		 </g>
		 <g
			id="g5746"
			transform="translate(273.608,151.3955)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5748" />
		 </g>
		 <g
			id="g5750"
			transform="translate(285.0661,141.891)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5752" />
		 </g>
		 <g
			id="g5754"
			transform="translate(285.0661,142.8937)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5756" />
		 </g>
		 <g
			id="g5758"
			transform="translate(276.7916,138.0636)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5760" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g5762">
	   <g
		  id="g5764">
		 <g
			id="g5770">
		   <g
			  id="g5772">
			 <path
				d="m 281.415,147.633 -2.684,-1.572 c -0.085,-0.041 -0.162,-0.097 -0.231,-0.166 v 0 c 0,-0.002 -0.002,-0.003 -0.003,-0.005 v 0 c -0.031,-0.032 -0.06,-0.066 -0.086,-0.103 v 0 c -0.003,-0.005 -0.008,-0.01 -0.01,-0.014 v 0 c -0.024,-0.033 -0.044,-0.069 -0.064,-0.107 v 0 c -0.006,-0.011 -0.012,-0.02 -0.017,-0.031 v 0 c -0.017,-0.033 -0.03,-0.068 -0.045,-0.105 v 0 c -0.006,-0.016 -0.013,-0.032 -0.019,-0.05 v 0 c -0.01,-0.031 -0.018,-0.065 -0.026,-0.098 v 0 c -0.007,-0.025 -0.015,-0.049 -0.02,-0.074 v 0 c -0.006,-0.028 -0.008,-0.059 -0.013,-0.088 v 0 c -0.005,-0.033 -0.012,-0.065 -0.015,-0.101 v 0 c -0.002,-0.023 -0.002,-0.047 -0.003,-0.071 v 0 c -0.003,-0.042 -0.006,-0.084 -0.006,-0.129 v 0 c 0,-0.277 0.048,-0.569 0.138,-0.86 v 0 c 0.264,-0.872 0.884,-1.735 1.608,-2.152 v 0 c 0.243,-0.141 0.474,-0.216 0.685,-0.235 v 0 c 0.052,-0.005 0.103,-0.006 0.153,-0.003 v 0 c 0.004,0 0.008,10e-4 0.012,0.002 v 0 c 0.045,0.002 0.089,0.008 0.132,0.016 v 0 c 0.009,0.002 0.017,0.005 0.026,0.008 v 0 c 0.037,0.008 0.073,0.018 0.108,0.031 v 0 c 0.014,0.005 0.027,0.013 0.041,0.019 v 0 c 0.027,0.012 0.055,0.024 0.081,0.039 v 0 c 0.002,0.001 0.003,0.001 0.005,0.002 v 0 l 2.712,1.588 c 0.28,0.158 0.467,0.482 0.506,0.94 v 0 c 0.007,0.059 0.009,0.122 0.008,0.185 v 0 c 0.001,0.343 -0.075,0.709 -0.208,1.067 v 0 c -0.135,0.365 -0.329,0.724 -0.564,1.041 v 0 c -0.278,0.376 -0.611,0.693 -0.973,0.902 v 0 c -0.239,0.139 -0.468,0.214 -0.675,0.233 v 0 c -0.033,0.003 -0.066,0.005 -0.099,0.005 v 0 c -0.169,0 -0.322,-0.04 -0.454,-0.114"
				style="fill:url(#linearGradient5788);stroke:none"
				id="path5790" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5792">
	   <g
		  id="g5794"
		  clip-path="url(#clipPath5798)">
		 <g
			id="g5800"
			transform="translate(281.6899,142.9118)">
		   <path
			  d="m 0,0 c 0,1.115 -0.787,2.472 -1.759,3.033 -0.971,0.561 -1.758,0.112 -1.758,-1.002 0,-1.115 0.787,-2.473 1.758,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5802" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g5804">
	   <g
		  id="g5806">
		 <g
			id="g5812">
		   <g
			  id="g5814">
			 <path
				d="m 279.194,144.851 -2.667,-1.515 c -0.034,-0.017 -0.065,-0.039 -0.091,-0.067 v 0 c -10e-4,0 -0.002,-0.001 -0.002,-0.002 v 0 c -0.013,-0.011 -0.023,-0.026 -0.034,-0.04 v 0 c -10e-4,-0.002 -0.003,-0.004 -0.005,-0.006 v 0 c -0.009,-0.013 -0.017,-0.027 -0.025,-0.042 v 0 c -0.002,-0.004 -0.004,-0.008 -0.006,-0.012 v 0 c -0.006,-0.013 -0.012,-0.027 -0.017,-0.042 v 0 c -0.003,-0.006 -0.006,-0.012 -0.008,-0.02 v 0 c -0.005,-0.012 -0.008,-0.025 -0.011,-0.038 v 0 c -0.003,-0.01 -0.006,-0.019 -0.007,-0.03 v 0 c -0.003,-0.011 -0.004,-0.023 -0.006,-0.034 v 0 c -0.002,-0.014 -0.004,-0.026 -0.005,-0.041 v 0 c -10e-4,-0.008 -10e-4,-0.018 -0.002,-0.027 v 0 c -10e-4,-0.017 -0.003,-0.034 -0.003,-0.052 v 0 c 0,-0.109 0.02,-0.225 0.055,-0.339 v 0 c 0.105,-0.346 0.35,-0.687 0.637,-0.853 v 0 c 0.096,-0.054 0.188,-0.085 0.27,-0.092 v 0 c 0.021,-0.002 0.042,-0.002 0.061,-10e-4 v 0 c 0.002,0 0.003,0 0.005,0 v 0 c 0.018,0.002 0.035,0.004 0.052,0.007 v 0 c 0.004,0.001 0.007,0.002 0.01,0.003 v 0 c 0.015,0.003 0.029,0.007 0.043,0.012 v 0 c 0.006,0.002 0.011,0.005 0.017,0.008 v 0 c 0.01,0.005 0.021,0.009 0.031,0.015 v 0 c 0.001,0.001 0.002,0.001 0.002,0.001 v 0 l 2.679,1.523 c 0.112,0.062 0.186,0.189 0.201,0.371 v 0 c 0.003,0.024 0.004,0.048 0.004,0.073 v 0 c 0,0.136 -0.031,0.281 -0.083,0.422 v 0 c -0.053,0.145 -0.131,0.287 -0.223,0.412 v 0 c -0.11,0.149 -0.243,0.275 -0.386,0.357 v 0 c -0.094,0.055 -0.184,0.085 -0.267,0.093 v 0 c -0.013,10e-4 -0.026,10e-4 -0.039,10e-4 v 0 c -0.067,0 -0.128,-0.015 -0.18,-0.045"
				style="fill:url(#linearGradient5830);stroke:none"
				id="path5832" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5834">
	   <g
		  id="g5836"
		  clip-path="url(#clipPath5840)">
		 <g
			id="g5842"
			transform="translate(277.6973,142.0894)">
		   <path
			  d="m 0,0 c 0,0.441 -0.311,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.397 0,-0.44 0.312,-0.978 0.696,-1.2 C -0.311,-0.619 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5844" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g5846">
	   <g
		  id="g5848">
		 <g
			id="g5854">
		   <g
			  id="g5856">
			 <path
				d="m 275.174,144.162 -1.054,-0.624 c -0.084,-0.041 -0.162,-0.097 -0.23,-0.167 v 0 c -10e-4,-0.001 -0.003,-0.002 -0.004,-0.004 v 0 c -0.03,-0.031 -0.059,-0.065 -0.085,-0.102 v 0 c -0.004,-0.006 -0.008,-0.01 -0.012,-0.016 v 0 c -0.023,-0.033 -0.043,-0.069 -0.063,-0.106 v 0 c -0.005,-0.011 -0.012,-0.02 -0.017,-0.03 v 0 c -0.016,-0.034 -0.031,-0.07 -0.044,-0.106 v 0 c -0.007,-0.017 -0.014,-0.032 -0.02,-0.05 v 0 c -0.01,-0.032 -0.018,-0.065 -0.026,-0.098 v 0 c -0.007,-0.024 -0.015,-0.049 -0.02,-0.075 v 0 c -0.005,-0.027 -0.009,-0.057 -0.013,-0.086 v 0 c -0.005,-0.034 -0.011,-0.067 -0.015,-0.102 v 0 c -0.002,-0.022 -0.002,-0.047 -0.003,-0.07 v 0 c -0.002,-0.043 -0.006,-0.085 -0.006,-0.13 v 0 c 0,-0.277 0.049,-0.569 0.137,-0.859 v 0 c 0.265,-0.872 0.886,-1.736 1.609,-2.153 v 0 c 0.243,-0.141 0.475,-0.216 0.685,-0.235 v 0 c 0.052,-0.004 0.104,-0.005 0.153,-0.003 v 0 c 0.004,0 0.008,0.001 0.012,0.002 v 0 c 0.045,0.003 0.089,0.008 0.132,0.016 v 0 c 0.009,0.002 0.017,0.006 0.026,0.007 v 0 c 0.037,0.009 0.073,0.019 0.107,0.032 v 0 c 0.015,0.005 0.028,0.013 0.043,0.02 v 0 c 0.027,0.011 0.054,0.023 0.081,0.038 v 0 c 0,0.001 0.002,0.001 0.003,0.002 v 0 l 1.083,0.641 c 0.281,0.158 0.467,0.481 0.507,0.939 v 0 c 0.006,0.059 0.009,0.122 0.009,0.186 v 0 c 0,0.342 -0.077,0.708 -0.21,1.067 v 0 c -0.134,0.365 -0.329,0.724 -0.564,1.041 v 0 c -0.278,0.374 -0.611,0.692 -0.972,0.901 v 0 c -0.24,0.139 -0.468,0.215 -0.675,0.234 v 0 c -0.034,0.003 -0.067,0.004 -0.099,0.004 v 0 c -0.169,0 -0.323,-0.039 -0.455,-0.114"
				style="fill:url(#linearGradient5872);stroke:none"
				id="path5874" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5876">
	   <g
		  id="g5878"
		  clip-path="url(#clipPath5882)">
		 <g
			id="g5884"
			transform="translate(277.0791,140.389)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.759,3.033 -0.971,0.561 -1.758,0.112 -1.758,-1.002 0,-1.115 0.787,-2.473 1.758,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5886" />
		 </g>
		 <g
			id="g5888"
			transform="translate(282.8798,147.1099)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5890" />
		 </g>
		 <g
			id="g5892"
			transform="translate(282.8772,147.5458)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5894" />
		 </g>
		 <g
			id="g5896"
			transform="translate(281.2031,146.5796)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5898" />
		 </g>
		 <g
			id="g5900"
			transform="translate(292.6612,137.0751)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5902" />
		 </g>
		 <g
			id="g5904"
			transform="translate(292.6612,138.0777)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5906" />
		 </g>
		 <g
			id="g5908"
			transform="translate(284.3866,133.2477)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5910" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g5912">
	   <g
		  id="g5914">
		 <g
			id="g5920">
		   <g
			  id="g5922">
			 <path
				d="m 289.01,142.817 -2.684,-1.572 c -0.085,-0.041 -0.162,-0.097 -0.23,-0.166 v 0 c -10e-4,-0.002 -0.003,-0.003 -0.004,-0.005 v 0 c -0.031,-0.031 -0.059,-0.066 -0.085,-0.103 v 0 c -0.004,-0.005 -0.009,-0.009 -0.012,-0.014 v 0 c -0.023,-0.033 -0.043,-0.069 -0.063,-0.107 v 0 c -0.006,-0.011 -0.012,-0.02 -0.017,-0.031 v 0 c -0.017,-0.033 -0.03,-0.068 -0.044,-0.105 v 0 c -0.007,-0.016 -0.014,-0.032 -0.02,-0.05 v 0 c -0.01,-0.031 -0.018,-0.065 -0.026,-0.098 v 0 c -0.007,-0.025 -0.015,-0.049 -0.02,-0.075 v 0 c -0.006,-0.027 -0.009,-0.058 -0.013,-0.087 v 0 c -0.005,-0.033 -0.011,-0.066 -0.015,-0.101 v 0 c -0.002,-0.023 -0.002,-0.047 -0.003,-0.07 v 0 c -0.002,-0.043 -0.006,-0.085 -0.006,-0.13 v 0 c 0,-0.277 0.049,-0.569 0.138,-0.86 v 0 c 0.264,-0.872 0.884,-1.735 1.608,-2.152 v 0 c 0.243,-0.141 0.474,-0.216 0.685,-0.235 v 0 c 0.052,-0.005 0.103,-0.006 0.153,-0.003 v 0 c 0.004,0 0.008,0.001 0.013,0.002 v 0 c 0.044,0.003 0.089,0.008 0.131,0.016 v 0 c 0.009,0.002 0.017,0.005 0.026,0.007 v 0 c 0.037,0.009 0.073,0.019 0.108,0.032 v 0 c 0.014,0.005 0.027,0.013 0.041,0.02 v 0 c 0.027,0.011 0.055,0.023 0.081,0.038 v 0 c 0.002,0.001 0.003,0.001 0.005,0.002 v 0 l 2.712,1.589 c 0.28,0.157 0.467,0.481 0.506,0.939 v 0 c 0.007,0.06 0.009,0.122 0.008,0.186 v 0 c 10e-4,0.342 -0.075,0.708 -0.208,1.066 v 0 c -0.134,0.365 -0.329,0.725 -0.564,1.041 v 0 c -0.278,0.376 -0.611,0.693 -0.973,0.902 v 0 c -0.239,0.139 -0.468,0.214 -0.675,0.233 v 0 c -0.033,0.003 -0.066,0.005 -0.098,0.005 v 0 c -0.17,0 -0.323,-0.04 -0.455,-0.114"
				style="fill:url(#linearGradient5938);stroke:none"
				id="path5940" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5942">
	   <g
		  id="g5944"
		  clip-path="url(#clipPath5948)">
		 <g
			id="g5950"
			transform="translate(289.285,138.0959)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.759,3.033 -0.971,0.561 -1.758,0.112 -1.758,-1.002 0,-1.115 0.787,-2.473 1.758,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5952" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g5954">
	   <g
		  id="g5956">
		 <g
			id="g5962">
		   <g
			  id="g5964">
			 <path
				d="m 286.789,140.035 -2.667,-1.515 c -0.034,-0.017 -0.065,-0.039 -0.091,-0.067 v 0 c -0.001,0 -0.002,-0.001 -0.002,-0.002 v 0 c -0.012,-0.011 -0.023,-0.026 -0.034,-0.04 v 0 c -10e-4,-0.002 -0.003,-0.004 -0.005,-0.005 v 0 c -0.008,-0.014 -0.017,-0.028 -0.025,-0.043 v 0 c -10e-4,-0.004 -0.004,-0.008 -0.006,-0.012 v 0 c -0.006,-0.013 -0.012,-0.027 -0.017,-0.042 v 0 c -0.003,-0.006 -0.006,-0.012 -0.008,-0.02 v 0 c -0.005,-0.012 -0.007,-0.025 -0.011,-0.038 v 0 c -0.003,-0.01 -0.005,-0.019 -0.007,-0.03 v 0 c -0.003,-0.011 -0.004,-0.022 -0.006,-0.034 v 0 c -0.001,-0.014 -0.004,-0.026 -0.006,-0.041 v 0 c 0,-0.008 0,-0.018 -10e-4,-0.027 v 0 c -0.001,-0.017 -0.003,-0.034 -0.003,-0.052 v 0 c 0,-0.109 0.02,-0.225 0.055,-0.34 v 0 c 0.105,-0.345 0.35,-0.686 0.637,-0.851 v 0 c 0.096,-0.055 0.188,-0.086 0.271,-0.093 v 0 c 0.02,-0.002 0.041,-0.002 0.06,-10e-4 v 0 c 0.002,0 0.003,0 0.005,0 v 0 c 0.017,0.002 0.035,0.004 0.052,0.007 v 0 c 0.004,0.001 0.007,0.002 0.01,0.003 v 0 c 0.015,0.004 0.029,0.007 0.043,0.012 v 0 c 0.006,0.002 0.011,0.005 0.017,0.008 v 0 c 0.011,0.005 0.021,0.01 0.031,0.015 v 0 c 10e-4,0.001 0.002,0.001 0.002,0.001 v 0 l 2.679,1.523 c 0.112,0.062 0.186,0.19 0.201,0.371 v 0 c 0.003,0.024 0.004,0.048 0.004,0.073 v 0 c 0,0.136 -0.031,0.281 -0.083,0.422 v 0 c -0.053,0.145 -0.131,0.288 -0.223,0.413 v 0 c -0.11,0.148 -0.242,0.274 -0.386,0.356 v 0 c -0.094,0.055 -0.185,0.086 -0.267,0.093 v 0 c -0.013,0.001 -0.026,0.001 -0.039,0.001 v 0 c -0.067,0 -0.127,-0.015 -0.18,-0.045"
				style="fill:url(#linearGradient5980);stroke:none"
				id="path5982" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g5984">
	   <g
		  id="g5986"
		  clip-path="url(#clipPath5990)">
		 <g
			id="g5992"
			transform="translate(285.2924,137.2735)">
		   <path
			  d="m 0,0 c 0,0.441 -0.311,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.397 0,-0.441 0.312,-0.978 0.696,-1.2 C -0.311,-0.619 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path5994" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g5996">
	   <g
		  id="g5998">
		 <g
			id="g6004">
		   <g
			  id="g6006">
			 <path
				d="m 282.769,139.346 -1.054,-0.624 c -0.084,-0.042 -0.162,-0.097 -0.23,-0.167 v 0 c -0.001,-0.001 -0.003,-0.002 -0.005,-0.004 v 0 c -0.029,-0.031 -0.058,-0.065 -0.084,-0.102 v 0 c -0.004,-0.006 -0.008,-0.01 -0.012,-0.015 v 0 c -0.022,-0.034 -0.043,-0.07 -0.063,-0.107 v 0 c -0.005,-0.011 -0.012,-0.02 -0.017,-0.03 v 0 c -0.016,-0.034 -0.031,-0.07 -0.044,-0.106 v 0 c -0.006,-0.017 -0.014,-0.032 -0.02,-0.05 v 0 c -0.01,-0.032 -0.018,-0.065 -0.027,-0.098 v 0 c -0.006,-0.024 -0.013,-0.049 -0.019,-0.075 v 0 c -0.005,-0.027 -0.009,-0.057 -0.012,-0.086 v 0 c -0.006,-0.034 -0.012,-0.067 -0.016,-0.102 v 0 c -0.002,-0.022 -0.002,-0.047 -0.002,-0.07 v 0 c -0.003,-0.043 -0.007,-0.085 -0.007,-0.13 v 0 c 0,-0.277 0.049,-0.569 0.137,-0.859 v 0 c 0.266,-0.872 0.886,-1.735 1.609,-2.153 v 0 c 0.243,-0.14 0.475,-0.216 0.685,-0.235 v 0 c 0.052,-0.004 0.104,-0.005 0.153,-0.003 v 0 c 0.004,0 0.008,10e-4 0.012,0.002 v 0 c 0.045,0.003 0.089,0.007 0.132,0.016 v 0 c 0.009,0.002 0.017,0.006 0.026,0.008 v 0 c 0.037,0.009 0.073,0.018 0.107,0.031 v 0 c 0.015,0.006 0.028,0.014 0.043,0.02 v 0 c 0.027,0.012 0.055,0.023 0.081,0.039 v 0 c 10e-4,0.001 0.002,0.001 0.003,0.001 v 0 l 1.083,0.641 c 0.281,0.158 0.467,0.481 0.507,0.939 v 0 c 0.006,0.059 0.009,0.122 0.008,0.186 v 0 c 0.001,0.342 -0.076,0.708 -0.209,1.067 v 0 c -0.134,0.365 -0.328,0.724 -0.563,1.041 v 0 c -0.278,0.374 -0.612,0.692 -0.973,0.901 v 0 c -0.24,0.139 -0.468,0.215 -0.675,0.234 v 0 c -0.034,0.003 -0.067,0.004 -0.099,0.004 v 0 c -0.169,0 -0.323,-0.039 -0.455,-0.114"
				style="fill:url(#linearGradient6022);stroke:none"
				id="path6024" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6026">
	   <g
		  id="g6028"
		  clip-path="url(#clipPath6032)">
		 <g
			id="g6034"
			transform="translate(284.6741,135.5731)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6036" />
		 </g>
		 <g
			id="g6038"
			transform="translate(290.4748,142.294)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6040" />
		 </g>
		 <g
			id="g6042"
			transform="translate(290.4723,142.7299)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6044" />
		 </g>
		 <g
			id="g6046"
			transform="translate(288.7982,141.7636)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6048" />
		 </g>
		 <g
			id="g6050"
			transform="translate(300.2563,132.2592)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6052" />
		 </g>
		 <g
			id="g6054"
			transform="translate(300.2563,133.2618)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6056" />
		 </g>
		 <g
			id="g6058"
			transform="translate(291.9817,128.4318)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6060" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g6062">
	   <g
		  id="g6064">
		 <g
			id="g6070">
		   <g
			  id="g6072">
			 <path
				d="m 296.605,138.001 -2.684,-1.572 c -0.085,-0.041 -0.162,-0.097 -0.23,-0.167 v 0 c -10e-4,-0.001 -0.003,-0.002 -0.004,-0.004 v 0 c -0.031,-0.031 -0.059,-0.066 -0.085,-0.102 v 0 c -0.004,-0.006 -0.008,-0.01 -0.012,-0.015 v 0 c -0.023,-0.033 -0.043,-0.069 -0.063,-0.107 v 0 c -0.006,-0.011 -0.012,-0.02 -0.017,-0.031 v 0 c -0.016,-0.033 -0.031,-0.068 -0.044,-0.105 v 0 c -0.007,-0.016 -0.014,-0.032 -0.02,-0.05 v 0 c -0.01,-0.031 -0.018,-0.065 -0.026,-0.098 v 0 c -0.007,-0.025 -0.015,-0.049 -0.02,-0.075 v 0 c -0.005,-0.027 -0.009,-0.058 -0.013,-0.086 v 0 c -0.005,-0.034 -0.011,-0.067 -0.015,-0.102 v 0 c -0.002,-0.023 -0.002,-0.047 -0.003,-0.07 v 0 c -0.002,-0.043 -0.006,-0.085 -0.006,-0.13 v 0 c 0,-0.277 0.049,-0.569 0.138,-0.859 v 0 c 0.264,-0.873 0.884,-1.736 1.608,-2.153 v 0 c 0.243,-0.141 0.474,-0.216 0.685,-0.235 v 0 c 0.052,-0.005 0.103,-0.005 0.153,-0.003 v 0 c 0.004,0 0.008,0.001 0.012,0.002 v 0 c 0.045,0.003 0.089,0.008 0.132,0.016 v 0 c 0.009,0.002 0.017,0.006 0.026,0.007 v 0 c 0.037,0.009 0.073,0.019 0.107,0.032 v 0 c 0.016,0.005 0.028,0.013 0.042,0.02 v 0 c 0.027,0.011 0.055,0.023 0.082,0.038 v 0 c 0,0.001 0.002,0.001 0.004,0.002 v 0 l 2.712,1.589 c 0.28,0.157 0.467,0.481 0.506,0.939 v 0 c 0.007,0.06 0.009,0.122 0.008,0.186 v 0 c 0.001,0.343 -0.075,0.708 -0.208,1.066 v 0 c -0.134,0.366 -0.329,0.725 -0.564,1.041 v 0 c -0.278,0.376 -0.611,0.693 -0.973,0.902 v 0 c -0.239,0.139 -0.468,0.214 -0.675,0.233 v 0 c -0.033,0.003 -0.066,0.005 -0.098,0.005 v 0 c -0.17,0 -0.324,-0.039 -0.455,-0.114"
				style="fill:url(#linearGradient6088);stroke:none"
				id="path6090" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6092">
	   <g
		  id="g6094"
		  clip-path="url(#clipPath6098)">
		 <g
			id="g6100"
			transform="translate(296.88,133.28)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6102" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g6104">
	   <g
		  id="g6106">
		 <g
			id="g6112">
		   <g
			  id="g6114">
			 <path
				d="m 294.385,135.22 -2.668,-1.516 c -0.034,-0.017 -0.065,-0.039 -0.092,-0.067 v 0 c 0,0 -10e-4,-0.001 -10e-4,-0.001 v 0 c -0.012,-0.012 -0.023,-0.027 -0.034,-0.041 v 0 c -10e-4,-0.002 -0.003,-0.004 -0.004,-0.005 v 0 c -0.009,-0.014 -0.018,-0.028 -0.026,-0.043 v 0 c -10e-4,-0.004 -0.004,-0.008 -0.006,-0.012 v 0 c -0.006,-0.013 -0.012,-0.027 -0.017,-0.042 v 0 c -0.003,-0.006 -0.006,-0.012 -0.008,-0.019 v 0 c -0.005,-0.013 -0.007,-0.026 -0.011,-0.039 v 0 c -0.003,-0.01 -0.005,-0.019 -0.007,-0.03 v 0 c -0.003,-0.011 -0.004,-0.022 -0.006,-0.034 v 0 c -10e-4,-0.014 -0.004,-0.026 -0.006,-0.041 v 0 c 0,-0.008 0,-0.018 -0.001,-0.027 v 0 c -10e-4,-0.017 -0.002,-0.034 -0.002,-0.052 v 0 c 0,-0.109 0.019,-0.225 0.055,-0.34 v 0 c 0.104,-0.345 0.35,-0.686 0.636,-0.851 v 0 c 0.096,-0.055 0.188,-0.086 0.271,-0.093 v 0 c 0.02,-0.002 0.041,-0.002 0.06,-0.001 v 0 c 0.002,0 0.004,0 0.005,0 v 0 c 0.017,0.002 0.036,0.004 0.053,0.007 v 0 c 0.002,10e-4 0.006,0.002 0.009,0.003 v 0 c 0.015,0.004 0.029,0.007 0.043,0.013 v 0 c 0.006,0.001 0.011,0.004 0.017,0.007 v 0 c 0.011,0.005 0.022,0.01 0.031,0.015 v 0 c 0.001,0.001 0.002,0.001 0.002,0.001 v 0 l 2.679,1.523 c 0.111,0.062 0.186,0.19 0.201,0.371 v 0 c 0.003,0.024 0.004,0.048 0.004,0.073 v 0 c 0,0.136 -0.031,0.281 -0.084,0.422 v 0 c -0.052,0.145 -0.13,0.288 -0.222,0.413 v 0 c -0.11,0.148 -0.242,0.274 -0.385,0.356 v 0 c -0.095,0.055 -0.186,0.086 -0.268,0.093 v 0 c -0.013,0.001 -0.026,0.002 -0.039,0.002 v 0 c -0.066,0 -0.127,-0.016 -0.179,-0.045"
				style="fill:url(#linearGradient6130);stroke:none"
				id="path6132" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6134">
	   <g
		  id="g6136"
		  clip-path="url(#clipPath6140)">
		 <g
			id="g6142"
			transform="translate(292.8875,132.4575)">
		   <path
			  d="m 0,0 c 0,0.441 -0.312,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.397 0,-0.44 0.312,-0.978 0.696,-1.2 C -0.312,-0.618 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6144" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g6146">
	   <g
		  id="g6148">
		 <g
			id="g6154">
		   <g
			  id="g6156">
			 <path
				d="m 290.364,134.53 -1.054,-0.624 c -0.085,-0.042 -0.162,-0.097 -0.23,-0.167 v 0 c -10e-4,-0.001 -0.003,-0.002 -0.004,-0.004 v 0 c -0.03,-0.031 -0.059,-0.065 -0.085,-0.102 v 0 c -0.004,-0.005 -0.008,-0.01 -0.012,-0.015 v 0 c -0.022,-0.034 -0.043,-0.07 -0.063,-0.107 v 0 c -0.005,-0.01 -0.012,-0.019 -0.017,-0.03 v 0 c -0.016,-0.034 -0.031,-0.07 -0.044,-0.106 v 0 c -0.006,-0.017 -0.014,-0.032 -0.02,-0.05 v 0 c -0.01,-0.031 -0.018,-0.065 -0.027,-0.098 v 0 c -0.006,-0.024 -0.013,-0.049 -0.019,-0.075 v 0 c -0.005,-0.027 -0.009,-0.057 -0.013,-0.086 v 0 c -0.005,-0.034 -0.011,-0.067 -0.015,-0.102 v 0 c -0.001,-0.022 -0.001,-0.047 -0.002,-0.07 v 0 c -0.003,-0.043 -0.007,-0.085 -0.007,-0.13 v 0 c 0,-0.277 0.049,-0.568 0.137,-0.859 v 0 c 0.266,-0.872 0.886,-1.735 1.609,-2.153 v 0 c 0.243,-0.14 0.475,-0.216 0.685,-0.235 v 0 c 0.053,-0.004 0.104,-0.005 0.153,-0.003 v 0 c 0.004,0 0.008,10e-4 0.012,0.002 v 0 c 0.045,0.003 0.089,0.007 0.131,0.017 v 0 c 0.01,0.001 0.018,0.005 0.027,0.007 v 0 c 0.037,0.009 0.073,0.018 0.107,0.031 v 0 c 0.015,0.006 0.028,0.014 0.043,0.02 v 0 c 0.027,0.012 0.055,0.023 0.081,0.039 v 0 c 10e-4,0 0.003,0.001 0.003,0.002 v 0 l 1.083,0.64 c 0.281,0.158 0.467,0.481 0.507,0.939 v 0 c 0.006,0.059 0.009,0.122 0.008,0.186 v 0 c 10e-4,0.342 -0.076,0.709 -0.209,1.067 v 0 c -0.134,0.365 -0.328,0.724 -0.563,1.041 v 0 c -0.278,0.375 -0.611,0.692 -0.973,0.901 v 0 c -0.24,0.14 -0.468,0.215 -0.675,0.234 v 0 c -0.034,0.003 -0.067,0.004 -0.099,0.004 v 0 c -0.169,0 -0.323,-0.039 -0.455,-0.114"
				style="fill:url(#linearGradient6172);stroke:none"
				id="path6174" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6176">
	   <g
		  id="g6178"
		  clip-path="url(#clipPath6182)">
		 <g
			id="g6184"
			transform="translate(292.2692,130.7572)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6186" />
		 </g>
		 <g
			id="g6188"
			transform="translate(298.0699,137.4781)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6190" />
		 </g>
		 <g
			id="g6192"
			transform="translate(298.0674,137.9139)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6194" />
		 </g>
		 <g
			id="g6196"
			transform="translate(296.3932,136.9477)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6198" />
		 </g>
		 <g
			id="g6200"
			transform="translate(565.9107,254.2521)">
		   <path
			  d="M 0,0 35.164,-20.846 23.28,-27.708"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  data-duration="3000"
			  data-delay="1000"

			  id="path6202" />


			  <circle r="2" cx="0" cy="0" class="path6202-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path6202-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path6202-circle circle"/>
	
			  
		 </g>
		 <g
			id="g6204"
			transform="translate(589.3532,240.3546)">
		   <path
			  d="M 0,0 41.421,23.915 66.356,9.514"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  data-duration="3000"
			  data-delay="1000"
			  id="path6206" />
			  <circle r="2" cx="0" cy="0" class="path6206-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path6206-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path6206-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path6206-circle circle"/>
		 </g>
		 <g
			id="g6208"
			transform="translate(649.4652,246.2631)">
		   <path
			  d="M 30.173352,-10.011935 23.753,-13.719 0,0 6.1811534,3.5687141"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  id="path6210"
			  inkscape:label="path6210"
			  sodipodi:nodetypes="cccc" />
		   <path
			  d="m 6.0562628,3.5068637 5.7691552,3.330845 23.915,-13.6249997 -6.133782,-3.541601"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path4"
			  inkscape:label="path6210-1"
			  sodipodi:nodetypes="cccc" />
		 </g>
		 <g
			id="g6212"
			transform="translate(679.4646,236.1512)">
		   <path
			  d="M 0,0 35.606,-20.109"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path6214" />
		 </g>
		 <g
			id="g6216"
			transform="translate(667.7254,237.7425)">
		   <path
			  d="m 0,0 -4.456,-2.573 -0.011,1.002 4.456,2.573 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6218" />
		 </g>
		 <g
			id="g6220"
			transform="translate(667.7143,238.7441)">
		   <path
			  d="m 0,0 -7.792,5.119 -4.964,-2.869 8.3,-4.823 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6222" />
		 </g>
		 <g
			id="g6224"
			transform="translate(663.2584,236.1713)">
		   <path
			  d="M 0,0 -8.3,4.823 -8.29,3.821 0.011,-1.002 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6226" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g6228">
	   <g
		  id="g6230">
		 <g
			id="g6236">
		   <g
			  id="g6238">
			 <path
				d="m 657.685,245.831 c -0.608,-0.351 -1.143,-1.018 -1.458,-1.746 v 0 c -0.189,-0.433 -0.299,-0.886 -0.299,-1.307 v 0 c 0,-0.562 0.197,-0.955 0.513,-1.141 v 0 l 0.067,-0.039 2.796,-1.643 10e-4,10e-4 c -0.316,0.187 -0.513,0.58 -0.514,1.141 v 0 c 10e-4,0.422 0.11,0.874 0.299,1.308 v 0 c 0.315,0.727 0.85,1.395 1.458,1.745 v 0 c 0.488,0.282 0.929,0.305 1.245,0.118 v 0 l -2.863,1.68 c -0.134,0.08 -0.291,0.121 -0.464,0.121 v 0 c -0.235,0 -0.501,-0.076 -0.781,-0.238"
				style="fill:url(#linearGradient6254);stroke:none"
				id="path6256" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6258">
	   <g
		  id="g6260"
		  clip-path="url(#clipPath6264)">
		 <g
			id="g6266"
			transform="translate(660.548,244.1506)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.928 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6268" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g6270">
	   <g
		  id="g6272">
		 <g
			id="g6278">
		   <g
			  id="g6280">
			 <path
				d="m 660.698,242.91 c -0.261,-0.15 -0.491,-0.437 -0.626,-0.75 v 0 c -0.081,-0.186 -0.128,-0.381 -0.129,-0.562 v 0 c 10e-4,-0.241 0.086,-0.41 0.221,-0.49 v 0 l 0.028,-0.017 4.262,-2.469 c -0.135,0.08 -0.22,0.25 -0.221,0.491 v 0 c 0,0.181 0.048,0.375 0.129,0.561 v 0 c 0.135,0.314 0.365,0.6 0.626,0.751 v 0 c 0.21,0.121 0.399,0.131 0.536,0.05 v 0 l -4.29,2.485 c -0.058,0.034 -0.126,0.052 -0.2,0.052 v 0 c -0.101,0 -0.215,-0.033 -0.336,-0.102"
				style="fill:url(#linearGradient6296);stroke:none"
				id="path6298" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6300">
	   <g
		  id="g6302">
		 <g
			id="g6308">
		   <g
			  id="g6310">
			 <path
				d="m 664.988,240.425 c -0.416,-0.241 -0.754,-0.828 -0.754,-1.312 v 0 c 0,-0.484 0.338,-0.682 0.754,-0.441 v 0 c 0.417,0.24 0.755,0.828 0.755,1.312 v 0 c 0,0.344 -0.171,0.543 -0.419,0.543 v 0 c -0.101,0 -0.215,-0.032 -0.336,-0.102"
				style="fill:url(#linearGradient6326);stroke:none"
				id="path6328" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6330">
	   <g
		  id="g6332">
		 <g
			id="g6338">
		   <g
			  id="g6340">
			 <path
				d="m 664.007,242.154 c -0.607,-0.35 -1.143,-1.018 -1.458,-1.745 v 0 c -0.188,-0.434 -0.298,-0.887 -0.298,-1.308 v 0 c 0,-0.561 0.198,-0.954 0.513,-1.141 v 0 l 0.066,-0.039 0.915,-0.527 c -0.316,0.187 -0.513,0.579 -0.513,1.141 v 0 c 0,0.421 0.109,0.874 0.298,1.308 v 0 c 0.315,0.727 0.851,1.395 1.457,1.745 v 0 c 0.488,0.282 0.929,0.305 1.247,0.118 v 0 l -0.98,0.566 c -0.135,0.079 -0.292,0.121 -0.466,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.781,-0.239"
				style="fill:url(#linearGradient6356);stroke:none"
				id="path6358" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6360">
	   <g
		  id="g6362"
		  clip-path="url(#clipPath6366)">
		 <g
			id="g6368"
			transform="translate(664.9883,241.5887)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.928 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6370" />
		 </g>
		 <g
			id="g6372"
			transform="translate(660.443,244.8788)">
		   <path
			  d="m 0,0 -1.02,-0.589 -0.003,0.435 1.02,0.589 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6374" />
		 </g>
		 <g
			id="g6376"
			transform="translate(660.4405,245.3141)">
		   <path
			  d="M 0,0 -1.638,0.949 -2.656,0.361 -1.02,-0.589 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6378" />
		 </g>
		 <g
			id="g6380"
			transform="translate(659.4201,244.7249)">
		   <path
			  d="m 0,0 -1.636,0.951 0.003,-0.436 1.636,-0.95 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6382" />
		 </g>
		 <g
			id="g6384"
			transform="translate(680.1689,244.9657)">
		   <path
			  d="m 0,0 -4.456,-2.573 -0.011,1.002 4.456,2.573 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6386" />
		 </g>
		 <g
			id="g6388"
			transform="translate(680.1577,245.9673)">
		   <path
			  d="m 0,0 -7.792,5.119 -4.964,-2.869 8.3,-4.823 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6390" />
		 </g>
		 <g
			id="g6392"
			transform="translate(675.7019,243.3945)">
		   <path
			  d="M 0,0 -8.3,4.823 -8.29,3.821 0.011,-1.002 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6394" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g6396">
	   <g
		  id="g6398">
		 <g
			id="g6404">
		   <g
			  id="g6406">
			 <path
				d="m 670.128,253.054 c -0.607,-0.351 -1.143,-1.018 -1.458,-1.745 v 0 c -0.189,-0.434 -0.299,-0.887 -0.299,-1.309 v 0 c 10e-4,-0.561 0.198,-0.954 0.513,-1.14 v 0 -0.001 l 0.067,-0.038 2.796,-1.642 0.001,0.001 c -0.316,0.186 -0.513,0.578 -0.514,1.14 v 0 c 10e-4,0.421 0.11,0.875 0.299,1.308 v 0 c 0.315,0.728 0.851,1.395 1.458,1.746 v 0 c 0.488,0.281 0.929,0.304 1.246,0.117 v 0 l -2.863,1.68 c -0.135,0.079 -0.292,0.121 -0.466,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.78,-0.238"
				style="fill:url(#linearGradient6422);stroke:none"
				id="path6424" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6426">
	   <g
		  id="g6428"
		  clip-path="url(#clipPath6432)">
		 <g
			id="g6434"
			transform="translate(672.9915,251.3738)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6436" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g6438"
		inkscape:label="g6438">
	   <g
		  id="g6440">
		 <g
			id="g6446">
		   <g
			  id="g6448">
			 <path
				d="m 673.141,250.133 c -0.261,-0.151 -0.491,-0.437 -0.626,-0.749 v 0 c -0.081,-0.187 -0.128,-0.382 -0.129,-0.563 v 0 c 10e-4,-0.241 0.086,-0.409 0.222,-0.489 v 0 l -10e-4,-0.001 0.029,-0.017 4.261,-2.468 c -0.135,0.08 -0.219,0.249 -0.22,0.49 v 0 c 10e-4,0.181 0.047,0.376 0.128,0.562 v 0 c 0.135,0.312 0.366,0.599 0.627,0.75 v 0 c 0.209,0.121 0.399,0.131 0.535,0.05 v 0 l -4.29,2.486 c -0.058,0.034 -0.125,0.052 -0.2,0.052 v 0 c -0.101,0 -0.215,-0.033 -0.336,-0.103"
				style="fill:url(#linearGradient6464);stroke:none"
				id="path6466" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6468">
	   <g
		  id="g6470">
		 <g
			id="g6476">
		   <g
			  id="g6478">
			 <path
				d="m 677.432,247.648 c -0.417,-0.241 -0.754,-0.828 -0.754,-1.313 v 0 c 0,-0.484 0.337,-0.681 0.754,-0.441 v 0 c 0.417,0.242 0.754,0.828 0.754,1.313 v 0 c 0,0.344 -0.171,0.544 -0.419,0.544 v 0 c -0.101,0 -0.215,-0.033 -0.335,-0.103"
				style="fill:url(#linearGradient6494);stroke:none"
				id="path6496" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6498">
	   <g
		  id="g6500">
		 <g
			id="g6506">
		   <g
			  id="g6508">
			 <path
				d="m 676.451,249.377 c -0.608,-0.35 -1.143,-1.018 -1.458,-1.745 v 0 c -0.189,-0.433 -0.298,-0.886 -0.299,-1.307 v 0 c 0.001,-0.562 0.198,-0.955 0.514,-1.142 v 0 l 0.066,-0.038 0.914,-0.528 c -0.316,0.187 -0.513,0.58 -0.513,1.141 v 0 c 0,0.422 0.11,0.874 0.299,1.308 v 0 c 0.315,0.727 0.85,1.395 1.458,1.745 v 0 c 0.487,0.282 0.928,0.305 1.245,0.118 v 0 l -0.98,0.566 c -0.134,0.08 -0.291,0.121 -0.465,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.781,-0.239"
				style="fill:url(#linearGradient6524);stroke:none"
				id="path6526" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6528">
	   <g
		  id="g6530"
		  clip-path="url(#clipPath6534)">
		 <g
			id="g6536"
			transform="translate(677.4317,248.8119)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.928 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6538" />
		 </g>
		 <g
			id="g6540"
			transform="translate(672.8865,252.102)">
		   <path
			  d="m 0,0 -1.02,-0.589 -0.003,0.435 1.02,0.589 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6542" />
		 </g>
		 <g
			id="g6544"
			transform="translate(672.8839,252.5373)">
		   <path
			  d="M 0,0 -1.638,0.95 -2.656,0.361 -1.02,-0.589 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6546" />
		 </g>
		 <g
			id="g6548"
			transform="translate(671.8635,251.9481)">
		   <path
			  d="m 0,0 -1.636,0.951 0.003,-0.436 1.636,-0.95 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6550" />
		 </g>
		 <g
			id="g6552"
			transform="translate(589.1903,226.5445)">
		   <path
			  d="m 0,0 -17.818,-10.28 23.759,-13.717 -23.759,-13.722 12.441,-7.68"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  data-duration="2500"
			  data-delay="900"
			  id="path6554" />

			  <circle r="2" cx="0" cy="0" class="path6554-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path6554-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path6554-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path6554-circle circle"/>

		 </g>
		 <g
			id="g6556"
			transform="translate(622.8157,159.2348)">
		   <path
			  d="M 0,0 -18.188,10.5"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  id="path6558" />
		   
		 </g>
		 <g
			id="g6560"
			transform="translate(654.5269,195.6865)">
		   <path
			  d="m -29.529107,10.721275 -6.203643,-3.5790246 23.754,-13.715"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path6562"
			  sodipodi:nodetypes="ccc"
			  inkscape:label="path6562" />
		   
		   <path
			  d="M -0.28124968,0.1875 -24.03825,13.9035 -29.716607,10.627525"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  id="path3"
			  sodipodi:nodetypes="ccc"
			  inkscape:label="path6562-1" />
		   
		 </g>
		 <g
			id="g6564"
			transform="translate(625.3162,206.2538)">
		   <path
			  d="M 0,0 -35.64,20.572"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  data-duration="2000"
			  data-delay="1000"

			  id="path6566" />


			  <circle r="2" cx="0" cy="0" class="path6566-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path6566-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path6566-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path6566-circle circle"/>

		 </g>
		 <g
			id="g6568"
			transform="translate(648.5894,192.9992)">
		   <path
			  d="M 0,0 15.645,-9.228"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path6570" />
		   
		 </g>
	   </g>
	 </g>
	 <g
		id="g6572">
	   <g
		  id="g6574">
		 <g
			id="g6580">
		   <g
			  id="g6582">
			 <path
				d="M 639.174,193.873 H 638.6 v -3.251 c 0,-3.185 4.473,-5.767 9.989,-5.767 v 0 c 5.518,0 9.99,2.582 9.99,5.767 v 0 1.321 1.93 h -0.574 c -1.374,2.236 -5.07,3.837 -9.416,3.837 v 0 c -4.345,0 -8.041,-1.601 -9.415,-3.837"
				style="fill:url(#linearGradient6598);stroke:none"
				id="path6600" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6602">
	   <g
		  id="g6604">
		 <g
			id="g6610">
		   <g
			  id="g6612">
			 <path
				d="m 640.264,193.895 c 0,-2.654 3.728,-4.806 8.325,-4.806 v 0 c 4.598,0 8.325,2.152 8.325,4.806 v 0 c 0,2.655 -3.727,4.807 -8.325,4.807 v 0 c -4.597,0 -8.325,-2.152 -8.325,-4.807"
				style="fill:url(#linearGradient6624);stroke:none"
				id="path6626" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6628">
	   <g
		  id="g6630">
		 <g
			id="g6636">
		   <g
			  id="g6638">
			 <path
				d="m 640.264,193 c 0,-2.655 3.728,-4.807 8.325,-4.807 v 0 c 4.598,0 8.325,2.152 8.325,4.807 v 0 c 0,2.654 -3.727,4.806 -8.325,4.806 v 0 c -4.597,0 -8.325,-2.152 -8.325,-4.806"
				style="fill:url(#linearGradient6646);stroke:none"
				id="path6648" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6650">
	   <g
		  id="g6652">
		 <g
			id="g6658">
		   <g
			  id="g6660">
			 <path
				d="m 638.6,193.895 c 0,-3.185 4.473,-5.767 9.989,-5.767 v 0 c 5.518,0 9.99,2.582 9.99,5.767 v 0 c 0,3.186 -4.472,5.768 -9.99,5.768 v 0 c -5.516,0 -9.989,-2.582 -9.989,-5.768 m 1.665,0 c 0,2.655 3.727,4.807 8.324,4.807 v 0 c 4.598,0 8.325,-2.152 8.325,-4.807 v 0 c 0,-2.654 -3.727,-4.806 -8.325,-4.806 v 0 c -4.597,0 -8.324,2.152 -8.324,4.806"
				style="fill:url(#linearGradient6672);stroke:none"
				id="path6674" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6676">
	   <g
		  id="g6678"
		  clip-path="url(#clipPath6682)">
		 <g
			id="g6684"
			transform="translate(609.5608,173.747)"
			inkscape:label="g6684">
		   <path
			  d="M -25.96949,7.2695071 -31.679,3.973 -12.419,-6.856"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path6686"
			  sodipodi:nodetypes="ccc"
			  inkscape:label="path6686" />
		   <path
			  d="M -0.09374956,-0.09374977 -19.41675,11.01325 -26.06324,7.1757571"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  id="path2"
			  sodipodi:nodetypes="ccc"
			  inkscape:label="path6686-1" />
		   
		 </g>
	   </g>
	 </g>
	 <g
		id="g6688">
	   <g
		  id="g6690">
		 <g
			id="g6696">
		   <g
			  id="g6698">
			 <path
				d="m 592.941,171.326 h -0.712 v -4.035 c 0,-3.954 5.551,-7.159 12.398,-7.159 v 0 c 6.847,0 12.399,3.205 12.399,7.159 v 0 1.64 2.395 h -0.712 c -1.707,2.775 -6.295,4.763 -11.687,4.763 v 0 c -5.393,0 -9.98,-1.988 -11.686,-4.763"
				style="fill:url(#linearGradient6714);stroke:none"
				id="path6716" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6718">
	   <g
		  id="g6720">
		 <g
			id="g6726">
		   <g
			  id="g6728">
			 <path
				d="m 594.295,171.355 c 0,-3.295 4.626,-5.966 10.332,-5.966 v 0 c 5.706,0 10.331,2.671 10.331,5.966 v 0 c 0,3.294 -4.625,5.964 -10.331,5.964 v 0 c -5.706,0 -10.332,-2.67 -10.332,-5.964"
				style="fill:url(#linearGradient6740);stroke:none"
				id="path6742" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6744">
	   <g
		  id="g6746">
		 <g
			id="g6752">
		   <g
			  id="g6754">
			 <path
				d="m 594.295,170.242 c 0,-3.295 4.626,-5.965 10.332,-5.965 v 0 c 5.706,0 10.331,2.67 10.331,5.965 v 0 c 0,3.294 -4.625,5.964 -10.331,5.964 v 0 c -5.706,0 -10.332,-2.67 -10.332,-5.964"
				style="fill:url(#linearGradient6762);stroke:none"
				id="path6764" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6766">
	   <g
		  id="g6768">
		 <g
			id="g6774">
		   <g
			  id="g6776">
			 <path
				d="m 592.229,171.355 c 0,-3.954 5.551,-7.159 12.398,-7.159 v 0 c 6.847,0 12.399,3.205 12.399,7.159 v 0 c 0,3.952 -5.552,7.157 -12.399,7.157 v 0 c -6.847,0 -12.398,-3.205 -12.398,-7.157 m 2.066,0 c 0,3.294 4.626,5.964 10.332,5.964 v 0 c 5.706,0 10.332,-2.67 10.332,-5.964 v 0 c 0,-3.295 -4.626,-5.966 -10.332,-5.966 v 0 c -5.706,0 -10.332,2.671 -10.332,5.966"
				style="fill:url(#linearGradient6788);stroke:none"
				id="path6790" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6792">
	   <g
		  id="g6794"
		  clip-path="url(#clipPath6798)">
		 <g
			id="g6800"
			transform="translate(387.3678,137.4389)">
		   <path
			  d="M -5.7587378,-17.323927 -17.653834,-10.475583 0.26516518,-0.13258256 11.959162,-6.9405241"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  id="path6802"
			  sodipodi:nodetypes="cccc" />
		   <path
			  d="M 11.832959,-6.8750256 23.682216,-13.773356 6.1824659,-24.301169 -5.8953884,-17.251718"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path6802-8"
			  sodipodi:nodetypes="cccc" />
		 </g>
		 <g
			id="g6804"
			transform="translate(403.3071,128.1592)">
		   <path
			  d="M 0,0 -17.919,-10.261"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path6806" />
		 </g>
		 <g
			id="g6808"
			transform="translate(395.2302,132.8615)">
		   <path
			  d="M 0,0 -17.86,-10.338"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path6810" />
		 </g>
		 <g
			id="g6812"
			transform="translate(384.3157,133.0866)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6814" />
		 </g>
		 <g
			id="g6816"
			transform="translate(384.3157,134.0893)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6818" />
		 </g>
		 <g
			id="g6820"
			transform="translate(376.0411,129.2592)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6822" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g6824">
	   <g
		  id="g6826">
		 <g
			id="g6832">
		   <g
			  id="g6834">
			 <path
				d="m 380.665,138.829 -2.684,-1.573 c -0.085,-0.041 -0.163,-0.097 -0.23,-0.166 v 0 c -0.002,-0.002 -0.004,-0.003 -0.005,-0.004 v 0 c -0.03,-0.032 -0.058,-0.066 -0.085,-0.103 v 0 c -0.004,-0.005 -0.007,-0.009 -0.011,-0.015 v 0 c -0.024,-0.033 -0.044,-0.069 -0.064,-0.107 v 0 c -0.005,-0.01 -0.012,-0.02 -0.017,-0.03 v 0 c -0.016,-0.033 -0.03,-0.069 -0.043,-0.105 v 0 c -0.008,-0.017 -0.015,-0.033 -0.02,-0.05 v 0 c -0.011,-0.032 -0.019,-0.066 -0.027,-0.098 v 0 c -0.006,-0.026 -0.014,-0.049 -0.019,-0.075 v 0 c -0.006,-0.028 -0.009,-0.058 -0.013,-0.087 v 0 c -0.006,-0.034 -0.012,-0.066 -0.015,-0.101 v 0 c -0.003,-0.023 -0.002,-0.048 -0.004,-0.07 v 0 c -0.002,-0.044 -0.006,-0.086 -0.006,-0.131 v 0 c 0,-0.276 0.05,-0.569 0.138,-0.859 v 0 c 0.264,-0.872 0.885,-1.735 1.608,-2.152 v 0 c 0.243,-0.141 0.474,-0.217 0.685,-0.235 v 0 h 10e-4 c 0.052,-0.005 0.103,-0.005 0.152,-0.004 v 0 c 0.005,0.001 0.008,0.002 0.013,0.002 v 0 c 0.045,0.003 0.089,0.008 0.131,0.017 v 0 c 0.009,0.002 0.017,0.005 0.026,0.007 v 0 c 0.037,0.008 0.073,0.018 0.108,0.032 v 0 c 0.015,0.005 0.027,0.012 0.042,0.019 v 0 c 0.027,0.012 0.055,0.023 0.081,0.038 v 0 c 0.001,10e-4 0.003,10e-4 0.004,0.002 v 0 l 2.713,1.589 c 0.28,0.158 0.466,0.481 0.506,0.939 v 0 c 0.006,0.06 0.008,0.123 0.008,0.186 v 0 c 0,0.343 -0.076,0.709 -0.209,1.066 v 0 c -0.134,0.366 -0.329,0.726 -0.564,1.042 v 0 c -0.277,0.375 -0.611,0.693 -0.972,0.901 v 0 c -0.24,0.139 -0.469,0.215 -0.676,0.234 v 0 c -0.033,0.003 -0.066,0.005 -0.098,0.005 v 0 c -0.169,0 -0.323,-0.04 -0.454,-0.114"
				style="fill:url(#linearGradient6850);stroke:none"
				id="path6852" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6854">
	   <g
		  id="g6856"
		  clip-path="url(#clipPath6860)">
		 <g
			id="g6862"
			transform="translate(380.9395,134.1074)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.759,3.033 -0.971,0.561 -1.758,0.112 -1.758,-1.002 0,-1.115 0.787,-2.473 1.758,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6864" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g6866">
	   <g
		  id="g6868">
		 <g
			id="g6874">
		   <g
			  id="g6876">
			 <path
				d="m 378.445,136.047 -2.669,-1.516 c -0.033,-0.016 -0.064,-0.039 -0.091,-0.066 v 0 c -10e-4,-0.001 -10e-4,-0.001 -0.002,-0.001 v 0 c -0.011,-0.013 -0.023,-0.027 -0.033,-0.041 v 0 c -0.002,-0.003 -0.004,-0.004 -0.004,-0.006 v 0 c -0.01,-0.013 -0.018,-0.027 -0.026,-0.043 v 0 c -0.002,-0.003 -0.004,-0.008 -0.007,-0.011 v 0 c -0.006,-0.014 -0.011,-0.027 -0.017,-0.042 v 0 c -0.003,-0.007 -0.005,-0.013 -0.008,-0.02 v 0 c -0.004,-0.012 -0.007,-0.026 -0.011,-0.039 v 0 c -0.002,-0.01 -0.005,-0.019 -0.007,-0.029 v 0 c -0.002,-0.012 -0.004,-0.023 -0.005,-0.035 v 0 c -0.002,-0.012 -0.005,-0.026 -0.006,-0.04 v 0 c -0.001,-0.009 -0.001,-0.018 -0.002,-0.027 v 0 c -0.001,-0.018 -0.001,-0.034 -0.001,-0.052 v 0 c 0,-0.11 0.018,-0.225 0.054,-0.34 v 0 c 0.105,-0.346 0.35,-0.687 0.636,-0.851 v 0 c 0.096,-0.056 0.188,-0.087 0.271,-0.094 v 0 h 10e-4 c 0.02,-0.002 0.04,-0.002 0.06,-0.001 v 0 c 0.001,0 0.003,0.001 0.004,0.001 v 0 c 0.018,10e-4 0.036,0.003 0.053,0.006 v 0 c 0.003,10e-4 0.007,0.002 0.01,0.003 v 0 c 0.015,0.004 0.029,0.007 0.043,0.013 v 0 c 0.005,0.001 0.011,0.005 0.016,0.008 v 0 c 0.011,0.004 0.022,0.009 0.032,0.015 v 0 c 0,0 0.001,0 0.002,0 v 0 l 2.679,1.523 c 0.111,0.063 0.185,0.191 0.2,0.372 v 0 c 0.003,0.023 0.004,0.048 0.004,0.073 v 0 c 0,0.135 -0.03,0.28 -0.083,0.422 v 0 c -0.053,0.144 -0.129,0.287 -0.223,0.413 v 0 c -0.11,0.147 -0.241,0.273 -0.384,0.356 v 0 c -0.096,0.055 -0.186,0.085 -0.268,0.092 v 0 c -0.013,0.001 -0.026,0.002 -0.039,0.002 v 0 c -0.067,0 -0.127,-0.016 -0.179,-0.045"
				style="fill:url(#linearGradient6892);stroke:none"
				id="path6894" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6896">
	   <g
		  id="g6898"
		  clip-path="url(#clipPath6902)">
		 <g
			id="g6904"
			transform="translate(376.9469,133.285)">
		   <path
			  d="m 0,0 c 0,0.441 -0.311,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.397 0,-0.44 0.312,-0.978 0.696,-1.2 C -0.311,-0.619 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6906" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g6908">
	   <g
		  id="g6910">
		 <g
			id="g6916">
		   <g
			  id="g6918">
			 <path
				d="m 374.424,135.358 -1.054,-0.624 c -0.085,-0.042 -0.163,-0.098 -0.231,-0.167 v 0 c -0.001,-0.002 -0.003,-0.003 -0.004,-0.004 v 0 c -0.03,-0.032 -0.058,-0.066 -0.085,-0.103 v 0 c -0.004,-0.005 -0.007,-0.01 -0.011,-0.014 v 0 c -0.023,-0.034 -0.044,-0.07 -0.064,-0.107 v 0 c -0.005,-0.01 -0.011,-0.02 -0.017,-0.031 v 0 c -0.016,-0.033 -0.03,-0.068 -0.043,-0.105 v 0 c -0.007,-0.017 -0.014,-0.033 -0.02,-0.051 v 0 c -0.011,-0.03 -0.019,-0.065 -0.027,-0.098 v 0 c -0.006,-0.024 -0.014,-0.048 -0.019,-0.074 v 0 c -0.006,-0.028 -0.009,-0.058 -0.013,-0.087 v 0 c -0.006,-0.033 -0.012,-0.066 -0.015,-0.101 v 0 c -0.002,-0.022 -0.002,-0.048 -0.003,-0.071 v 0 c -0.003,-0.042 -0.007,-0.085 -0.007,-0.13 v 0 c 0,-0.277 0.05,-0.568 0.138,-0.859 v 0 c 0.265,-0.872 0.886,-1.734 1.608,-2.152 v 0 c 0.244,-0.14 0.475,-0.217 0.685,-0.235 v 0 c 0,0 0,0 10e-4,0 v 0 c 0.053,-0.004 0.103,-0.006 0.152,-0.003 v 0 c 0.005,0 0.009,10e-4 0.013,10e-4 v 0 c 0.045,0.003 0.089,0.009 0.131,0.017 v 0 c 0.009,0.002 0.017,0.005 0.026,0.008 v 0 c 0.037,0.008 0.073,0.018 0.108,0.03 v 0 c 0.015,0.006 0.028,0.014 0.042,0.02 v 0 c 0.028,0.012 0.055,0.023 0.081,0.039 v 0 c 0.002,0.001 0.003,0.001 0.004,0.002 v 0 l 1.083,0.64 c 0.28,0.158 0.467,0.482 0.507,0.94 v 0 c 0.005,0.059 0.008,0.121 0.008,0.185 v 0 c 0,0.343 -0.077,0.709 -0.21,1.067 v 0 c -0.134,0.365 -0.328,0.724 -0.563,1.041 v 0 c -0.277,0.375 -0.611,0.692 -0.972,0.902 v 0 c -0.24,0.139 -0.469,0.214 -0.676,0.233 v 0 c -0.034,0.003 -0.067,0.004 -0.099,0.004 v 0 c -0.169,0 -0.322,-0.039 -0.454,-0.113"
				style="fill:url(#linearGradient6934);stroke:none"
				id="path6936" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g6938">
	   <g
		  id="g6940"
		  clip-path="url(#clipPath6944)">
		 <g
			id="g6946"
			transform="translate(376.3286,131.5846)">
		   <path
			  d="m 0,0 c 0,1.115 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6948" />
		 </g>
		 <g
			id="g6950"
			transform="translate(382.1293,138.3055)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6952" />
		 </g>
		 <g
			id="g6954"
			transform="translate(382.1268,138.7414)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6956" />
		 </g>
		 <g
			id="g6958"
			transform="translate(380.4527,137.7752)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6960" />
		 </g>
		 <g
			id="g6962"
			transform="translate(391.9108,128.2707)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6964" />
		 </g>
		 <g
			id="g6966"
			transform="translate(391.9108,129.2733)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6968" />
		 </g>
		 <g
			id="g6970"
			transform="translate(383.6362,124.4433)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path6972" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g6974">
	   <g
		  id="g6976">
		 <g
			id="g6982">
		   <g
			  id="g6984">
			 <path
				d="m 388.26,134.013 -2.684,-1.573 c -0.085,-0.041 -0.163,-0.097 -0.23,-0.166 v 0 c -0.002,-0.002 -0.004,-0.003 -0.005,-0.005 v 0 c -0.03,-0.031 -0.058,-0.065 -0.085,-0.102 v 0 c -0.004,-0.005 -0.007,-0.009 -0.011,-0.015 v 0 c -0.023,-0.033 -0.044,-0.069 -0.064,-0.107 v 0 c -0.005,-0.01 -0.012,-0.02 -0.017,-0.03 v 0 c -0.016,-0.033 -0.03,-0.069 -0.043,-0.105 v 0 c -0.007,-0.017 -0.015,-0.033 -0.02,-0.05 v 0 c -0.011,-0.032 -0.019,-0.066 -0.027,-0.098 v 0 c -0.006,-0.025 -0.014,-0.049 -0.019,-0.075 v 0 c -0.006,-0.028 -0.009,-0.057 -0.013,-0.087 v 0 c -0.006,-0.034 -0.012,-0.066 -0.015,-0.101 v 0 c -0.002,-0.022 -0.002,-0.048 -0.003,-0.07 v 0 c -0.003,-0.043 -0.007,-0.086 -0.007,-0.131 v 0 c 0,-0.276 0.05,-0.569 0.138,-0.859 v 0 c 0.265,-0.872 0.886,-1.735 1.608,-2.152 v 0 c 0.243,-0.141 0.475,-0.217 0.685,-0.235 v 0 h 0.001 c 0.052,-0.004 0.103,-0.005 0.152,-0.004 v 0 c 0.005,0.001 0.008,0.002 0.013,0.002 v 0 c 0.045,0.003 0.089,0.008 0.131,0.017 v 0 c 0.009,0.002 0.017,0.005 0.026,0.007 v 0 c 0.037,0.009 0.073,0.018 0.108,0.031 v 0 c 0.015,0.006 0.027,0.014 0.042,0.02 v 0 c 0.028,0.012 0.056,0.023 0.081,0.039 v 0 c 10e-4,0 0.003,0 0.004,0.001 v 0 l 2.713,1.589 c 0.28,0.158 0.466,0.481 0.506,0.939 v 0 c 0.006,0.06 0.009,0.123 0.009,0.186 v 0 c 0,0.343 -0.077,0.709 -0.21,1.066 v 0 c -0.134,0.366 -0.329,0.726 -0.564,1.043 v 0 c -0.277,0.374 -0.611,0.692 -0.972,0.9 v 0 c -0.24,0.139 -0.468,0.216 -0.675,0.234 v 0 c -0.034,0.003 -0.067,0.005 -0.099,0.005 v 0 c -0.169,0 -0.322,-0.039 -0.454,-0.114"
				style="fill:url(#linearGradient7000);stroke:none"
				id="path7002" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7004">
	   <g
		  id="g7006"
		  clip-path="url(#clipPath7010)">
		 <g
			id="g7012"
			transform="translate(388.5345,129.2915)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7014" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7016">
	   <g
		  id="g7018">
		 <g
			id="g7024">
		   <g
			  id="g7026">
			 <path
				d="m 386.04,131.231 -2.669,-1.516 c -0.033,-0.016 -0.064,-0.039 -0.091,-0.066 v 0 c -10e-4,0 -10e-4,-0.001 -10e-4,-0.001 v 0 c -0.012,-0.013 -0.024,-0.026 -0.034,-0.041 v 0 c -0.002,-0.003 -0.003,-0.004 -0.004,-0.006 v 0 c -0.01,-0.014 -0.018,-0.027 -0.026,-0.042 v 0 c -0.002,-0.004 -0.005,-0.009 -0.007,-0.012 v 0 c -0.006,-0.014 -0.011,-0.027 -0.017,-0.042 v 0 c -0.002,-0.007 -0.005,-0.013 -0.008,-0.02 v 0 c -0.003,-0.012 -0.007,-0.025 -0.011,-0.039 v 0 c -0.001,-0.01 -0.005,-0.019 -0.007,-0.029 v 0 c -0.002,-0.011 -0.004,-0.023 -0.005,-0.035 v 0 c -0.002,-0.012 -0.005,-0.026 -0.006,-0.039 v 0 c -10e-4,-0.009 -10e-4,-0.019 -0.002,-0.028 v 0 c 0,-0.017 -10e-4,-0.034 -10e-4,-0.052 v 0 c 0,-0.109 0.019,-0.225 0.054,-0.34 v 0 c 0.105,-0.345 0.35,-0.687 0.636,-0.851 v 0 c 0.096,-0.056 0.188,-0.087 0.271,-0.094 v 0 h 10e-4 c 0.02,-0.002 0.04,-0.002 0.06,-10e-4 v 0 c 10e-4,0 0.003,10e-4 0.005,10e-4 v 0 c 0.018,0.001 0.035,0.003 0.052,0.006 v 0 c 0.003,0.001 0.006,0.002 0.01,0.003 v 0 c 0.015,0.004 0.029,0.007 0.043,0.013 v 0 c 0.005,0.002 0.011,0.005 0.016,0.008 v 0 c 0.011,0.004 0.022,0.009 0.032,0.015 v 0 c 0,0 10e-4,0 0.002,0 v 0 l 2.68,1.523 c 0.11,0.063 0.184,0.191 0.199,0.372 v 0 c 0.003,0.023 0.004,0.048 0.004,0.074 v 0 c 0,0.134 -0.03,0.279 -0.083,0.422 v 0 c -0.053,0.144 -0.129,0.286 -0.223,0.412 v 0 c -0.11,0.147 -0.241,0.273 -0.384,0.356 v 0 c -0.096,0.055 -0.186,0.085 -0.268,0.092 v 0 c -0.013,0.001 -0.026,0.002 -0.039,0.002 v 0 c -0.067,0 -0.127,-0.016 -0.179,-0.045"
				style="fill:url(#linearGradient7042);stroke:none"
				id="path7044" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7046">
	   <g
		  id="g7048"
		  clip-path="url(#clipPath7052)">
		 <g
			id="g7054"
			transform="translate(384.542,128.4691)">
		   <path
			  d="m 0,0 c 0,0.441 -0.311,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.397 0,-0.441 0.312,-0.978 0.696,-1.2 C -0.311,-0.619 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7056" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7058">
	   <g
		  id="g7060">
		 <g
			id="g7066">
		   <g
			  id="g7068">
			 <path
				d="m 382.019,130.542 -1.054,-0.624 c -0.085,-0.042 -0.162,-0.098 -0.231,-0.167 v 0 c -10e-4,-0.002 -0.002,-0.003 -0.003,-0.004 v 0 c -0.031,-0.032 -0.059,-0.066 -0.086,-0.103 v 0 c -0.004,-0.004 -0.007,-0.01 -0.011,-0.014 v 0 c -0.023,-0.034 -0.044,-0.07 -0.064,-0.107 v 0 c -0.005,-0.01 -0.011,-0.02 -0.017,-0.031 v 0 c -0.016,-0.033 -0.029,-0.068 -0.043,-0.104 v 0 c -0.007,-0.018 -0.014,-0.034 -0.02,-0.052 v 0 c -0.011,-0.03 -0.019,-0.065 -0.027,-0.098 v 0 c -0.006,-0.024 -0.014,-0.048 -0.019,-0.074 v 0 c -0.006,-0.028 -0.009,-0.058 -0.013,-0.087 v 0 c -0.005,-0.033 -0.012,-0.066 -0.014,-0.101 v 0 c -0.003,-0.022 -0.003,-0.047 -0.004,-0.071 v 0 c -0.003,-0.042 -0.006,-0.084 -0.006,-0.129 v 0 c 0,-0.278 0.048,-0.569 0.137,-0.86 v 0 c 0.265,-0.872 0.886,-1.734 1.609,-2.152 v 0 c 0.243,-0.14 0.474,-0.217 0.684,-0.235 v 0 h 0.001 c 0.053,-0.004 0.103,-0.006 0.153,-0.003 v 0 c 0.004,0.001 0.008,0.001 0.012,0.001 v 0 c 0.045,0.003 0.089,0.009 0.131,0.018 v 0 c 0.009,0.001 0.018,0.004 0.027,0.007 v 0 c 0.036,0.008 0.072,0.018 0.108,0.03 v 0 c 0.014,0.006 0.027,0.014 0.041,0.02 v 0 c 0.027,0.012 0.055,0.024 0.081,0.039 v 0 c 0.002,10e-4 0.003,10e-4 0.005,0.002 v 0 l 1.082,0.641 c 0.28,0.157 0.466,0.481 0.507,0.939 v 0 c 0.005,0.06 0.008,0.122 0.008,0.186 v 0 c 0,0.342 -0.076,0.708 -0.21,1.066 v 0 c -0.134,0.365 -0.328,0.724 -0.563,1.041 v 0 c -0.277,0.376 -0.611,0.692 -0.972,0.902 v 0 c -0.24,0.139 -0.469,0.214 -0.676,0.233 v 0 c -0.033,0.003 -0.066,0.005 -0.099,0.005 v 0 c -0.169,0 -0.322,-0.04 -0.454,-0.114"
				style="fill:url(#linearGradient7084);stroke:none"
				id="path7086" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7088">
	   <g
		  id="g7090"
		  clip-path="url(#clipPath7094)">
		 <g
			id="g7096"
			transform="translate(383.9237,126.7687)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.759,3.033 -0.971,0.561 -1.758,0.112 -1.758,-1.002 0,-1.115 0.787,-2.473 1.758,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7098" />
		 </g>
		 <g
			id="g7100"
			transform="translate(389.7244,133.4896)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7102" />
		 </g>
		 <g
			id="g7104"
			transform="translate(389.7219,133.9255)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7106" />
		 </g>
		 <g
			id="g7108"
			transform="translate(388.0477,132.9592)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7110" />
		 </g>
		 <g
			id="g7112"
			transform="translate(399.5059,123.4548)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7114" />
		 </g>
		 <g
			id="g7116"
			transform="translate(399.5059,124.4574)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7118" />
		 </g>
		 <g
			id="g7120"
			transform="translate(391.2313,119.6274)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7122" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7124">
	   <g
		  id="g7126">
		 <g
			id="g7132">
		   <g
			  id="g7134">
			 <path
				d="m 395.855,129.197 -2.684,-1.573 c -0.085,-0.041 -0.163,-0.097 -0.23,-0.166 v 0 c -0.002,-0.002 -0.004,-0.003 -0.005,-0.004 v 0 c -0.03,-0.032 -0.058,-0.066 -0.085,-0.103 v 0 c -0.004,-0.005 -0.007,-0.009 -0.011,-0.014 v 0 c -0.023,-0.034 -0.044,-0.07 -0.064,-0.108 v 0 c -0.005,-0.01 -0.012,-0.019 -0.017,-0.03 v 0 c -0.016,-0.033 -0.03,-0.069 -0.043,-0.105 v 0 c -0.007,-0.017 -0.014,-0.033 -0.02,-0.05 v 0 c -0.011,-0.032 -0.019,-0.066 -0.027,-0.098 v 0 c -0.006,-0.025 -0.014,-0.049 -0.019,-0.075 v 0 c -0.006,-0.028 -0.009,-0.057 -0.013,-0.087 v 0 c -0.006,-0.033 -0.012,-0.066 -0.015,-0.101 v 0 c -0.002,-0.022 -0.002,-0.048 -0.003,-0.07 v 0 c -0.003,-0.043 -0.007,-0.086 -0.007,-0.131 v 0 c 0,-0.276 0.05,-0.568 0.138,-0.859 v 0 c 0.265,-0.872 0.886,-1.734 1.608,-2.152 v 0 c 0.243,-0.14 0.475,-0.217 0.685,-0.235 v 0 h 10e-4 c 0.052,-0.004 0.103,-0.005 0.152,-0.004 v 0 c 0.005,0.001 0.008,0.002 0.013,0.002 v 0 c 0.045,0.003 0.089,0.008 0.131,0.017 v 0 c 0.009,0.002 0.017,0.005 0.026,0.008 v 0 c 0.037,0.008 0.073,0.018 0.108,0.03 v 0 c 0.015,0.006 0.027,0.014 0.042,0.02 v 0 c 0.028,0.013 0.056,0.023 0.081,0.039 v 0 c 0.002,0.001 0.002,0.001 0.004,0.002 v 0 l 2.713,1.588 c 0.28,0.158 0.466,0.482 0.507,0.94 v 0 c 0.005,0.059 0.008,0.122 0.008,0.185 v 0 c 0,0.343 -0.077,0.709 -0.21,1.067 v 0 c -0.134,0.365 -0.329,0.725 -0.563,1.042 v 0 c -0.278,0.374 -0.612,0.692 -0.972,0.901 v 0 c -0.241,0.138 -0.469,0.215 -0.676,0.234 v 0 c -0.034,0.002 -0.067,0.004 -0.099,0.004 v 0 c -0.169,0 -0.322,-0.039 -0.454,-0.114"
				style="fill:url(#linearGradient7150);stroke:none"
				id="path7152" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7154">
	   <g
		  id="g7156"
		  clip-path="url(#clipPath7160)">
		 <g
			id="g7162"
			transform="translate(396.1296,124.4756)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7164" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7166">
	   <g
		  id="g7168">
		 <g
			id="g7174">
		   <g
			  id="g7176">
			 <path
				d="m 393.635,126.415 -2.669,-1.516 c -0.033,-0.016 -0.064,-0.038 -0.091,-0.065 v 0 c -10e-4,-0.001 -10e-4,-0.002 -10e-4,-0.002 v 0 c -0.012,-0.013 -0.024,-0.026 -0.035,-0.041 v 0 c 0,-0.002 -0.002,-0.004 -0.003,-0.006 v 0 c -0.01,-0.014 -0.018,-0.027 -0.025,-0.042 v 0 c -0.003,-0.004 -0.006,-0.008 -0.008,-0.012 v 0 c -0.006,-0.014 -0.011,-0.028 -0.017,-0.041 v 0 c -0.002,-0.008 -0.005,-0.014 -0.008,-0.021 v 0 c -0.003,-0.012 -0.007,-0.025 -0.011,-0.039 v 0 c -10e-4,-0.01 -0.005,-0.019 -0.007,-0.029 v 0 c -0.002,-0.011 -0.003,-0.023 -0.005,-0.035 v 0 c -0.002,-0.012 -0.005,-0.026 -0.006,-0.039 v 0 c 0,-0.009 0,-0.019 -0.001,-0.028 v 0 c -10e-4,-0.017 -0.002,-0.034 -0.002,-0.051 v 0 c 0,-0.11 0.019,-0.225 0.054,-0.341 v 0 c 0.105,-0.345 0.35,-0.686 0.636,-0.851 v 0 c 0.096,-0.056 0.188,-0.086 0.271,-0.094 v 0 h 0.001 c 0.02,-0.001 0.04,-0.001 0.06,-0.001 v 0 c 0.001,0 0.003,0.001 0.005,0.001 v 0 c 0.018,10e-4 0.035,0.003 0.052,0.007 v 0 c 0.003,0.001 0.006,0.001 0.01,0.002 v 0 c 0.015,0.004 0.03,0.007 0.043,0.013 v 0 c 0.005,0.002 0.011,0.005 0.016,0.008 v 0 c 0.011,0.005 0.022,0.009 0.033,0.015 v 0 c 0,0 0,0 10e-4,0 v 0 l 2.68,1.523 c 0.11,0.063 0.184,0.191 0.199,0.372 v 0 c 0.003,0.023 0.004,0.048 0.004,0.074 v 0 c 0,0.135 -0.031,0.28 -0.083,0.422 v 0 c -0.053,0.144 -0.129,0.286 -0.223,0.412 v 0 c -0.11,0.148 -0.241,0.274 -0.384,0.356 v 0 c -0.096,0.055 -0.186,0.085 -0.268,0.092 v 0 c -0.013,0.001 -0.026,0.002 -0.039,0.002 v 0 c -0.067,0 -0.127,-0.016 -0.179,-0.045"
				style="fill:url(#linearGradient7192);stroke:none"
				id="path7194" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7196">
	   <g
		  id="g7198"
		  clip-path="url(#clipPath7202)">
		 <g
			id="g7204"
			transform="translate(392.1371,123.6531)">
		   <path
			  d="m 0,0 c 0,0.441 -0.311,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.397 0,-0.44 0.312,-0.978 0.696,-1.2 C -0.311,-0.618 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7206" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7208">
	   <g
		  id="g7210">
		 <g
			id="g7216">
		   <g
			  id="g7218">
			 <path
				d="m 389.615,125.726 -1.054,-0.624 c -0.086,-0.042 -0.163,-0.097 -0.232,-0.167 v 0 c -0.001,-0.001 -0.002,-0.002 -0.003,-0.004 v 0 c -0.031,-0.032 -0.059,-0.066 -0.086,-0.103 v 0 c -0.003,-0.004 -0.007,-0.01 -0.011,-0.014 v 0 c -0.023,-0.033 -0.044,-0.069 -0.064,-0.107 v 0 c -0.005,-0.01 -0.011,-0.02 -0.017,-0.031 v 0 c -0.016,-0.033 -0.029,-0.068 -0.044,-0.104 v 0 c -0.006,-0.017 -0.013,-0.034 -0.019,-0.052 v 0 c -0.01,-0.03 -0.019,-0.064 -0.027,-0.098 v 0 c -0.006,-0.024 -0.014,-0.047 -0.019,-0.074 v 0 c -0.006,-0.027 -0.009,-0.058 -0.013,-0.087 v 0 c -0.005,-0.033 -0.012,-0.066 -0.014,-0.101 v 0 c -0.003,-0.023 -0.003,-0.047 -0.004,-0.071 v 0 c -0.003,-0.042 -0.006,-0.084 -0.006,-0.129 v 0 c 0,-0.277 0.048,-0.569 0.137,-0.86 v 0 c 0.265,-0.872 0.886,-1.734 1.609,-2.152 v 0 c 0.243,-0.14 0.474,-0.217 0.685,-0.235 v 0 h 0.001 c 0.052,-0.004 0.102,-0.006 0.152,-0.003 v 0 c 0.004,0.001 0.008,0.002 0.012,0.002 v 0 c 0.045,0.002 0.089,0.008 0.132,0.016 v 0 c 0.009,0.002 0.017,0.005 0.026,0.008 v 0 c 0.037,0.008 0.073,0.018 0.108,0.031 v 0 c 0.014,0.005 0.027,0.013 0.041,0.019 v 0 c 0.027,0.012 0.055,0.024 0.081,0.039 v 0 c 0.002,10e-4 0.003,10e-4 0.005,0.002 v 0 l 1.082,0.641 c 0.28,0.157 0.466,0.481 0.507,0.939 v 0 c 0.005,0.059 0.008,0.122 0.008,0.186 v 0 c 0,0.343 -0.076,0.708 -0.21,1.066 v 0 c -0.134,0.366 -0.328,0.725 -0.563,1.041 v 0 c -0.277,0.376 -0.611,0.693 -0.972,0.902 v 0 c -0.24,0.139 -0.469,0.214 -0.676,0.233 v 0 c -0.033,0.003 -0.066,0.005 -0.099,0.005 v 0 c -0.169,0 -0.322,-0.04 -0.453,-0.114"
				style="fill:url(#linearGradient7234);stroke:none"
				id="path7236" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7238">
	   <g
		  id="g7240"
		  clip-path="url(#clipPath7244)">
		 <g
			id="g7246"
			transform="translate(391.5188,121.9528)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.759,3.033 -0.971,0.561 -1.758,0.112 -1.758,-1.002 0,-1.115 0.787,-2.473 1.758,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7248" />
		 </g>
		 <g
			id="g7250"
			transform="translate(397.3195,128.6737)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7252" />
		 </g>
		 <g
			id="g7254"
			transform="translate(397.3169,129.1095)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7256" />
		 </g>
		 <g
			id="g7258"
			transform="translate(395.6428,128.1433)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7260" />
		 </g>
		 <g
			id="g7262"
			transform="translate(407.1009,118.6389)">
		   <path
			  d="M 0,0 -8.264,-4.83 -8.275,-3.827 0,1.003 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7264" />
		 </g>
		 <g
			id="g7266"
			transform="translate(407.1009,119.6415)">
		   <path
			  d="m 0,0 -4.413,2.589 -8.261,-4.842 4.399,-2.577 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7268" />
		 </g>
		 <g
			id="g7270"
			transform="translate(398.8264,114.8115)">
		   <path
			  d="m 0,0 -4.4,2.577 0.011,-1.004 4.4,-2.576 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7272" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7274">
	   <g
		  id="g7276">
		 <g
			id="g7282">
		   <g
			  id="g7284">
			 <path
				d="m 403.45,124.381 -2.684,-1.572 c -0.085,-0.042 -0.163,-0.098 -0.23,-0.167 v 0 c -0.002,-0.002 -0.004,-0.003 -0.005,-0.004 v 0 c -0.03,-0.032 -0.058,-0.066 -0.085,-0.103 v 0 c -0.004,-0.004 -0.007,-0.009 -0.011,-0.014 v 0 c -0.023,-0.034 -0.044,-0.07 -0.064,-0.108 v 0 c -0.005,-0.009 -0.011,-0.019 -0.017,-0.03 v 0 c -0.016,-0.033 -0.03,-0.069 -0.043,-0.104 v 0 c -0.007,-0.018 -0.014,-0.034 -0.02,-0.052 v 0 c -0.011,-0.03 -0.019,-0.065 -0.027,-0.097 v 0 c -0.006,-0.025 -0.014,-0.049 -0.019,-0.075 v 0 c -0.006,-0.028 -0.009,-0.058 -0.013,-0.087 v 0 c -0.006,-0.033 -0.012,-0.066 -0.015,-0.101 v 0 c -0.002,-0.022 -10e-4,-0.047 -0.003,-0.07 v 0 c -0.003,-0.043 -0.007,-0.085 -0.007,-0.13 v 0 c 0,-0.277 0.05,-0.569 0.138,-0.86 v 0 c 0.265,-0.872 0.886,-1.734 1.608,-2.152 v 0 c 0.244,-0.14 0.475,-0.217 0.685,-0.235 v 0 c 0,0 0,0 0.001,0 v 0 c 0.052,-0.004 0.103,-0.005 0.152,-0.003 v 0 c 0.005,0 0.008,0.001 0.013,0.001 v 0 c 0.045,0.003 0.089,0.008 0.131,0.018 v 0 c 0.009,10e-4 0.017,0.004 0.026,0.007 v 0 c 0.037,0.008 0.073,0.018 0.108,0.03 v 0 c 0.015,0.006 0.027,0.014 0.042,0.02 v 0 c 0.028,0.012 0.055,0.024 0.081,0.039 v 0 c 0.002,0.001 0.003,0.001 0.004,0.002 v 0 l 2.713,1.588 c 0.28,0.159 0.466,0.482 0.507,0.94 v 0 c 0.005,0.059 0.008,0.122 0.008,0.185 v 0 c 0,0.343 -0.077,0.709 -0.21,1.067 v 0 c -0.134,0.365 -0.328,0.725 -0.563,1.042 v 0 c -0.277,0.374 -0.611,0.692 -0.972,0.901 v 0 c -0.241,0.138 -0.469,0.215 -0.676,0.234 v 0 c -0.033,0.003 -0.066,0.004 -0.098,0.004 v 0 c -0.169,0 -0.323,-0.04 -0.455,-0.114"
				style="fill:url(#linearGradient7300);stroke:none"
				id="path7302" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7304">
	   <g
		  id="g7306"
		  clip-path="url(#clipPath7310)">
		 <g
			id="g7312"
			transform="translate(403.7247,119.6597)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.759,3.033 -0.971,0.561 -1.758,0.112 -1.758,-1.002 0,-1.115 0.787,-2.473 1.758,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7314" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7316">
	   <g
		  id="g7318">
		 <g
			id="g7324">
		   <g
			  id="g7326">
			 <path
				d="m 401.23,121.599 -2.669,-1.516 c -0.033,-0.016 -0.064,-0.038 -0.091,-0.066 v 0 c 0,0 0,-10e-4 -0.001,-10e-4 v 0 c -0.012,-0.013 -0.024,-0.026 -0.034,-0.041 v 0 c -10e-4,-0.002 -0.003,-0.004 -0.004,-0.006 v 0 c -0.01,-0.013 -0.018,-0.027 -0.025,-0.042 v 0 c -0.003,-0.004 -0.006,-0.008 -0.008,-0.012 v 0 c -0.006,-0.013 -0.011,-0.028 -0.017,-0.041 v 0 c -0.002,-0.007 -0.005,-0.014 -0.008,-0.021 v 0 c -0.003,-0.012 -0.007,-0.025 -0.011,-0.039 v 0 c -0.001,-0.01 -0.005,-0.019 -0.007,-0.029 v 0 c -0.002,-0.011 -0.003,-0.023 -0.005,-0.035 v 0 c -0.002,-0.012 -0.005,-0.026 -0.006,-0.039 v 0 c 0,-0.009 0,-0.019 -10e-4,-0.028 v 0 c -0.001,-0.017 -0.002,-0.033 -0.002,-0.051 v 0 c 0,-0.11 0.019,-0.225 0.054,-0.341 v 0 c 0.105,-0.345 0.35,-0.686 0.636,-0.851 v 0 c 0.097,-0.056 0.188,-0.086 0.271,-0.094 v 0 h 10e-4 c 0.02,-0.001 0.041,-0.001 0.06,0 v 0 c 10e-4,0 0.003,0 0.005,0 v 0 c 0.018,10e-4 0.035,0.003 0.052,0.007 v 0 c 0.004,0.001 0.006,0.002 0.01,0.002 v 0 c 0.014,0.004 0.029,0.008 0.043,0.013 v 0 c 0.005,0.002 0.011,0.005 0.016,0.007 v 0 c 0.011,0.006 0.022,0.01 0.033,0.016 v 0 c 0,0 0.001,0 0.001,0 v 0 l 2.68,1.523 c 0.111,0.063 0.184,0.191 0.2,0.372 v 0 c 0.002,0.023 0.003,0.049 0.003,0.074 v 0 c 0,0.135 -0.03,0.28 -0.083,0.422 v 0 c -0.053,0.144 -0.129,0.286 -0.223,0.412 v 0 c -0.11,0.148 -0.241,0.274 -0.384,0.356 v 0 c -0.096,0.055 -0.186,0.085 -0.267,0.092 v 0 c -0.014,0.001 -0.027,0.002 -0.04,0.002 v 0 c -0.067,0 -0.128,-0.016 -0.179,-0.045"
				style="fill:url(#linearGradient7342);stroke:none"
				id="path7344" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7346">
	   <g
		  id="g7348"
		  clip-path="url(#clipPath7352)">
		 <g
			id="g7354"
			transform="translate(399.7321,118.8372)">
		   <path
			  d="m 0,0 c 0,0.441 -0.311,0.978 -0.696,1.2 -0.384,0.222 -0.696,0.044 -0.696,-0.397 0,-0.44 0.312,-0.978 0.696,-1.2 C -0.311,-0.618 0,-0.441 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7356" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7358">
	   <g
		  id="g7360">
		 <g
			id="g7366">
		   <g
			  id="g7368">
			 <path
				d="m 397.21,120.911 -1.055,-0.625 c -0.085,-0.041 -0.162,-0.097 -0.231,-0.167 v 0 c -10e-4,-0.001 -0.002,-0.002 -0.003,-0.004 v 0 c -0.031,-0.032 -0.059,-0.066 -0.086,-0.103 v 0 c -0.003,-0.005 -0.007,-0.01 -0.011,-0.014 v 0 c -0.023,-0.033 -0.044,-0.069 -0.063,-0.107 v 0 c -0.006,-0.01 -0.012,-0.02 -0.018,-0.031 v 0 c -0.016,-0.033 -0.029,-0.068 -0.044,-0.104 v 0 c -0.006,-0.017 -0.013,-0.033 -0.019,-0.052 v 0 c -0.01,-0.03 -0.018,-0.064 -0.026,-0.098 v 0 c -0.007,-0.024 -0.015,-0.047 -0.02,-0.073 v 0 c -0.006,-0.028 -0.008,-0.059 -0.013,-0.088 v 0 c -0.005,-0.033 -0.012,-0.065 -0.014,-0.101 v 0 c -0.003,-0.023 -0.003,-0.047 -0.004,-0.071 v 0 c -0.003,-0.042 -0.006,-0.084 -0.006,-0.129 v 0 c 0,-0.277 0.048,-0.569 0.137,-0.86 v 0 c 0.265,-0.872 0.885,-1.734 1.609,-2.152 v 0 c 0.243,-0.14 0.474,-0.217 0.685,-0.234 v 0 c 0,-10e-4 0,0 10e-4,-10e-4 v 0 c 0.052,-0.005 0.102,-0.006 0.152,-0.003 v 0 c 0.004,0 0.008,0.002 0.012,0.002 v 0 c 0.045,0.002 0.089,0.008 0.132,0.016 v 0 c 0.009,0.003 0.017,0.005 0.026,0.008 v 0 c 0.037,0.008 0.073,0.018 0.108,0.031 v 0 c 0.014,0.005 0.027,0.013 0.041,0.019 v 0 c 0.027,0.012 0.055,0.024 0.081,0.039 v 0 c 0.002,0.001 0.003,0.001 0.005,0.002 v 0 l 1.082,0.641 c 0.28,0.157 0.467,0.481 0.507,0.939 v 0 c 0.005,0.059 0.008,0.122 0.008,0.186 v 0 c 0,0.343 -0.076,0.708 -0.21,1.066 v 0 c -0.134,0.366 -0.328,0.725 -0.563,1.042 v 0 c -0.277,0.375 -0.611,0.692 -0.972,0.901 v 0 c -0.24,0.139 -0.469,0.215 -0.676,0.233 v 0 c -0.033,0.003 -0.066,0.005 -0.099,0.005 v 0 c -0.169,0 -0.322,-0.039 -0.453,-0.113"
				style="fill:url(#linearGradient7384);stroke:none"
				id="path7386" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7388">
	   <g
		  id="g7390"
		  clip-path="url(#clipPath7394)">
		 <g
			id="g7396"
			transform="translate(399.1139,117.1369)">
		   <path
			  d="m 0,0 c 0,1.114 -0.787,2.472 -1.758,3.033 -0.972,0.561 -1.759,0.112 -1.759,-1.002 0,-1.115 0.787,-2.473 1.759,-3.034 C -0.787,-1.563 0,-1.115 0,0"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7398" />
		 </g>
		 <g
			id="g7400"
			transform="translate(404.9146,123.8577)">
		   <path
			  d="m 0,0 -1.674,-0.966 -0.003,0.436 1.674,0.966 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7402" />
		 </g>
		 <g
			id="g7404"
			transform="translate(404.912,124.2936)">
		   <path
			  d="m 0,0 -1.019,0.577 -1.672,-0.965 1.017,-0.578 z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7406" />
		 </g>
		 <g
			id="g7408"
			transform="translate(403.2379,123.3274)">
		   <path
			  d="M 0,0 -1.017,0.578 -1.014,0.142 0.003,-0.436 Z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7410" />
		 </g>
		 <g
			id="g7412"
			transform="translate(451.1771,121.0575)"
			inkscape:label="g7412">
		   <path
			  d="M 11.882959,7.2318718 23.802,14.086748 7.1720004,23.687748 C 4.0725814,21.903982 5.7889324,22.929415 -4.9309986,16.70035"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  id="path7414"
			  sodipodi:nodetypes="cccc"
			  inkscape:label="path7414-1" />
		   <path
			  d="M -4.7984162,16.70035 -16.451417,9.9727478 0.13258282,0.39774781 12.015542,7.2318718"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path1"
			  sodipodi:nodetypes="cccc"
			  inkscape:label="path7414-2" />
		 </g>
		 <g
			id="g7416"
			transform="translate(473.1348,138.0615)">
		   <path
			  d="m 0,0 -4.456,-2.573 -0.011,1.002 4.456,2.573 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7418" />
		 </g>
		 <g
			id="g7420"
			transform="translate(473.1237,139.0632)">
		   <path
			  d="m 0,0 -7.792,5.119 -4.964,-2.869 8.3,-4.823 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7422" />
		 </g>
		 <g
			id="g7424"
			transform="translate(468.6678,136.4904)">
		   <path
			  d="m 0,0 -8.3,4.823 0.011,-1.002 8.3,-4.823 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7426" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7428">
	   <g
		  id="g7430">
		 <g
			id="g7436">
		   <g
			  id="g7438">
			 <path
				d="m 463.094,146.149 c -0.607,-0.35 -1.143,-1.018 -1.458,-1.745 v 0 c -0.189,-0.434 -0.298,-0.886 -0.299,-1.308 v 0 c 0.001,-0.561 0.198,-0.954 0.513,-1.141 v 0 l 0.067,-0.039 2.796,-1.641 h 10e-4 c -0.316,0.187 -0.513,0.579 -0.514,1.141 v 0 c 0.001,0.421 0.11,0.874 0.299,1.308 v 0 c 0.315,0.727 0.85,1.395 1.458,1.745 v 0 c 0.488,0.282 0.929,0.305 1.246,0.118 v 0 l -2.863,1.68 c -0.135,0.08 -0.292,0.121 -0.466,0.121 v 0 c -0.234,0 -0.499,-0.076 -0.78,-0.239"
				style="fill:url(#linearGradient7454);stroke:none"
				id="path7456" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7458">
	   <g
		  id="g7460"
		  clip-path="url(#clipPath7464)">
		 <g
			id="g7466"
			transform="translate(465.9574,144.4697)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7468" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7470">
	   <g
		  id="g7472">
		 <g
			id="g7478">
		   <g
			  id="g7480">
			 <path
				d="m 466.107,143.229 c -0.261,-0.151 -0.49,-0.438 -0.626,-0.75 v 0 c -0.081,-0.186 -0.128,-0.381 -0.128,-0.562 v 0 c 0,-0.241 0.085,-0.41 0.221,-0.49 v 0 l 0.028,-0.016 4.261,-2.469 h 10e-4 c -0.136,0.08 -0.221,0.248 -0.221,0.49 v 0 c 0,0.181 0.047,0.376 0.128,0.562 v 0 c 0.135,0.312 0.366,0.599 0.627,0.75 v 0 c 0.209,0.12 0.398,0.13 0.535,0.05 v 0 l -4.29,2.485 c -0.058,0.035 -0.126,0.053 -0.2,0.053 v 0 c -0.101,0 -0.215,-0.033 -0.336,-0.103"
				style="fill:url(#linearGradient7496);stroke:none"
				id="path7498" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7500">
	   <g
		  id="g7502">
		 <g
			id="g7508">
		   <g
			  id="g7510">
			 <path
				d="m 470.398,140.744 c -0.417,-0.241 -0.755,-0.828 -0.755,-1.312 v 0 c 0,-0.485 0.338,-0.682 0.755,-0.441 v 0 c 0.416,0.24 0.755,0.828 0.755,1.312 v 0 c 0,0.344 -0.171,0.543 -0.42,0.543 v 0 c -0.101,0 -0.215,-0.033 -0.335,-0.102"
				style="fill:url(#linearGradient7526);stroke:none"
				id="path7528" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7530">
	   <g
		  id="g7532">
		 <g
			id="g7538">
		   <g
			  id="g7540">
			 <path
				d="m 469.417,142.474 c -0.608,-0.351 -1.143,-1.018 -1.458,-1.745 v 0 c -0.188,-0.434 -0.298,-0.888 -0.298,-1.309 v 0 c 0,-0.562 0.197,-0.954 0.513,-1.14 v 0 -0.001 l 0.065,-0.038 0.915,-0.528 v 0.001 c -0.316,0.186 -0.512,0.578 -0.513,1.14 v 0 c 10e-4,0.421 0.11,0.875 0.299,1.308 v 0 c 0.315,0.728 0.85,1.395 1.458,1.746 v 0 c 0.486,0.281 0.928,0.304 1.245,0.116 v 0 l -0.98,0.567 c -0.135,0.079 -0.292,0.121 -0.466,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.78,-0.238"
				style="fill:url(#linearGradient7556);stroke:none"
				id="path7558" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7560">
	   <g
		  id="g7562"
		  clip-path="url(#clipPath7566)">
		 <g
			id="g7568"
			transform="translate(470.3977,141.9077)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7570" />
		 </g>
		 <g
			id="g7572"
			transform="translate(465.8524,145.1979)">
		   <path
			  d="m 0,0 -1.02,-0.589 -0.003,0.435 1.021,0.589 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7574" />
		 </g>
		 <g
			id="g7576"
			transform="translate(465.8499,145.6331)">
		   <path
			  d="M 0,0 -1.638,0.95 -2.656,0.361 -1.02,-0.589 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7578" />
		 </g>
		 <g
			id="g7580"
			transform="translate(464.8295,145.044)">
		   <path
			  d="m 0,0 -1.636,0.951 0.003,-0.436 1.636,-0.95 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7582" />
		 </g>
		 <g
			id="g7584"
			transform="translate(461.3682,131.0095)">
		   <path
			  d="m 0,0 -4.456,-2.573 -0.011,1.002 4.456,2.573 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7586" />
		 </g>
		 <g
			id="g7588"
			transform="translate(461.3571,132.0112)">
		   <path
			  d="m 0,0 -7.792,5.119 -4.964,-2.869 8.3,-4.823 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7590" />
		 </g>
		 <g
			id="g7592"
			transform="translate(456.9012,129.4384)">
		   <path
			  d="M 0,0 -8.3,4.823 -8.29,3.821 0.011,-1.002 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7594" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7596">
	   <g
		  id="g7598">
		 <g
			id="g7604">
		   <g
			  id="g7606">
			 <path
				d="m 451.327,139.098 c -0.607,-0.351 -1.142,-1.018 -1.457,-1.745 v 0 c -0.19,-0.434 -0.298,-0.887 -0.299,-1.308 v 0 c 10e-4,-0.563 0.198,-0.955 0.513,-1.141 v 0 -0.001 l 0.066,-0.038 2.798,-1.643 v 0.001 c -0.316,0.187 -0.513,0.579 -0.514,1.141 v 0 c 10e-4,0.421 0.11,0.874 0.298,1.308 v 0 c 0.315,0.727 0.851,1.394 1.458,1.745 v 0 c 0.488,0.282 0.929,0.305 1.247,0.117 v 0 l -2.864,1.681 c -0.134,0.08 -0.291,0.121 -0.465,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.781,-0.238"
				style="fill:url(#linearGradient7622);stroke:none"
				id="path7624" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7626">
	   <g
		  id="g7628"
		  clip-path="url(#clipPath7632)">
		 <g
			id="g7634"
			transform="translate(454.1909,137.4177)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7636" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7638">
	   <g
		  id="g7640">
		 <g
			id="g7646">
		   <g
			  id="g7648">
			 <path
				d="m 454.341,136.177 c -0.261,-0.151 -0.491,-0.437 -0.627,-0.75 v 0 c -0.081,-0.186 -0.128,-0.381 -0.128,-0.562 v 0 c 0,-0.241 0.085,-0.41 0.221,-0.49 v 0 -0.001 l 0.028,-0.016 4.262,-2.468 c -0.136,0.08 -0.22,0.249 -0.22,0.49 v 0 c 0,0.181 0.047,0.375 0.128,0.562 v 0 c 0.136,0.312 0.365,0.599 0.626,0.749 v 0 c 0.21,0.122 0.399,0.132 0.536,0.051 v 0 l -4.291,2.486 c -0.058,0.034 -0.125,0.051 -0.2,0.051 v 0 c -0.101,0 -0.215,-0.032 -0.335,-0.102"
				style="fill:url(#linearGradient7664);stroke:none"
				id="path7666" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7668">
	   <g
		  id="g7670">
		 <g
			id="g7676">
		   <g
			  id="g7678">
			 <path
				d="m 458.631,133.692 c -0.417,-0.241 -0.754,-0.829 -0.754,-1.313 v 0 c 0,-0.484 0.337,-0.681 0.754,-0.441 v 0 c 0.417,0.241 0.755,0.828 0.755,1.312 v 0 c 0,0.345 -0.171,0.544 -0.42,0.544 v 0 c -0.101,0 -0.215,-0.032 -0.335,-0.102"
				style="fill:url(#linearGradient7694);stroke:none"
				id="path7696" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7698">
	   <g
		  id="g7700">
		 <g
			id="g7706">
		   <g
			  id="g7708">
			 <path
				d="m 457.65,135.421 c -0.607,-0.35 -1.143,-1.018 -1.457,-1.745 v 0 c -0.189,-0.434 -0.299,-0.886 -0.299,-1.308 v 0 c 0,-0.561 0.197,-0.955 0.513,-1.141 v 0 l 0.066,-0.039 0.914,-0.527 c -0.315,0.186 -0.512,0.58 -0.513,1.141 v 0 c 10e-4,0.421 0.11,0.874 0.299,1.308 v 0 c 0.315,0.727 0.85,1.395 1.458,1.745 v 0 c 0.488,0.282 0.928,0.305 1.246,0.118 v 0 l -0.981,0.566 c -0.134,0.079 -0.291,0.121 -0.464,0.121 v 0 c -0.236,0 -0.501,-0.077 -0.782,-0.239"
				style="fill:url(#linearGradient7724);stroke:none"
				id="path7726" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7728">
	   <g
		  id="g7730"
		  clip-path="url(#clipPath7734)">
		 <g
			id="g7736"
			transform="translate(458.6311,134.8557)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7738" />
		 </g>
		 <g
			id="g7740"
			transform="translate(454.0859,138.1459)">
		   <path
			  d="m 0,0 -1.021,-0.589 -0.002,0.435 1.02,0.589 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7742" />
		 </g>
		 <g
			id="g7744"
			transform="translate(454.0833,138.5811)">
		   <path
			  d="M 0,0 -1.638,0.95 -2.656,0.361 -1.02,-0.589 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7746" />
		 </g>
		 <g
			id="g7748"
			transform="translate(453.0629,137.992)">
		   <path
			  d="m 0,0 -1.636,0.951 0.003,-0.436 1.636,-0.95 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7750" />
		 </g>
		 <g
			id="g7752"
			transform="translate(449.6017,123.9575)">
		   <path
			  d="m 0,0 -4.456,-2.573 -0.011,1.002 4.456,2.573 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7754" />
		 </g>
		 <g
			id="g7756"
			transform="translate(449.5906,124.9592)">
		   <path
			  d="m 0,0 -7.792,5.119 -4.964,-2.869 8.3,-4.823 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7758" />
		 </g>
		 <g
			id="g7760"
			transform="translate(445.1347,122.3864)">
		   <path
			  d="M 0,0 -8.3,4.823 -8.29,3.821 0.011,-1.002 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7762" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7764">
	   <g
		  id="g7766">
		 <g
			id="g7772">
		   <g
			  id="g7774">
			 <path
				d="m 439.561,132.045 c -0.608,-0.35 -1.143,-1.018 -1.458,-1.745 v 0 c -0.189,-0.434 -0.298,-0.886 -0.299,-1.308 v 0 c 0.001,-0.561 0.198,-0.954 0.514,-1.141 v 0 h -10e-4 l 0.067,-0.038 2.797,-1.642 c -0.316,0.186 -0.513,0.58 -0.514,1.141 v 0 c 0.001,0.421 0.111,0.874 0.299,1.308 v 0 c 0.315,0.727 0.85,1.395 1.458,1.745 v 0 c 0.488,0.282 0.929,0.305 1.246,0.118 v 0 l -2.864,1.68 c -0.134,0.08 -0.29,0.121 -0.464,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.781,-0.239"
				style="fill:url(#linearGradient7790);stroke:none"
				id="path7792" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7794">
	   <g
		  id="g7796"
		  clip-path="url(#clipPath7800)">
		 <g
			id="g7802"
			transform="translate(442.4243,130.3657)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.928 -1.756,-3.055 0,-1.126 0.786,-1.586 1.756,-1.026 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7804" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g7806">
	   <g
		  id="g7808">
		 <g
			id="g7814">
		   <g
			  id="g7816">
			 <path
				d="m 442.574,129.125 c -0.261,-0.15 -0.49,-0.438 -0.626,-0.75 v 0 c -0.081,-0.186 -0.128,-0.381 -0.128,-0.561 v 0 c 0,-0.242 0.085,-0.411 0.22,-0.491 v 0 l 0.028,-0.016 4.263,-2.47 v 10e-4 c -0.136,0.08 -0.221,0.249 -0.221,0.49 v 0 c 0,0.181 0.047,0.376 0.128,0.562 v 0 c 0.136,0.312 0.365,0.599 0.626,0.75 v 0 c 0.21,0.12 0.399,0.13 0.536,0.05 v 0 l -4.29,2.485 c -0.058,0.035 -0.126,0.053 -0.2,0.053 v 0 c -0.101,0 -0.215,-0.033 -0.336,-0.103"
				style="fill:url(#linearGradient7832);stroke:none"
				id="path7834" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7836">
	   <g
		  id="g7838">
		 <g
			id="g7844">
		   <g
			  id="g7846">
			 <path
				d="m 446.864,126.64 c -0.416,-0.24 -0.754,-0.828 -0.754,-1.312 v 0 c 0,-0.484 0.338,-0.682 0.754,-0.441 v 0 c 0.417,0.24 0.756,0.828 0.756,1.312 v 0 c 0,0.344 -0.172,0.543 -0.42,0.543 v 0 c -0.101,0 -0.215,-0.033 -0.336,-0.102"
				style="fill:url(#linearGradient7862);stroke:none"
				id="path7864" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7866">
	   <g
		  id="g7868">
		 <g
			id="g7874">
		   <g
			  id="g7876">
			 <path
				d="m 445.883,128.37 c -0.607,-0.351 -1.143,-1.019 -1.457,-1.745 v 0 c -0.189,-0.434 -0.298,-0.888 -0.298,-1.309 v 0 c 0,-0.562 0.197,-0.954 0.513,-1.14 v 0 -0.001 l 0.065,-0.038 0.915,-0.528 v 0.001 c -0.315,0.186 -0.513,0.578 -0.513,1.14 v 0 c 0,0.421 0.109,0.875 0.298,1.309 v 0 c 0.315,0.727 0.851,1.394 1.458,1.745 v 0 c 0.481,0.277 0.917,0.303 1.233,0.124 v 0 l -0.967,0.559 c -0.135,0.079 -0.292,0.121 -0.466,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.781,-0.238 m 2.227,-0.449 c -0.004,0.002 -0.009,0.005 -0.013,0.007 v 0 z"
				style="fill:url(#linearGradient7892);stroke:none"
				id="path7894" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7896">
	   <g
		  id="g7898"
		  clip-path="url(#clipPath7902)">
		 <g
			id="g7904"
			transform="translate(446.8646,127.8037)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7906" />
		 </g>
		 <g
			id="g7908"
			transform="translate(442.3193,131.0939)">
		   <path
			  d="m 0,0 -1.02,-0.589 -0.003,0.435 1.02,0.589 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7910" />
		 </g>
		 <g
			id="g7912"
			transform="translate(442.3168,131.5291)">
		   <path
			  d="M 0,0 -1.638,0.95 -2.656,0.361 -1.02,-0.589 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7914" />
		 </g>
		 <g
			id="g7916"
			transform="translate(441.2964,130.94)">
		   <path
			  d="m 0,0 -1.636,0.951 0.003,-0.436 1.636,-0.95 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path7918" />
		 </g>
		 <g
			id="g7920"
			transform="translate(381.3441,120.2479)"
			style="display:inline">
		   <path
			  d="M 0,0 -23.771,-13.727 -47.533,0.164 -89.166,-24.048 -53.476,-44.59"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  data-duration="5000"
			  data-delay="1000"
			  id="path7922"
			  sodipodi:nodetypes="ccccc"
			  inkscape:label="path7922" />

			  <circle r="2" cx="0" cy="0" class="path7922-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path7922-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path7922-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path7922-circle circle"/>

		   <path
			  d="m -53.555463,-44.617345 47.6869993,27.532 -11.8919993,6.848"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  data-duration="4000"
			  data-delay="1500"
			  id="path7922-7"
			  sodipodi:nodetypes="ccc" />
			  
			  <circle r="2" cx="0" cy="0" class="path7922-7-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path7922-7-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path7922-7-circle circle reverse"/>
			  <circle r="2" cx="0" cy="0" class="path7922-7-circle circle reverse"/>

		 </g>
	   </g>
	 </g>
	 <g
		id="g7924">
	   <g
		  id="g7926">
		 <g
			id="g7932">
		   <g
			  id="g7934">
			 <path
				d="m 300.644,108.094 h -0.574 v -3.251 c 0,-3.185 4.473,-5.768 9.99,-5.768 v 0 c 5.517,0 9.989,2.583 9.989,5.768 v 0 1.321 1.93 h -0.573 c -1.376,2.235 -5.072,3.837 -9.416,3.837 v 0 c -4.345,0 -8.042,-1.602 -9.416,-3.837"
				style="fill:url(#linearGradient7950);stroke:none"
				id="path7952" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7954">
	   <g
		  id="g7956">
		 <g
			id="g7962">
		   <g
			  id="g7964">
			 <path
				d="m 301.735,108.116 c 0,-2.654 3.728,-4.806 8.325,-4.806 v 0 c 4.597,0 8.324,2.152 8.324,4.806 v 0 c 0,2.655 -3.727,4.807 -8.324,4.807 v 0 c -4.597,0 -8.325,-2.152 -8.325,-4.807"
				style="fill:url(#linearGradient7976);stroke:none"
				id="path7978" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g7980">
	   <g
		  id="g7982">
		 <g
			id="g7988">
		   <g
			  id="g7990">
			 <path
				d="m 301.735,107.22 c 0,-2.654 3.728,-4.806 8.325,-4.806 v 0 c 4.597,0 8.324,2.152 8.324,4.806 v 0 c 0,2.655 -3.727,4.807 -8.324,4.807 v 0 c -4.597,0 -8.325,-2.152 -8.325,-4.807"
				style="fill:url(#linearGradient7998);stroke:none"
				id="path8000" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8002">
	   <g
		  id="g8004">
		 <g
			id="g8010">
		   <g
			  id="g8012">
			 <path
				d="m 300.07,108.116 c 0,-3.185 4.473,-5.767 9.99,-5.767 v 0 c 5.517,0 9.989,2.582 9.989,5.767 v 0 c 0,3.186 -4.472,5.768 -9.989,5.768 v 0 c -5.517,0 -9.99,-2.582 -9.99,-5.768 m 1.665,0 c 0,2.655 3.728,4.807 8.325,4.807 v 0 c 4.597,0 8.324,-2.152 8.324,-4.807 v 0 c 0,-2.654 -3.727,-4.806 -8.324,-4.806 v 0 c -4.597,0 -8.325,2.152 -8.325,4.806"
				style="fill:url(#linearGradient8024);stroke:none"
				id="path8026" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8028">
	   <g
		  id="g8030">
		 <g
			id="g8036">
		   <g
			  id="g8038">
			 <path
				d="M 336.273,87.564 H 335.7 v -3.251 c 0,-3.185 4.472,-5.767 9.989,-5.767 v 0 c 5.517,0 9.989,2.582 9.989,5.767 v 0 1.321 1.93 h -0.573 c -1.374,2.236 -5.072,3.837 -9.416,3.837 v 0 c -4.345,0 -8.041,-1.601 -9.416,-3.837"
				style="fill:url(#linearGradient8054);stroke:none"
				id="path8056" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8058">
	   <g
		  id="g8060">
		 <g
			id="g8066">
		   <g
			  id="g8068">
			 <path
				d="m 337.364,87.587 c 0,-2.655 3.728,-4.807 8.325,-4.807 v 0 c 4.597,0 8.324,2.152 8.324,4.807 v 0 c 0,2.654 -3.727,4.806 -8.324,4.806 v 0 c -4.597,0 -8.325,-2.152 -8.325,-4.806"
				style="fill:url(#linearGradient8080);stroke:none"
				id="path8082" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8084">
	   <g
		  id="g8086">
		 <g
			id="g8092">
		   <g
			  id="g8094">
			 <path
				d="m 337.364,86.691 c 0,-2.655 3.728,-4.807 8.325,-4.807 v 0 c 4.597,0 8.324,2.152 8.324,4.807 v 0 c 0,2.654 -3.727,4.806 -8.324,4.806 v 0 c -4.597,0 -8.325,-2.152 -8.325,-4.806"
				style="fill:url(#linearGradient8102);stroke:none"
				id="path8104" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8106">
	   <g
		  id="g8108">
		 <g
			id="g8114">
		   <g
			  id="g8116">
			 <path
				d="m 335.7,87.587 c 0,-3.186 4.472,-5.768 9.989,-5.768 v 0 c 5.517,0 9.989,2.582 9.989,5.768 v 0 c 0,3.185 -4.472,5.767 -9.989,5.767 v 0 c -5.517,0 -9.989,-2.582 -9.989,-5.767 m 1.664,0 c 0,2.654 3.728,4.806 8.325,4.806 v 0 c 4.597,0 8.324,-2.152 8.324,-4.806 v 0 c 0,-2.655 -3.727,-4.807 -8.324,-4.807 v 0 c -4.597,0 -8.325,2.152 -8.325,4.807"
				style="fill:url(#linearGradient8128);stroke:none"
				id="path8130" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8132">
	   <g
		  id="g8134"
		  clip-path="url(#clipPath8138)">
		 <g
			id="g8140"
			transform="translate(327.8681,75.6574)">
		   <path
			  d="M 0,0 -11.871,-6.853"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path8142" />
		 </g>
		 <g
			id="g8144"
			transform="translate(315.9768,82.5163)">
		   <path
			  d="M 0,0 -11.871,-6.853"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path8146" />
		 </g>
		 <g
			id="g8148"
			transform="translate(304.0314,89.3774)">
		   <path
			  d="M 0,0 -11.762,-6.858"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path8150" />
		 </g>
		 <g
			id="g8152"
			transform="translate(500.1042,106.5178)">
		   <path
			  d="M 0,0 -17.81,-10.283 26.851,-35.977"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path8154" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g8156">
	   <g
		  id="g8158">
		 <g
			id="g8164">
		   <g
			  id="g8166">
			 <path
				d="m 502.573,81.449 h -0.574 v -3.251 c 0,-3.185 4.473,-5.767 9.99,-5.767 v 0 c 5.516,0 9.989,2.582 9.989,5.767 v 0 1.322 1.929 h -0.573 c -1.375,2.237 -5.071,3.839 -9.416,3.839 v 0 c -4.345,0 -8.041,-1.602 -9.416,-3.839"
				style="fill:url(#linearGradient8182);stroke:none"
				id="path8184" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8186">
	   <g
		  id="g8188">
		 <g
			id="g8194">
		   <g
			  id="g8196">
			 <path
				d="m 503.664,81.473 c 0,-2.655 3.727,-4.806 8.325,-4.806 v 0 c 4.597,0 8.324,2.151 8.324,4.806 v 0 c 0,2.654 -3.727,4.806 -8.324,4.806 v 0 c -4.598,0 -8.325,-2.152 -8.325,-4.806"
				style="fill:url(#linearGradient8208);stroke:none"
				id="path8210" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8212">
	   <g
		  id="g8214">
		 <g
			id="g8220">
		   <g
			  id="g8222">
			 <path
				d="m 503.664,80.576 c 0,-2.654 3.727,-4.806 8.325,-4.806 v 0 c 4.597,0 8.324,2.152 8.324,4.806 v 0 c 0,2.654 -3.727,4.806 -8.324,4.806 v 0 c -4.598,0 -8.325,-2.152 -8.325,-4.806"
				style="fill:url(#linearGradient8230);stroke:none"
				id="path8232" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8234">
	   <g
		  id="g8236">
		 <g
			id="g8242">
		   <g
			  id="g8244">
			 <path
				d="m 501.999,81.473 c 0,-3.186 4.473,-5.768 9.99,-5.768 v 0 c 5.516,0 9.989,2.582 9.989,5.768 v 0 c 0,3.185 -4.473,5.767 -9.989,5.767 v 0 c -5.517,0 -9.99,-2.582 -9.99,-5.767 m 1.665,0 c 0,2.654 3.727,4.806 8.325,4.806 v 0 c 4.597,0 8.324,-2.152 8.324,-4.806 v 0 c 0,-2.655 -3.727,-4.806 -8.324,-4.806 v 0 c -4.598,0 -8.325,2.151 -8.325,4.806"
				style="fill:url(#linearGradient8256);stroke:none"
				id="path8258" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8260">
	   <g
		  id="g8262">
		 <g
			id="g8268">
		   <g
			  id="g8270">
			 <path
				d="m 570.162,83.886 h -0.467 v -2.643 c 0,-2.59 3.637,-4.69 8.124,-4.69 v 0 c 4.486,0 8.123,2.1 8.123,4.69 v 0 1.075 1.568 h -0.466 c -1.119,1.819 -4.124,3.122 -7.657,3.122 v 0 c -3.534,0 -6.54,-1.303 -7.657,-3.122"
				style="fill:url(#linearGradient8286);stroke:none"
				id="path8288" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8290">
	   <g
		  id="g8292">
		 <g
			id="g8298">
		   <g
			  id="g8300">
			 <path
				d="m 571.049,83.905 c 0,-2.158 3.031,-3.908 6.77,-3.908 v 0 c 3.738,0 6.769,1.75 6.769,3.908 v 0 c 0,2.159 -3.031,3.909 -6.769,3.909 v 0 c -3.739,0 -6.77,-1.75 -6.77,-3.909"
				style="fill:url(#linearGradient8312);stroke:none"
				id="path8314" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8316">
	   <g
		  id="g8318">
		 <g
			id="g8324">
		   <g
			  id="g8326">
			 <path
				d="m 571.049,83.176 c 0,-2.158 3.031,-3.908 6.77,-3.908 v 0 c 3.738,0 6.769,1.75 6.769,3.908 v 0 c 0,2.159 -3.031,3.909 -6.769,3.909 v 0 c -3.739,0 -6.77,-1.75 -6.77,-3.909"
				style="fill:url(#linearGradient8334);stroke:none"
				id="path8336" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8338">
	   <g
		  id="g8340">
		 <g
			id="g8346">
		   <g
			  id="g8348">
			 <path
				d="m 569.695,83.905 c 0,-2.59 3.637,-4.69 8.124,-4.69 v 0 c 4.486,0 8.123,2.1 8.123,4.69 v 0 c 0,2.59 -3.637,4.69 -8.123,4.69 v 0 c -4.487,0 -8.124,-2.1 -8.124,-4.69 m 1.354,0 c 0,2.159 3.031,3.909 6.77,3.909 v 0 c 3.738,0 6.769,-1.75 6.769,-3.909 v 0 c 0,-2.158 -3.031,-3.908 -6.769,-3.908 v 0 c -3.739,0 -6.77,1.75 -6.77,3.908"
				style="fill:url(#linearGradient8360);stroke:none"
				id="path8362" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8364">
	   <g
		  id="g8366"
		  clip-path="url(#clipPath8370)">
		 <g
			id="g8372"
			transform="translate(520.8793,145.9444)">
		   <path
			  d="M 0,0 35.662,-20.559 -8.89,-46.281"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  data-duration="3000"
			  data-delay="1000"
				  
			  id="path8374" />

			  <circle r="2" cx="0" cy="0" class="path8374-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path8374-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path8374-circle circle"/>
			  <circle r="2" cx="0" cy="0" class="path8374-circle circle"/>

		 </g>
		 <g
			id="g8376"
			transform="translate(482.977,167.7945)">
		   <path
			  d="M 0,0 14.127,-8.145"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path8378" />
		   
		 </g>
		 <g
			id="g8380"
			transform="translate(515.0699,142.6062)"
			style="display:inline">
		   <path
			  d="m -17.694454,16.995217 5.612562,3.240525 23.757,-13.7159996 -5.5267042,-3.1909527"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line"
			  id="path8382"
			  sodipodi:nodetypes="cccc" />
		   <path
			  d="M 6.3100502,3.4057473 -0.04124564,-0.26129977 -23.798246,13.4547 l 6.265438,3.617474"
			  style="display:inline;fill:none;stroke:#ffffff;stroke-width:1"
			  class="line-reverse"
			  id="path8382-1"
			  sodipodi:nodetypes="cccc" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g8384">
	   <g
		  id="g8386">
		 <g
			id="g8392">
		   <g
			  id="g8394">
			 <path
				d="m 444.092,179.968 32.192,-18.58 16.092,9.301 -32.372,18.112 z"
				style="fill:url(#radialGradient8406);stroke:none"
				id="path8408" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8410">
	   <g
		  id="g8412">
		 <g
			id="g8418">
		   <g
			  id="g8420">
			 <path
				d="m 444.099,188.181 v -3.514 l 15.911,9.405 v 3.519 z"
				style="fill:url(#linearGradient8436);stroke:none"
				id="path8438" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8440">
	   <g
		  id="g8442">
		 <g
			id="g8448">
		   <g
			  id="g8450">
			 <path
				d="m 444.099,193.696 v -3.513 l 15.911,9.154 v 3.519 z"
				style="fill:url(#linearGradient8466);stroke:none"
				id="path8468" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8470">
	   <g
		  id="g8472">
		 <g
			id="g8478">
		   <g
			  id="g8480">
			 <path
				d="m 444.099,182.915 v -2.935 l 15.911,8.827 v 3.519 z"
				style="fill:url(#linearGradient8496);stroke:none"
				id="path8498" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8500">
	   <g
		  id="g8502">
		 <g
			id="g8508">
		   <g
			  id="g8510">
			 <path
				d="m 460.01,194.065 32.373,-18.113 v 3.516 l -32.373,18.113 z"
				style="fill:url(#linearGradient8522);stroke:none"
				id="path8524" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8526">
	   <g
		  id="g8528">
		 <g
			id="g8534">
		   <g
			  id="g8536">
			 <path
				d="m 460.01,199.331 32.373,-18.114 v 3.516 l -32.373,18.114 z"
				style="fill:url(#linearGradient8548);stroke:none"
				id="path8550" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8552">
	   <g
		  id="g8554">
		 <g
			id="g8560">
		   <g
			  id="g8562">
			 <path
				d="m 460.01,188.8 32.373,-18.114 v 3.516 l -32.373,18.114 z"
				style="fill:url(#linearGradient8574);stroke:none"
				id="path8576" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8578">
	   <g
		  id="g8580"
		  clip-path="url(#clipPath8584)">
		 <g
			id="g8586">
		   <g
			  id="g8588" />
		   <g
			  id="g8622">
			 <g
				clip-path="url(#clipPath8592)"
				opacity="0.600006"
				id="g8620">
			   <g
				  id="g8618">
				 <g
					id="g8616">
				   <g
					  id="g8614">
					 <g
						id="g8612">
					   <path
						  d="m 460.016,202.847 -0.006,-14.028 32.364,-18.128 v 14.048 z"
						  style="fill:url(#radialGradient8608);stroke:none"
						  id="path8610" />
					 </g>
				   </g>
				 </g>
			   </g>
			 </g>
		   </g>
		 </g>
		 <g
			id="g8624">
		   <g
			  id="g8626" />
		   <g
			  id="g8660">
			 <g
				clip-path="url(#clipPath8630)"
				opacity="0.600006"
				id="g8658">
			   <g
				  id="g8656">
				 <g
					id="g8654">
				   <g
					  id="g8652">
					 <g
						id="g8650">
					   <path
						  d="m 460.005,202.857 0.005,-14.029 -15.921,-8.842 v 13.72 z"
						  style="fill:url(#radialGradient8646);stroke:none"
						  id="path8648" />
					 </g>
				   </g>
				 </g>
			   </g>
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8662">
	   <g
		  id="g8664">
		 <g
			id="g8670">
		   <g
			  id="g8672">
			 <path
				d="m 444.092,184.667 32.192,-18.004 v 3.516 l -32.192,18.005 z"
				style="fill:url(#linearGradient8684);stroke:none"
				id="path8686" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8688">
	   <g
		  id="g8690">
		 <g
			id="g8696">
		   <g
			  id="g8698">
			 <path
				d="m 444.092,179.97 32.192,-18.582 v 3.516 l -32.192,18.005 z"
				style="fill:url(#linearGradient8710);stroke:none"
				id="path8712" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8714">
	   <g
		  id="g8716">
		 <g
			id="g8722">
		   <g
			  id="g8724">
			 <path
				d="m 444.092,189.932 32.192,-18.253 v 3.765 l -32.192,18.255 z"
				style="fill:url(#linearGradient8736);stroke:none"
				id="path8738" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8740">
	   <g
		  id="g8742">
		 <g
			id="g8748">
		   <g
			  id="g8750">
			 <path
				d="m 476.284,175.432 v -3.513 l 16.092,9.294 v 3.519 z"
				style="fill:url(#linearGradient8762);stroke:none"
				id="path8764" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8766">
	   <g
		  id="g8768">
		 <g
			id="g8774">
		   <g
			  id="g8776">
			 <path
				d="m 476.284,170.18 v -3.513 l 16.092,9.295 v 3.519 z"
				style="fill:url(#linearGradient8788);stroke:none"
				id="path8790" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8792">
	   <g
		  id="g8794">
		 <g
			id="g8800">
		   <g
			  id="g8802">
			 <path
				d="m 476.284,164.901 v -3.513 l 16.092,9.294 v 3.519 z"
				style="fill:url(#linearGradient8814);stroke:none"
				id="path8816" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8818">
	   <g
		  id="g8820"
		  clip-path="url(#clipPath8824)">
		 <g
			id="g8826">
		   <g
			  id="g8828" />
		   <g
			  id="g8862">
			 <g
				clip-path="url(#clipPath8832)"
				opacity="0.660004"
				id="g8860">
			   <g
				  id="g8858">
				 <g
					id="g8856">
				   <g
					  id="g8854">
					 <g
						id="g8852">
					   <path
						  d="m 492.377,184.735 -32.373,18.112 -15.912,-9.161 32.193,-18.252 z"
						  style="fill:url(#radialGradient8848);stroke:none"
						  id="path8850" />
					 </g>
				   </g>
				 </g>
			   </g>
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8864">
	   <g
		  id="g8866">
		 <g
			id="g8872">
		   <g
			  id="g8874">
			 <path
				d="m 393.216,140.825 23.749,-13.727 47.512,27.428 -23.483,13.883 z"
				style="fill:url(#radialGradient8886);stroke:none"
				id="path8888" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8890">
	   <g
		  id="g8892">
		 <g
			id="g8898">
		   <g
			  id="g8900">
			 <path
				d="m 440.984,176.188 23.484,-13.873 v 5.184 l -23.484,13.883 z"
				style="fill:url(#linearGradient8916);stroke:none"
				id="path8918" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8920">
	   <g
		  id="g8922">
		 <g
			id="g8928">
		   <g
			  id="g8930">
			 <path
				d="m 440.984,183.959 23.484,-13.873 v 5.184 l -23.484,13.883 z"
				style="fill:url(#linearGradient8946);stroke:none"
				id="path8948" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8950">
	   <g
		  id="g8952">
		 <g
			id="g8958">
		   <g
			  id="g8960">
			 <path
				d="m 440.984,168.417 23.484,-13.873 v 5.184 l -23.484,13.883 z"
				style="fill:url(#linearGradient8976);stroke:none"
				id="path8978" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g8980">
	   <g
		  id="g8982">
		 <g
			id="g8988">
		   <g
			  id="g8990">
			 <path
				d="m 393.206,153.781 v -5.189 l 47.778,27.587 v 5.189 z"
				style="fill:url(#linearGradient9002);stroke:none"
				id="path9004" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9006">
	   <g
		  id="g9008">
		 <g
			id="g9014">
		   <g
			  id="g9016">
			 <path
				d="m 393.206,161.553 v -5.191 l 47.778,27.587 v 5.19 z"
				style="fill:url(#linearGradient9028);stroke:none"
				id="path9030" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9032">
	   <g
		  id="g9034">
		 <g
			id="g9040">
		   <g
			  id="g9042">
			 <path
				d="m 393.206,146.011 v -5.19 l 47.778,27.587 v 5.189 z"
				style="fill:url(#linearGradient9054);stroke:none"
				id="path9056" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9058">
	   <g
		  id="g9060"
		  clip-path="url(#clipPath9064)">
		 <g
			id="g9066">
		   <g
			  id="g9068" />
		   <g
			  id="g9102">
			 <g
				clip-path="url(#clipPath9072)"
				opacity="0.600006"
				id="g9100">
			   <g
				  id="g9098">
				 <g
					id="g9096">
				   <g
					  id="g9094">
					 <g
						id="g9092">
					   <path
						  d="m 440.976,189.139 0.008,-20.703 -47.764,-27.608 v 20.733 z"
						  style="fill:url(#radialGradient9088);stroke:none"
						  id="path9090" />
					 </g>
				   </g>
				 </g>
			   </g>
			 </g>
		   </g>
		 </g>
		 <g
			id="g9104">
		   <g
			  id="g9106" />
		   <g
			  id="g9140">
			 <g
				clip-path="url(#clipPath9110)"
				opacity="0.600006"
				id="g9138">
			   <g
				  id="g9136">
				 <g
					id="g9134">
				   <g
					  id="g9132">
					 <g
						id="g9130">
					   <path
						  d="m 440.992,189.153 -0.008,-20.704 23.498,-13.898 v 20.734 z"
						  style="fill:url(#radialGradient9126);stroke:none"
						  id="path9128" />
					 </g>
				   </g>
				 </g>
			   </g>
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9142">
	   <g
		  id="g9144">
		 <g
			id="g9150">
		   <g
			  id="g9152">
			 <path
				d="m 416.965,140.072 v -5.189 l 47.512,27.431 v 5.189 z"
				style="fill:url(#linearGradient9164);stroke:none"
				id="path9166" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9168">
	   <g
		  id="g9170">
		 <g
			id="g9176">
		   <g
			  id="g9178">
			 <path
				d="m 416.965,132.287 v -5.189 l 47.512,27.431 v 5.189 z"
				style="fill:url(#linearGradient9190);stroke:none"
				id="path9192" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9194">
	   <g
		  id="g9196">
		 <g
			id="g9202">
		   <g
			  id="g9204">
			 <path
				d="m 416.965,147.843 v -5.189 l 47.512,27.431 v 5.189 z"
				style="fill:url(#linearGradient9216);stroke:none"
				id="path9218" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9220">
	   <g
		  id="g9222">
		 <g
			id="g9228">
		   <g
			  id="g9230">
			 <path
				d="m 393.216,156.357 23.749,-13.717 v 5.184 l -23.749,13.727 z"
				style="fill:url(#linearGradient9242);stroke:none"
				id="path9244" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9246">
	   <g
		  id="g9248">
		 <g
			id="g9254">
		   <g
			  id="g9256">
			 <path
				d="m 393.216,148.607 23.749,-13.718 v 5.185 l -23.749,13.727 z"
				style="fill:url(#linearGradient9268);stroke:none"
				id="path9270" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9272">
	   <g
		  id="g9274">
		 <g
			id="g9280">
		   <g
			  id="g9282">
			 <path
				d="m 393.216,140.816 23.749,-13.718 v 5.185 l -23.749,13.726 z"
				style="fill:url(#linearGradient9294);stroke:none"
				id="path9296" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9298">
	   <g
		  id="g9300"
		  clip-path="url(#clipPath9304)">
		 <g
			id="g9306">
		   <g
			  id="g9308" />
		   <g
			  id="g9342">
			 <g
				clip-path="url(#clipPath9312)"
				opacity="0.660004"
				id="g9340">
			   <g
				  id="g9338">
				 <g
					id="g9336">
				   <g
					  id="g9334">
					 <g
						id="g9332">
					   <path
						  d="m 393.216,161.555 47.777,27.584 23.485,-13.882 -47.513,-27.429 z"
						  style="fill:url(#radialGradient9328);stroke:none"
						  id="path9330" />
					 </g>
				   </g>
				 </g>
			   </g>
			 </g>
		   </g>
		 </g>
		 <g
			id="g9344"
			transform="translate(510.081,147.9726)">
		   <path
			  d="m 0,0 -4.456,-2.573 -0.011,1.002 4.456,2.573 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9346" />
		 </g>
		 <g
			id="g9348"
			transform="translate(510.0699,148.9743)">
		   <path
			  d="m 0,0 -7.792,5.119 -4.964,-2.869 8.3,-4.823 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9350" />
		 </g>
		 <g
			id="g9352"
			transform="translate(505.614,146.4015)">
		   <path
			  d="M 0,0 -8.3,4.823 -8.29,3.821 0.011,-1.002 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9354" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g9356">
	   <g
		  id="g9358">
		 <g
			id="g9364">
		   <g
			  id="g9366">
			 <path
				d="m 500.04,156.061 c -0.608,-0.351 -1.143,-1.018 -1.458,-1.745 v 0 c -0.188,-0.434 -0.298,-0.888 -0.299,-1.309 v 0 c 10e-4,-0.561 0.198,-0.954 0.514,-1.14 v 0 l -0.001,-10e-4 0.067,-0.038 2.797,-1.642 v 0.001 c -0.316,0.186 -0.513,0.578 -0.513,1.14 v 0 c 0,0.421 0.11,0.875 0.299,1.308 v 0 c 0.314,0.728 0.849,1.395 1.457,1.746 v 0 c 0.488,0.281 0.929,0.304 1.246,0.117 v 0 l -2.863,1.68 c -0.135,0.079 -0.292,0.121 -0.466,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.78,-0.238"
				style="fill:url(#linearGradient9382);stroke:none"
				id="path9384" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9386">
	   <g
		  id="g9388"
		  clip-path="url(#clipPath9392)">
		 <g
			id="g9394"
			transform="translate(502.9036,154.3808)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.928 -1.756,-3.055 0,-1.126 0.786,-1.586 1.756,-1.026 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9396" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g9398">
	   <g
		  id="g9400">
		 <g
			id="g9406">
		   <g
			  id="g9408">
			 <path
				d="m 503.053,153.14 c -0.26,-0.151 -0.49,-0.437 -0.625,-0.749 v 0 c -0.082,-0.188 -0.129,-0.382 -0.129,-0.563 v 0 c 0,-0.241 0.085,-0.409 0.221,-0.49 v 0 l 0.028,-0.016 4.262,-2.469 c -0.136,0.08 -0.221,0.249 -0.221,0.49 v 0 c 0,0.181 0.047,0.376 0.128,0.562 v 0 c 0.136,0.312 0.365,0.599 0.627,0.75 v 0 c 0.209,0.121 0.399,0.131 0.535,0.05 v 0 l -4.29,2.486 c -0.058,0.034 -0.125,0.052 -0.2,0.052 v 0 c -0.101,0 -0.215,-0.033 -0.336,-0.103"
				style="fill:url(#linearGradient9424);stroke:none"
				id="path9426" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9428">
	   <g
		  id="g9430">
		 <g
			id="g9436">
		   <g
			  id="g9438">
			 <path
				d="m 507.344,150.655 c -0.416,-0.241 -0.755,-0.828 -0.755,-1.313 v 0 c 0,-0.484 0.339,-0.681 0.755,-0.441 v 0 c 0.417,0.242 0.755,0.828 0.755,1.313 v 0 c 0,0.344 -0.171,0.543 -0.419,0.543 v 0 c -0.101,0 -0.215,-0.033 -0.336,-0.102"
				style="fill:url(#linearGradient9454);stroke:none"
				id="path9456" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9458">
	   <g
		  id="g9460">
		 <g
			id="g9466">
		   <g
			  id="g9468">
			 <path
				d="m 506.363,152.384 c -0.607,-0.35 -1.143,-1.017 -1.458,-1.745 v 0 c -0.189,-0.434 -0.298,-0.886 -0.298,-1.307 v 0 c 0,-0.562 0.197,-0.955 0.513,-1.142 v 0 l 0.066,-0.038 0.914,-0.528 h 10e-4 c -0.316,0.187 -0.513,0.58 -0.514,1.142 v 0 c 0.001,0.421 0.11,0.873 0.298,1.307 v 0 c 0.316,0.727 0.851,1.395 1.459,1.745 v 0 c 0.487,0.282 0.928,0.305 1.246,0.118 v 0 l -0.981,0.566 c -0.135,0.08 -0.292,0.121 -0.465,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.781,-0.239"
				style="fill:url(#linearGradient9484);stroke:none"
				id="path9486" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9488">
	   <g
		  id="g9490"
		  clip-path="url(#clipPath9494)">
		 <g
			id="g9496"
			transform="translate(507.3439,151.8189)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.927 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9498" />
		 </g>
		 <g
			id="g9500"
			transform="translate(502.7986,155.109)">
		   <path
			  d="m 0,0 -1.02,-0.589 -0.003,0.435 1.02,0.589 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9502" />
		 </g>
		 <g
			id="g9504"
			transform="translate(502.7961,155.5442)">
		   <path
			  d="M 0,0 -1.638,0.95 -2.656,0.361 -1.02,-0.589 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9506" />
		 </g>
		 <g
			id="g9508"
			transform="translate(501.7757,154.9551)">
		   <path
			  d="m 0,0 -1.636,0.951 0.003,-0.436 1.636,-0.95 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9510" />
		 </g>
		 <g
			id="g9512"
			transform="translate(520.4591,155.2217)">
		   <path
			  d="m 0,0 -4.456,-2.573 -0.011,1.002 4.456,2.573 z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9514" />
		 </g>
		 <g
			id="g9516"
			transform="translate(520.4479,156.2233)">
		   <path
			  d="m 0,0 -7.792,5.119 -4.964,-2.869 8.3,-4.823 z"
			  style="fill:#919394;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9518" />
		 </g>
		 <g
			id="g9520"
			transform="translate(515.9921,153.6505)">
		   <path
			  d="M 0,0 -8.3,4.823 -8.29,3.821 0.011,-1.002 Z"
			  style="fill:#6d6f70;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9522" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g9524">
	   <g
		  id="g9526">
		 <g
			id="g9532">
		   <g
			  id="g9534">
			 <path
				d="m 510.418,163.309 c -0.608,-0.35 -1.143,-1.017 -1.458,-1.745 v 0 c -0.188,-0.433 -0.298,-0.886 -0.298,-1.307 v 0 c 0,-0.562 0.197,-0.955 0.513,-1.142 v 0 l 0.066,-0.038 2.797,-1.642 c -0.316,0.186 -0.512,0.58 -0.513,1.141 v 0 c 0,0.422 0.11,0.874 0.299,1.308 v 0 c 0.315,0.727 0.85,1.395 1.458,1.745 v 0 c 0.487,0.282 0.928,0.305 1.245,0.118 v 0 l -2.863,1.68 c -0.134,0.08 -0.291,0.121 -0.464,0.121 v 0 c -0.236,0 -0.501,-0.076 -0.782,-0.239"
				style="fill:url(#linearGradient9550);stroke:none"
				id="path9552" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9554">
	   <g
		  id="g9556"
		  clip-path="url(#clipPath9560)">
		 <g
			id="g9562"
			transform="translate(513.2817,161.6298)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.928 -1.756,-3.054 0,-1.127 0.786,-1.587 1.756,-1.027 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9564" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g9566">
	   <g
		  id="g9568">
		 <g
			id="g9574">
		   <g
			  id="g9576">
			 <path
				d="m 513.432,160.389 c -0.261,-0.15 -0.491,-0.437 -0.626,-0.75 v 0 c -0.081,-0.186 -0.129,-0.38 -0.129,-0.561 v 0 c 0,-0.242 0.084,-0.411 0.22,-0.491 v 0 l 0.029,-0.017 4.262,-2.469 v 0.001 c -0.136,0.08 -0.221,0.249 -0.221,0.49 v 0 c 0,0.181 0.048,0.376 0.129,0.562 v 0 c 0.135,0.313 0.365,0.599 0.626,0.75 v 0 c 0.209,0.121 0.399,0.131 0.535,0.05 v 0 l -4.29,2.485 c -0.058,0.035 -0.125,0.053 -0.2,0.053 v 0 c -0.101,0 -0.215,-0.033 -0.335,-0.103"
				style="fill:url(#linearGradient9592);stroke:none"
				id="path9594" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9596">
	   <g
		  id="g9598">
		 <g
			id="g9604">
		   <g
			  id="g9606">
			 <path
				d="m 517.722,157.904 c -0.416,-0.24 -0.755,-0.828 -0.755,-1.312 v 0 c 0,-0.484 0.339,-0.682 0.755,-0.441 v 0 c 0.417,0.24 0.755,0.828 0.755,1.312 v 0 c 0,0.344 -0.171,0.543 -0.419,0.543 v 0 c -0.101,0 -0.215,-0.032 -0.336,-0.102"
				style="fill:url(#linearGradient9622);stroke:none"
				id="path9624" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9626">
	   <g
		  id="g9628">
		 <g
			id="g9634">
		   <g
			  id="g9636">
			 <path
				d="m 516.741,159.634 c -0.607,-0.351 -1.143,-1.019 -1.458,-1.745 v 0 c -0.189,-0.434 -0.298,-0.888 -0.299,-1.309 v 0 c 10e-4,-0.561 0.198,-0.954 0.513,-1.14 v 0 -0.001 l 0.067,-0.038 0.914,-0.528 v 0.001 c -0.315,0.186 -0.513,0.578 -0.513,1.14 v 0 c 0,0.421 0.11,0.875 0.298,1.309 v 0 c 0.315,0.726 0.851,1.394 1.458,1.745 v 0 c 0.488,0.282 0.929,0.304 1.247,0.117 v 0 l -0.981,0.566 c -0.135,0.079 -0.292,0.121 -0.465,0.121 v 0 c -0.235,0 -0.5,-0.076 -0.781,-0.238"
				style="fill:url(#linearGradient9652);stroke:none"
				id="path9654" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9656">
	   <g
		  id="g9658"
		  clip-path="url(#clipPath9662)">
		 <g
			id="g9664"
			transform="translate(517.7219,159.0679)">
		   <path
			  d="m 0,0 c -0.97,-0.56 -1.756,-1.928 -1.756,-3.055 0,-1.126 0.786,-1.586 1.756,-1.026 0.97,0.56 1.756,1.927 1.756,3.054 C 1.756,0.1 0.97,0.56 0,0"
			  style="fill:#2d4b87;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9666" />
		 </g>
		 <g
			id="g9668"
			transform="translate(513.1767,162.358)">
		   <path
			  d="m 0,0 -1.021,-0.589 -0.002,0.435 1.02,0.589 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9670" />
		 </g>
		 <g
			id="g9672"
			transform="translate(513.1741,162.7933)">
		   <path
			  d="M 0,0 -1.638,0.949 -2.656,0.361 -1.02,-0.589 Z"
			  style="fill:#499ad4;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9674" />
		 </g>
		 <g
			id="g9676"
			transform="translate(512.1537,162.2041)">
		   <path
			  d="m 0,0 -1.636,0.951 0.003,-0.436 1.636,-0.95 z"
			  style="fill:#0980c3;fill-opacity:1;fill-rule:nonzero;stroke:none"
			  id="path9678" />
		 </g>
	   </g>
	 </g>
	 <g
		id="g9680">
	   <g
		  id="g9682"
		  clip-path="url(#clipPath9686)">
		 <g
			id="g9688">
		   <g
			  id="g9690" />
		   <g
			  id="g9720"
			  mask="url(#mask9708)">
			 <g
				id="g9722">
			   <path
				  d="m 127.382,175.123 41.336,-24.54 7.497,5.5 -40.833,23.668 z"
				  style="fill:url(#linearGradient9734);stroke:none"
				  id="path9736" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9738">
	   <g
		  id="g9740"
		  clip-path="url(#clipPath9744)">
		 <g
			id="g9746">
		   <g
			  id="g9748" />
		   <g
			  id="g9778"
			  mask="url(#mask9766)">
			 <g
				id="g9780">
			   <path
				  d="m 137.218,125.718 89.226,-52.97 8.856,5.386 -90.082,52.211 z"
				  style="fill:url(#linearGradient9792);stroke:none"
				  id="path9794" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9796">
	   <g
		  id="g9798"
		  clip-path="url(#clipPath9802)">
		 <g
			id="g9804">
		   <g
			  id="g9806" />
		   <g
			  id="g9836"
			  mask="url(#mask9824)">
			 <g
				id="g9838">
			   <path
				  d="m 224.256,174.676 19.117,-12.218 5.699,6.497 -19.014,11.567 z"
				  style="fill:url(#linearGradient9850);stroke:none"
				  id="path9852" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9854">
	   <g
		  id="g9856"
		  clip-path="url(#clipPath9860)">
		 <g
			id="g9862">
		   <g
			  id="g9864" />
		   <g
			  id="g9894"
			  mask="url(#mask9882)">
			 <g
				id="g9896">
			   <path
				  d="m 244.768,164.454 10.899,-7.155 3.737,4.569 -10.868,6.729 z"
				  style="fill:url(#linearGradient9908);stroke:none"
				  id="path9910" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9912">
	   <g
		  id="g9914"
		  clip-path="url(#clipPath9918)">
		 <g
			id="g9920">
		   <g
			  id="g9922" />
		   <g
			  id="g9952"
			  mask="url(#mask9940)">
			 <g
				id="g9954">
			   <path
				  d="m 277.967,86.557 41.121,-24.926 8.78,7.812 -40.698,23.911 z"
				  style="fill:url(#linearGradient9966);stroke:none"
				  id="path9968" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g9970">
	   <g
		  id="g9972"
		  clip-path="url(#clipPath9976)">
		 <g
			id="g9978">
		   <g
			  id="g9980" />
		   <g
			  id="g10010"
			  mask="url(#mask9998)">
			 <g
				id="g10012">
			   <path
				  d="m 517.983,71.569 8.663,-5.839 6.06,7.103 -9.338,6.026 z"
				  style="fill:url(#linearGradient10024);stroke:none"
				  id="path10026" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10028">
	   <g
		  id="g10030"
		  clip-path="url(#clipPath10034)">
		 <g
			id="g10036">
		   <g
			  id="g10038" />
		   <g
			  id="g10068"
			  mask="url(#mask10056)">
			 <g
				id="g10070">
			   <path
				  d="m 582.549,74.185 6.09,-4.412 5.601,7.358 -6.597,4.505 z"
				  style="fill:url(#linearGradient10082);stroke:none"
				  id="path10084" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10086">
	   <g
		  id="g10088"
		  clip-path="url(#clipPath10092)">
		 <g
			id="g10094">
		   <g
			  id="g10096" />
		   <g
			  id="g10126"
			  mask="url(#mask10114)">
			 <g
				id="g10128">
			   <path
				  d="m 113.622,361.016 5.411,-6.124 13.623,17.596 -4.776,6.17 z"
				  style="fill:url(#linearGradient10140);stroke:none"
				  id="path10142" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10144">
	   <g
		  id="g10146"
		  clip-path="url(#clipPath10150)">
		 <g
			id="g10152">
		   <g
			  id="g10154" />
		   <g
			  id="g10184"
			  mask="url(#mask10172)">
			 <g
				id="g10186">
			   <path
				  d="m 184.473,409.965 3.716,-13.273 45.443,29.633 -2.445,13.175 z"
				  style="fill:url(#linearGradient10198);stroke:none"
				  id="path10200" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10202">
	   <g
		  id="g10204"
		  clip-path="url(#clipPath10208)">
		 <g
			id="g10210">
		   <g
			  id="g10212" />
		   <g
			  id="g10242"
			  mask="url(#mask10230)">
			 <g
				id="g10244">
			   <path
				  d="m 136.044,375.07 7.101,-11.2 22.495,19.031 -6.162,10.98 z"
				  style="fill:url(#linearGradient10256);stroke:none"
				  id="path10258" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10260">
	   <g
		  id="g10262"
		  clip-path="url(#clipPath10266)">
		 <g
			id="g10268">
		   <g
			  id="g10270" />
		   <g
			  id="g10300"
			  mask="url(#mask10288)">
			 <g
				id="g10302">
			   <path
				  d="m 607.081,157.058 6.161,-10.98 20.829,21.294 -4.496,8.717 z"
				  style="fill:url(#linearGradient10314);stroke:none"
				  id="path10316" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10318">
	   <g
		  id="g10320"
		  clip-path="url(#clipPath10324)">
		 <g
			id="g10326">
		   <g
			  id="g10328" />
		   <g
			  id="g10358"
			  mask="url(#mask10346)">
			 <g
				id="g10360">
			   <path
				  d="m 648.589,181.679 6.162,-10.98 23.434,18.81 -7.101,11.202 z"
				  style="fill:url(#linearGradient10372);stroke:none"
				  id="path10374" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10388">
	   <g
		  id="g10390"
		  clip-path="url(#clipPath10394)">
		 <g
			id="g10396">
		   <g
			  id="g10398" />
		   <g
			  id="g10428"
			  mask="url(#mask10416)">
			 <g
				id="g10430">
			   <path
				  d="m 703.792,215.581 5.619,-9.15 16.938,13.185 -6.371,9.394 z"
				  style="fill:url(#linearGradient10442);stroke:none"
				  id="path10444" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10446">
	   <g
		  id="g10448">
		 <g
			id="g10454">
		   <g
			  id="g10456">
			 <path
				d="m 696.915,223.3 h -0.399 v -2.265 c 0,-2.217 3.114,-4.015 6.956,-4.015 v 0 c 3.843,0 6.957,1.798 6.957,4.015 v 0 0.921 1.344 h -0.399 c -0.958,1.557 -3.532,2.672 -6.558,2.672 v 0 c -3.025,0 -5.599,-1.115 -6.557,-2.672"
				style="fill:url(#linearGradient10472);stroke:none"
				id="path10474" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10476">
	   <g
		  id="g10478">
		 <g
			id="g10484">
		   <g
			  id="g10486">
			 <path
				d="m 697.675,223.316 c 0,-1.849 2.596,-3.347 5.797,-3.347 v 0 c 3.202,0 5.797,1.498 5.797,3.347 v 0 c 0,1.849 -2.595,3.347 -5.797,3.347 v 0 c -3.201,0 -5.797,-1.498 -5.797,-3.347"
				style="fill:url(#linearGradient10498);stroke:none"
				id="path10500" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10502">
	   <g
		  id="g10504">
		 <g
			id="g10510">
		   <g
			  id="g10512">
			 <path
				d="m 697.675,222.691 c 0,-1.848 2.596,-3.347 5.797,-3.347 v 0 c 3.202,0 5.797,1.499 5.797,3.347 v 0 c 0,1.849 -2.595,3.348 -5.797,3.348 v 0 c -3.201,0 -5.797,-1.499 -5.797,-3.348"
				style="fill:url(#linearGradient10520);stroke:none"
				id="path10522" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10524">
	   <g
		  id="g10526">
		 <g
			id="g10532">
		   <g
			  id="g10534">
			 <path
				d="m 696.516,223.316 c 0,-2.218 3.114,-4.017 6.956,-4.017 v 0 c 3.843,0 6.957,1.799 6.957,4.017 v 0 c 0,2.218 -3.114,4.016 -6.957,4.016 v 0 c -3.842,0 -6.956,-1.798 -6.956,-4.016 m 1.159,0 c 0,1.849 2.596,3.347 5.797,3.347 v 0 c 3.202,0 5.797,-1.498 5.797,-3.347 v 0 c 0,-1.849 -2.595,-3.347 -5.797,-3.347 v 0 c -3.201,0 -5.797,1.498 -5.797,3.347"
				style="fill:url(#linearGradient10546);stroke:none"
				id="path10548" />
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10550">
	   <g
		  id="g10552"
		  clip-path="url(#clipPath10556)">
		 <g
			id="g10558">
		   <g
			  id="g10560" />
		   <g
			  id="g10590"
			  mask="url(#mask10578)">
			 <g
				id="g10592">
			   
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10608">
	   <g
		  id="g10610"
		  clip-path="url(#clipPath10614)">
		 <g
			id="g10616">
		   <g
			  id="g10618" />
		   <g
			  id="g10648"
			  mask="url(#mask10636)">
			 <g
				id="g10650">
			   <path
				  d="m 258.11,312.2 c 0,-2.361 1.915,-4.277 4.277,-4.277 v 0 c 2.362,0 4.276,1.916 4.276,4.277 v 0 c 0,2.362 -1.914,4.276 -4.276,4.276 v 0 c -2.362,0 -4.277,-1.914 -4.277,-4.276"
				  style="fill:url(#radialGradient10662);stroke:none"
				  id="path10664" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10666">
	   <g
		  id="g10668"
		  clip-path="url(#clipPath10672)">
		 <g
			id="g10674">
		   <g
			  id="g10676" />
		   <g
			  id="g10706"
			  mask="url(#mask10694)">
			 <g
				id="g10708">
			   <path
				  d="m 246.341,319.074 c 0,-2.361 1.914,-4.276 4.277,-4.276 v 0 c 2.361,0 4.275,1.915 4.275,4.276 v 0 c 0,2.362 -1.914,4.277 -4.275,4.277 v 0 c -2.363,0 -4.277,-1.915 -4.277,-4.277"
				  style="fill:url(#radialGradient10720);stroke:none"
				  id="path10722" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>
	 <g
		id="g10724">
	   <g
		  id="g10726"
		  clip-path="url(#clipPath10730)">
		 <g
			id="g10732">
		   <g
			  id="g10734" />
		   <g
			  id="g10764"
			  mask="url(#mask10752)">
			 <g
				id="g10766">
			   <path
				  d="m 235.48,325.616 c 0,-2.362 1.914,-4.276 4.276,-4.276 v 0 c 2.362,0 4.276,1.914 4.276,4.276 v 0 c 0,2.362 -1.914,4.276 -4.276,4.276 v 0 c -2.362,0 -4.276,-1.914 -4.276,-4.276"
				  style="fill:url(#radialGradient10778);stroke:none"
				  id="path10780" />
			 </g>
		   </g>
		 </g>
	   </g>
	 </g>

		<g  id="circle-1">
			<Circle cx="0"  cy="0" x="515" y="-360" value="" name="PPS #1" fill="#843c0c" unit="" set_class="non_bounce"/>
			<Circle cx="0"  cy="0" x="400" y="-280" value="" name="PPS #2" fill="#843c0c" unit="" set_class="non_bounce"/>
			<Circle cx="0"  cy="0" x="440" y="-190" value="" name="PPS #3" fill="#843c0c" unit=""  set_class="non_bounce"/>

		    <Circle cx="223.614"  cy="248.5051" x="205" y="-241" :value="ps_data[5].value" :unit="unit_1" :name="ps_data[5].name" fill="#265caa"/>
			<Circle cx="345.0804"  cy="170.4571" x="329" y="-165" :value="ps_data[4].value" :unit="unit_1"  :name="ps_data[4].name" fill="#265caa"/>
			<Circle cx="415.9433"  cy="236.3761" x="402" y="-230" :value="ps_data[7].value" :unit="unit_1" :name="ps_data[7].name" fill="#265caa"/>
			<Circle cx="404.3892"  cy="310.9874" x="390" y="-302" :value="ps_data[1].value" :unit="unit_1" :name="ps_data[1].name" fill="#265caa"/>
			<Circle cx="509.6275"  cy="298.4151" x="498" y="-290" :value="ps_data[3].value" :unit="unit_1" :name="ps_data[3].name" fill="#265caa"/>
			<Circle cx="365.8256"  cy="405.4457" x="350" y="-395" :value="ps_data[2].value" :unit="unit_1" :name="ps_data[2].name" fill="#265caa"/>
			<Circle cx="443.0526"  cy="143.6301" x="430" y="-139" :value="ps_data[0].value" :unit="unit_1" :name="ps_data[0].name" fill="#843c0c"/>
			<Circle cx="514.128"  cy="160.2934" x="502" y="-154" :value="ps_data[6].value" :unit="unit_1" :name="ps_data[6].name" fill="#843c0c"/>
		</g>


		<g  id="circle-2" style="display:none">
			<Circle cx="0"  cy="0" x="515" y="-360" value="" name="PPS #1" fill="#843c0c" unit="" set_class="non_bounce"/>
			<Circle cx="0"  cy="0" x="400" y="-280" value="" name="PPS #2" fill="#843c0c" unit="" set_class="non_bounce"/>
			<Circle cx="0"  cy="0" x="440" y="-190" value="" name="PPS #3" fill="#843c0c" unit=""  set_class="non_bounce"/>
		   <!--선산구, 선산신, 원호, 문성 -->  
  			<DrainField cx="265"  cy="462" x="254" y="-447" :value="g_level_data[5].value" :unit="unit_2" :name="g_level_data[5].name" fill="#265caa"/>
			<DrainField cx="220"  cy="437" x="209" y="-422" :value="g_level_data[6].value" :unit="unit_2" :name="g_level_data[6].name"  fill="#265caa"/>
			<DrainField cx="180"  cy="407" x="167" y="-393" :value="g_level_data[4].value" :unit="unit_2" :name="g_level_data[4].name" fill="#265caa"/>
			<DrainField cx="170"  cy="335" x="157" y="-323" :value="g_level_data1[1].value" :unit="unit_2" name="MS" fill="#265caa" />
			<!-- 북삼조 -->  
			<DrainField cx="190"  cy="195" x="177" y="-186" :value="g_level_data[8].value" :unit="unit_2" :name="g_level_data[8].name"  fill="#265caa"/>
			<!--DrainField cx="215"  cy="185" x="203" y="-176" :value="g_level_data[8].value" :unit="unit_2"  name="" fill="#265caa"/-->

			<!-- 신평생활 -->  
			<DrainField cx="300"  cy="237" x="290" y="-228" :value="g_level_data[7].value" :unit="unit_2" :name="g_level_data[7].name"  fill="#265caa"/>
			<!-- 김천,초곡, 혁신,아포 -->  
			<DrainField cx="165"  cy="155" x="152" y="-148" :value="g_level_data[11].value" :unit="unit_2" :name="g_level_data[11].name"  fill="#265caa"/>
			<DrainField cx="200"  cy="135" x="188" y="-128" :value="g_level_data1[2].value" :unit="unit_2" name="CG" fill="#265caa"  />
			<DrainField cx="235"  cy="115" x="223" y="-108" :value="g_level_data[10].value" :unit="unit_2" :name="g_level_data[10].name"  fill="#265caa"/>
			<DrainField cx="270"  cy="95"  x="260" y="-88"   :value="g_level_data[9].value" :unit="unit_2" :name="g_level_data[9].name" fill="#265caa"/>
            <!-- 신평공업 -->  
			<DrainField cx="350"  cy="120" x="341" y="-113" :value="g_level_data[0].value" :unit="unit_2" :name="g_level_data[0].name" fill="#265caa"/>
			<!--DrainField cx="385"  cy="100" x="377" y="-93"  :value="g_level_data[0].value" :unit="unit_2" name="" fill="#265caa"/-->
			<!-- 하이테크, 4공단공업 -->
			<DrainField cx="525"  cy="120" x="519" y="-113" :value="g_level_data1[3].value" :unit="unit_2" name="HIT" fill="#265caa"  />
			<DrainField cx="593"  cy="125" x="589" y="-118"  :value="g_level_data[1].value" :unit="unit_2" :name="g_level_data[1].name"  fill="#265caa"/>
			<!-- 확장단지,4공단생활, 해평 -->
			<DrainField cx="620"  cy="210" x="617" y="-202" :value="g_level_data[3].value" :unit="unit_2" :name="g_level_data[3].name" fill="#265caa"/>
			<DrainField cx="663"  cy="232" x="661" y="-223" :value="g_level_data[2].value" :unit="unit_2" :name="g_level_data[2].name"  fill="#265caa"/>
			<DrainField cx="720"  cy="262" x="719" y="-253" :value="g_level_data[12].value" :unit="unit_2" :name="g_level_data[12].name"  fill="#265caa"  />
		
		</g>

	</g>
 </svg>
 
 

</template>
<style scoped>
	svg {
		width: 100%;
		height: auto;
	}

    .line {
        stroke-dasharray: 2;
        stroke-dashoffset: 30;
        animation: dash 5s linear infinite;
    }

    .line-reverse {
        stroke-dasharray: 2;
        stroke-dashoffset: 30;
        animation: dash 5s linear infinite;
        animation-direction: reverse;
    }
    
    .circle{
        fill:url(#radialGradient10604);
        stroke:none;
    }

    @keyframes dash {
        to {
            stroke-dashoffset: 0;
        }
    }

</style>

<script>

	function func(_this, direction, i){
		var id = _this.attr('id');
		var duration = _this.attr('data-duration');
		var delay = _this.attr('data-delay');
		if(duration == undefined){
			duration = 3000;
		}
		if(delay == undefined){
			delay = 300;
		}
		/*
		duration = 3000;
		delay = 3000;
		*/
		var opacity = [0,1];
		if(direction == 'normal'){
			opacity = [1,0];
		}

		var path = anime.path('#' + id);
		anime({
			targets: '.'+ id + '-circle',
			opacity: opacity,
			translateX: path('x'),
			translateY: path('y'),
			rotate: path('angle'),
			direction: direction,
			easing: 'linear',
			duration: duration,
			// delay:delay,
			// duration: anime.stagger(100, {"start": 1000}),
			delay: anime.stagger(delay, {"start": 0}),
			// delay: anime.stagger( 200, { grid: [ 14, 5 ], from: 'center' } ),
			loop: true,
			autoplay: true
		});

	}

	export default {
		name: 'WiPlat',
        props:{
			g_level_data1: {},
			g_level_data: {},
			ps_data:{},
			unit_1:{  
				    type: String,
                	required: true,
					default: ''
				 },
			unit_2: {
				    type: String,
	               	required: true,
					default: ''
			},
			
	
		},
		
		data(){
			/*
			<wt_list>
			1	신평공업배수지
			2	4공단공업배수지
			3	4공단생활배수지
			4	확장단지배수지
			5	원호배수지
			6	선산구배수지
			7	선산신배수지
			8	신평생활배수지
			9	북삼조절지
			10	아포배수지
			11	혁신배수지
			12	김천배수지
			<ps_data> 
			1	신평공업 송수펌프동
			2	원호생활 송수펌프동
			3	선산 가압장
			4	국가산단/4공단생활
			5	김천 가압장
			6	북삼 가압장
			7	4공단공업 송수펌프동
			8	신평생활 송수펌프동



			*/
			return {
				gLevelPer_url :[
							{value : '2.5' ,name:	'신평공업' }, 
							{value : '2.5' ,name:	'4공단공업' }, 
							{value : '2.5' ,name:	'4공단생활' }, 
							{value : '2.5' ,name:	'확장단지', }, 
							{value : '2.5' ,name:	'원호', }, 
							{value : '2.5' ,name:	'선산(구)', }, 
							{value : '2.5' ,name:	'선산(신)', }, 
							{value : '2.5' ,name:	'신평생활', }, 
							{value : '2.5' ,name:	'북삼', }, 
							{value : '2.5' ,name:	'아포', }, 
							{value : '2.5' ,name:	'혁신', }, 
							{value : '2.5' ,name:	'김천', }, 
							{value : '2.5' ,name:	'문성', }, 
					],
			}
		},
		components: {
			Circle,
			DrainField
		},
		mounted : function(){
			$('.line').each(function(){
				func($(this), 'normal');
			});
			$('.line-reverse').each(function(){
				func($(this), 'reverse');
			});
		}
	}
</script>