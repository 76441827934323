<template>

<!-- title -->

                      
   <div style="width:100%;height:calc( 100% ); float:left;">	
      <!-- 펌프운영관리 -->
      <div style=" width: 100%; padding: 0 10px 20px 10px;">
        <div class="fL div-middle " style="height: 100%; width: 100%; background: none;">
          <div style="padding: 0 25px 10px 25px; width: 100%;  height: calc(100% - 10px);">
            <!--div class="div_title"><span style="  width: 100%;">펌프 운영 관리</span>
            </div--> 
            <div class="div_content1">
       
              <!-- 시간대 -->
              
              <b-row class="row_data" >
               <div class="div_content1_left div_box_border div-flex-center fL" style="width: calc(10% - 10px);">
                  <input id="input_search_date" class="date_design" style="width: 100%;text-align:center;" :value='last_date' />
                </div>
                <!-- 오른쪽 영역  -->
                <div class="div_content1_right div_box_border  fL" id="col-time"  style=" display: flex;  justify-content: center;">
                   <div :class="['div_content_col', {'is--red' : '03' === item.value},{'is--gray' : '02' === item.value}]" v-for="(item, idx) in item_24" :key="idx" >
                        {{item.idx}}
                   </div>
                  
                </div>
               </b-row>
              <!-- 각정수장 -->
               <div style="height:90%;">
               <b-row class='row_data' >
               <!-- 왼쪽 영역  -->
                <div class="div_content1_left div_box_border div-flex-center1 fL" style="width: calc(10% - 10px);">
                  <div>                  
                   목표 수위 설정
                  </div>
                </div>                   
                <!-- 오른쪽 영역  -->
                <div class="div_content1_right div_box_border div-flex-center1 fL" id="col-time"  style=" display: flex; justify-content: center;" >
                   <div class='div_content_col1 data1' v-for="(item, idx) in item_24" :key="idx" >                    
                      <b-form-input size="sm" v-model="properties[item.idx]" style="text-align: center;height:100%;font-size:13pt; "></b-form-input>
                   </div>
                </div>
              </b-row>    
               <b-row :class="[ 'row_data', items.color, {'block' : 'block' === items.block}]" v-for="(items, idx) in items_24" :key="idx" >
                <!-- 왼쪽 영역  -->
                <div class="div_content1_left div_box_border div-flex-center1 fL" style="width: calc(10% - 10px);">
                  <div>                  
                   목표수위
                  </div>
                </div>                   

                <!-- 오른쪽 영역  -->
                <div class="div_content1_right div_box_border  fL" id="col-time"  style=" display: flex; justify-content: center;" >
                   <div :class="['div_content_col data', {'blue_1' :item.idx < current_hour}, {'blue_2' : item.idx == current_hour},
                    {'blue_3' : item.idx > current_hour}]"
                    v-for="(item, idx) in gitem_24" :key="idx" >                    
                        {{item.value}}
                   </div>
                </div>
               </b-row>
               <b-row :class="[ 'row_data', items.color, {'block' : 'block' === items.block}]" v-for="(items, idx) in items_24" :key="idx" >
                <!-- 왼쪽 영역  -->
                <div class="div_content1_left div_box_border div-flex-center1 fL" style="width: calc(10% - 10px);">
                  <div>                  
                   목표수위 재계산
                  </div>
                </div>   
               <!-- 오른쪽 영역  -->
                <div class="div_content1_right div_box_border  fL" id="col-time"  style=" display: flex; justify-content: center;" >
                   <div class='div_content_col data blue_1' v-for="(item, idx) in gitem_24_cal" :key="idx" >                    
                        {{item.value}}
                   </div>
                </div>
              </b-row>
              <b-row>
              </b-row>    
              <b-row>
                <b-col sm=10 >
                </b-col>    
                <b-col sm=1  >
                <div class="btn_cntl"  @click="set_g_level" style="float:right;margin:10px 30px;">
                    <button class='map button' type="button"  v-b-tooltip.hover style="width:120px;" >목표수위재계산</button> 
                </div>
                </b-col>

                <b-col sm=1  >
                <div class="btn_cntl"  @click="g_set_save" style="float:right;margin:10px 10px;">
                    <button class='map button' type="button"  v-b-tooltip.hover style="width:120px;" >설정저장</button> 
                </div>
                </b-col>
              </b-row>    
              </div>
              <b-row>
              
              <!-- 배수지 조건 
              <div style="height: 46.6%; width: calc(100% - 10px);margin: 0 10px 0 0;" >
            
                <div class="div_content1_left fL" style="width: calc(20% - 10px);  height: 100%;">
                  <div class="div-flex-center div_box_border fL" style="width: calc(60% - 16px);  height: calc(100% - 6px);  margin-right: 10px;">관압모드</div>
                  <div class="div-flex-center div_box_border fL" style="width: calc(40% - 6px);height: calc(50% - 11px);margin-bottom: 10px;">고압</div>
                  <div class="div-flex-center div_box_border fL" style="width: calc(40% - 6px);height: calc(50% - 11px);">저압</div>
                </div>
              
                <div class="fL" style="width: 80%; height: 100%;">
                  <div class="div_content1_right2 div-flex-center div_box_border fL" style="margin-bottom: 10px;" id="col-valve"></div>
                  <div class="div_content1_right2 div-flex-center div_box_border fL"  id="col-water"></div>
                </div>
                
              </div>
              -->
              </b-row> 
            </div>
          </div>
        </div>
      </div>	
   </div>


</template>
<script>
//import ScheduleItem from './ScheduleItem.vue'
export default {
  name: "gLevel_set",
  props: [
      'wt_properties',    
      //'last_date',
      'lei_value_23',     
  ],
  watch: {
    wt_properties(){
      this.search_data(); // 실행할 이벤트
    },
    ws_id() {
      this.search_data(); // 실행할 이벤트
    },
 
  },
  data() {
     const category = [{idx:1 , block : '3,11,20', color : "--gray,blue_2, yellow_1", m_id_won :'33,38'},//신평공업 전력요금 m_id  예측,스카다 전력요금 m_id
                       {idx:2 , block : '9,24,50', color : "--gray,blue_2, yellow_1", m_id_won :'23,50'},//원호생활 전력요금 m_id  예측,스카다
                       {idx:3 , block : '5,10,20', color : "--gray,blue_2, yellow_1", m_id_won :'61,65'},//선산가압장 전력요금 m_id  예측,스카다
                       {idx:4 , block : '7,12,20', color : "--gray,blue_2, yellow_1", m_id_won :'142,149'}, //국가
                       {idx:5 , block : '15,23,35', color : "--gray,blue_2, yellow_1", m_id_won :'170,177'}, //김천가압장
                       {idx:6 , block : '3,16,30', color : "--gray,blue_2, yellow_1", m_id_won :'114,120'}, //북삼(가) 전력요금 m_id  예측,스카다
                       {idx:7 , block : '3,16,30', color : "--gray,blue_2, yellow_1", m_id_won :'203,210'}, //4공단공업
                       {idx:8 , block : '3,15,30', color : "--gray,blue_2, yellow_1", m_id_won :'246,254'}, //신평생활
                      

                      ]
    //시간대 피크별 색깔
     const item_24 =  [{idx:'0', value:'03'},{idx:'1',  value:'01'},{idx:'2', value:'01'},{idx:'3',  value:'01'},{idx:'4',  value:'01'},{idx:'5',  value:'01'},{idx:'6',  value:'01'}
                ,{idx:'7',  value:'01'},{idx:'8',  value:'01'},{idx:'9', value:'02'},{idx:'10',  value:'03'},{idx:'11',  value:'03'},{idx:'12',  value:'03'}
                ,{idx:'13',  value:'02'},{idx:'14',  value:'02'},{idx:'15', value:'02'},{idx:'16',  value:'02'},{idx:'17',  value:'03'},{idx:'18',  value:'03'}
                ,{idx:'19',  value:'03'},{idx:'20',  value:'02'},{idx:'21',  value:'02'},{idx:'22',  value:'02'},{idx:'23',  value:'01'}
                ];
     const items_24 =  [{idx:0,value:55}
                ]
     //g_level 피크별 색깔
     const gitem_24 =  [{idx:'00', value:null},{idx:'01',  value:null},{idx:'02', value:null},{idx:'03',  value:null},{idx:'04',  value:null},{idx:'05',  value:null},{idx:'06',  value:null}
                ,{idx:'07',  value:null},{idx:'08',  value:null},{idx:'09', value:null},{idx:'10',  value:null},{idx:'11',  value:null},{idx:'12',  value:null}
                ,{idx:'13',  value:null},{idx:'14',  value:null},{idx:'15', value:null},{idx:'16',  value:null},{idx:'17',  value:null},{idx:'18',  value:null}
                ,{idx:'19',  value:null},{idx:'20',  value:null},{idx:'21',  value:null},{idx:'22',  value:null},{idx:'23',  value:null}
                ];     
     //g_level 피크별 색깔
     const gitem_24_cal =  [{idx:'00', value:null},{idx:'01',  value:null},{idx:'02', value:null},{idx:'03',  value:null},{idx:'04',  value:null},{idx:'05',  value:null},{idx:'06',  value:null}
                ,{idx:'07',  value:null},{idx:'08',  value:null},{idx:'09', value:null},{idx:'10',  value:null},{idx:'11',  value:null},{idx:'12',  value:null}
                ,{idx:'13',  value:null},{idx:'14',  value:null},{idx:'15', value:null},{idx:'16',  value:null},{idx:'17',  value:null},{idx:'18',  value:null}
                ,{idx:'19',  value:null},{idx:'20',  value:null},{idx:'21',  value:null},{idx:'22',  value:null},{idx:'23',  value:null}
                ];                       
     // 월별 시간별 목표수위 설정
     let properties =  { 0:null,1:null,2:null,3:null,4:null,5:null,6:null,7:null,8:null,9:null,
                        10:null,11:null,12:null,13:null,14:null,15:null,16:null,17:null,18:null,19:null,
                        20:null,21:null,22:null,23:null, }
                
     let properties_bak =   { 0:null,1:null,2:null,3:null,4:null,5:null,6:null,7:null,8:null,9:null,
                        10:null,11:null,12:null,13:null,14:null,15:null,16:null,17:null,18:null,19:null,
                        20:null,21:null,22:null,23:null, }
                                          
    return {
      inval : null,
      columns: [
        { label: "Tag", field: "tag" },
        { label: "Name", field: "name" },
      ],
      qtb_id : 1,  
      type : 2,
      type_name : "24H",
      box: [],
      sort_:'',
      m_id:"",
      tag_id:"",
      tag : "",
      ws_id: 1, 
      fac_id: 4,
      infiniteId : +new Date(),  
      listKey: 0,
      item_24 ,
      gitem_24,
      gitem_24_cal, 
      items_24,
      save_sdate : null,
      save_edate : null,
      properties,      
      properties_bak,   
      category,
      last_date:null,
      last_month: "",
      current_hour : "",
    };
  },
methods: {
    change_type(a){
      this.type= a;
    },
    current_date(today, a){
      var year = today.getFullYear();
      var month = ('0' + (today.getMonth() + 1)).slice(-2);
      var day = ('0' + today.getDate()).slice(-2);
      var hours = ('0' + today.getHours()).slice(-2);
      this.current_hour = hours
     // var minutes = ('0' + today.getMinutes()).slice(-2);
     // var dateString = year + '-' + month  + '-' + day +" "+hours+ ':' + minutes;
     var dateString = year + '-' + month  + '-' + day +" "+hours+ ':00';
     if(a == 1){ //시간값
       dateString = year + '-' + month  + '-' + day ;
     }

      return dateString
    },
 
   

    change_date_flow(add_day) {
      //현재 날짜보다 이후에 것을 search시 검색이 안된다
       var change_date=  document.getElementById('input_search_date').value.split(" ");
       this.last_date =  change_date[0];
       if(this.s_current_date <= this.last_date && add_day > 0 ){
         return
       }
       let default_date = new Date(this.last_date);
        //alert(default_date)
       default_date.setDate(default_date.getDate()+Number(add_day)).toLocaleString() ;
       this.last_date = this.current_date(default_date,0)
       if(this.last_date > this.s_current_date){
         return
       }else{
         this.search_data();
       }
         
        
    },
    set_g_level(){
      let keys = Object.keys(this.properties)

      let g_arr = []
       //23번 제외
      var start_level = this.lei_value_23
      var start_idx = -1
      for(let j=0; j<keys.length-1; j++) {
          if(this.properties[keys[j]]===null || this.properties[keys[j]] === "" ) continue     
          var end_level = Number(this.properties[keys[j]])  
          var cal_arr = this.calc_g_level(start_level,end_level, start_idx, j )
          g_arr = g_arr.concat(cal_arr)

          start_level = end_level
          start_idx = j
      } 
      this.gitem_24_cal = g_arr
      this.gitem_24_cal.push({idx:23, value: Math.round((start_level+0.1)*100)/100})
    },
    //목표수위 계산하기
    calc_g_level(start_level,end_level, start_idx, end_idx) {
      //item_24 ==> 피크부하, 중부하, 경부하 갯수 체크
      var low_cnt = 0
      var mid_cnt = 0
      var high_cnt = 0
      var cnt = end_idx - start_idx 
      var interval_level = (end_level - start_level) / cnt            
      var intervalPeak_level = interval_level * 1.5

      for(let j= start_idx+1 ; j<= end_idx ; j++) {
        if(this.item_24[j].value ==="01") low_cnt += 1
        else if(this.item_24[j].value ==="02") mid_cnt += 1
        else if(this.item_24[j].value ==="03") high_cnt += 1
      }

      mid_cnt = mid_cnt === 0 ? 1 : mid_cnt
      high_cnt = high_cnt === 0 ? 1 : high_cnt

      var intervalMidRangeLoad = ( ((end_level - start_level) - (intervalPeak_level * high_cnt)) / mid_cnt ) 
      var cal_level = start_level 
      var cal_level_arr =[]
      for(let i= start_idx+1 ; i<= end_idx ; i++) {
         if(this.item_24[i].value ==="01") cal_level += interval_level
         else if(this.item_24[i].value ==="02") cal_level += intervalMidRangeLoad
         else if(this.item_24[i].value ==="03") cal_level += intervalPeak_level
         cal_level_arr.push({idx:i, value:Math.round(cal_level*100)/100})
      }
      return cal_level_arr
    },
    check_float(text){
      //var pattern =  /^[0-9]*.[0-9]*[1-9]+$/
      var pattern =/[\-0-9\.]+/g
      if (pattern.test(text)) {
	        return true
      }else{
        return false
      }

    },
    //목표수위를 저장한다.
    //8,22시 데이터 수정만 가능하다. 1보다 크다.
    async g_set_save(){      
        

        let params = {}
        let keys = Object.keys(this.properties_bak)
        if(this.ws_id === undefined || this.fac_id === undefined ) {
            alert("초기화 되었습니다.")
            return
        }
       //23번 제외
        for(let j=0; j<keys.length-1; j++) {
            if(this.properties_bak[keys[j]]===this.properties[keys[j]] ) continue       
            if(keys[j] === 8 || keys[j] === 22) { //8,22는 기본적으로 목표수위 설정을 하여야 한다.
              if(this.properties[keys[j]] < 1 ){
                alert("목표수위를 1이상으로 입력하여야 한다.")
                return 
              }
            }
            var check = this.check_float(this.properties[keys[j]])
            if(!(this.properties[keys[j]] === null || this.properties[keys[j]]==="")){
              if(!check){
                alert("목표 수위를 숫자로 입력해 주세요.")
                return
              }else{
                this.properties[keys[j]] = Math.round(this.properties[keys[j]]*1000)/1000
              }
            }
            params[""+keys[j]] = this.properties[keys[j]]
        }   
        if(Object.keys(params).length<=0) return 
        
        let data = {type: 'modify',ws_id:this.ws_id, fac_id:this.fac_id, start_date:this.save_sdate,
            end_date:this.save_edate,params:params} ;
        try {
            let update_url = this.URL+'api/setting/waterTank/g_level/update'
            let res = await this.$http.post(update_url,data)
            if(res.data.check ===1){
                alert("fail : "+ res.data.msg) ;
            }else{
                this.search_data();
            }
            
        } catch (err) {
            return null
        }
        

    },
    search_data(){ //type = 1 : 24시간 , 2 : 1시간데이터
      this.last_date = this.wt_properties.G_LEI_DATE 
      var today = new Date(this.last_date); //현재시간

      var month = today.getMonth() + 1
      //var today = new Date(2023,5,1,9,0,0,0);
        //4. 시간별 전력 type 01: 경부하, 02:중부하, 03:피크부하
      this.$http.get(this.URL+'api/setting/monthHour/peekList?search_date='+this.last_date+'&month='+month).then((res) => {  
        if(res.data.list.length >0){
            for(let i=0; i<res.data.list.length; i++) {
              var item = res.data.list[i]  
              this.item_24[item.HOUR].value = item.VALUE               
            }
        }

      });   
      //2. 현재 계산된 목표수위 가져오기
      this.tag = this.wt_properties.LEI_TAG
      this.ws_id = this.wt_properties.WS_ID  
      this.fac_id = this.wt_properties.FAC_ID
    
      this.m_id = this.wt_properties.G_LEI_M_ID
      this.tag_id = this.wt_properties.G_LEI_TAG_ID
      //var g_level_url = this.URL+'api/level/day/lineChart?s_tag_id='+this.scada_tag_id+'&m_id='+this.g_m_id+'&tag_id='+this.g_tag_id+'&s_date='+this.last_date
      var g_level_url = this.URL+'api/level/day/lineChart?s_tag_id='+this.tag+'&m_id='+this.m_id+'&tag_id='+this.tag_id+'&s_date='+this.last_date
      this.$http.get(g_level_url).then((res) => {  
        if(res.data.list.length >0){
            for(let i=0; i<res.data.list.length; i++) {
              var item = res.data.list[i]  
              this.gitem_24[item.FC_HOUR].value = item.F_VALUE               
            }
            this.gitem_24_cal = this.gitem_24
        }

      });  
      //3. 목표수위 설정 가져오기
     //
     var g_level_set_url = this.URL+'api/setting/gLevel/list?ws_id='+this.ws_id+'&fac_id='+this.fac_id+'&search_date='+this.last_date
      this.$http.get(g_level_set_url).then((res) => {  
        if(res.data.list.length >0){
            for(let i=0; i<res.data.list.length; i++) {
              //var item = res.data.list[i]  
              this.properties[ res.data.list[i].TWL_HOUR] =  res.data.list[i].TWL_VALUE   

            }
            this.save_sdate = res.data.list[0].START_DATE
            this.save_edate = res.data.list[0].END_DATE 
            this.properties_bak =  Object.assign({}, this.properties)
        }

      });        
    },

  },
  mounted() {
      

  },
  beforeUnmount() {
     clearInterval(this.inval);
  },
  components: {
  // ScheduleItem: ScheduleItem,
    //InfiniteLoading : toVue3(InfiniteLoading),
  },
};
</script>
<style lang="scss" scoped>
.row_data{
  height: calc(33% - 10px);
  max-height: 50px;
  width: calc(100%);
  margin: 0;
}

.row_data.block{
  margin-top : 5px;
  height: calc(33%);
  border-top:5px dashed steelblue;
  padding-top:5px;
}

.blue_1 .div_content1_left{
  background-color: rgb(21, 45, 90);
}
.blue_2 .div_content1_left{
  background-color: rgb(6, 31, 78);
}
.--gray .div_content1_left {
   background-color:#979696;
}

.yellow_1 .div_content1_left{
  background-color: #735B10;
}
.blue .div_content1_left{
  background-color: rgb(15, 76, 190);
}
.div_content_col.data {
   background-color: #B3B3B5;
}
.div_content_col.is--red {
   background-color: #940A0A;
}
.div_content_col.is--gray {
   background-color: #979696;
}
.div_content_col.yellow {
   background-color: #D9A810;
}
.div_content_col.d_yellow {
   background-color: #773E1A;
}
.div_content_col.dd_yellow {
   background-color: #735B10;
}
.div_content_col.data.blue_1 {
   background-color: #7F96C0;
}

.div_content_col.data.blue_2 {
   background-color: rgb(21, 45, 90);
}
.div_content_col.data.blue_3 {
   background-color: rgb(15, 76, 190);
}

// 2022 new 추가
.monitoring-content {
  background-color: none;
  padding: 0 0 30px;
  margin-top: 0px;
}
// .item-total{
//    height: 4px;
//     width: 200px;
//     position: relative;
//     margin: 10px;
// }

.row {
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.col {
  padding-right: 8px;
  padding-left: 8px;
}

.item-total {
      display: flex;
     
      span {
        font-size: 18px;
        margin-left: 16px;
        font-weight: normal;
      }

      strong {
        //color: $color-primary;
        font-size: 40px;
        font-weight: bold;
        margin-left: 20px;
        font-family: 'Roboto', sans-serif;
      }
    }
  
</style>
<style scoped>
/*
.mode_on{
	background: url(@/assets/img/img/pump_O/on.png) no-repeat !important;
}
.mode_off{
	background: url(@/assets/img/img/pump_O/off.png) no-repeat !important;
	opacity: 0.4;
}
*/
@-webkit-keyframes scale-up-center {
  0% {    -webkit-transform: scale(0.9);   transform: scale(0.9);  }
  100% {    -webkit-transform: scale(1);   transform: scale(1);  }
}
@keyframes scale-up-center {
  0% {    -webkit-transform: scale(0.9);  transform: scale(0.9);  }
  100% {   -webkit-transform: scale(1);   transform: scale(1);  }
}

input[type='radio']{
background: none;
border: none;
}

 input[type='radio']:after {
	height: calc(100% - 20px);
    width: calc(100% - 20px);
    background: #15284e;
    border: 10px #15284e;
    box-shadow: 0 0 10px #489cf2;
    border-style: solid;
    border-radius: 50%;
    content: '';
    display: inline-block;
}

input[type='radio']:checked:after {
	-webkit-animation: scale-up-center 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
     animation: scale-up-center 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    background: #489cf2;
    border: 10px #15284e;
    box-shadow: 0 0 10px #489cf2;
    border-style: solid;
    border-radius: 50%;
    content: '';
    display: inline-block;
}
.mdi-left.button{
  width: 20px;
  height: 20px;
  line-height: 6px;
  margin: 0 1em 0 .5em;
  text-indent: -4px;   
  border: none;
}   
.div_title {
	margin-bottom: 10px;
	display: flex;
  align-items: center;
  justify-content: space-between;  
}
.div_content1{
	width: calc(100% - 0px);
	height: calc(34vh - 90px);
	margin-right: 10px;
	padding: 15px;
}
.div_content1_left{
	width: calc(20% - 16px);
    height: calc(100% - 6px);
    margin-right: 10px;
    color: #fff;
    font-family: 'KHNPHDRegular';
}
.div_legend{
	width: calc(20% - 40px);
    height: calc(100% - 30px);
    margin-right: 10px;
    padding: 15px;
 /*   background: url(@/assets/img/div_new.png) center no-repeat; */
    background-size: 101% 100%;
    display: flex;
    color: #c3eaff;
    box-shadow: 0 0 5px #0cc7f7b0;
    font-family: 'KHNPHDRegular';
    flex-direction: column;
}
.div_legend_top{
	width: 100%;
    height: 50%;
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.div_legend_color{
	width: 20px;
    height: 20px;
    margin: 0 10px;
    border: 2px solid #ffffff8f;
    border-radius: 5px;
}
.div_legend_status{
	border: none;
    background-position: center !important;
    background-size: contain;
}
.div_type_btn{
	width: calc(80% / 5 - 10px);
    height: 100%;
    float: left;
   /* background: url(@/assets/img/pump_O/disable_true.png) no-repeat; */
    opacity: 0.5;
    margin-right: 10px;
    background-size: 100% 100% !important;
    font-size: 18px;
    font-family: 'KHNPHDBold';
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div_content1_right{
  /*
    width: calc(80% - 10px);
    height: calc(100% - 6px);
    padding: 6px 0px 6px 6px;
*/ 
    width: calc(90% - 10px);
    background: none !important;
}
.div_content1_right2{
	width: calc(100% - 18px);
    height: calc(50% - 21px);
    background: none !important;
    padding: 5px;
}
.div_content_col{
	width: calc(100% / 24 );
    height: calc(100% - 4px);
   /* margin: 3px 3px 3px 4px; */
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'KHNPHDRegular';
    font-size: 16px;
    background-color: #0080008f;
    /*
    border: 2px solid #ffffff8f;
    border-radius: 5px;
    */
    margin : 0 2.5px;
    color: #fff;
}
.div_content_col2{
	width: calc(100% / 24 - 9px);
    height: calc(100% - 4px);
    margin-right: 5px;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0a4cba8f;
    border: 2px solid #0A5AAE8f;
    border-radius: 5px;
    color: #fff;
}
.div_content_col2_status{
/*	background: url(@/assets/img/pump_O/on.png) no-repeat; */
    width: 100%;
    height: 100%;
    background-position: center !important;
}
.div_content2{
	width: calc(100% - 40px);
	height: calc(100% - 100px);
	padding: 20px;
}
.div_content2_left_category{
	height: calc(100% / 5 - 11px);
    width: calc(100% - 6px);
    margin-bottom: 5px;
    color: #fff;
    font-family: 'KHNPHDRegular';
    display: flex;
    align-items: center;
    text-indent: 20px;
}
.div_content2_left_category1{
	height: calc(100% - 6px);
    width: calc(80% - 11px);
    margin-right: 5px;
    display: flex;
    align-items: center;
}
.div_content2_left_category2{
	height: 48px;
    width: 50px;
    background: none;
    border: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div_content2_right_category{
	height: calc(100% / 5 - 11px);
    width: calc(100% - 6px);
    margin-bottom: 5px;
    color: #fff;
    font-family: 'KHNPHDRegular';
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.div_content2_right_col{
	width: calc(100% / 6 - 32px);
    height: calc(100% - 15px);
    padding: 10px 10px 5px 10px;
    margin-right: 10px;
  /*  background: url(@/assets/img/box_bg_big.png) no-repeat;*/
    background-size: 100% 100%;
}
.div-flex-center{
	display: flex;
    align-items: center;
    justify-content: center;
}
.div-flex-center1{
	display: flex;
    align-items: center;
 
}
.div_box_border{
  /*
	border: 1px solid #489cf2d1;
	box-shadow: 0 0 3px #489cf2; */
	background-color: #0947ae66;
	border-radius: 5px;
}
.div_box_title_border{
	background: #067be6cc;
    border: 3px solid #0cc7f7b0;
    box-shadow: 0 0 5px #0cc7f7b0;
    justify-content: center;
    text-indent: 0;
    border-radius: 5px;
}
.button_setting{
	height:25px;
	padding: 5px;
}
.input_design{
	background-color: #15284e8f;
    border: 1px solid #ffffff8f;
    border-radius: 5px;
    color: #fff;
    font-family: 'KHNPHDRegular';
    font-size: 16px;
    width: 80%;
    margin-left: -20px;
    text-align: left;
}
 .input_noChange{
     border: 1px solid #ffffff00;
    background-color: #15284e00;
    border-radius: 5px;
    color: #fff;
    font-family: 'KHNPHDRegular';
    font-size: 16px;
    width: 80%;
    margin-left: -20px;
}
.nowInfoText{
    color: #489cf2;
    font-size: 20px;
    font-weight: bold;
    text-shadow: none;
    margin: 0 5px;
}
</style>

