import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
//import { createI18n }  from 'vue-i18n'
import store from "./store";
import ECharts from "vue-echarts";
import VueLogger from "vuejs3-logger";
import Datepicker from "vue3-datepicker";
import 'vue3-datepicker/dist/vue3-datepicker.css';
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import Xrange from 'highcharts/modules/xrange';
//import mapInit from "highcharts/modules/map";
//import mapData from "@highcharts/map-collection/custom/world.geo.json";
//import mapData from "./assets/gb-all.topo.json";
import axios from "axios";

import "./assets/css/custom.css";
import "./assets/css/global.css";
import "./assets/css/peakcontrol.css";
import "./assets/css/layout.css";
import "./assets/css/font.css";
import './assets/scss/style.scss';
import i18n from './i18n'
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import './assets/css/icons.min.css';
import VueGoodTablePlugin from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
//import BootstrapVue from 'bootstrap-vue'
//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/table_widget_v2.css';
import mitt from 'mitt';

const isProduction = process.env.NODE_ENV === "production";


// Use plugin with optional defaults

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

//mapInit(Highcharts);
Xrange(Highcharts);

//Highcharts.maps["myMapName"] = mapData;
Highcharts.backgroundColor = "#000";
const emitter = mitt();


const app = createApp(App);
app.use(VueGoodTablePlugin);
//app.use(BootstrapVue)
app.use(store);
app.use(BootstrapVue3);
app.use(ECharts);
app.use(Datepicker);
app.use(router);
app.use(VueLogger, options);
app.component('Vue3Datepicker', Datepicker);

app.use(HighchartsVue);
app.use(i18n);

app.config.globalProperties.$http = axios
app.config.globalProperties.emitter = emitter;

//app.config.globalProperties.URL = 'http://localhost:3000/'
//app.config.globalProperties.URL = 'http://ems.neverlosewater.com/'
//app.config.globalProperties.URL = 'http://10.167.1.27:11112/'
app.config.globalProperties.URL = ''
app.mount("#app");