<template>
    <MenuItem menuImg="icon/pms.png" menuKey="100" menuTitle="스마트PMS"/>
    
    <!-- 스마트정수정 pms 1탭스 메뉴만 정리함 -->
    <MenuItem menuImg="icon/pms_dash.svg" menuClick="location.href='http://192.168.1.99:8082'" menuTitle="대시보드" menuKey="1"/>
    <MenuItem menuImg="icon/AUTO_MENU/chaksu.png" @handleMenuClick="isChaksu=!isChaksu" menuTitle="착수" menuKey="1"/>
    <div class="twoDepthMenu" v-if="isChaksu">
        <MenuItem menuClick="location.href='/monitor3'" menuTitle="유입밸브" menuKey="2"/>
    </div>
    
    <MenuItem menuImg="icon/AUTO_MENU/yakpoom.png" @handleMenuClick="isYakpoom=!isYakpoom" menuTitle="약품" menuKey="1"/>
    <div class="twoDepthMenu" v-if="isYakpoom">
        <MenuItem menuClick="location.href='/monitor4'" menuTitle="약품펌프" menuKey="2"/>
        <MenuItem menuClick="location.href='/monitor6'" menuTitle="자동급수펌프" menuKey="2"/>
    </div>
    
    <MenuItem menuImg="icon/AUTO_MENU/honhwa.png" @handleMenuClick="isHonhwa=!isHonhwa" menuTitle="혼화응집" menuKey="1"/>
    <div class="twoDepthMenu" v-if="isHonhwa">
        <MenuItem menuClick="location.href='/monitor7'" menuTitle="급속분사교반기" menuKey="2"/>
        <MenuItem menuClick="location.href='/monitor8'" menuTitle="응집기" menuKey="2"/>
    </div>
    

    <MenuItem menuImg="icon/AUTO_MENU/chemjeon.png" @handleMenuClick="isChemjeon=!isChemjeon" menuTitle="침전" menuKey="1"/>
    <div class="twoDepthMenu" v-if="isChemjeon">
        <MenuItem menuClick="location.href='/monitor9'" menuTitle="슬러지수집기" menuKey="2"/>
    </div>
    

    <MenuItem menuImg="icon/AUTO_MENU/yeogwa.png" @handleMenuClick="isYeogwa=!isYeogwa" menuTitle="여과" menuKey="1"/>
    <div class="twoDepthMenu" v-if="isYeogwa">
        <MenuItem menuClick="location.href='/monitor10'" menuTitle="역세펌프" menuKey="2"/>
        <MenuItem menuClick="location.href='/monitor11'" menuTitle="역세송풍기" menuKey="2"/>
    </div>
    
    <MenuItem menuImg="icon/AUTO_MENU/gac.png" @handleMenuClick="isGac=!isGac" menuTitle="GAC" menuKey="1"/>
    <div class="twoDepthMenu" v-if="isGac">
        <MenuItem menuClick="location.href='/monitor12'" menuTitle="역세펌프" menuKey="2"/>
    </div>
    

    <MenuItem menuImg="icon/PMS_MENU/ozone.png" @handleMenuClick="isOzone=!isOzone" menuTitle="오존" menuKey="1"/>
    <div class="twoDepthMenu" v-if="isOzone">
        <MenuItem menuClick="location.href='/monitor15'" menuTitle="오존발생기" menuKey="2"/>
        <MenuItem menuClick="location.href='/monitor13'" menuTitle="모티브펌프" menuKey="2"/>
        <MenuItem menuClick="location.href='/monitor14'" menuTitle="냉각수펌프" menuKey="2"/>
    </div>
    
    <MenuItem menuImg="icon/AUTO_MENU/sodoc.png" @handleMenuClick="isSodoc=!isSodoc" menuTitle="소독" menuKey="1"/>
    <div class="twoDepthMenu" v-if="isSodoc">
        <MenuItem menuClick="location.href='/monitor16'" menuTitle="차염발생기" menuKey="2"/>
    </div>

    <MenuItem menuImg="icon/AUTO_MENU/auto_songsu.svg" @handleMenuClick="isAutoSongsu=!isAutoSongsu" menuTitle="송수" menuKey="1"/>
    <div class="twoDepthMenu" v-if="isAutoSongsu">
        <MenuItem menuClick="location.href='/monitor1'" menuTitle="송수펌프모터" menuKey="2"/>
        <MenuItem menuClick="location.href='/monitor2'" menuTitle="펌프기동반" menuKey="2"/>
        <MenuItem menuClick="location.href='/monitor17'" menuTitle="변압기반" menuKey="2"/>
        <MenuItem menuClick="location.href='/monitor18'" menuTitle="VCB반" menuKey="2"/>
    </div>

    <MenuItem menuImg="icon/PMS_MENU/tonggae.png" menuClick="location.href='#'" menuTitle="통계/이력" menuKey="1"/>
    
        
</template>

<script>
import MenuItem from '../MenuItem.vue'
export default {
    components: { MenuItem },
    data(){
        return {
            isChaksu:false, isYakpoom:false, isHonhwa:false, isChemjeon:false, isYeogwa:false, isGac:false, isOzone:false, isSodoc:false, isAutoSongsu:false
        }
    }

}
</script>

<style>

</style>