
import {createI18n} from 'vue-i18n'
import en from './locales/en.json'
import ko from './locales/ko.json'
//import vi from './locales/vn.json'

//Vue.use(VueI18n)

//export default new VueI18n({
  export default new createI18n({
  locale: 'ko',
  fallbackLocale: 'ko',
  messages: { en, ko }
})
