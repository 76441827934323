<template>
    <b-col >
        <div class="bg" style="height:calc(48vh);">
            <div class="div_title wt">Water Tank Level(%)</div>
            <b-col style="width: 105%; height: 105%;margin-left:-30px;margin-top:-30px;">                
                <v-chart :option="option" :autoresize="true" />
            </b-col>
        </div>
    </b-col>
</template>

<script>
import "echarts";
import VChart from 'vue-echarts';
export default {
    props:[
        'chartData',
        'xdata'
    ],
    components: {
        VChart
    },
    watch: {
      chartData: function() {
            this.redraw();
       }, 
       url() {
        if(this.url==='') return
        
        this.chartData =[]
   

        this.xdata = [];
        this.$http.get(this.url).then((res) => {
            //데이터 셋팅
              var b_data = res.data.list;

             if( b_data !=="" && b_data != null) {
     
                 var gcdata = [];
             
                 //var ccdata = [];
                 var xcdata = [];
                 var size = b_data.length 
                
                 for(let i=0; i < size ; ++i){        
                    xcdata.push(b_data[i].FAC_NM)
                    gcdata.push(b_data[i].g_level_per);                  
                
                 }
                 this.chartData = gcdata ;    
                   
                 this.xdata = xcdata ;
                  //this.redraw();
             }else{
                this.xdata = [];
                this.chartData =[]
     

             }
        })
        .catch((error) => {
            // this.loaded = true;
            this.xdata = [];
            this.chartData =[]
      
            console.log(error)
        });
       


       }
    },    
    data() {
        return {
         //   chartData : [],
        //    xdata : [],
            option: {
                yAxis: {
                    type: 'category',
                    data: ['오존설비동', '역세펌프', '염소투입동', '구미(정)펌프', '선산(가)', '북삼(가)', '김천(가)']
                },
                xAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [150, 230, 224, 218, 135, 147, 260],
                        type: 'bar',
                        areaStyle: {}
                    }
                ],
                grid : {containLabel:true},
                tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                        type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            restore: {},
                            saveAsImage: {}
                            }
                },
            }
        }
    },
   methods: {

        redraw () {
            this.option.series[0].data = this.chartData;            
            this.option.yAxis.data = this.xdata;
       },    
    },
}
</script>

<style scoped>
.bg {
  /*  background: url(@/assets/img/box_bg_middle.png) no-repeat; 
    background-size: 100% 100%;  */
     border: solid 1px #2b89e97a;
     background-color: #0f1d3c69; 
}

.bar {
    background: url(@/assets/img/00_title_objline.png) repeat-x;
    background-size: 100% 100%;
    height: 50%;
    margin-left:
        015px;
    mix-blend-mode: overlay;
}

.dash_title {
    height: 100%;
    font-family: "KHNPHDRegular";
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #5180B4;
    background-position-x: right;
}
.div_title.wt{
    background: null;
    background-color: #0a4078; 
    opacity: 0.8;
}
</style>