<template>
<div>
    <MenuItem menuImg="icon/ems.png" menuKey="100" menuTitle="WEMS"/>

    <MenuItem menuImg="icon/dashboard.png" @handleMenuClick="this.$router.push('/dashboard')" menuTitle="Dashboard" menuKey="1" />
    <MenuItem menuImg="icon/ai.png" @handleMenuClick="this.$router.push('/PumpControl')" menuTitle="Pump & Valve" menuKey="1" />
    <MenuItem menuImg="icon/spend.png" @handleMenuClick="this.$router.push('/PumpControlDetailed')" menuTitle="Optimized Control" menuKey="1" />
    <MenuItem menuImg="icon/reduce.png" @handleMenuClick="this.$router.push('/MajorDrainage')" menuTitle="Water Tank" menuKey="1" />

  
</div>
</template>
<script>
import MenuItem from '../MenuItem.vue'
export default {
    components: { MenuItem }
    ,data(){
        return{
            ai : false, songsumenu:false, peak :false, 
            dr:false,isEnergyUse:false,isEnergyOptimize:false,
            isSetting:false, isReport:false
        }
    },
    methods : {
        goToDashboard() {
        },
        menuclose(){
            this.songsumenu = false;
            this.peak = false;
            this.isEnergyUse = false;
            this.isEnergyOptimize = false;
            this.isSetting = false;
        }
    }    
}
</script>