<template>
  <div :class="className" :style="{ display: 'block' }">
    <div class="d-flex flex-column">
      <div class="flex_center_between justify_start">
        <div class="content__text-box" :style="{ width: '120px' }">{{data.title}}</div>
        <div class="content__value-box">
          <span>{{data.value}}</span><span class="unit">{{data.unit}}</span>
        </div>
      </div>
      <div class="flex_center_between justify_start" v-if="data.sub">
        <div class="content__text-box">{{data.sub.title}}</div>
        <div class="content__value-box">
          <span>{{data.sub.value}}</span><span class="unit">{{data.sub.unit}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:[
      'data'
  ],
  data(){
      return {
        className:this.data.sub?'fL animationItem-two':'fL animationItem'
      }
  }
};
</script>

<style>
.animationItem-two {
  -webkit-animation: scroll 12s infinite;
  animation: scroll 12s infinite;
}

.animationItem {
  -webkit-animation: scroll1 12s infinite;
  animation: scroll1 12s infinite;
}

@-webkit-keyframes scroll {
  0.0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  12.3% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  24.9% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  41.5% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  58.1% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  74.7% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  91.7% {
    -webkit-transform: translateY(145px);
    transform: translateY(145px);
  }

  95.0% {
    -webkit-transform: translateY(145px);
    transform: translateY(145px);
  }
}

@keyframes scroll {
  0.0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  12.3% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  24.9% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  41.5% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  58.1% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  74.7% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  91.7% {
    -webkit-transform: translateY(145px);
    transform: translateY(145px);
  }

  100% {
    -webkit-transform: translateY(145px);
    transform: translateY(145px);
  }
}
@-webkit-keyframes scroll1 {
  0.0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  12.3% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  24.9% {
    -webkit-transform: translateY(28px);
    transform: translateY(28px);
  }

  41.5% {
    -webkit-transform: translateY(28px);
    transform: translateY(28px);
  }

  58.1% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  74.7% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  91.7% {
    -webkit-transform: translateY(74px);
    transform: translateY(74px);
  }

  100% {
    -webkit-transform: translateY(74px);
    transform: translateY(74px);
  }

}

@keyframes scroll1 {
  0.0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  12.3% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  24.9% {
    -webkit-transform: translateY(28px);
    transform: translateY(28px);
  }

  41.5% {
    -webkit-transform: translateY(28px);
    transform: translateY(28px);
  }

  58.1% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  74.7% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  91.7% {
    -webkit-transform: translateY(74px);
    transform: translateY(74px);
  }

  100% {
    -webkit-transform: translateY(74px);
    transform: translateY(74px);
  }

}
</style>
