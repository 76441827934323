<template>
    <MenuItem menuImg="icon/auto.png" menuKey="100" menuTitle="자율운영"/>
    
    <MenuItem menuImg="icon/AUTO_MENU/chaksu.png" menuTitle="착수"  @handleMenuClick="isChaksu = !isChaksu" menuKey="1" />
    <div class="twoDepthMenu" v-if="isChaksu">
        <MenuItem menuClick="" menuTitle="세부현황" menuKey="2"/>
        <MenuItem menuClick="" menuTitle="시뮬레이터" menuKey="2"/>
    </div>
    
    <MenuItem menuImg="icon/AUTO_MENU/yakpoom.png" menuTitle="약품"  @handleMenuClick="isYakpoom = !isYakpoom" menuKey="1" />
    <div class="twoDepthMenu" v-if="isYakpoom">
        <MenuItem menuClick="" menuTitle="세부현황" menuKey="2"/>
        <MenuItem menuClick="" menuTitle="시뮬레이터" menuKey="2"/>
    </div>

    <MenuItem menuImg="icon/AUTO_MENU/honhwa.png" menuTitle="혼화/응집"  @handleMenuClick="isHonhwa=!isHonhwa" menuKey="1" />
    <div class="twoDepthMenu" v-if="isHonhwa">
        <MenuItem menuClick="" menuTitle="세부현황" menuKey="2"/>
        <MenuItem menuClick="" menuTitle="시뮬레이터" menuKey="2"/>
    </div>
    
    <MenuItem menuImg="icon/AUTO_MENU/chemjeon.png" menuTitle="침전"  @handleMenuClick="isChemjeon=!isChemjeon" menuKey="1" />
    <div class="twoDepthMenu" v-if="isChemjeon">
        <MenuItem menuClick="" menuTitle="세부현황" menuKey="2"/>
        <MenuItem menuClick="" menuTitle="시뮬레이터" menuKey="2"/>
    </div>
    
    <MenuItem menuImg="icon/AUTO_MENU/yeogwa.png" menuTitle="여과"  @handleMenuClick="isYeogwa=!isYeogwa" menuKey="1" />
    <div class="twoDepthMenu" v-if="isYeogwa">
        <MenuItem menuClick="" menuTitle="세부현황" menuKey="2"/>
        <MenuItem menuClick="" menuTitle="시뮬레이터" menuKey="2"/>
    </div>
    
    <MenuItem menuImg="icon/AUTO_MENU/gac.png" menuTitle="GAC여과"  @handleMenuClick="isGac=!isGac" menuKey="1" />
    <div class="twoDepthMenu" v-if="isGac">
        <MenuItem menuClick="" menuTitle="세부현황" menuKey="2"/>
        <MenuItem menuClick="" menuTitle="시뮬레이터" menuKey="2"/>
    </div>
    
    <MenuItem menuImg="icon/AUTO_MENU/sodoc.png" menuTitle="소독"  @handleMenuClick="isSodoc=!isSodoc" menuKey="1" />
    <div class="twoDepthMenu hiddenMenu" v-if="isSodoc">
        <MenuItem menuClick="" menuTitle="세부현황" menuKey="2"/>
        <MenuItem menuClick="" menuTitle="시뮬레이터" menuKey="2"/>
    </div>

    <MenuItem menuImg="icon/AUTO_MENU/auto_songsu.svg" menuTitle="송수"  @handleMenuClick=handleMenuClick menuKey="1" />
</template>

<script>
import MenuItem from '../MenuItem.vue';

export default {
    components: { MenuItem },
    data(){
        return{
            isChaksu:false,isYakpoom:false, isHonhwa:false,isChemjeon:false, isYeogwa:false, isSodoc:false, isGac:false
        }
    }
}
</script>

<style>

</style>