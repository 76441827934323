<template>
	
	<g style="opacity:0.8"
		  :class="set_class"
	>
		<g :transform="`translate(${cx},${cy})`">
			<path
			:style="`fill:${fill};fill-opacity:1;fill-rule:nonzero;stroke:none`"
			d="m 0,0 c 0,-12.969 -10.514,-23.483 -23.483,-23.483 -12.97,0 -23.484,10.514 -23.484,23.483 0,12.969 10.514,23.483 23.484,23.483 C -10.514,23.483 0,12.969 0,0" />
		</g>
		<g :transform="`translate(${cx},${cy})`">
			<path  
				style="fill:none;stroke:#ffffff;stroke-width:0.75;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
				d="m 0,0 c 0,-12.969 -10.514,-23.483 -23.483,-23.483 -12.97,0 -23.484,10.514 -23.484,23.483 0,12.969 10.514,23.483 23.484,23.483 C -10.514,23.483 0,12.969 0,0 Z" />
		</g>

		<text
			xml:space="preserve"
			style="font-size:10.074px;opacity:1;fill:none;fill-opacity:1;stroke:#ffffff;stroke-width:1.25925;stroke-linejoin:miter;stroke-dasharray:none;stroke-opacity:1"
			:x=x
			:y=y
			transform="scale(0.97823159,-1.0222528)">
			<tspan dy="-0.5em" style="text-align:center;text-anchor:middle;stroke-width:0.5" :x=x :y=y>
				{{value}}
			</tspan>
			<tspan dy="1em" style="text-align:center;text-anchor:middle;stroke-width:0.5" :x=x :y=y>
				{{unit}}
			</tspan>

			<tspan dy="4em" style="font-size:0.8em; font-weight:normal; text-align:center;text-anchor:middle;stroke-width:0.5" :x=x :y=y>
				{{name}}
			</tspan>
		</text>
	</g>
</template>
<style scoped>

	.bounce {
		animation-name: bounce;
		animation-timing-function: linear;
		animation-duration: 2.5s;
		animation-iteration-count: infinite;
	}

	@keyframes bounce {
		0%   { transform: translateY(0); }
		50%  { transform: translateY(-5px); }
		100% { transform: translateY(0); }
	}
</style>
<script>
	export default {
        props:{
            cx: {
                type: String,
                required: true
            },
            cy: {
                type: String,
                required: true
            },
            x: {
                type: String,
                required: true
            },
            y: {
                type: String,
                required: true
            },
            value: {
                
            },
            unit: {
                type: String,
                required: true,
                default: '' 
            },
            name: {
                type: String,
                required: true,
                default: '' 
            },
            fill:{
                type: String,
                default: '#265caa'
			},
			set_class: {
                type: String,
                default: 'bounce'
			},
        },
		data() {
			return {
				isHovering: false
			};
		},
		methods: {
		},
		mounted : function(){
		}
	}
</script>
