<template>
    <b-col >
        <div :class="['levelchart', { 'large': height_size === 'large' }]"  >
       
            <b-col class="mx-3" style="width: 100%; height: 100%;" >
                <v-chart :option="option" :autoresize="true" />
            </b-col>
        </div>
    </b-col>
</template>

<script>
import "echarts";
import VChart from 'vue-echarts';
export default {
    props:[
        'height_size',
        'ps_data'
   
    ],
    components: {
        VChart
    },
    watch:{
        ps_data(){
            this.chartData = this.ps_data
            //this.redraw();
        },
        chartData(){
            this.redraw();
        },
  
    },
    data() {

        return {
           search_date : '2024-02-01',
           option : {
               // color: ['#496a9b','#334c7e', '#00DDFF',  '#FF0087', '#FFBF00'],
                tooltip: {
                    trigger: 'item'
                },
                grid: {
                    left: '-5%',
                    right: '14%',
                    bottom: '10%',
                    top: '1%',
                    containLabel: true
                },
                legend: {
                    orient: 'vertical',
                    right: 10,
                    top: 'center',
                    color : '#000',
                    textStyle: { color: 'white'  }
                },
                series: [
                     
                        {
                        name: '시설별 전력분포(kW)',
                        type: 'pie',
                        center: ["40%", "41%"],
                        radius: ['25%', '70%'],
                        avoidLabelOverlap: false,                      
                      
                        itemStyle: {
                            borderRadius: 5,
                            opacity: 0.8,
                        // borderColor: '#fff',
                            borderWidth: 1,
                           // color : "#fff"
                        },
                    label: {
                        show: true,
                        position: 'center'
                    },
                    /*
                    emphasis: {
                        label: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                        }
                    },
                    */
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: 173, name: 'HPG' },
                        { value: 419, name: 'SPG' },
                        { value: 2913, name: '4GDG' },
                        { value: 229, name: 'KCG' },
                        { value: 338, name: 'BSG' },
                        { value: 61, name: 'SSG' },

                    ]
                    }
                ]
            }

        }
    },
    methods: {
        search_data(){
            this.chartData =[]
           // this.xdata = [];
            var eng_ps_name = {'신평공업 송수펌프동':'SPG','원호생활 송수펌프동': 'WHS', '선산 가압장':'SSG','국가산단/4공단생활':'KGSD','김천 가압장':'KCG',
            '북삼 가압장' :'BSG','4공단공업 송수펌프동':'4GDG','신평생활 송수펌프동':'SPS'
            }
            var url = this.URL+'api/pump/ps_lastdata'
            this.$http.get(url).then((res) => {

                var b_data = res.data.list;
                if( b_data !=="" && b_data != null) {
                    var ccdata = [];
                    var xcdata = [];
                    var size = b_data.length 
                    for(let i=0; i < size ; ++i){
                       // xcdata.push(b_data[i].RCV_DT)
                         var name = eng_ps_name[b_data[i].FAC_NM]
                        ccdata.push({value: b_data[i].PWI_VALUE, name : name});
                    }
                    this.chartData = ccdata ;
                    this.redraw();
                    //this.xdata = xcdata ;
                }else{
                    this.xdata = [];
                    this.chartData = [] ;
                    
                }
            })
            .catch((error) => {
                // this.loaded = true;
                this.xdata = [];
                this.chartData = [] ;           
                this.loaded_ts_flow = true
                console.log(error)
            });
        },
        redraw () {
            this.option.series[0].data = this.chartData;
          //  this.option.xAxis.data = this.xdata;
        },
        current_date(today, a){
            var year = today.getFullYear();
            var month = ('0' + (today.getMonth() + 1)).slice(-2);
            var day = ('0' + today.getDate()).slice(-2);
            var hours = ('0' + today.getHours()).slice(-2);
            this.current_hour =  today.getHours()    
            
            // var minutes = ('0' + today.getMinutes()).slice(-2);
            // var dateString = year + '-' + month  + '-' + day +" "+hours+ ':' + minutes;
            var dateString = year + '-' + month  + '-' + day +" "+hours+ ':00';
            this.last_time = dateString
            if(a == 1){ //시간값
            dateString = year + '-' + month  + '-' + day ;
            }

            return dateString
        },

    },
   mounted() {
    //  this.$http.get(this.URL+'api/get_monitoring_list').then((res) => {
     //var today = new Date();
     var today = new Date(2023,5,1,0,0,0,0);   
     this.search_date = this.current_date(today,0);
     //var today = new Date(2023,5,1,0,0,0,0);     

     this.last_date = this.current_date(today,1);
     
     this.search_data();   
     // this.chartData = this.data
     //setInterval(this.realtime_setting, 1000*60*10);//5분 마다 새로고침
     

  },  
}
</script>

<style lang="scss" scoped>
.levelchart {
 /*   background: url(@/assets/img/box_bg_middle.png) no-repeat; */
    //height : 250px;
    height: calc(25vh - 10px );
}
.levelchart.large {
    height: calc(90vh/2 - 50px );
}
.bar {
    background: url(@/assets/img/00_title_objline.png) repeat-x;
    background-size: 100% 100%;
    height: 30%;
    margin-left: 015px;
    mix-blend-mode: overlay;
}

.dash_title {
    height: 100%;
    font-family: "KHNPHDRegular";
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #5180B4;
    background-position-x: right;
}
</style>