<template>
<div>
 <div class="contents-container" style="left:18px; float:left;width: calc(100% - 20px); height: calc(100% - 105px); margin: 10px;padding-left:20px;">
<!-- title -->
  <div class="title_wrap">
    <span class="title">주요배수지 관리</span>
    <div class="title_line"></div>
    
  </div>

   <!--div style="float:right;margin-right: 4%; margin-top:-40px;" > <b-form-select class="facility__input"  v-model="qtb_id" :options="qtb_id_option"  ></b-form-select></div-->
                           
   <div style="width:100%;height:calc(100% - 80px);margin-top:0px; float:left;">	

      <div style="height: calc(90vh - 10px); width: 100%; padding: 0 10px 20px 10px;">        
        <div class="fL div-middle " style="height: 100%; width: 100%; background: none;">
                <!-- 검색조건 -->
          <!--div style="display: flex;align-items: center;width:99%;height:40px; margin-top: 20px;justify-content: flex-end; margin-right: 42px;">
	            <span class="fL">
                 <b-form-input size="sm" v-model="search_date" type="date" class=" input_date m-0 btn-gray-date white"/>
              </span> 
            	<span class="buttonArea fL"><span class="button" @click="search_data">조회</span></span>
          </div-->
      <!-- 배수지리스트-->
      <div  style="width:calc(15% - 3px);height: calc(86% - 26px);float:left">
        <WTLeft :wt_list = "box"  @updateCheckIndex="updateCheckIndex"  :activeCheckIndex="activeCheckIndex"></WTLeft>
      </div>  
      <div style="float:left;width:calc(5% - 3px);margin-top:50px;height: calc(67% - 26px);">
       <ArrowMove></ArrowMove> 
      </div>
      <div class="div-middle plate_img fL" style="margin-left:10px;width:calc(40% - 10px);height: calc(47% - 26px);">
        <div class="div_title">배수지 정보</div>
        <div  class="items4ChartArea js-plotly-plot" style="  height: calc(75% - 30px);  width: 100%;">
            <div class="wt_proper" >
              <!--선택된 배수지의 하루동안 목표수위, 운영수위 데이터를 뿌려준다.-->
              <!--LevelTrend :height_size="chart_height"  :url="real_url"></LevelTrend-->
                <b-row>
                    <b-col sm="2"></b-col>
                    <b-col sm="2">
                        <label>배수지명</label>
                    </b-col>
                    <b-col sm="5">                      
                        <b-form-input size="sm" v-model="properties['FAC_NM']" ></b-form-input>
                    </b-col>
                    <b-col sm="3"></b-col>
                </b-row> 
                <b-row>
                    <b-col sm="2"></b-col>
                    <b-col sm="2">
                        <label>최저 수위</label>
                    </b-col>
                    <b-col sm="5">                      
                        <b-form-input size="sm" v-model="properties['MIN_LEVEL']" ></b-form-input>
                    </b-col>
                    <b-col sm="2"></b-col>
                </b-row>  
                <b-row>
                    <b-col sm="2"></b-col>
                    <b-col sm="2">
                        <label>최고 수위</label>
                    </b-col>
                    <b-col sm="5">                      
                        <b-form-input size="sm" v-model="properties['MAX_LEVEL']" ></b-form-input>
                    </b-col>
                    <b-col sm="2"></b-col>
                </b-row> 
                <b-row>
                    <b-col sm="2"></b-col>
                    <b-col sm="2">
                        <label>가상 면적</label>
                    </b-col>
                    <b-col sm="5">                      
                        <b-form-input size="sm" v-model="properties['V_AREA']" ></b-form-input>
                    </b-col>
                    <b-col sm="2"></b-col>
                </b-row>
                <b-row>
                    <b-col sm="2"></b-col>
                    <b-col sm="2">
                        <label>상세설명</label>
                    </b-col>
                    <b-col sm="5">                      
                        <b-form-textarea size="sm" v-model="properties['FAC_DESC']" ></b-form-textarea>
                    </b-col>
                    <b-col sm="2"></b-col>
                </b-row>    
                                                         
            </div>
          </div> 
          <div class="btn_cntl"  @click="watertank_save" :aria-disabled="control_active">
              <!--span :class="['ai_status color', {'red': arr_item.ai_status === 'ON'}]"> {{arr_item.ai_status}} </span-->
              <button class='map button' type="button"  v-b-tooltip.hover >저 장</button> 
          </div>    
       
      </div>
      <div class="div-middle plate_img fL" style="margin-left:10px;width:calc(39% - 10px);height: calc(47% - 26px);">
      	<div class="div_title">알고리즘 사용 수위계 선택</div>
        <div id="donut" class="items4ChartArea js-plotly-plot" style="  height: calc(100% - 40px);  width: 90%; margin: 3% 0 0 10%;">
          <div class="plot-container plotly" style="height:75%;" >
            <!--v-radio-group>
              <v-radio :label="item.tag_name" v-bind:checked="item.status === 'A'" :value="item.status" :v-for="item in level_tags" v-bind:key="item" />
            </v-radio-group-->
           <b-form-radio-group  size="lg" v-model="select_tag_id" :options="tag_id_option" class="level_radio"></b-form-radio-group>
            <!--선택된 배수지의 하루동안 수위분포 데이터를 합산해서 뿌려준다.-->
            <!--useTrend :height_size="chart_height"  :search_date="call_s_date" ></useTrend-->
          </div>
          <div class="btn_cntl"  @click="status_save" :aria-disabled="control_active">
            <!--span :class="['ai_status color', {'red': arr_item.ai_status === 'ON'}]"> {{arr_item.ai_status}} </span-->
            
            <button class='map button' type="button"  v-b-tooltip.hover >저 장</button> 
          </div>
        </div>    
      </div>
      <div class="div-middle plate_img fL" style="margin-left:10px;width:calc(79% - 10px);height: calc(44% - 16px);margin-top: 10px;">
	      <div class="div_title">목표 수위 설정 및 적용결과 </div>
		    <div  style="height:calc(100% - 60px);padding: 10px; display: flex;  flex-direction: row;   flex-wrap: wrap;   align-items: center;   justify-content: center;">
          <!-- 배수지의 현재수위를 뿌려준다-->
          <glevelSet :wt_properties="wt_properties"  :last_date = "last_date" :lei_value_23="lei_value_23" />
		    </div>
      </div>    
      </div>
    </div>	
   </div>

  </div>
</div>
</template>
<script>
import glevelSet from "./item/glevel_set.vue";
//import useTrend from "@/components/chart/PieChart.vue";
import WTLeft from "@/components/watertank/wt_left.vue";
import ArrowMove from "@/components/watertank/ArrowMove.vue";
//import WaterLevel from './WaterLevel.vue'
export default {
  
  name: "WaterTankTrend",
  props: [],
  watch: {   
    /* 
    tag(){
      this.search_data();

    },
    
    current_hour(){
       this.change_date_time();
    },
    last_date(){
      this.change_date_time();
    }
    */
  },
  data() {
          
    return {
      fac_id: "4",
      ws_id: "1",
      lei_value_23: null,
      real_url : '',
      tag:'',
      tag_id:'',
      m_id:'',
      pie_url : '',
      last_date : '2024-02-02',
      call_s_date : '2024-02-02',
      time_unit : 0,   
      chart_height : 'large',   
      charttype : "",
      properties: {'FAC_NM':"신평공업배수지","FAC_DESC":"", 
                   "MIN_LEVEL":3.6,"MAX_LEVEL": 4.6 , "V_AREA" : 2000},  //배수지 설정 태그
      properties_bak: {}, // 
      activeCheckIndex : 0,
      time_unit_option : [
                { value: 0, text: '시' },
                { value: 1, text: '일' },
      
      ],
      major : 0,
      box : [],
      wt_properties: {},
      level_value : [],
      select_tag_id : 'tag1',
      select_tag_id_bak : 'tag1',
      tag_id_option : [
        {text:"tag1", value:"tag1", tag_name: "tag1" ,tag_id:"1", status: "A"},
        {text:"tag2", value:"tag2", tag_name: "tag2" ,tag_id:"2", status: "I"},
        {text:"tag3", value:"tag3", tag_name: "tag3" ,tag_id:"3", status: "I"},
        {text:"tag4", value:"tag4", tag_name: "tag4" ,tag_id:"4", status: "I"},
      ]

    };
  },
  methods: {
    goRouter(path) {
     // this.$router.push({path: path, query: { tag: this.tag }})
      this.$router.push({path: path})
    },
    change_date(e){
      // this.last_date = e
    },
    change_hour(e){
       //this.current_hour = e
    },
    current_date(today, a){
      var year = today.getFullYear();
      var month = ('0' + (today.getMonth() + 1)).slice(-2);
      var day = ('0' + today.getDate()).slice(-2);
      var hours = ('0' + today.getHours()).slice(-2);
      this.current_hour =  today.getHours()    
      
     // var minutes = ('0' + today.getMinutes()).slice(-2);
     // var dateString = year + '-' + month  + '-' + day +" "+hours+ ':' + minutes;
     var dateString = year + '-' + month  + '-' + day +" "+hours+ ':00';
     this.last_time = dateString
     if(a == 1){ //시간값
       dateString = year + '-' + month  + '-' + day ;
     }

      return dateString
    },
    change_date_mnf(a){
        let default_date = new Date(this.last_date.toLocaleString());
          default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
        this.last_date = this.date_to_str(default_date)
       // this.change_date_time();
    },
    change_date_time(){
      var hours = ('0' + this.current_hour).slice(-2);
      this.last_time = this.last_date +" "+ hours +":00"
    },
    date_to_str(format){
      var year = format.getFullYear();
      var month = format.getMonth() + 1;
      if(month<10) month = '0' + month;
      var date = format.getDate();
      if(date<10) date = '0' + date; 
  
      return year + "-" + month + "-" + date ;
    },

    change_date_flow(add_day) {
      //현재 날짜보다 이후에 것을 search시 검색이 안된다
       var change_date=  document.getElementById('input_search_date').value.split(" ");
       this.last_date =  change_date[0];
       if(this.s_current_date <= this.last_date && add_day > 0 ){
         return
       }
       let default_date = new Date(this.last_date);
        //alert(default_date)
       default_date.setDate(default_date.getDate()+Number(add_day)).toLocaleString() ;
       this.last_date = this.current_date(default_date,0)
       if(this.last_date > this.s_current_date){
         return
       }else{
         this.search_data();
       }
         
        
    },
    realtime_setting(){
      var today = new Date();
      this.s_current_date = this.current_date(today,0);
       //var today = new Date(2023,5,1,0,0,0,0);     
       this.last_date = this.current_date(today,1);
    },
    updateCheckIndex(payload) {
       this.activeCheckIndex = payload   
       this.ws_id = this.box[payload].WS_ID  
       this.fac_id = this.box[payload].FAC_ID
       this.tag=this.box[payload].LEI_TAG
       this.tag_name = this.box[payload].FAC_NM
       this.m_id = this.box[payload].G_LEI_M_ID
       this.tag_id = this.box[payload].G_LEI_TAG_ID
       this.lei_value_23 = this.box[payload].lei_value_23
       this.last_date = this.box[0].G_LEI_DATE    
      // var sdate = this.box[payload].date
       this.wt_properties = this.box[payload]
       this.search_data(0);
      
      // if(sdate !== null) {  
      
        //  this.get_time_series_level() //테이블값 셋팅
       
      // }
    }, 
    get_time_series_level() {
      //var sdate = this.start_date  
        this.real_url = this.URL+'api/level/day/lineChart?s_tag_id='+this.tag+'&m_id='+this.m_id+'&tag_id='+this.tag_id+'&s_date='+this.search_date
 
    },
    check_float(text){
      //var pattern =  /^[0-9]*.[0-9]*[1-9]+$/
      var pattern =/[\-0-9\.]+/g
      if (pattern.test(text)) {
	        return true
      }else{
        return false
      }

    },
    //배수지 정보 수정 저장
    async watertank_save(){
      let params = {}
      let keys = Object.keys(this.properties_bak)
      if(this.ws_id === undefined || this.fac_id === undefined ) {
          alert("데이터 초기화 새로고침해 주세요.")
          return
      }
      for(let j=0; j<keys.length-1; j++) {
        if(this.properties_bak[keys[j]]===this.properties[keys[j]] ) continue
        if(keys[j] === "MIN_LEVEL" || keys[j] === "MAX_LEVEL" || keys[j] === "V_AREA") { //8,22는 기본적으로 목표수위 설정을 하여야 한다.
          var check = this.check_float(this.properties[keys[j]])
          if(this.properties[keys[j]] < 1 && !check ){
             alert("목표수위를 1이상으로 입력하여야 한다.")
             return 
          }else{
                this.properties[keys[j]] = Math.round(this.properties[keys[j]]*100)/100
          }          
        }
        params[keys[j]] = this.properties[keys[j]]        
      }
      if(Object.keys(params).length<=0) return  
      let data = {type: 'modify',ws_id:this.ws_id, fac_id:this.fac_id,params:params} ;
      try {
          let update_url = this.URL+'api/setting/waterTank/update'
          let res = await this.$http.post(update_url,data)
          if(res.data.check ===1){
              alert("fail : "+ res.data.msg) ;
          }else{
              this.search_data(1);
          }
          
      } catch (err) {
          return null
      }

      


    },
    async status_save(){
      let params = {} ; 
      var data = {} ;
      let keys = this.select_tag_id
     
      //활성화할 tag값이 변경될시 
      if(this.select_tag_id === this.select_tag_id_bak) return       
      if(this.select_tag_id  === "" || this.select_tag_id  ===  null ) {
        alert("태그를 선택하여야 합니다.")
        return
      }
      var update_url = this.URL+'api/setting/tag/update'  
      for(let j=0; j<this.tag_id_option.length; j++) {
        if(this.select_tag_id === this.tag_id_option[j].value ){
           params['TAG_STATUS'] = 'A'   
           keys =  this.tag_id_option[j].value   
           
        }else{
           keys = this.tag_id_option[j].value
           params['TAG_STATUS'] = 'I'    
        }  
        if(this.tag_id_option[j].value === undefined || this.tag_id_option[j].value === null || this.tag_id_option[j].value === ""   )  continue 
        data = {type: 'modify',id: keys, params:params} ;
        this.update_status_yn(data,update_url) 
      }           
      
      //배수지 수위태그 정보 update한다.
      
      var update_url_1 = this.URL+'api/setting/waterTankLevel/update'  
      data = {fac_id : this.fac_id, ws_id : this.ws_id , lei_tag_id : this.select_tag_id}
      this.update_status_yn(data,update_url_1)
      
      alert("정상적으로 저장되었습니다.") ;
      this.search_data(2)
   
    },        
    async update_status_yn(data,update_url){
        
        let res;
        
        
        try {
         
            res = await this.$http.post(update_url,data)
            if(res.data.check ===1){
              alert("fail : "+ res.data.msg) ;

            }else{
              //alert("정상적으로 저장되었습니다.") ;
               //this.search_data(2)
            }
         
        } catch (err) {
            return null
        }
        

    },   
    search_data(a){
      //1. 배수지 정보 가져와서 뿌려주기
      //~api/level/tag/list?ws_id=1&fac_id=4
      
      let ws_id = this.ws_id
      let fac_id = this.fac_id
      if(a < 2 ){
        this.properties = []
        this.$http.get(this.URL+'api/level/tag/list?ws_id='+ws_id+'&fac_id='+fac_id).then((res) => {  
          if(res.data.list.length >0){
              for(let i=0; i<res.data.list.length; i++) {
                var item = res.data.list[i]  
                this.properties = item
                this.properties_bak =  Object.assign({}, this.properties)
              }
          }

        });        
      }     
      //2. 배수지 스카다 수위태그 리스트 뿌려주기
      // ~api/setting/tag/list?ws_id=3&fac_id=13&page=0&perPage=20&search_term=LEI&sort_field=TAG_ID&sort_type=asc
 
     
      
      if(a === 0 || a === 2  ){
        this.tag_id_option = []
        this.select_tag_id = ""
        var tag_list = []
        this.$http.get(this.URL+'api/setting/tag/list?ws_id='+ws_id+'&fac_id='+fac_id+'&page=0&perPage=20&search_term=LEI&sort_field=TAG_NM&sort_type=asc').then((res) => {  
          if(res.data.rows.length >0){
              for(let i=0; i<res.data.rows.length; i++) {
                var item = res.data.rows[i] 
                if(item.TAG_TYPE === 'SC' ){
                  var item_arr =  {text:item.TAG_NM + " ("+item.SCADA_TAG_ID+")", value: item.TAG_ID, tag_name: item.TAG_NM ,tag_id:item.SCADA_TAG_ID, status: item.TAG_STATUS}
                  if(item.TAG_STATUS === 'A' && this.select_tag_id === ""){
                    this.select_tag_id = item.TAG_ID
                    this.select_tag_id_bak =  item.TAG_ID
                  }
                  tag_list.push(item_arr)
                }
              }
              this.tag_id_option  =  tag_list
          }

        });    
      }         
      //3. 배수지 목표수위 가져오기
       //1. 수위트렌드는 선택된 배수지의 일별 수위 운영데이터와 목표데이터 가져온다.
      //this.real_url = this.URL+'api/level/day/lineChart?s_tag_id='+this.tag+'&m_id='+this.m_id+'&tag_id='+this.tag_id+'&s_date='+this.search_date
     
      //var search_date = this.search_date
      //this.call_s_date = this.search_date
      //스카다 수위 태그 리스트를 가져온다.
      //http://ems.neverlosewater.com/api/setting/tag/list?ws_id=3&fac_id=13&page=0&perPage=20&search_term=LEI&sort_field=TAG_ID&sort_type=asc

     
      //http://ems.neverlosewater.com/api/level/day/lineChart?s_tag_id=865-338-LEI-8001&m_id=24&tag_id=565&s_date=2024-02-29&e_date=2024-03-01
      //box 데이터를 셋팅한다.
      //1. 오늘 날짜인 경우 최소 한시간 단위로 순시값 표시
      
      //3. 배수지별 수위 분포 : 배수지별 하루치 유입량을 계산해서 용량을 표시한다.
      /*
      this.$http.get(this.URL+'api/scada/get_forecaste_data_all?s_id='+ws_id + "&date=" +search_data ).then((res) => {  
        if(res.data.length >0){
            for(let i=0; i<res.data.length; i++) {
              var item = res.data[i]  
              this.items_24.push(item)
            }
        }

      });             
      */
    }

  },
  mounted() {
    //  this.$http.get(this.URL+'api/get_monitoring_list').then((res) => {
     /*
     var today = new Date();
     this.s_current_date = this.current_date(today,0);
     var today = new Date(2024,1,3,0,0,0,0);     
     this.search_date = this.current_date(today,1);
     */
    // 1. 수위 리스트 가져오기

    
    // 2.변경데이터 저장하기

     //1. 목표 수위 계산 가장 마지막 일 가져온다.     
     //this.last_date = this.current_date(today,1);
     //2. 시간대별 경부하, 중부하, 피크부하 를 가져온다.
     // EMS_LOAD_TIME_INFO

     //this.inval = setInterval(this.search_data, 1000*60*10);//15분 마다 새로고침
     //3. 배수지별 목표수위 설정 데이터 가져오기
     //최종일 계산된 목표수위 데이터 가져온다.
     this.$http.get(this.URL+'api/level/list').then((res) => {
       if(res.data.check == 0 )
         if(res.data.list.length>0){
            this.box = res.data.list
        
            this.ws_id = this.box[0].WS_ID  
            this.fac_id = this.box[0].FAC_ID
            this.tag=this.box[0].LEI_TAG
            this.tag_name = this.box[0].FAC_NM
            this.m_id = this.box[0].G_LEI_M_ID
            this.tag_id = this.box[0].G_LEI_TAG_ID
            this.lei_value_23 = this.box[0].lei_value_23     
            this.last_date = this.box[0].G_LEI_DATE    
            this.wt_properties = this.box[0]
            this.search_data(0)
            //this.real_url = this.URL+'api/level/day/lineChart?s_tag_id='+this.tag+'&m_id='+this.m_id+'&tag_id='+this.tag_id+'&s_date='+this.search_date
            
         }

     });
     
     //수위태그 리스트를 가져온다. 조건이 active 먼저 이름 sortting 첫번째 것 선택된걸로 뿌련준다
     //저장시 tag_name 으로 하나만 active해주고, 나머진 inactive한다.

     

  },
  beforeUnmount() {},
  components: {   
    glevelSet : glevelSet,
    WTLeft : WTLeft,
    ArrowMove:ArrowMove,
    //WaterLevel:WaterLevel,
   // : useTrend,
  // ScheduleItem: ScheduleItem,
    //InfiniteLoading : toVue3(InfiniteLoading),
  },
};
</script>
<style >
.level_radio .form-check-input[type=radio] {
    
    border-radius: 50%;
    visibility: visible;
    display: block;
}
.level_radio input[type=radio]:checked + label {
    color: #fff;
    background:none;
}


</style>
<style lang="scss" scoped>

.form-control, .form-control:disabled, .form-control[readonly] {
    font-size: 1rem;
}
.wt_proper {
  margin : 25px 0px;
  font-size : 17px;

}
.btn_cntl {
  
    height: 40px;
    float: right;
    //position: fixed;
    margin-right:10%;
    width: 120px; 
}
.level_radio {
 width: 80%;
 display: flex;
 flex-direction: column;
}


.s_date{
   margin-left:20px;

}
.facility__input.search{
  font-size: 13px;
  height: 30px;
  width: 100px;
  background-color: #15284e;
  padding: 0 8px ;
  margin-left:20px;
}
.facility__input.search.w-150 {
   width: 150px;
}
.input_date.m-0.btn-gray-date.white{
    border: 1px solid rgb(107, 167, 236) !important;
    border-bottom: 1px solid rgb(107, 167, 236) !important;
    background-color: #15284e;
    color: #fff;
    width: 130px;
    height: 30px;
    font-size: 13px;
    margin-left: 10px;
    font-family: KHNPHDRegular;
    text-align: center;
    margin-left:20px;

}


 input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
 }  
.contents-container.trend{
  
    background-image: url("@/assets/img/design_bg.png");
    background-size: 100% 100%;

}


  
</style>

