
<template>
	<div class="container">
		<div class="rectangle">{{ item.name }}</div>
		<div v-for="(iitem, i) in item.ho" :key="i" :class="[{ 'pump-circle active': iitem.value === 1, 'pump-circle not-active': iitem.value === 0},{'pump-hz': iitem.hz === 1 }]">{{i+1}}</div>
		<div v-for="(k, j) in 5-item.ho.length" :key="j" class="pump-circle non-circle"></div>
		
	</div>
</template>
<style scoped>
	.active {
		background-color:#eb5b5b;
	}
	.active.pump-hz {
		background-color:#f09125;
	}
	.not-active{
		background-color:#707070;
	}
	.non-circle {
		background-color:#70707000;
	}
	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 2.5% 8% 2.5% 6%;
		margin-right: 1%;
	}
	
	.rectangle {
		width: 100px;
		height: 33px;
		/*
		background-color: #123A72;
		*/
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		
		border-radius: 10px;
	    font-family: LABDigital;
    	font-size: 20px;
	}
	
	.pump-circle {
		width: 28px;
		height: 28px;
		border-radius: 50%;
		/* background-color: #EB5B5B; 
		border: 2px solid #ffffff; */
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
	}
</style>
<script>
	export default {
		props:{
			item:{
			
			},
		


		}
	}
</script>
