<template>
  <div :class="{ page_bg: !changeClass, 'sub_page_bg': changeClass }">
    <HeaderBar/>
    <!-- <MenuBar /> -->
    <MenuBar />

    <router-view @onChangeBgClass="onChangeBgClass" />
  </div>
</template>

<script>
// import MenuBar from "@/views/Common/MenuBar.vue";
import HeaderBar from "@/views/Common/HeaderBar.vue";
import MenuBar from "@/views/Common/MenuBar.vue";

export default {
  components: { HeaderBar, MenuBar },
  data() {
    return {
      changeClass: false,
    };
  },
  methods: {
    onChangeBgClass() {
      this.changeClass = !this.changeClass;
    },
  },
};
</script>
<style scoped lang="scss">
@import "assets/css/base.css";
@import "assets/scss/base/variables";
</style>
<style>

html {
  width: 100%;
  height: 100%;
}
</style>
