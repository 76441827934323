<template>
  <div style="height:100%;">
 	<div class="div_title">Water Tank</div>
	<div class="left_bg" >
		<div style="width: inherit;height: inherit;margin: 10px;overflow : auto;display: flex;flex-direction: column;justify-content: space-evenly;">
		<div :class="['fontContent',{'is--active': activeCheckIndex === WT_IDX } ]"  
		v-for="(item, WT_IDX) in wt_list" :key="WT_IDX" @click="selectBox(WT_IDX)">{{item.FAC_NM}}</div>
		
		<!--div class="fontContent" style="width: calc(100% - 4px); height: 40px; color: rgb(255, 255, 255); cursor: pointer; text-align: center; line-height: 40px; background: url(&quot;img/div_new.png&quot;) center center / 102% 110% no-repeat; border: 2px solid rgba(255, 255, 255, 0.54);" id="basujiList_0" onclick="selectBtn(&quot;745-617-LEI-8965&quot;,&quot;0&quot;,&quot;14&quot;)">남양6_한계수위도달</div>
		 <div class="fontContent" style="width:calc(100% - 4px);height:40px;color:#fff;cursor:pointer; text-align: center;line-height:40px" id="basujiList_1" onclick="selectBtn(&quot;745-617-LEI-8965&quot;,&quot;1&quot;,&quot;14&quot;)">남양6</div>
		 <div class="fontContent" style="width:calc(100% - 4px);height:40px;color:#fff;cursor:pointer; text-align: center;line-height:40px" id="basujiList_2" onclick="selectBtn(&quot;745-617-LEI-8982&quot;,&quot;2&quot;,&quot;14&quot;)">봉담2</div>
		 <div class="fontContent" style="width:calc(100% - 4px);height:40px;color:#fff;cursor:pointer; text-align: center;line-height:40px" id="basujiList_3" onclick="selectBtn(&quot;745-617-LEI-8856&quot;,&quot;3&quot;,&quot;14&quot;)">남양6신설</div>
		 <div class="fontContent" style="width:calc(100% - 4px);height:40px;color:#fff;cursor:pointer; text-align: center;line-height:40px" id="basujiList_4" onclick="selectBtn(&quot;745-617-LEI-8984&quot;,&quot;4&quot;,&quot;14&quot;)">마도</div>
		 <div class="fontContent" style="width:calc(100% - 4px);height:40px;color:#fff;cursor:pointer; text-align: center;line-height:40px" id="basujiList_5" onclick="selectBtn(&quot;745-617-LEI-8950&quot;,&quot;5&quot;,&quot;14&quot;)">배양5</div>
		 <div class="fontContent" style="width:calc(100% - 4px);height:40px;color:#fff;cursor:pointer; text-align: center;line-height:40px" id="basujiList_6" onclick="selectBtn(&quot;745-617-LEI-8988&quot;,&quot;6&quot;,&quot;14&quot;)">배양6</div>
		 <div class="fontContent" style="width:calc(100% - 4px);height:40px;color:#fff;cursor:pointer; text-align: center;line-height:40px" id="basujiList_7" onclick="selectBtn(&quot;745-617-LEI-8975&quot;,&quot;7&quot;,&quot;14&quot;)">광평</div>
		 <div class="fontContent" style="width:calc(100% - 4px);height:40px;color:#fff;cursor:pointer; text-align: center;line-height:40px" id="basujiList_8" onclick="selectBtn(&quot;745-617-LEI-8960&quot;,&quot;8&quot;,&quot;14&quot;)">비봉</div>
		 <div class="fontContent" style="width:calc(100% - 4px);height:40px;color:#fff;cursor:pointer; text-align: center;line-height:40px" id="basujiList_9" onclick="selectBtn(&quot;745-617-LEI-8945&quot;,&quot;9&quot;,&quot;14&quot;)">동화</div>
         <div class="fontContent" style="width:calc(100% - 4px);height:40px;color:#fff;cursor:pointer; text-align: center;line-height:40px" id="basujiList_10" onclick="selectBtn(&quot;745-617-LEI-8852&quot;,&quot;10&quot;,&quot;14&quot;)">남양5</div>
		 <div class="fontContent" style="width:calc(100% - 4px);height:40px;color:#fff;cursor:pointer; text-align: center;line-height:40px" id="basujiList_11" onclick="selectBtn(&quot;745-617-LEI-8900&quot;,&quot;11&quot;,&quot;14&quot;)">문호</div>
		 <div class="fontContent" style="width:calc(100% - 4px);height:40px;color:#fff;cursor:pointer; text-align: center;line-height:40px" id="basujiList_12" onclick="selectBtn(&quot;745-617-LEI-8940&quot;,&quot;12&quot;,&quot;14&quot;)">상리</div>
		 <div class="fontContent" style="width:calc(100% - 4px);height:40px;color:#fff;cursor:pointer; text-align: center;line-height:40px" id="basujiList_13" onclick="selectBtn(&quot;745-617-LEI-8934&quot;,&quot;13&quot;,&quot;14&quot;)">능5</div-->
	  </div>	
	</div>
  </div>
</template>

<script>
export default {
    props:{
	    wt_list : {type: null},
	    activeCheckIndex: {type: null },
	},
	data(){
		return {

		}
	},
	methods:{
		selectBox (index) {
      		this.$emit('updateCheckIndex', index)
    	},
	}
}
</script>

<style  lang="scss"  scoped>
.left_bg{
    height: 100%;
    padding: 5%;
    background: url('@/assets/img/analysis/table_bg_03.png');
    background-size: 100% 100%;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    justify-content: center;
    }
.fontContent {
	font-family: KHNPHDRegular;
    font-size: 18px;
    color: white;
    text-shadow: 0 0 9px #5cafff;
	width: calc(100% - 4px);
    height: 40px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    border: none;
}
.fontContent.is--active {
   background: url('@/assets/img/div_new.png') center center / 102% 110% no-repeat;
   border: 2px solid rgba(255, 255, 255, 0.54);
}	
</style>