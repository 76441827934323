<template>
  <div>
    <div class="mx-5" style="display: block;margin-top:20px; ">
      <!-- 전력소비 & 전력절감-->
      <b-row>
        <b-col class="position-relative">
          <div
            class="position-absolute bottom-0 start-0 px-3"
            :style="{ width: '416px', top: '0px !important' }"
          >
            <!-- 소비 현황 시작 -->
            <MainDataViewRotation :dataType="useData" />
            <!-- 소비 현황 끝 -->
            <!-- 절감 현황 시작 -->
            <MainDataViewRotation :dataType="optiData" />
            <MainDataViewRotation :dataType="optiData2" />
            <!-- 절감 현황 끝 -->
          </div>
           <div
            style="position:absolute; top:0px; right:18%;z-index:1;"
            :style="{ width: '320px', top: '0px !important' }"
          >
           <div>
            <button :class="['map button',{'is--active': isFirst}]" type="button"  v-b-tooltip.hover @click="change_btn('ps',1)">Power<br/>Consumption(kW)</button> 
            <!--button class="map button active" type="button"  v-b-tooltip.hover @click="change_btn">소비전력(kW)</button-->      
            <button class="map button" type="button"  v-b-tooltip.hover @click="change_btn('ps',2)">Water<br/>Production(㎥/hr)</button>      
           </div>
           <div> 
            <button class="map button " style="margin-top:5px;"   type="button"  v-b-tooltip.hover @click="change_btn('level',3)">Water Level<br/>(m)</button> 
            <button class="map button" type="button"  v-b-tooltip.hover @click="change_btn('level',4)"><span style="top;-5px;">Achievement<br/> Rate(%)</span></button> 
            <!--button class="map button" type="button"  v-b-tooltip.hover >원단위</button--> 
           </div>

          </div>
           <div
            style="position:absolute; top:0px; right:-30px; z-index:1;height:70%;   "
            :style="{ width: '19%',top: '0px !important' }"
          >
           <div class="bg_data" >
            <b-row><span class="right_title_font fL" style="width: 100%; height: 34px; cursor: pointer;text-align:center;margin-top:25px; font-size:14pt; " 
            onclick="location.href='analysis'"> | Pumping Station |</span>
            </b-row>
            <!--b-row v-for="(items, index) in pumpdata" :key="index" style="margin : 10px 0 0 40px;" -->
              <!--b-col xl="3" style="padding:0;"> <span style="font-family: LABDigital; font-size: 20px;">{{items.name}}</span></b-col>
              <b-col xl="9">
                <div class="status_circle" style="right: 0px; top: 0px;">
                <ul >
                  <li v-for="(item, i) in items.ho" :key="i" >
                    <a  href="#" :class = "item.value === 0 ? 'circle1 color_01' : 'circle1 color_03'" >{{i+1}}</a>
                  </li>
                
                </ul>
              </div></b-col-->
            <b-row style="padding: 0 20px;">
               <b-col xl="12">   
                 <PumpComponent :item = items v-for="(items, index) in pumpdata" :key="index" />
            <!--PumpComponent name="원호생활" :pump_0="true" :pump_1="false" :pump_2="false" :pump_3="false" />
						<PumpComponent name="국가산단" :pump_0="true" :pump_1="false" :pump_2="false" :pump_3="false" />
						<PumpComponent name="4공단공업" :pump_0="true" :pump_1="false" :pump_2="false" :pump_3="false" />
						<PumpComponent name="신평공업" :pump_0="true" :pump_1="false" :pump_2="false" :pump_3="false" />
						<PumpComponent name="신평생활" :pump_0="true" :pump_1="false" :pump_2="false" :pump_3="false" />
						<PumpComponent name="선산(가)" :pump_0="true" :pump_1="false" :pump_2="false" :pump_3="false" />
						<PumpComponent name="김천(가)" :pump_0="true" :pump_1="false" :pump_2="false" :pump_3="false" />
						<PumpComponent name="북상(가)" :pump_0="true" :pump_1="false" :pump_2="false" :pump_3="false" />
						<PumpComponent name="해평(가)" :pump_0="true" :pump_1="false" :pump_2="false" :pump_3="false" /-->
               </b-col>
            </b-row>  

				
					
	
          </div>

         </div>
 
        </b-col>
      </b-row>
      <!-- //전력소비 & 전력절감-->
 
      <!-- 3D MAP 영역 -->
      <b-row
        class="align-items-center map"
        :style="{
          width: '85%',
          height: 'calc(100vh - 10px)',
          margin: '0 auto',
         
        }"
      >
        <!--MapContents></MapContents-->
        	<MapDashboard style="position:absolute; top:50px; left:8.1%; z-index:0;"
          :ps_data = "ps_data" 
          :g_level_data = "gLevelPer_url" 
          :g_level_data1 = "gLevelPer_url1" 
          :unit_1 ="unit_1" 
          :unit_2 ="unit_2"  />
      
      </b-row>

      <div 
            style="position:absolute; top:75%; right:0px; width:350px; border: solid 1px #2b89e97a; margin:0 5px;z-index:1;    background-color: #0f1d3c69; "
            :style="{ width: '23%',top: '70% !important' }"
          > 
            <div class="div_title">Power Consumption</div>
              <div id="donut" class="items4ChartArea js-plotly-plot" style="  height: calc(100% - 40px);  width: 100%;margin-left: -40px;">
                <div class="plot-container plotly">  
                  <!--major-item :ps_id = "ps_id" :ws_id="ws_id"></major-item-->
                  <useTrend  :height_size="chart_height" ></useTrend>
                </div>
              </div>    
           <!--hover-popup :hoverVal="hoverVal"/-->
      </div>
      <div class="main leftchart" >       
        <gLevelChart :chartData="chartData" :xdata = "xdata"></gLevelChart>
      </div>
      <!-- //3D MAP 영역 
      <b-row class="row-cols-3 gx-3">
        <PumpRight></PumpRight>
        <MajorRight></MajorRight>
        <PeakRight></PeakRight>
      </b-row>
      -->
    </div>
  </div>
</template>

<script>
import MapDashboard from '@/components/MapDashboard.vue'
import useTrend from "@/components/chart/PieChart2.vue";
import gLevelChart from "@/components/chart/gLevelChart.vue";
import MainDataViewRotation from "@/views/DashBoard/DashBoard/MainSmallComponents/MainDataViewRotation.vue";
import PumpComponent  from "@/components/objects/PumpComponent.vue";
export default {
  components: {
    MainDataViewRotation,
    //MapContents,
    useTrend,
    MapDashboard,
    gLevelChart,  
    PumpComponent
  },
  data() {
    const useData = {
      title: "Power",
      title2: "Consump.",
      subTitle: "Achievement",
      subValue: 0,
      subUnit: "kW",      
      color: "blue_round",
      innerData: [
        { title: "Current day", value: 0, unit: "kWh", sub: { title: "Rate", value: 0, unit: "%" }, },
        { title: "Current Month", value: 0, unit: "kWh", sub: { title: "Rate", value: 0, unit: "%" }, },
        { title: "Current Year", value: 0, unit: "kWh", sub: { title: "Rate", value: 0, unit: "%" }, },
        { title: "Prev Day", value: 0, unit: "kWh", sub: { title: "Rate", value: 0, unit: "%" }, },
      ],
    };
    const optiData = {
      title: "Energy",
      title2: "Saving",
      subTitle: "Energy Saving",
      subValue: 0,
      subUnit: "kW",
      color: "green_round",
      innerData: [
        { title: "Current day", value: "1,762", unit: "kWh" },
        { title: "Current Month", value: "7,549", unit: "kWh" },
        { title: "Current Year", value: "10,582", unit: "kWh" },
        { title: "Prev Day", value: "1,762", unit: "kWh" },
      ],
    };
    const optiData2 = {
      subTitle: "Carbon Reduction",
      subValue: 0,
      subUnit: "kW",
      innerData: [
        { title: "Current day", value: 23.5, unit: "kg" },
        { title: "Current Month", value: 122.68, unit: "kg" },
        { title: "Current Year", value: 180.22, unit: "kg" },
        { title: "Prev Day", value: 23.5, unit: "kg" },
      ],
    };

    const pumpdata =  [
        { name: "WHS", ho: [{p_no : 1,value: 0, hz: ""},{p_no : 2,value: 1, hz: ""},{p_no : 3,value: 1, hz: ""}] }, //0 : on/ 1: off
        { name: "KGSD", ho: [{p_no : 1,value: 0, hz: ""},{p_no : 2,value: 0, hz: ""},{p_no : 3,value: 1, hz: ""},{p_no : 4,value: 1, hz: ""},{p_no : 5,value: 1, hz: ""}] },
        { name: "4GDG", ho: [{p_no : 1,value: 1, hz: ""},{p_no : 2,value: 1, hz: ""},{p_no : 3,value: 0, hz: ""},{p_no : 4,value: 0, hz: ""},{p_no : 5,value: 1, hz: ""}] },
        { name: "SSG", ho: [{p_no : 1,value: 0, hz: ""},{p_no : 2,value: 1, hz: ""},{p_no : 3,value: 1, hz: ""},{p_no : 4,value: 1, hz: ""}] },
        { name: "SPS", ho: [{p_no : 1,value: 0, hz: ""},{p_no : 2,value: 0, hz: ""},{p_no : 3,value: 1, hz: ""},{p_no : 4,value: 1, hz: ""}] },
        { name: "SSG", ho: [{p_no : 1,value: 0, hz: ""},{p_no : 2,value: 1, hz: ""},{p_no : 3,value: 0, hz: ""}] },
        { name: "KCG", ho: [{p_no : 1,value: 0, hz: ""},{p_no : 2,value: 1, hz: ""},{p_no : 3,value: 0, hz: ""},{p_no : 4,value: 1, hz: ""}] },
        { name: "BSG", ho: [{p_no : 1,value: 1, hz: ""},{p_no : 2,value: 0, hz: ""},{p_no : 3,value: 1, hz: ""},{p_no : 4,value: 1, hz: ""}] },
        { name: "HPG", ho: [{p_no : 1,value: 0, hz: ""},{p_no : 2,value: 1, hz: ""}] },
    ]; 
    const color = ["blue","blue","blue","blue"];
    return {
      chart_height : 'large1', 
      isFirst : true,
      gLevelPer_url :[
              {value : 2.5 ,name:	'신평공업' }, 
              {value : 2.5 ,name:	'4공단공업' }, 
              {value : 2.5 ,name:	'해평' },
              {value : 2.5 ,name:	'4공단생활' }, 
              {value : 2.5 ,name:	'확장단지', }, 
              {value : 2.5 ,name:	'원호', }, 
              {value : 2.5 ,name:	'선산(구)', }, 
              {value : 2.5 ,name:	'선산(신)', }, 
              {value : 2.5 ,name:	'신평생활', }, 
              {value : 2.5 ,name:	'북삼', }, 
              {value : 2.5 ,name:	'아포', }, 
              {value : 2.5 ,name:	'혁신', }, 
              {value : 2.5 ,name:	'김천', }, 
              {value : 2.5 ,name:	'문성', }, 
      ],
      gLevelPer_url1 :[
              {value : 2.5 ,name:	'해평' }, 
              {value : 2.5 ,name:	'문성' }, 
              {value : 2.5 ,name:	'초곡' }, 
              {value : 2.5 ,name:	'하이테크', }, 
      ],
     
      useData,
      optiData,
      optiData2,
      pumpdata,
      color,
      ps_data : [
              {value : 210 ,name:	'SPG' }, 
              {value : 470 ,name:	'WHS' }, 
              {value : 120 ,name:	'SSG' }, 
              {value : 440 ,name:	'KGSD', }, 
              {value : 370 ,name:	'KCG', }, 
              {value : 320 ,name:	'BSG', },              
              {value : 230 ,name:	'4GDG', }, 
              {value : 1100 ,name:	'SPS', }, 
      ],
      ps_data_pwi : [],
      ps_data_flw : [],
      level_data : [],
      level_data1 : [],
      g_level_data:[],
      g_level_data1 :[
          {value : '-' ,name:	'해평' }, 
          {value : '-' ,name:	'문성' }, 
          {value : '-' ,name:	'초곡' }, 
          {value : '-' ,name:	'하이테크', }, 
      ],
      chartData : [],
      x_data : [],
      unit_1:"kW",
      unit_2:"m",
      hoverVal:{title:"피크전력 대비 최대 소비 전력 비율", mainValue:400, values:[
         {title:'구미(정)',value:'80%'},
         {title:'선산(가)',value:'88%'},
         {title:'김천(가)',value:'82%'},
         {title:'북산(가)',value:'85%'},
      ]}
    };
  },
  methods: {
    change_btn(button,type) {

      this.isFirst = false

      //지도원표시 
      if (button === 'ps') { //1 : 소비전력, 2: 송수유량 3: 배수지 수위, 4: 목표수위
        document.getElementById('circle-1').style.display = 'block';
        document.getElementById('circle-2').style.display = 'none';
      } else {
        document.getElementById('circle-1').style.display = 'none';
        document.getElementById('circle-2').style.display = 'block';
      }

      if(type==1){     
         this.ps_data = this.ps_data_pwi;
         this.unit_1 = 'kW';
      }else if(type==2){
         this.ps_data = this.ps_data_flw;
         this.unit_1 = '㎥/hr';
      }else if(type==3){ //수위,
        this.gLevelPer_url = this.level_data
        this.gLevelPer_url1 = this.level_data1
        this.unit_2 = 'm'
      }else{ //목표율 
        this.gLevelPer_url = this.g_level_data
        this.gLevelPer_url1 = this.g_level_data1
        this.unit_2 = '%'

      }
    },
    set_level_data1(){
        this.level_data1 =[] ;
     
        var url = this.URL+'api/level/GLevelPercent?type=1'
        this.$http.get(url).then((res) => {

            var b_data = res.data.list;
            if( b_data !=="" && b_data != null) {
                var ccdata = []; //지도 수위 데이터
             
                var size = b_data.length 
                for(let i=0; i < size ; ++i){
                    ccdata.push({value: b_data[i].hp_l_level, name : 'HP'});
                    ccdata.push({value: b_data[i].ms_l_level, name : 'MS'});
                    ccdata.push({value: b_data[i].cg_l_level, name : 'CG'});
                    ccdata.push({value: b_data[i].ht_l_level, name : 'HIT'});
                }
        
                this.level_data1 = ccdata ;
                this.gLevelPer_url1 = ccdata;
            }else{
                this.level_data = [];
            
                
            }
        })
        .catch((error) => {
            // this.loaded = true;
           this.level_data = [];
        });
    },
    //
    set_level_data(){
        this.g_level_data =[] ;
        this.level_data =[] ;
        this.chartData = [] ;
        this.xdata = [];
        var url = this.URL+'api/level/GLevelPercent'
        var eng_wt_name = {'신평공업배수지':'SPG_WT','4공단공업배수지': '4GDG_WT', '4공단생활배수지':'4GDS_WT','확장단지배수지':'HJD_WT','원호배수지':'WH_WT',
                        '선산구배수지' :'SSO_WT','선산신배수지':'SSN_WT','신평생활배수지':'SPS_WT','북삼조절지':'BS_WT','아포배수지':'AP_WT',
                        '혁신배수지':'HS_WT','김천배수지':'GC_WT','해평배수지':'HP_WT','문성배수지':'MS_WT'
                       }
        this.$http.get(url).then((res) => {

            var b_data = res.data.list;
            if( b_data !=="" && b_data != null) {
                var ccdata = []; //지도 데이터
                var ccdata1 = []; //지도 데이터
                var gcdata = []; //chart - x
                var xcdata = []; //chart name
                var size = b_data.length 
                for(let i=0; i < size ; ++i){
                    // xcdata.push(b_data[i].RCV_DT)
                    var name =  eng_wt_name[b_data[i].FAC_NM]
                    xcdata.push(name)
                    gcdata.push(b_data[i].g_level_per);    
                    
                    name = name.substr(0, name.length-3)
                    ccdata.push({value: b_data[i].l_level, name : name});
                    ccdata1.push({value: b_data[i].g_level_per,name : name});
                }
                this.chartData = gcdata ;
                this.g_level_data = ccdata1 ;
                this.gLevelPer_url = ccdata1;
                this.level_data = ccdata ;
                this.xdata = xcdata ;
            }else{
                this.xdata = [];
                this.chartData = [] ;
                
            }
        })
        .catch((error) => {
            // this.loaded = true;
            this.xdata = [];
            this.chartData = [] ;           
            this.loaded_ts_flow = true
            console.log(error)
        });
    },
    set_ps_data(){
         this.ps_data_pwi =[]
         this.ps_data_flw =[]
          // this.xdata = [];
          var url = this.URL+'api/pump/ps_lastdata'
          var eng_ps_name = {'신평공업 송수펌프동':'SPG','원호생활 송수펌프동': 'WHS', '선산 가압장':'SSG','국가산단/4공단생활':'KGSD','김천 가압장':'KCG',
                        '북삼 가압장' :'BSG','4공단공업 송수펌프동':'4GDG','신평생활 송수펌프동':'SPS'
                       }
          this.$http.get(url).then((res) => {

              var b_data = res.data.list;
              if( b_data !=="" && b_data != null) {
                  var ccdata = [];
                  var xcdata = [];
                  var size = b_data.length 
                  for(let i=0; i < size ; ++i){
                      // xcdata.push(b_data[i].RCV_DT)
                      var name = eng_ps_name[b_data[i].FAC_NM]
                      ccdata.push({value: b_data[i].PWI_VALUE, name : name});
                      xcdata.push({value: b_data[i].FRI_VALUE, name : name});
                  }
                  this.ps_data_pwi = ccdata ;
                  this.ps_data_flw = xcdata ;
                  this.ps_data = ccdata ;
                  this.unit_1 = 'kW';
              }else{
                  this.ps_data_pwi = [];
                  this.ps_data_flw = [] ;
                  
              }
          })
          .catch((error) => {
              // this.loaded = true;
              this.ps_data_pwi = [];
              this.ps_data_flw = [] ;
              console.log(error)
          });
    },
    set_pump_data(){
         // this.pumpdata =[]
         
          var url = this.URL+'api/pump/lastdata'
          this.$http.get(url).then((res) => {

              var b_data = res.data.list;
              if( b_data !=="" && b_data != null) {
                  var ccdata1 = []; var ccdata2 = [];  var ccdata3 = [];  var ccdata4 = [];  var ccdata4_1 = [];  
                  var ccdata5 = []; var ccdata6 = [];  var ccdata7 = [];  var ccdata8 = [];  
                                  
                  var size = b_data.length 
                  for(let i=0; i < size ; ++i){                   
                    if( b_data[i].GRP_IDX === 1 ){ //신평공업      
                      ccdata1.push({value: b_data[i].scada_act_yn, name : b_data[i].FAC_NM, hz:b_data[i].scada_inverter_yn});
                    }else if( b_data[i].GRP_IDX === 2 ){ //원호생활
                      ccdata2.push({value: b_data[i].scada_act_yn, name : b_data[i].FAC_NM, hz:b_data[i].scada_inverter_yn});
                    }else if( b_data[i].GRP_IDX === 3 ){ //선산
                      ccdata3.push({value: b_data[i].scada_act_yn, name : b_data[i].FAC_NM, hz:b_data[i].scada_inverter_yn});
                    }else if( b_data[i].GRP_IDX === 4 ){ //국가산단
                      if(b_data[i].FAC_NM.indexOf('해평')>-1){
                        ccdata4_1.push({value: b_data[i].scada_act_yn, name : b_data[i].FAC_NM, hz:b_data[i].scada_inverter_yn});
                      }else{
                        ccdata4.push({value: b_data[i].scada_act_yn, name : b_data[i].FAC_NM, hz:b_data[i].scada_inverter_yn});
                      }
                    }else if( b_data[i].GRP_IDX === 5 ){ //김천가압장
                      ccdata5.push({value: b_data[i].scada_act_yn, name : b_data[i].FAC_NM, hz:b_data[i].scada_inverter_yn});
                    }else if( b_data[i].GRP_IDX === 6 ){ //북삼가압장
                      ccdata6.push({value: b_data[i].scada_act_yn, name : b_data[i].FAC_NM, hz:b_data[i].scada_inverter_yn});
                    }else if( b_data[i].GRP_IDX === 7 ){ //4공단공업
                      ccdata7.push({value: b_data[i].scada_act_yn, name : b_data[i].FAC_NM, hz:b_data[i].scada_inverter_yn});
                    }else if( b_data[i].GRP_IDX === 8 ){ //신평생활
                      ccdata8.push({value: b_data[i].scada_act_yn, name : b_data[i].FAC_NM, hz:b_data[i].scada_inverter_yn});
                    }
                  }

                  this.pumpdata[0].ho = ccdata2 ; //원호
                  this.pumpdata[1].ho = ccdata4 ; //국가산단
                  this.pumpdata[2].ho = ccdata7 ; //4공단공업
                  this.pumpdata[3].ho = ccdata1 ; //신평공업
                  this.pumpdata[4].ho = ccdata8 ; //신평생활
                  this.pumpdata[5].ho = ccdata3 ; //선산(가)
                  this.pumpdata[6].ho = ccdata5 ; //김천(가)
                  this.pumpdata[7].ho = ccdata6 ; //북삼(가)                
                  this.pumpdata[8].ho = ccdata4_1 ; //해평   
              }else{
                
                  this.pumpdata = [] ;
                  
              }
          })
          .catch((error) => {
              // this.loaded = true;
             //alert(error)
             // this.chartData = [] ;           
           
          });
    },
    search_data(){
      //pump_data set
      //ps_data set      
      //level_data set
      
    }
  },
   mounted() {
      this.isFirst = true
      //this.gLevelPer_url =this.URL+'api/level/GLevelPercent'
      this.set_level_data();
      this.set_level_data1();
      this.set_ps_data();
      this.set_pump_data();
   }
};
</script>

<style scoped >
/*
.align-items-center.map{
   background-image: url(@/assets/img/dashboard/pipe6.png);
   background-size : cover  ;
}
*/
.main.leftchart{
  position:absolute;  
  margin-left:-20px;
  
  z-index:1;
  width: 18%;  
  top: 50% !important;
  min-width: 370px;
}
.header.position-relative{
  z-index: 1;
}
.position-absolute.bottom-0.start-0.px-3{
  margin-left: -40px;
  z-index: 1;
}
button.map.button {
    margin: 0 10px 10px;
    width: 140px;
    height: 50px;
    /*background-color: #18513D;*/
    font-size: 12pt;
    background-color: #203864;
    border-radius: 5px;   
    line-height: 1.2rem;
    color: #fff;
}
button.map.button.is--active {
  background-color: #0f97be;
}
button.map.button:active, button.map.button:hover, button.map.button:focus {
 
    background-color: #0f97be;
    caret-color: rgb(255, 255, 255); 
    color: rgb(255, 255, 255);
   
    
}
.bg_data{
   background: url(@/assets/img/00_table_bg_long.png) no-repeat; 
   background-position: center;
   background-size: 100% 104%;
   height : 58vh;
}
.hoverInfo1 {
   width: 540px;
   height: 200px;
   position: absolute;
   float: left;
   display: none;
}          
</style>

<style scoped lang="scss">
.status_circle > div {
    // float: left;
    width: 11.5%;
    font-size: 12px;
    text-align: center;
    position: relative;
    display: inline-block;
    width: 24px; 
    
}
// .status_circle > li a, 
.status_circle > div::after {
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #707070;
    display: block;   
    left: 50%;
    top: 10px;
    -webkit-transform: translate(-45%,0);
    transform: translate(-45%,0);
}
.status_circle > div {
    // float: left;
    width: 11.5%;
    font-size: 12px;
    text-align: center;
    position: relative;
    display: inline-block;
    width: 24px; 
    
}

.status_circle ul{
  display: flex;
    padding-left: 0;
    list-style: none;
  //  position: absolute;
    bottom: 0;
    right: 0;
}
.status_circle ul li{
  display: list-item;
    text-align: -webkit-match-parent;
    margin-right: 16px;
    list-style: none;
}
.status_circle ul li a{
  display: block; 
    line-height: 0.6 !important;
    position: relative;
    display: block;
    padding: 9px;
    font-size: 12px;
    z-index: 3;
    color: #fff;
    border-radius: 20px;
    background-color: #707070;
    // border-color: #28AF81;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    line-height: 0.6 !important;
    line-height: 0.6 !important;
}
.status_circle .color_01{
                    background: #EB5B5B;
                }
.status_circle .color_02{
                    background: #638CFB;
                }

      
</style>                
