<template>
    <MenuItem menuImg="icon/setting.png" menuKey="100" menuTitle="Management"/>
    
    <MenuItem menuImg="icon/AUTO_MENU/alarm.png" menuTitle="Alarm History"  @handleMenuClick=handleMenuClick menuKey="1" />
    <MenuItem menuImg="icon/AUTO_MENU/monitor.png" menuTitle="Monitoring"  @handleMenuClick=handleMenuClick menuKey="1" />
        
</template>

<script>
import MenuItem from '../MenuItem.vue'
export default {
    components: { MenuItem }

}
</script>

<style>

</style>